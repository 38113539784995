import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import WrapupSubmitButton from '../wrapup/wrapup_submit_button';
import ForwardButton from '../wrapup/ForwardButton';
import styles from './home.scss';
import Tasks from './tasks';
import Details from '../details/index';
import Wrapup from '../wrapup';
import { BoundaryError } from '../reusable_components/boundaryerror';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '../reusable_components/Expansions';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const Home = props => {
  const {
    helpers,
    taskState,
    templateState,
    wrapupState,
    flags,
    emailState,
    enable_sms_forwarding,
  } = props;
  const { detailView, tab, tasks, archived, filterTaskType } = taskState;
  const { getTaskByTaskId, mergeClasses } = helpers;
  const { wrapup } = wrapupState;

  const list = helpers.getTaskList({ tasks, archived, filterTaskType });

  let detailedViewItem = detailView ? getTaskByTaskId(detailView, list) : null;

  detailedViewItem =
    detailedViewItem && detailedViewItem.class_type === 'OFFER'
      ? null
      : detailedViewItem;

  let { dont_allow_save_and_archive } =
    (detailedViewItem &&
      detailedViewItem.template &&
      (templateState[detailedViewItem.template] || {})) ||
    {};

  return (
    <div id="home" className={styles.home}>
      <BoundaryError>
        <Tasks {...props} />
      </BoundaryError>
      <div
        id="blue-bar"
        className={mergeClasses(
          styles.blue_bar,
          detailView ? styles.display_detail : ''
        )}
      ></div>
      <div
        id="info_column"
        className={mergeClasses(
          styles.info_column,
          detailView ? styles.display_detail : ''
        )}
      >
        {detailedViewItem ? (
          <BoundaryError>
            <Details
              {...props}
              task={detailedViewItem}
              template={
                (templateState || {})[(detailedViewItem || {}).template]
              }
            />
          </BoundaryError>
        ) : null}
        {detailedViewItem && (
          <>
            {['archived', 'tasks', 'sms', 'email'].includes(tab) &&
            ((detailedViewItem.wrap_up && detailedViewItem.wrap_up != {}) ||
              wrapup[detailedViewItem.id]) ? (
              <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandLessIcon className={styles.expand_icon} />}
                >
                  WRAP UP
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                  <BoundaryError>
                    <Wrapup {...props} task={detailedViewItem} />
                  </BoundaryError>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : null}
            {(tab === 'tasks' || 'sms' || 'email') &&
            detailedViewItem &&
            detailedViewItem.status === 'ASSIGNED' &&
            !dont_allow_save_and_archive ? (
              <div className={styles.submit_wrapper}>
                <BoundaryError>
                  <WrapupSubmitButton task={detailedViewItem} tab={tab} />
                </BoundaryError>
                {detailedViewItem.type.toUpperCase() === 'SMS' &&
                  enable_sms_forwarding && (
                    <BoundaryError>
                      <ForwardButton
                        {...props}
                        task={detailedViewItem}
                        tab={tab}
                      />
                    </BoundaryError>
                  )}
              </div>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { integrationState } = state;
  const { enable_sms_forwarding } = integrationState;
  return {
    emailState: state.emailState,
    enable_sms_forwarding,
  };
};

export default connect(mapStateToProps)(withLDConsumer()(Home));
