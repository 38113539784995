import React from 'react';
import styles from './callError.scss';
import { connect } from 'react-redux';
import {
  setErrorClickedInLocalStorage,
  getCallErrorMsgById,
} from '../../utils';
import { updateCallError } from '../../store/action/task';

const CallError = ({ call = {}, callError = [], updateCallError }) => {
  const displayError = getCallErrorMsgById(call.id, callError) || {};

  return (
    <div className={styles.call_error_box}>
      <div className={styles.call_error_title}>CALL ERROR</div>
      <span className={styles.call_error_type}>{displayError.errorType}</span>
      <div className={styles.call_error_block}>
        <span className={styles.call_error_user_role}>
          {displayError.role ? displayError.role : 'User'} {': '}
          <span className={styles.call_error_user_id}>
            {displayError.userId}
          </span>
        </span>
        <span className={styles.call_error_task}>
          {displayError.taskId && 'TASK ID : '}
          <span className={styles.call_error_task_id}>
            {displayError.taskId}
          </span>
        </span>
      </div>
      <button
        className={styles.call_error_cancel}
        onClick={async e => {
          e.preventDefault();
          e.stopPropagation();
          let call_id;
          (callError || []).forEach(item => {
            if (item[call.id] && item[call.id].callId === call.id) {
              item[call.id].displayError = false;
              call_id = call.id;
            }
          });

          if (call_id) {
            setErrorClickedInLocalStorage(call_id);
          }
          updateCallError({ callError });
        }}
      >
        CANCEL
      </button>
    </div>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { callError } = state.taskState;

  return {
    callError,
  };
};

const mapDispatchToProps = dispatch => ({
  updateCallError: state => {
    dispatch(updateCallError(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CallError);
