export const updateScriptByName = ({
  name,
  html,
  text,
  scriptType,
  task_id,
}) => {
  return {
    type: 'UPDATE_SCRIPT_BY_NAME',
    name,
    html,
    text,
    scriptType,
    task_id,
  };
};

export const updateScriptSelection = ({ name, scriptType, task_id }) => {
  return {
    type: 'UPDATE_SCRIPT_CURRENT_SELECTION',
    name,
    scriptType,
    task_id,
  };
};
