export const updateSidebar = sidebarState => {
  return {
    type: 'UPDATE_SIDEBAR',
    sidebarState,
  };
};

export const expandSidebar = expand => {
  return {
    type: 'EXPAND_SIDEBAR',
    expand,
  };
};

export const collapseSidebar = () => {
  return {
    type: 'COLLAPSE_SIDEBAR',
  };
};
