export const sidebarState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_SIDEBAR': {
      const newState = { ...state, ...action.sidebarState };
      return newState;
    }

    case 'EXPAND_SIDEBAR': {
      const { expand } = action;
      const newState = { ...state, ...expand };
      return newState;
    }

    case 'COLLAPSE_SIDEBAR': {
      const newState = { ...state, expand: false };
      return newState;
    }

    default:
      return state;
  }
};
