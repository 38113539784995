export const loggingState = (state = {}, action) => {
  if (action.type === 'UPDATE_LOG_GENESYS_WS_MESSAGE') {
    const newState = { ...state, logGenesysWSMessage: action.flag };
    return newState;
  }
  if (action.type === 'UPDATE_LOG_CONTROLLER_WS_MESSAGE') {
    const newState = { ...state, logControllerWSMessage: action.flag };
    return newState;
  }
  if (action.type === 'UPDATE_SEND_CONTROLLER_WS_MESSAGE') {
    const newState = { ...state, sendGenesysWSMessageInterval: action.flag };
    return newState;
  }

  return state;
};
