import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import {
  FormControl,
  FormControlLabel,
  Checkbox,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  updateTeamArgsByName,
  updateTeamWithUsers,
  showActiveCallUsersInTeam,
} from '../../../store/action/team';
import { fetchTeam } from '../../../services';

const useStyles = makeStyles(theme => ({
  formControl: {
    flexDirection: 'row',
    display: 'flex',
    borderTop: '1px dotted #fff',
    marginTop: theme.spacing(2),
    flexWrap: 'wrap',
  },
  root: {
    color: '#e7474e',
  },
  checked: {
    color: '#e7474e !important',
  },
  indicator: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  textRoot: {
    '& .MuiFormLabel-root': {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    },
  },
}));

export const TeamFilter = ({
  args = {},
  list = {},
  showActiveCallUsers,
  updateTeamArgsByName,
  updateTeamWithUsers,
  showActiveCallUsersInTeam,
  flags,
}) => {
  const classes = useStyles();

  const handleFilterName = e => {
    updateFilterByName({ name: e.target.name, value: e.target.checked });
  };

  const updateFilterByName = ({ name, value }) => {
    updateTeamArgsByName({ name, value });
    setTimeout(
      () =>
        fetchTeam({ ...args, [name]: { ...args[name], value } }).then(data => {
          data.length && updateTeamWithUsers(data);
        }),
      1000
    );
  };

  return (
    <FormControl className={classes.formControl}>
      <FormControlLabel
        control={
          <Checkbox
            classes={{ root: classes.root, checked: classes.checked }}
            checked={showActiveCallUsers}
            onChange={e => {
              showActiveCallUsersInTeam(e.target.checked);
            }}
            name={'active_calls'}
          />
        }
        label={'Active Calls'}
      />
      {Object.entries(args)?.map((item, index) => {
        const [key, value] = item;
        if (value?.component === null) return null;
        if (value?.component === 'Selection' && flags?.enableAdvancedFilters)
          return (
            <Selection
              key={`${key}_${index}`}
              options={list[key]}
              name={key}
              value={value?.value}
              updateFilterByName={updateFilterByName}
            />
          );
        if (value?.component === 'Check')
          return (
            <Check
              key={`${key}_${index}`}
              label={value?.label}
              name={key}
              checked={value?.value}
              handleFilterName={handleFilterName}
            />
          );
      })}
    </FormControl>
  );
};

const mapStateToProps = state => {
  const { teamState, userState } = state;
  const { id = '', team, teams = [] } = userState;
  const {
    args = {},
    users = [],
    showActiveCallUsers,
    selectedTeam = '',
  } = teamState;
  let list = { ids: [], skills: [], group: [] };

  users
    .filter(u => u.id !== id)
    .forEach(u => {
      const { id, group = '', skills = [] } = u || {};
      list?.ids?.push(id);
      list?.skills?.push(skills);
      !list?.group?.includes(group) && list.group.push(group);
    });

  return {
    args: {
      ...args,
      team: {
        ...args?.team,
        value:
          selectedTeam === '' || !selectedTeam
            ? teams.length
              ? teams
              : team
              ? [team]
              : []
            : [selectedTeam],
      },
    },
    list: { ...list, skills: [...new Set(list?.skills?.flat())] },
    showActiveCallUsers,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTeamArgsByName: ({ name, value }) =>
      dispatch(updateTeamArgsByName({ name, value })),
    updateTeamWithUsers: users => dispatch(updateTeamWithUsers(users)),
    showActiveCallUsersInTeam: active =>
      dispatch(showActiveCallUsersInTeam(active)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(TeamFilter));

const Check = ({ checked, handleFilterName, name, label }) => {
  const classes = useStyles();
  return (
    <FormControlLabel
      control={
        <Checkbox
          classes={{ root: classes.root, checked: classes.checked }}
          checked={checked}
          onChange={handleFilterName}
          name={name}
        />
      }
      label={label}
    />
  );
};

const Selection = ({ options, name, updateFilterByName, value }) => {
  const classes = useStyles();
  return (
    <Autocomplete
      onChange={(event, value) => {
        updateFilterByName({ name, value });
      }}
      defaultValue={value}
      style={{ width: '100%' }}
      classes={{
        popupIndicator: classes.indicator,
        clearIndicator: classes.indicator,
      }}
      multiple
      id={name}
      options={options}
      limitTags={1}
      getOptionLabel={option => option}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="standard"
            label={name}
            className={classes.textRoot}
          />
        );
      }}
    />
  );
};
