import React from 'react';

import styles from './tasks.scss';
import { connect } from 'react-redux';
import { mergeClasses, displayTimestamp } from '../../utils';
import store from '../../store';
import { userState } from '../../store/reducer';
import { getAudio, stopSound } from '../../utils/sounds';
import ParticipantStatus from './participantStatus';
import { setSelectedTask, archiveTask } from '../../store/action/task';
import { decreaseSMSCount, increaseSMSCount } from '../../store/action/sms';
import { updateScriptSelection } from '../../store/action/scripts';
import { decreaseEmailCount } from '../../store/action/email';
import { archiveCallTask } from '../../store/action/call';
import { Button } from '../reusable_components';
import Copy from '../reusable_components/Copy';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { updateErrorMsg } from '../../store/action/error';
import AutoArchiveTimer from './AutoArchiveTimer';
import TaskIcon from './taskIcon';

const { dispatch, getState } = store;
const actionMap = {
  ACCEPTED: 'ACCEPT',
  REJECTED: 'REJECT',
  DECLINED: 'REJECT',
  SENT_TO_VOICEMAIL: 'VM',
  COMPLETED: 'ARCHIVE',
};

const buttonClassMap = {
  ACCEPTED: 'accept',
  REJECTED: 'reject',
  DECLINED: 'reject',
  SENT_TO_VOICEMAIL: 'voicemail',
  COMPLETED: 'complete',
};

const taskActionMap = {
  ACCEPTED: 'acceptOffer',
  REJECTED: 'rejectOffer',
  DECLINED: 'rejectOffer',
  SENT_TO_VOICEMAIL: 'sendToVoicemail',
  COMPLETED: 'completeTask',
};
const ariaLabelMap = {
  ACCEPTED: 'Accept',
  REJECTED: 'Reject',
  DECLINED: 'decline',
  SENT_TO_VOICEMAIL: 'Send-to-Voicemail',
  COMPLETED: 'Complete',
};

const mapActionToText = action => {
  if (action in actionMap) {
    return actionMap[action];
  }

  console.warn('Unknown task type', action);
  return undefined;
};

const Task = props => {
  const {
    task,
    index,
    services,
    tab,
    helpers,
    selectTask,
    taskState,
    templateState,
    wrapupState,
    smsState,
    decreaseSMSCount,
    emailState,
    decreaseEmailCount,
    callState,
    flags,
    agentCallMessage,
    updateScriptSelection,
    timeout,
    apply_to,
    status,
    canAutoArchive,
    updateErrorMsg,
    archiveCallTask,
  } = props;
  const { tasks, detailView, events } = taskState;
  const { updateOffer } = services;
  const { wrapup } = wrapupState;

  const selectedStyle = task && task.id === detailView ? styles.selected : '';

  const callComplete =
    task && task.call
      ? events[task.call.id] && events[task.call.id].callEnded === true
      : true;
  let template = task.template ? templateState[task.template] || {} : {};
  let { wrapup_fields } = template;
  const { template_metadata = {} } = task;
  const agentOnCall =
    task.type &&
    (task.type.toUpperCase() === 'CALL' ||
      task.type.toUpperCase() === 'FREE_FORM_CALL') &&
    callState.find(
      t =>
        t.id === task.id &&
        ((t.call_info || {}).participants || []).some(
          p => p.id === t.user_id && p.status.toLowerCase() === 'connected'
        ) &&
        t.call_info &&
        t.call_info.status.toLowerCase() !== 'completed'
    );
  let disableSubmit = false;
  if (!wrapup_fields) {
    disableSubmit = agentOnCall;
  } else {
    const requiredFields = wrapup_fields.filter(item => {
      return item.required;
    });

    const callEnded = task.call
      ? events[task.call.id] && events[task.call.id].callEnded
      : true;

    const requiredFieldsFilled =
      requiredFields.every(item => {
        return (
          wrapup[task.id] &&
          wrapup[task.id][item.id] !== undefined &&
          (item.component.toLowerCase() === 'text'
            ? wrapup[task.id][item.id].length
            : true)
        );
      }) || requiredFields.length === 0;
    disableSubmit = agentOnCall || !requiredFieldsFilled;
  }
  const allowSubmitComplete =
    callComplete &&
    wrapup[task.id] &&
    wrapup[task.id].submit &&
    (wrapup[task.id].submitted || task.wrap_up) &&
    !disableSubmit;

  let wrapupRequired;
  if (!template) {
    wrapupRequired = false;
  } else {
    wrapup_fields = template.wrapup_fields;

    if (!wrapup_fields) {
      wrapupRequired = false;
    } else {
      wrapupRequired = wrapup_fields.length;
    }
  }

  const { task_icon } = task || template || { task_icon: '' };
  const { type, call = {}, direction } = task;
  let newDescriptionLines = [];

  task?.description?.split('<br/>').forEach(item => {
    item.split(/\r\n|\r|\n/).forEach(sentence => {
      newDescriptionLines.push(sentence);
    });
  });

  let { dont_allow_archive } =
    (task && task.template && (templateState[task.template] || {})) || {};
  if (task?.class_type?.toUpperCase() === 'TASK') {
    if (
      task.type === 'CALL' &&
      !(template?.call_auto_archive?.apply_to || []).includes(status)
    )
      localStorage.setItem(
        task.id,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(task.id)),
          doNotAutoArchive: true,
          startTimer: 0,
          endTimer: 0,
        })
      );
    else
      localStorage.setItem(
        task.id,
        JSON.stringify({
          ...JSON.parse(localStorage.getItem(task.id)),
          doNotAutoArchive: false,
        })
      );
  }

  return (
    <div
      id={`task_${task.id}`}
      className={mergeClasses(styles.task_item, selectedStyle)}
      onClick={e => {
        e.preventDefault();
        if (
          task &&
          task.type.toLowerCase() === 'sms' &&
          task.status.toUpperCase() !== 'COMPLETED'
        ) {
          console.log('sms count changed');
          smsState.totalCount > 0 && decreaseSMSCount(task.id);
        }
        if (
          task &&
          task.type.toLowerCase() === 'email' &&
          task.status.toUpperCase() !== 'COMPLETED'
        ) {
          console.log('email count changed');
          emailState.totalCount > 0 && decreaseEmailCount(task.id);
        }
        detailView !== task.id && selectTask(task.id);
        if (task?.call_scripts && detailView !== task.id) {
          const defaultScript = task.call_scripts.find(
            cs => cs.opened_by_default
          );
          const name = defaultScript?.scripts[0]?.name;
          const scriptType = defaultScript?.type;

          updateScriptSelection({
            name,
            task_id: task.id,
            scriptType,
          });
        }
      }}
    >
      <div className={styles.task_description}>
        {flags.enableIconColorCoding ? (
          <TaskIcon task={task} template={template} />
        ) : (
          <div
            style={
              task_icon?.match(/\.(jpeg|jpg|svg|png)$/) != null
                ? { backgroundImage: `url('${task_icon}'` }
                : {}
            }
            className={mergeClasses(
              styles.task_icon,
              styles[task_icon] || '',
              styles[type] || '',
              styles[call.direction || direction] || ''
            )}
          ></div>
        )}

        <div className={styles.info}>
          {(flags || {}).enableCampaignLogo && template_metadata.campaign && (
            <div className={styles.campaign_wrapper} id={`${task.id}_campaign`}>
              <img
                className={styles.campaign_logo}
                src={(template_metadata.campaign || {}).logo}
                alt={(template_metadata.campaign || {}).alt || 'campaign Logo'}
              />
              <p className={styles.campaign_text}>
                {(template_metadata.campaign || {}).text || ''}
              </p>
            </div>
          )}
          <div className={mergeClasses(styles.subtitle)}>
            {newDescriptionLines.map((item, index) => {
              return <p key={index}>{item}</p>;
            })}
          </div>
          <div className={mergeClasses(styles.sub_desc)}>
            {displayTimestamp(
              Number(
                new Date(
                  task.updated_at ||
                    task.created_at ||
                    task.updatedAt ||
                    task.createdAt
                )
              )
            )}
          </div>
          {(task.type.toUpperCase() === 'CALL' ||
            task.type.toUpperCase() === 'FREE_FORM_CALL' ||
            task.type.toUpperCase() === 'COACHING') &&
            task.call_info &&
            task.call_info.quality_issue_reported && (
              <div className={styles.call_quality}>
                CALL QUALITY ISSUE REPORTED
              </div>
            )}
          {task.type.toUpperCase() === 'MISSEDCALL' && task.duration && (
            <div className={styles.duration}>
              {'DURATION '} {task.duration}
            </div>
          )}
          {(task.type.toUpperCase() === 'CALL' ||
            task.type.toUpperCase() === 'FREE_FORM_CALL' ||
            task.type.toUpperCase() === 'COACHING') &&
            agentCallMessage && (
              <div className={styles.agent_message}>
                {'Your phone '}
                {agentCallMessage?.toUpperCase()}
              </div>
            )}
          {(flags || {}).enableAutoArchiveOnCallTask &&
            task?.status === 'ASSIGNED' &&
            (task.type.toUpperCase() === 'CALL' ||
              task.type.toUpperCase() === 'FREE_FORM_CALL' ||
              task.type.toUpperCase() === 'COACHING') &&
            timeout > 0 &&
            canAutoArchive &&
            (apply_to || []).includes(status) && (
              <div className={styles.agent_message}>
                <AutoArchiveTimer
                  timeout={timeout}
                  taskId={task.id}
                  flags={flags}
                />
              </div>
            )}
        </div>
      </div>

      <div className={styles.button_list}>
        {(tab || '').toLowerCase() !== 'archived'
          ? task.actions
              .filter(choice => {
                // return true;
                return choice !== 'COMPLETED' || !wrapupRequired;
              })
              .filter(choice => {
                return !(choice === 'COMPLETED' && dont_allow_archive);
              })
              .filter(choice => {
                return !(choice === 'AUTO_ACCEPT');
              })
              .map((choice, i) => {
                let buttonDisable = false;
                if (task.status === 'CREATED') buttonDisable = true;
                if (
                  choice === 'COMPLETED' &&
                  (task.type === 'CALL' ||
                    task.type.toUpperCase() === 'FREE_FORM_CALL' ||
                    task.type === 'COACHING')
                ) {
                  buttonDisable = !allowSubmitComplete;
                }

                return (
                  <Button
                    allowMultilpleClicks={false}
                    ariaLabel={
                      choice in ariaLabelMap ? ariaLabelMap[choice] : choice
                    }
                    className={mergeClasses(
                      styles.button,
                      buttonDisable ? styles.dont_allow : '',
                      styles[buttonClassMap[choice]]
                    )}
                    disableButton={buttonDisable}
                    onClickHandler={async e => {
                      e.preventDefault();
                      e.stopPropagation();
                      e.target.disabled = true;

                      // stop sound for inbound interaction offer
                      task.type.toLowerCase() === 'call' &&
                        (task.parent_task_id ||
                          task.direction.toUpperCase() === 'INBOUND') &&
                        stopSound(getAudio.inboundRingAudio);

                      task.type.toLowerCase() === 'sms' &&
                        smsState.totalCount > 0 &&
                        (task.class_type === 'OFFER'
                          ? decreaseSMSCount(task.task_id)
                          : decreaseSMSCount(task.id));
                      task.type.toLowerCase() === 'email' &&
                        emailState.totalCount > 0 &&
                        (task.class_type === 'OFFER'
                          ? decreaseEmailCount(task.task_id)
                          : decreaseEmailCount(task.id));
                      let clicked = helpers.getClicked();
                      clicked[task.id] = true;
                      if (task.task_id) {
                        clicked[task.task_id] = true;
                      }

                      helpers.setClicked(clicked);
                      let currentCallList = helpers.getCurrentCallId(
                        callState,
                        userState
                      );

                      if (currentCallList.length > 1) {
                        console.error(
                          'You are in more than 1 call',
                          currentCallList
                        );
                      }

                      let res = await updateOffer({
                        task,
                        status: choice,
                        index,
                      });

                      if (
                        choice === 'COMPLETED' &&
                        res.error &&
                        res.error.status === 'CONFLICT'
                      ) {
                        alert('You cannot submit until you finish your task');
                        return;
                      }

                      if (
                        task.type.toLowerCase() === 'call' &&
                        choice === 'ACCEPTED'
                      ) {
                        // let [currentCallId] = currentCallList;
                        let updatedTask =
                          task.task_id &&
                          (await services.fetchUpdatedTaskById(task.task_id));
                        console.log('updatedTask: ', updatedTask);
                        if (
                          props.currentCallId &&
                          updatedTask &&
                          updatedTask.call &&
                          updatedTask.call.id
                        ) {
                          let res = await services.swapCalls(
                            props.currentCallId,
                            updatedTask.call.id
                          );
                          if (!res) {
                            alert(
                              'Oops, something went wrong while we tried to swap your call!'
                            );
                          } else {
                            let currentTask = helpers.getTaskByCallInfo(res);
                            let updatedTask =
                              currentTask &&
                              (await services.fetchUpdatedTaskById(
                                currentTask.id
                              ));
                          }
                        } else if (
                          props.currentCallId &&
                          updatedTask &&
                          updatedTask.call
                        ) {
                          let leaveResponse = await services.leaveCallAndPutCallOnHold(
                            props.currentCallId
                          );

                          if (leaveResponse) {
                            let postResponse = await services.postOutboundCallByTaskId(
                              task.task_id
                            );
                          }
                        }
                      }

                      let message = taskActionMap[choice];

                      let taskResult = await services[message]({
                        task,
                        status: choice,
                        index,
                        res,
                        state: getState(),
                      });
                    }}
                    key={`${i}_${choice}_${task.id}`}
                  >
                    <div
                      className={mergeClasses(
                        styles.button_icon,
                        styles[buttonClassMap[choice]]
                      )}
                    ></div>
                    <div
                      className={mergeClasses(
                        styles.button_text,
                        styles[buttonClassMap[choice]]
                      )}
                    >
                      {mapActionToText(choice)}
                    </div>
                  </Button>
                );
              })
          : null}
      </div>
      {task &&
        (task.type.toUpperCase() === 'CALL' ||
          task.type.toUpperCase() === 'FREE_FORM_CALL') &&
        task.call && <ParticipantStatus current_task={task} />}
      {task &&
        task.type.toUpperCase() === 'SMS' &&
        task.status.toUpperCase() !== 'COMPLETED' &&
        smsState[task.id] &&
        smsState[task.id].sms_list &&
        smsState[task.id].sms_list.length > 0 && (
          <div className={mergeClasses(styles.sms_preview)}>
            {(
              smsState[task.id].sms_list[smsState[task.id].sms_list.length - 1]
                .text || ''
            )
              .toString()
              .substring(0, 40)}
            {(
              smsState[task.id].sms_list[smsState[task.id].sms_list.length - 1]
                .text || ''
            ).toString().length >= 40 && <>...</>}
          </div>
        )}
      <div id={`task_id_${task.id}`} className={styles.task_id}>
        {task.class_type && task.class_type.toLowerCase() === 'offer'
          ? 'OFFER'
          : 'TASK'}{' '}
        ID - {task.id}
        <Copy textToCopy={task.id} tooltipBeforeCopy="Copy TaskID" />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownprops) => {
  const {
    callState,
    userState,
    taskState,
    templateState,
    wrapupState,
    smsState,
    emailState,
  } = state;

  let currentCallId;
  callState.forEach(task => {
    task &&
      task.call_info &&
      task.call_info.participants &&
      task.call_info.status !== 'completed' &&
      task.call_info.participants.forEach(participant => {
        if (
          participant.id === userState.id &&
          participant.status === 'connected'
        ) {
          currentCallId = task.call_info.id;
        }
      });
  });
  const currentCallTask = callState.find(ct => ct?.id === ownprops?.task?.id);
  const agent = currentCallTask?.call_info?.participants?.find(
    p => p.id === userState.id
  );
  const agentCallMessage =
    agent?.status?.toLowerCase() !== 'connected' && agent?.status;

  const canAutoArchive = callState.find(
    t =>
      t.id === ownprops?.task?.id &&
      (t?.call?.cant_connect ||
        t?.call_info?.status?.toLowerCase() === 'completed')
  );

  const { call_auto_archive = {} } =
    templateState[currentCallTask?.template] || {};
  const { timeout = 0, apply_to = [] } = call_auto_archive;

  const { status } = userState;

  return {
    taskState,
    templateState,
    wrapupState,
    smsState,
    callState,
    currentCallId,
    emailState,
    agentCallMessage,
    timeout: timeout * 1000,
    canAutoArchive,
    apply_to,
    status,
  };
};

const mapDispatchToProps = dispatch => ({
  archiveCallTask: task => {
    dispatch(archiveCallTask(task));
  },
  updateConnectionFunc: state => {
    dispatch(updateConnection(state));
  },
  selectTask: id => {
    dispatch(setSelectedTask(id));
  },
  increaseSMSCount: task_id => {
    dispatch(increaseSMSCount(task_id));
  },
  decreaseSMSCount: task_id => {
    dispatch(decreaseSMSCount(task_id));
  },
  decreaseEmailCount: task_id => {
    dispatch(decreaseEmailCount(task_id));
  },
  updateScriptSelection: ({ name, scriptType, task_id }) => {
    dispatch(updateScriptSelection({ name, scriptType, task_id }));
  },
  updateErrorMsg: ({ error_msg, error_type, error_severity }) => {
    dispatch(updateErrorMsg({ error_msg, error_type, error_severity }));
  },
  // Enable after refactorized service and this component
  // archiveTask: task => {
  //   dispatch(archiveTask(task));
  // }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Task));
