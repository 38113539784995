// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto|Rubik&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap);"]);
// Module
exports.push([module.id, "*{margin:0px;box-sizing:border-box;font-family:\"Rubik\",\"Roboto\",\"Open Sans\",sans-serif}html,body{height:100%;background:#333;overflow:hidden}body:hover{overflow:scroll}::-webkit-scrollbar{width:6px;height:6px}::-webkit-scrollbar-track{background:transparent}::-webkit-scrollbar-thumb{background:rgba(255,255,255,.5);border-radius:6px}::-webkit-scrollbar-thumb:hover{background:#555;border-radius:6px}.setenv__root___3fO_5{display:flex;height:100%;flex-direction:column;align-content:stretch}.setenv__root___3fO_5>div{display:flex;flex-direction:column;height:100%}.setenv__root___3fO_5>div>div{display:flex;flex-direction:column;height:100%}.setenv__root___3fO_5>.setenv__list___iNYvz{background-color:gray}#setenv__root___3fO_5{display:flex;height:100%;flex-direction:column;align-content:stretch}a{color:#fff}button{color:#000}.setenv__body___30e17{position:relative;display:flex;flex-direction:row;align-items:stretch;height:100%}#setenv__wrapper___1LSzO{display:flex;flex-direction:row;height:100%}[data-tooltip]:before{position:absolute;content:attr(data-tooltip);opacity:0;transition:all .15s ease;padding:10px;border-radius:20px;z-index:100}[data-tooltip]:hover:before{opacity:1;background:#e3e3e8;color:#212121;margin-top:-50px;margin-left:20px;transition-delay:700ms;transition-property:opacity}.setenv__setenv___2iXfw{background:#fff;position:absolute;align-self:center;display:flex;flex-direction:column;justify-content:space-evenly;justify-self:center;height:200px !important;width:400px;padding-top:15px;padding-bottom:15px;padding-left:15px;padding-right:15px;top:50%;left:50%;transform:translate(-50%, -50%);border-radius:5px;color:#184895}.setenv__setenv___2iXfw *{color:#184895}.setenv__radio___3HtpN{margin-right:5px;display:inline}.setenv__selection___2Ihti{display:flex !important;list-style:none;flex-direction:row !important;justify-content:flex-start}.setenv__env_selection___1aAv2{height:0px}", ""]);
// Exports
exports.locals = {
	"root": "setenv__root___3fO_5",
	"list": "setenv__list___iNYvz",
	"body": "setenv__body___30e17",
	"wrapper": "setenv__wrapper___1LSzO",
	"setenv": "setenv__setenv___2iXfw",
	"radio": "setenv__radio___3HtpN",
	"selection": "setenv__selection___2Ihti",
	"env_selection": "setenv__env_selection___1aAv2"
};
module.exports = exports;
