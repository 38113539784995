export const integrationState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_INTEGRATION': {
      const newState = {
        ...state,
        ...action.group,
      };
      return newState;
    }

    default:
      return state;
  }
};
