import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import { BoundaryError } from '../reusable_components/boundaryerror';
import {
  setOpenHotdeskModalFlag,
  updateHDPhones,
} from '../../store/action/hotdesk';
import { updateUserAssignedPhone } from '../../store/action/user';
import { getAvailableHDPhones, assignHDPhone } from '../../services';
import FeedbackSnackbar from '../feedback/FeedbackSnackbar';
import { Feedback } from '../../utils';
import { Autocomplete } from '@material-ui/lab';
import { TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Close as CloseIcon } from '@material-ui/icons';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    width: '50%',
    height: 'calc(50% - 112px)',
    border: '1px solid #404040',
    background: '#424242',
    overflow: 'hidden',
    borderRaduis: 4,
    outline: 'none',
    padding: 0,
    zIndex: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:hover': {
      overflow: 'overlay',
    },
  },
  overlay: {
    overflow: 'hidden',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 400,
    backgroundColor: `rgb(0, 0, 0, 0.75)`,
    '&:hover': {
      overflow: 'overlay',
    },
  },
  title: {
    display: 'flex',
    color: 'white',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 32,
  },
  indicator: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  textRoot: {
    '& .MuiFormLabel-root': {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    },
  },
  inputBaseRoot: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  underline: {
    borderBottom: '1px solid rgba(255,255,255,0.23)',
    '&::before': {
      borderBottom: '1px solid rgba(255,255,255,0.23)',
    },
  },
  buttonContainer: {
    margin: 32,
    display: 'flex',
    justifyContent: 'flex-end',
    '&:hover': {
      color: '#000',
    },
  },
  disabledButton: {
    color: '#000',
  },
}));

const Hotdesk = ({
  flags,
  phones,
  user_id,
  username,
  hotdesking_eligible,
  assigned_phone,
  setOpenHotdeskModalFlag,
  openHotdeskModal,
  updateHDPhones,
  updateUserAssignedPhone,
}) => {
  const classes = useStyles();
  const [feedback, setFeedback] = useState({ msg: '', severity: '' });
  const [phoneId, setPhoneId] = useState(assigned_phone);
  const [selectedValue, setValue] = useState(null);

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  const updatePhone = ({ value }) => {
    setValue(value);
    value ? setPhoneId(value?.id) : setPhoneId('');
  };

  const assignPhone = ({ phone_id }) => {
    assignHDPhone({ user_id, phone_id })
      .then(res => res.json())
      .then(data => {
        const { assigned_phone, sip_number } = data;
        if (assigned_phone && sip_number)
          updateUserAssignedPhone({ assigned_phone, sip_number });
        closeHDModal();
      })
      .catch(err => {
        console.error('Err: ', err);
        updateFeedback(
          `Error: cannot assign phone ${err.message}`,
          Feedback.ERROR
        );
      });
  };

  const handleSubmit = () => {
    if (!phoneId && assigned_phone) {
      updateFeedback(`Info: Using previously assigned phone `, Feedback.INFO);
      assignPhone({ phone_id: assigned_phone });
    } else if (!phoneId) {
      return updateFeedback(`Warning: didnot select phone `, Feedback.WARNING);
    } else assignPhone({ phone_id: phoneId });
  };

  const closeHDModal = () => {
    if (assigned_phone) {
      setValue(phones?.find(ph => ph?.id === assigned_phone));
      setOpenHotdeskModalFlag({ openHotdeskModal: false });
    } else updateFeedback(`Warning: didnot select phone `, Feedback.WARNING);
  };

  useEffect(() => {
    if (user_id && hotdesking_eligible && openHotdeskModal)
      getAvailableHDPhones({ user_id })
        .then(res => res.json())
        .then(data => {
          const phones = flags.filterHdPhones
            ? (data || []).filter(
                p =>
                  p?.alias?.includes(username, 0) || // this filter is under the assumption that username or Rio is part of phone alias
                  p?.alias === '' ||
                  p?.alias?.includes('Rio', 0)
              )
            : data;
          updateHDPhones({ phones });
        })
        .catch(err => {
          console.error('Err: ', err);
          updateFeedback(
            `Error fetching list of phones ${err.message}`,
            Feedback.ERROR
          );
        });
  }, [user_id, hotdesking_eligible, openHotdeskModal]);

  useEffect(() => {
    if (phones && assigned_phone)
      setValue(phones.find(ph => ph?.id === assigned_phone));
  }, [phones, assigned_phone]);

  const Selection = ({ phones, updatePhone, defaultValue }) => {
    return (
      <Autocomplete
        onChange={(event, value) => {
          updatePhone({ value });
        }}
        defaultValue={defaultValue}
        value={selectedValue}
        style={{ width: `calc(100% - 64px)`, margin: 32 }}
        classes={{
          popupIndicator: classes.indicator,
          clearIndicator: classes.indicator,
        }}
        id={'select HD phone'}
        options={phones}
        limitTags={1}
        getOptionLabel={option => option.alias || option.mac_address}
        renderInput={params => {
          return (
            <TextField
              {...params}
              variant="standard"
              label={'Choose phone'}
              className={classes.textRoot}
              InputProps={{
                ...params.InputProps,
                classes: {
                  underline: classes.underline,
                  root: classes.inputBaseRoot,
                },
              }}
            />
          );
        }}
      />
    );
  };

  return (
    <BoundaryError>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'right'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
        />
      )}
      <Modal
        isOpen={openHotdeskModal}
        className={classes.modal}
        overlayClassName={classes.overlay}
      >
        <div>
          <div className={classes.title}>
            <h3>Choose phone from the list</h3>
            <CloseIcon onClick={closeHDModal} />
          </div>

          <Selection
            phones={phones || []}
            updatePhone={updatePhone}
            defaultValue={(phones || []).find(ph => ph?.id === assigned_phone)}
          />
        </div>

        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            classes={{ disabled: classes.disabledButton }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </BoundaryError>
  );
};

const mapStateToProps = state => {
  const { hotdeskState, userState } = state;
  const { phones, openHotdeskModal } = hotdeskState;
  const {
    id: user_id,
    hotdesking_eligible,
    assigned_phone,
    username,
  } = userState;
  return {
    phones,
    openHotdeskModal,
    hotdesking_eligible,
    assigned_phone,
    user_id,
    username,
  };
};

const mapDispatchToProps = dispatch => ({
  setOpenHotdeskModalFlag: ({ openHotdeskModal }) => {
    dispatch(setOpenHotdeskModalFlag({ openHotdeskModal }));
  },
  updateHDPhones: ({ phones }) => {
    dispatch(updateHDPhones({ phones }));
  },
  updateUserAssignedPhone: ({ assigned_phone, sip_number }) => {
    dispatch(updateUserAssignedPhone({ assigned_phone, sip_number }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Hotdesk));
