import { Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { mergeClasses, strTemplateRenderer, timeRangeParser, parseMetricValue } from '../../utils';
import { getUserMetrics, getUserStatusMetrics } from '../../services';
import AssessmentIcon from '@material-ui/icons/Assessment';
import styles from './header.scss';


const getAvailbility = async (fetcher, user_id, availableStatusMap={}) => {
    const init = new Date()
    init.setHours(0,0,0,0);
    const myStatus = (await fetcher({user_id, from: {seconds: init/1000}})).statuses;
    const data = myStatus.reduce((agg, curr) => {
        agg.total = agg.total + curr.seconds
        if (availableStatusMap[curr.name]) {
            agg.available = agg.available + curr.seconds;
        }
        return agg
    }, {total: 0, available: 0})
    const availability = data.total && data.available ? Math.round(10000*data.available / data.total) /100: 0
    return strTemplateRenderer("Availability: {{$1}}%", 0, availability);
}
const gatherStats = async (fetcher, buckets, displayOptions, { integration, group, team, user_id}) => {
    const values = (await Promise.all(buckets.map(async b => {
        const range = timeRangeParser(b.timeRange);
        // switch (b.timeRange) {
        //     case 
        // }
        const bMetrics = (await fetcher({ integration, group, team, user_id, range, metrics: b.metrics})).reduce((agg, curr) => {
            agg[curr.name] = curr;
            return agg;
        }, {});
        return { [b.timeRange]: bMetrics };
    }))).reduce((agg, curr) => {
        agg = { ...agg, ...curr }
        return agg
    }, {});
    const aggregatedStats = displayOptions.map((m, idx) => {
        let value = '';
        let a = 0, b = 0;
        const tr = m.time_range || '/d';
        const bM = values[tr];
        const seconds = Math.round(Date.now()/1000) - timeRangeParser(tr).from.seconds;
        const factor = seconds ? 360000 / seconds : 0;
        switch (m.from_metrics.agg) {
            case 'serie':
                let serie = bM[m.from_metrics.metrics[0]].serie
                serie = serie.map(v => {
                        return { ...v, value: parseMetricValue(v.value, m.from_metrics.value_type)};
                    })
                value = `${bM[m.from_metrics.metrics[0]].serie.map((v, i) => strTemplateRenderer(m.from_metrics.pattern, i + 1, v.label, v.value)).join(' | ')}`;// `${v.label} ${v.value}`).join(' | ')}`;
                break
            case 'serie/h':
                value = `${bM[m.from_metrics.metrics[0]].serie.map((v, i) => strTemplateRenderer(m.from_metrics.pattern, i + 1, v.label, parseMetricValue(Math.round(v.value * factor) / 100), m.from_metrics.value_type)).join(' | ')}`;// `${v.label} ${v.value}`).join(' | ')}`;
                break
            case 'diff':
                a = (bM[m.from_metrics.metrics[0]] || {}).value || 0;
                b = (bM[m.from_metrics.metrics[1]] || {}).value || 0;
                let v = parseMetricValue(a - b, m.from_metrics.value_type);
                value = strTemplateRenderer(m.from_metrics.pattern, idx, v);
                break;
            case 'diff/h':
                a = (bM[m.from_metrics.metrics[0]] || {}).value || 0;
                b = (bM[m.from_metrics.metrics[1]] || {}).value || 0;
                
                value = strTemplateRenderer(m.from_metrics.pattern, idx, Math.round(factor * (a - b)) / 100);
                break;
            case 'ratio%':
                a = (bM[m.from_metrics.metrics[0]] || {}).value || 0;
                b = (bM[m.from_metrics.metrics[1]] || {}).value || 0;
                const cp = a && b ? Math.round(10000 * a / b) / 100 : 0;
                value = strTemplateRenderer(m.from_metrics.pattern, idx, cpR);
                break;
            case 'ratio':
                a = (bM[m.from_metrics.metrics[0]] || {}).value || 0;
                b = (bM[m.from_metrics.metrics[1]] || {}).value || 0;
                const c = a && b ? Math.round(100*a / b)/100 : 0;
                value = strTemplateRenderer(m.from_metrics.pattern, idx, c);
                break;
            case 'sum':
                const sum = parseMetricValue((m.from_metrics.metrics || []).reduce((agg, curr) => { return agg + (curr.value || 0) }, 0), m.from_metrics.value_type);
                value = strTemplateRenderer(m.from_metrics.pattern, idx, sum);
                break;
            case 'sum/h':
                const sumR = Math.round(factor * (m.from_metrics.metrics || []).reduce((agg, curr) => { return agg + (curr.value || 0) }, 0))/100;
                value = strTemplateRenderer(m.from_metrics.pattern, idx, Math.round(factor*sumR));
                break;
            default:
                let args = (m.from_metrics.metrics || []).map(v => strTemplateRenderer((bM[v] || {}).value || 0, m.from_metrics.value_type));
                value = strTemplateRenderer(m.from_metrics.pattern, idx, ...args);
        }

        return {
            ...m.from_metrics, value
        }
    });
    return aggregatedStats;
}

export const HeaderStats = props => {
    const { 
        stats = [],
        metricBuckets = {},
        myId,
        availability,
        availableStatusMap,
        integration,
        group,
        team,
        getStatusStats = getUserStatusMetrics,
        statsFetcher = getUserMetrics
    } = props;
    
        const [statsValues, setStatsValues] = useState([]);
        const [myAvailability, setAviavility] = useState('Availavility: ???');
        useEffect(() => {
            if (!myId) {
                return;
            }
            const getStats = async () => {
                try {
                    const aggregatedStats = await gatherStats(statsFetcher, metricBuckets, stats, { integration, group, team, user_id: myId });
                    setStatsValues(aggregatedStats);
                } catch (e) {
                    console.error(e);
                }
            }
            const interval = setInterval(getStats, 10000);
            getStats();
            return () => {
                clearInterval(interval)
            }
        }, [stats, metricBuckets, myId])

    useEffect(() => {
        if (!availability || Object.keys(availableStatusMap || {}).length === 0) {
            return;
        }
        const setMyAvailavility = async () => {
            try {
                const ava = await getAvailbility(getStatusStats, myId, availableStatusMap);
                setAviavility(ava);

            } catch (e) {
                console.error(e);
            }
        }
        const interval = setInterval(setMyAvailavility, 10000)
        setMyAvailavility()
        return () => {
            clearInterval(interval);
        }
    }, [myId, availability, availableStatusMap]);

        return (<Grid container direction="row" justify="center" alignItems="center" className={mergeClasses(styles.stats)} spacing={1} >
            <Grid item ><AssessmentIcon style={{ fontSize: 40 }}/></Grid>
            <Grid item={true} container={true} direction="row" xs={10} spacing={1}>
                {availability && (<Grid item className={mergeClasses(styles.stat)}><Typography>{myAvailability || 'Availavility: ???'}</Typography></Grid>)}
                {statsValues.map(s => (<Grid key={s.name} item className={mergeClasses(styles.stat)}><Typography>{`${s.label}: ${s.value}`}</Typography></Grid>))}
            </Grid>
        </Grid>)
    }

const mapStateToProps = state => {
    const { integrationState = {}, userState = {} } = state;
    const { stats = {} } = integrationState;
    const { availability=true, display = [
        { on_header: true, time_range: '/2h', from_metrics: { tooltip: 'test2', name: 'test2', label: 'Test2', metrics: ['serie2'], value_type: 'time' } },
        { on_header: true, time_range: '2h', from_metrics: { tooltip: 'test3', name: 'test3', label: 'Test3', metrics: ['scalar1'], value_type: 'time' } },
        { on_header: true, time_range: '2h', from_metrics: { tooltip: 'test4', name: 'test4', label: 'Test4', metrics: ['scalar2', 'scalar3'], agg: 'diff' } },
        { on_header: true, time_range: '2h', from_metrics: { tooltip: 'test5', name: 'test5', label: 'Test5', metrics: ['scalar2', 'scalar3'], agg: 'rate' } },
        { on_header: true, time_range: '2h', from_metrics: { tooltip: 'test6', name: 'test6', label: 'Test6', metrics: ['scalar2', 'scalar4'], agg: 'rate', pattern: "{{$1}} leads/hour" } },
        { on_header: true, time_range: '2h', from_metrics: { tooltip: 'test7', name: 'test7', label: 'Test7', metrics: ['scalar4', 'scalar3'], agg: 'concat', pattern: "{{$1}}/{{$2}}" } },
        { on_header: true, time_range: '2h', from_metrics: { tooltip: 'test', name: 'test1', label: 'Test', metrics: ['serie1'], pattern: '({{$idx}}) {{$1}} {{$2}}', agg: 'serie/h' } },
    ] } = stats;
    const statsOptions = display.filter(d => d.on_header);
    const configCallStats =  statsOptions.reduce((agg, curr) => {
        const bucketTime = curr.time_range || '/d';
        const timeBucket = agg[bucketTime] || {};
        for (const m of curr.from_metrics.metrics) {
            timeBucket[m] = true;
        }
        agg[bucketTime] = timeBucket;
        return agg;
    }, {});
    for (const k of Object.keys(configCallStats)) {
        configCallStats[k] = Object.values(configCallStats[k])
    }
    const metricBuckets = Object.keys(configCallStats).map(k => {
        return {
            timeRange: k,
            metrics: Object.values(configCallStats[k])
        }
    });
    const {
        statusList = [], id, integration, group, team
    } = userState;
    const availableStatusMap = statusList.reduce((agg, curr) => {
        if (curr.available) {
            agg[curr.label] = true; 
        }
        return agg
    }, {})
    return {
        stats: statsOptions, myId: id, metricBuckets, availability, availableStatusMap, integration, group, team
    };
};

export default connect(mapStateToProps)(HeaderStats);
