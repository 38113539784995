export const hotdeskState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_USER_HOTDESK_PHONES': {
      const { phones } = action;
      return {
        ...state,
        phones,
      };
    }
    case 'UPDATE_USER_HOTDESK_MODAL_FLAG': {
      const { openHotdeskModal } = action;
      console.log('action', action);
      return {
        ...state,
        openHotdeskModal,
      };
    }
    default:
      return state;
  }
};
