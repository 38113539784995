export const scannerState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_SCANNER_STATE': {
      const newState = { ...state };
      newState.scanners = action.scanners;

      return { ...newState };
    }
    case 'DELETE_SCANNER_BY_ID': {
      const newState = { ...state };
      const { scanners = [] } = newState;
      const { id } = action;
      const updatedScanners = scanners.filter(s => s.id !== id);

      return { ...newState, scanners: updatedScanners };
    }
    case 'UPDATE_SCANNER_BY_ID': {
      const newState = { ...state };
      const { scanners = [] } = newState;
      const { id, scanner } = action;
      const updatedScanners = scanners.map(s => (s.id === id ? scanner : s));

      return { ...newState, scanners: updatedScanners };
    }
    case 'CREATE_NEW_SCANNER_KEYWORD': {
      const newState = { ...state };
      const { scanners = [] } = newState;
      const { keyword } = action;
      scanners.unshift(keyword);
      newState.newKeyword = '';

      return { ...newState, scanners };
    }
    case 'UPDATE_NEW_SCANNER': {
      const { newKeyword } = action;
      return { ...state, newKeyword };
    }

    default:
      return state;
  }
};
