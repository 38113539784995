// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto|Rubik&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap);"]);
// Module
exports.push([module.id, "*{margin:0px;box-sizing:border-box;font-family:\"Rubik\",\"Roboto\",\"Open Sans\",sans-serif}html,body{height:100%;background:#333;overflow:hidden}body:hover{overflow:scroll}::-webkit-scrollbar{width:6px;height:6px}::-webkit-scrollbar-track{background:transparent}::-webkit-scrollbar-thumb{background:rgba(255,255,255,.5);border-radius:6px}::-webkit-scrollbar-thumb:hover{background:#555;border-radius:6px}.Attachments__root___3s64a{display:flex;height:100%;flex-direction:column;align-content:stretch}.Attachments__root___3s64a>div{display:flex;flex-direction:column;height:100%}.Attachments__root___3s64a>div>div{display:flex;flex-direction:column;height:100%}.Attachments__root___3s64a>.Attachments__list___1Qnys{background-color:gray}#Attachments__root___3s64a{display:flex;height:100%;flex-direction:column;align-content:stretch}a{color:#fff}button{color:#000}.Attachments__body___1ZmS-{position:relative;display:flex;flex-direction:row;align-items:stretch;height:100%}#Attachments__wrapper___2_fMD{display:flex;flex-direction:row;height:100%}[data-tooltip]:before{position:absolute;content:attr(data-tooltip);opacity:0;transition:all .15s ease;padding:10px;border-radius:20px;z-index:100}[data-tooltip]:hover:before{opacity:1;background:#e3e3e8;color:#212121;margin-top:-50px;margin-left:20px;transition-delay:700ms;transition-property:opacity}.Attachments__attachments___2xCpe{border-top:2px dotted #424242;margin:15px 0}.Attachments__attachments_count___1p4bJ{margin:12px 0;padding-left:12px;align-items:center;display:flex;justify-content:flex-start}.Attachments__attachment___2kFoI{display:flex;float:left;margin:0 0 16px 16px;height:30px;width:fit-content;position:relative;border:1px solid #424242;text-align:center;padding:3px}.Attachments__filename___2z8eF{padding:2px}.Attachments__not_loaded___VZC6O{margin:0 12px;position:relative;font-size:14px;color:#ff9404}.Attachments__img_src___1Xo3u{text-decoration:underline;opacity:.7}", ""]);
// Exports
exports.locals = {
	"root": "Attachments__root___3s64a",
	"list": "Attachments__list___1Qnys",
	"body": "Attachments__body___1ZmS-",
	"wrapper": "Attachments__wrapper___2_fMD",
	"attachments": "Attachments__attachments___2xCpe",
	"attachments_count": "Attachments__attachments_count___1p4bJ",
	"attachment": "Attachments__attachment___2kFoI",
	"filename": "Attachments__filename___2z8eF",
	"not_loaded": "Attachments__not_loaded___VZC6O",
	"img_src": "Attachments__img_src___1Xo3u"
};
module.exports = exports;
