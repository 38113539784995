export const userState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_USER': {
      const newState = { ...state, ...action.userState };

      return newState;
    }
    case 'SELECT_TEAM': {
      return { ...state, selectedTeam: action.team };
    }
    case 'UPDATE_ROUND_ROBIN_NOTIFICATION': {
      const notifications = state.notifications.map(notification => {
        if (notification.id === action.id) {
          return {
            ...notification,
            settings: action.setting,
          };
        }
        return notification;
      });

      return { ...state, notifications, ...action.userState };
    }
    case 'UPDATE_AUTO_LOGOUT_SETTING': {
      return {
        ...state,
        isAutoLogoutEnabled: action.isAutoLogoutEnabled,
        ...action.userState,
      };
    }
    case 'UPDATE_USER_STATUS': {
      const { available, status, telephony_status } = action;
      return {
        ...state,
        available,
        status,
        telephony_status: telephony_status || state?.telephony_status,
      };
    }
    case 'UPDATE_TELEPHONY_STATUS': {
      const { telephony_status } = action;
      return {
        ...state,
        telephony_status,
      };
    }
    case 'UPDATE_USER_STATUSLIST': {
      const { statusList } = action;
      return {
        ...state,
        statusList,
      };
    }
    case 'UPDATE_USER_INACTIVITY_TIMERS': {
      const { inactivity } = action;
      return {
        ...state,
        inactivity,
      };
    }
    case 'UPDATE_USER_ACTIVITY_FLAGS': {
      const { userIncall, ignoreInactivity } = action;
      return {
        ...state,
        userIncall,
        ignoreInactivity,
      };
    }
    case 'UPDATE_USER_ASSIGNED_PHONE': {
      const { assigned_phone, sip_number } = action;
      return {
        ...state,
        assigned_phone,
        sip_number,
      };
    }
    case 'UPDATE_USER_TELEPHONY_PROVIDER': {
      const { telephonyProvider } = action;
      return {
       ...state,
       telephonyProvider,
      };
    }

    default:
      return state;
  }
};
