import React, { useState } from 'react';
import styles from './Copy.scss';

const Copy = ({ textToCopy, tooltipBeforeCopy }) => {
  const [copyText, setCopyText] = useState('');

  return (
    <div
      data-tooltip={
        copyText !== textToCopy ? tooltipBeforeCopy || 'Copy' : 'Copied'
      }
      className={styles.copy_text}
      onClick={e => {
        e.preventDefault();
        navigator.clipboard.writeText(textToCopy);
        setCopyText(copyText);
      }}
    />
  );
};

export default Copy;
