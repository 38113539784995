export class ResponseError extends Error {
    get code() {
        return this._code;
    }
    get body() {
        return this._body;
    }
    constructor(message, code, body) {
        super(message);
        this._code = code;
        this._body = body;
    }
}