import React from 'react';
import styles from './Textbox.scss';
import { mergeClasses } from '../../utils/helpers';
import { TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const muiStyle = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: '100%',
    color: 'rgba(255, 255, 255, 0.6);',
  },

  cssLabel: {
    color: 'rgba(255, 255, 255, 0.6);',
    '&$cssFocused': {
      color: 'white',
    },
  },

  cssOutlinedInput: {
    color: 'rgba(255, 255, 255, 0.6);',
    fontSize: '16px',
    '&$cssFocused $notchedOutline': {
      borderColor: `white !important`,
      color: 'white',
    },
  },

  cssFocused: {
    color: 'white',
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(255, 255, 255, 0.6) !important',
  },
});

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: '#fff',
    '&$error': {
      color: '#e7474e',
      textTransform: 'uppercase',
    },
  },
  error: {},
}));

const Textbox = ({
  classes: muiClasses,
  label,
  value,
  key,
  multiline,
  maxlength,
  handleChange,
  disabled,
  helperText,
  error,
  endAdornment,
}) => {
  return (
    <TextField
      label={label}
      className={mergeClasses(styles.text_box)}
      multiline={multiline}
      disabled={disabled}
      key={`text_${key}`}
      value={value}
      onChange={e => {
        e.preventDefault();
        e.stopPropagation();
        handleChange(e.target.value);
      }}
      inputProps={{
        maxLength: maxlength,
      }}
      InputLabelProps={{
        classes: {
          root: muiClasses.cssLabel,
          focused: muiClasses.cssFocused,
          disabled: styles.input_disabled,
        },
      }}
      InputProps={{
        classes: {
          root: muiClasses.cssOutlinedInput,
          focused: muiClasses.cssFocused,
          notchedOutline: muiClasses.notchedOutline,
          disabled: styles.input_disabled,
        },
        ...(endAdornment && { endAdornment }),
      }}
      variant="outlined"
      helperText={helperText}
      error={error}
      FormHelperTextProps={{ classes: helperTextStyles() }}
    />
  );
};

export default withStyles(muiStyle)(Textbox);
