export const PARTICIPANT_ROLE = {
  user: 'internal', // Represents an internal Genesys Cloud user on a business conversation
  external: 'external', // Represents a non Genesys Cloud person on a business conversation
  agent: 'internal', // Represents an Genesys Cloud agent on a contact center conversation
  customer: 'external', // Represents the customer on a contact center conversation
  // acd: 'external', // Non-human participant that represents the ACD queue the conversation was routed through
  ivr: 'internal', //  Non-human participant that represents the voice IVR that the conversation was routed through
  // voicemail: 'voicemail', // Represents a Genesys Cloud user's voicemail
  //   fax: 'external', // Represents a non-human fax participant if the voice call is transmitting a fax document
  //   group: 'external', // Represents a non-human participant that represents the Group that was used for a group-ring voice conversation
  //   workflow: 'external', // Non-human participant that represents the workflow that the conversation was routed through (that's for non-voice media like email, message, chat - It corresponds to the Architect Inbound Message/Email/Chat/... flow portion)
};

export const PARTICIPANT_STATUS = {
  alerting: 'queued',
  dialing: 'ringing',
  contacting: 'pending',
  offering: 'pending',
  connected: 'connected',
  disconnected: 'disconnected',
  terminated: 'canceled',
  converting: '', // ?????
  uploading: '', // ????
  transmitting: '', // ??????
  scheduled: '', // ?????
  parked: 'queued', // ?????
};
