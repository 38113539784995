import React, { useState } from 'react';
import styles from './supervisor.scss';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { getArchiveQuery } from '../../utils';
import { fetchArchives } from '../../services';

import { connect } from 'react-redux';
import store from '../../store';
const { dispatch } = store;

const calendarTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        color: '#fff',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#000',
        color: '#979797',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#979797',
      },
      iconButton: {
        backgroundColor: '#000',
        '&:hover': {
          backgroundColor: '#3c6dbd',
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: '#979797',
        backgroundColor: '#000',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: '#54698d',
        },
      },
      current: {
        color: '#00ff00',
      },
      daySelected: {
        backgroundColor: '#3c6dbd',
        '&:active': {
          backgroundColor: '#54698d',
        },
        '&:hover': {
          backgroundColor: '#54698d',
        },
      },
      current: {
        color: '',
      },
    },
    MuiButtonBase: {
      root: {
        color: 'white !important',
      },
    },
  },
});

const Calendar = ({ id, archives }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = async date => {
    if (date !== '') {
      setSelectedDate(date);
      let query,
        archiveData = archives || [];

      query = getArchiveQuery({
        date: date,
        offset: archiveData.length,
      });
      await fetchArchives(id, query)
        .then(archives => {
          archiveData.push(...archives);
          dispatch({
            type: 'UPDATE_AGENT',
            agentState: {
              filter: null,
              archives: archiveData,
              date: date,
            },
          });
        })
        .catch(err => {
          console.error(
            `Error fetching archive list with query: ${query}`,
            err
          );
        });
    }
  };

  return (
    <MuiThemeProvider theme={calendarTheme}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          views={['month', 'date']}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = state => {
  const { agentState, archives } = state;
  return {
    id: (agentState.data || {}).id,
    archives,
  };
};

export default connect(mapStateToProps)(Calendar);
