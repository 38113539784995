import { connect } from 'react-redux';
import { saveOtherGroupSkills } from '../../../store/action/skills';
import { removeDuplicate } from '../../../utils';

export const mapTeamMngStateToProps = state => {
  const { userState, integrationState, teamState, skillsState } = state;
  const { integration, roles = [], team, teams = [], group } = userState;
  const { acl = {} } = integrationState;
  const { selectedTeam = '' } = teamState;
  const userRoles = [];
  for (const role of roles) {
    userRoles.push(role, ...((acl || {})[role] || []));
  }

  return {
    integration,
    teams: teams && teams.length ? teams : team ? [team] : [],
    userRoles,
    selectedTeam,
    group,
    otherGroupSkills: skillsState,
  };
};

const mapTeamMngDispatchToProps = dispatch => {
  return {
    saveOtherGroupSkills: ({ group, skills }) =>
      dispatch(saveOtherGroupSkills({ group, skills })),
  };
};

export const teamMngConnector = connect(
  mapTeamMngStateToProps,
  mapTeamMngDispatchToProps
);

const mapUserMngStateToProps = (state, ownprops) => {
  const { integrationState, skillsState } = state;
  const { skills = [] } = integrationState;

  const { agent = {} } = ownprops;
  const otherGroupSkills = skillsState[(agent || {}).group];
  const managedSkills = removeDuplicate(
    (skills || []).concat(otherGroupSkills || []),
    skill => skill.name
  );

  return {
    managedSkills,
  };
};

export const UserMngConnector = connect(mapUserMngStateToProps);
