import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import Modal from 'react-modal';
import { withOktaAuth } from '@okta/okta-react';

import Directory from './directory';
import Header from '../header';
import Home from './home';
import Supervisor from '../supervisor';
import Sidebar from '../sidebar';
import Settings from '../settings';
import Broadcast from '../broadcast';
import Hotdesk from './Hotdesk';
import FeedbackSnackbar from '../feedback/FeedbackSnackbar';
import { updateErrorMsg } from '../../store/action/error';

import jwtDecode from 'jwt-decode';

import styles from './home.scss';
import modalStyles from './modal.scss';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Button,
  DialogActions,
} from '@material-ui/core';
import { userLogout } from '../../services';
import { logoutReason } from '../../utils';

import store from '../../store';
import { updateUserTelephonyProvider } from '../../store/action/user';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  startGenesysAuthFlow,
  registerElectronAppAuthCallbackHandler,
  handleWebappVHCallback,
} from '../../utils/genesys/auth';

import { openReLoginDialog } from '../../store/action/connection';
import GenesysWebRTCSoftphone from '../softphone/genesysSoftPhone';
import { connectToGenesys } from '../../utils/genesys/websocket';
import { updateLoggingFlags } from '../../utils/genesys/logging';
import { consts } from '../../config/consts';
import OktaLogin from './logons/oktaLogin';
import GenesysLogin from './logons/genesysLogin';
import { startGenesysTokenRefresher } from '../../utils/genesys/token_refresher';
import { hasAppAccess, isWebapp } from '../../utils/webapp/user_access_control';
import AutoLogout from './autoLogout';
import NoAccessLogout from './logons/noAccessLogout';

const { dispatch } = store;

Modal.setAppElement('#root'); // for accessibility

const Main = props => {
  let mainProps = { ...props };

  const {
    authService,
    authState,
    helpers,
    showReLogin,
    openReLoginDialog,
    flags,
    directoryState,
    environmentState,
    connectionState,
    genesysConnectionState,
    userState,
  } = props;

  helpers.setAuthService(authService);
  window.authService = window.authService || authService;

  const oktaToken = authState.accessToken
    ? jwtDecode(authState.accessToken)
    : null;

  if (!oktaToken) {
    return <OktaLogin />;
  }

  if (!hasAppAccess(oktaToken)) {
    return <NoAccessLogout />;
  }

  const updateFeedback = (msg, severity = 'info', type) => {
    props.updateErrorMsg({
      error_msg: msg,
      error_type: type,
      error_severity: severity,
    });
  };

  const enableGenesysAuth = flags.enableGenesysAuth;
  const isGenesysAgent = consts.telephony.isGenesys(oktaToken.telephony);

  // Electron app auth callback
  useEffect(() => {
    if (isGenesysAgent && !isWebapp()) {
      // feature flags might not loaded at this point
      // register the Electron callback regardless of feature flag enableGenesysAuth
      registerElectronAppAuthCallbackHandler();
    }

    if (
      oktaToken.telephony &&
      oktaToken.telephony !== userState.telephonyProvider
    ) {
      dispatch(
        updateUserTelephonyProvider({
          telephonyProvider: oktaToken.telephony,
        })
      );
    }
  }, []);

  // webapp auth callback
  useEffect(() => {
    if (isGenesysAgent && isWebapp()) {
      // feature flags might not loaded at this point
      // register the Electron callback regardless of feature flag enableGenesysAuth
      handleWebappVHCallback();
    }
  }, [genesysConnectionState]);

  useEffect(() => {
    updateLoggingFlags(flags);
  }, [flags]);

  // Genesys auth
  useEffect(() => {
    if (enableGenesysAuth && isGenesysAgent) {
      startGenesysAuthFlow(oktaToken);
    }
  }, [enableGenesysAuth, genesysConnectionState]);

  // Genesys WS
  useEffect(() => {
    if (enableGenesysAuth && isGenesysAgent) {
      connectToGenesys(oktaToken);
    }
  }, [genesysConnectionState]);

  // Controller WS
  useEffect(() => {
    // connect to Controller WS, if
    // 1. Genesys auth is off
    // 2. OR the agent is not a Genesys agent
    // 3. OR Genesys is authenticated and Genesys WS connected
    if (
      !enableGenesysAuth ||
      !isGenesysAgent ||
      genesysConnectionState.wsStatus === 'connected'
    ) {
      if (
        connectionState.intent &&
        !connectionState.ws &&
        environmentState.env // TODO. why checking ENV?
      ) {
        try {
          props.createWS();
        } catch (e) {
          console.error('Error creating socket connection', e);
        }
        const count = sessionStorage.getItem('count');
        !count && sessionStorage.setItem('count', 0);
      }

      return;
    }
  }, [enableGenesysAuth, genesysConnectionState, connectionState]);

  useEffect(() => {
    if (genesysConnectionState.authStatus === consts.genesys.authed) {
      startGenesysTokenRefresher(genesysConnectionState.token);
    }
  }, [genesysConnectionState]);

  if (
    enableGenesysAuth &&
    consts.telephony.isGenesys(oktaToken.telephony) &&
    genesysConnectionState.authStatus !== consts.genesys.authed
  ) {
    return <GenesysLogin />;
  }

  return (
    <div id="main" className={styles.main}>
      <AutoLogout />
      {props?.error_msg?.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'left'}
          children={props?.error_msg}
          severity={props?.error_severity}
          updateFeedback={updateFeedback}
        />
      )}
      <Header {...props} />
      <Hotdesk />
      <GenesysWebRTCSoftphone />
      <div id="body" className={styles.body}>
        <Sidebar {...props} />
        <Route
          path="/"
          exact
          render={props => {
            let newProps = { ...mainProps, ...props };

            return (
              <>
                <Home {...newProps} />
                <Modal
                  isOpen={directoryState.display}
                  className={modalStyles.modal}
                  overlayClassName={modalStyles.overlay}
                >
                  {directoryState.callId ||
                  directoryState.forwardSms ||
                  directoryState.forwardTask ? (
                    <Directory {...newProps} />
                  ) : null}
                </Modal>
              </>
            );
          }}
        />

        <Route
          path="/direct"
          exact
          render={props => {
            let newProps = { ...mainProps, ...props };

            return (
              <>
                <Home {...newProps} />
                <Modal
                  isOpen={directoryState.display}
                  className={modalStyles.modal}
                  overlayClassName={modalStyles.overlay}
                >
                  {directoryState.callId ||
                  directoryState.forwardSms ||
                  directoryState.forwardTask ? (
                    <Directory {...newProps} />
                  ) : null}
                </Modal>
              </>
            );
          }}
        />

        <Route
          path="/call"
          exact
          render={props => {
            let newProps = { ...mainProps, ...props };

            return (
              <>
                <Home {...newProps} />
                <Modal
                  isOpen={directoryState.display}
                  className={modalStyles.modal}
                  overlayClassName={modalStyles.overlay}
                >
                  {directoryState.callId ||
                  directoryState.forwardSms ||
                  directoryState.forwardTask ? (
                    <Directory {...newProps} />
                  ) : null}
                </Modal>
              </>
            );
          }}
        />

        <Route
          exact
          path="/messages"
          render={props => (
            <div id="messages" className={styles.messages}>
              <Broadcast />
            </div>
          )}
        />

        <Route
          exact
          path="/supervisor"
          render={props => {
            let newProps = { ...mainProps, ...props };
            return (
              <div id="supervisor" className={styles.supervisor}>
                {/* <Sidebar {...newProps} /> */}
                <Supervisor {...newProps} />
              </div>
            );
          }}
        />

        <Route
          exact
          path="/settings"
          render={props => {
            let newProps = { ...mainProps, ...props };
            return (
              <div id="settings" className={styles.supervisor}>
                {/* <Sidebar {...newProps} /> */}
                <Settings {...newProps} />
              </div>
            );
          }}
        />
      </div>
      <Dialog
        open={!!showReLogin}
        onClose={() => openReLoginDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ backgroundColor: '#424242' }}
        >
          {'Auth Credentials expired'}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#424242' }}>
          <DialogContentText id="alert-dialog-description">
            You session has been expired
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#424242' }}>
          <Button onClick={() => authService.login()} color="secondary">
            Login
          </Button>
          <Button
            onClick={() => {
              userLogout(logoutReason.SESSION_EXPIRED);
              authService.logout('/');
            }}
            color="primary"
          >
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  const { error_type, error_msg, error_severity } = state.errorState;
  return {
    taskState: state.taskState,
    templateState: state.templateState,
    supervisorState: state.supervisorState,
    directoryState: state.directoryState,
    wrapupState: state.wrapupState,
    environmentState: state.environmentState,
    connectionState: state.connectionState,
    genesysConnectionState: state.genesysConnectionState,
    userState: state.userState,
    activityState: state.activityState,
    loggingState: state.loggingState,
    showReLogin: (state.connectionState || {}).showReLogin,
    error_msg,
    error_type,
    error_severity,
  };
};
const mapDispatchToProps = dispatch => ({
  openReLoginDialog: open => {
    dispatch(openReLoginDialog(open));
  },
  updateErrorMsg: ({ error_msg, error_type, error_severity }) => {
    dispatch(updateErrorMsg({ error_msg, error_type, error_severity }));
  },
});
export default withOktaAuth(
  connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(Main))
);
