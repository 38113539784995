import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Feedback } from '../../../utils';
import { getCallScript } from '../../../services';
import {
  updateScriptByName,
  updateScriptSelection,
} from '../../../store/action/scripts';

import { Button } from '@material-ui/core';

import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Spinner } from '../../reusable_components/Spinner';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    border: '1px solid #ffd85f',
    color: '#ffd85f',
    '&:hover': {
      backgroundColor: '#ffd85f',
      color: theme.palette.getContrastText('#ffd85f'),
    },
  },
  selectedButton: {
    margin: theme.spacing(1),
    backgroundColor: '#ffd85f',
    color: theme.palette.getContrastText('#ffd85f'),
    '&:hover': {
      backgroundColor: '#ffcc2c',
      color: theme.palette.getContrastText('#ffcc2c'),
    },
  },
}));

const Script = ({
  type,
  text,
  html,
  name,
  label,
  updateScriptByName,
  updateScriptSelection,
  updateFeedback,
  open,
  handleOpen,
  isCurrentSelection,
  isCurrentSelectionType,
  isCurrentTask,
  task_id,
}) => {
  const classes = useStyles();
  let mounted = true;
  useEffect(() => {
    if (type === 'introduction' && !(text || html))
      fetchCallScript({ script_name: name }, mounted);
    return () => {
      mounted = false;
    };
  }, []);
  const [loading, setLoading] = useState(false);

  const fetchCallScript = ({ script_name }, mounted) => {
    setLoading(true);
    getCallScript({ script_name })
      .then(res => res.json())
      .then(data => {
        console.log('Script: ', data);
        if (mounted) {
          updateScriptByName({
            name: data.name || data.template_name,
            html: data.html,
            text: data.text,
            scriptType: type,
            task_id,
          });
        }
      })
      .catch(err =>
        updateFeedback(
          `${err.code}: ${err.message} \n Script Name: ${script_name} `,
          Feedback.ERROR
        )
      )
      .finally(() => {
        mounted && !open && handleOpen(!open);
        mounted && setLoading(false);
      });
  };

  return (
    <>
      <Button
        className={
          open && isCurrentSelection && isCurrentSelectionType && isCurrentTask
            ? classes.selectedButton
            : classes.button
        }
        endIcon={
          loading ? (
            <Spinner size={20} />
          ) : open &&
            isCurrentSelection &&
            isCurrentSelectionType &&
            isCurrentTask ? (
            <VisibilityIcon />
          ) : (
            <VisibilityOffIcon />
          )
        }
        onClick={async e => {
          e.preventDefault();

          if (!(text || html)) fetchCallScript({ script_name: name }, mounted);
          else {
            if (!isCurrentSelection || !isCurrentSelectionType) {
              updateScriptSelection({ name, scriptType: type, task_id });
              !open && handleOpen(!open);
              return;
            }
            handleOpen(!open);
          }
        }}
      >
        {label || name}
      </Button>
    </>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { scriptState } = state;
  const { script = {}, type = '', task_id } = ownprops;
  const { name = '', label = '' } = script;
  const { text = '' } = scriptState[name] || {};
  const { html = '' } = scriptState[name] || {};
  const isCurrentSelection =
    name === ((scriptState || {}).currentSelection || {}).name;
  const isCurrentSelectionType =
    type === ((scriptState || {}).currentSelection || {}).scriptType;
  const isCurrentTask = scriptState?.currentSelection?.task_id === task_id;

  return {
    text,
    html,
    name,
    label,
    isCurrentSelection,
    isCurrentSelectionType,
    isCurrentTask,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateScriptByName: ({ name, html, text, scriptType, task_id }) => {
      dispatch(updateScriptByName({ name, html, text, scriptType, task_id }));
    },
    updateScriptSelection: ({ name, scriptType, task_id }) => {
      dispatch(updateScriptSelection({ name, scriptType, task_id }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Script);
