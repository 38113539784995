// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".button__disabled___288uQ{opacity:50% !important;cursor:progress}", ""]);
// Exports
exports.locals = {
	"disabled": "button__disabled___288uQ"
};
module.exports = exports;
