import React, { Component } from 'react';
import * as helpers from '../../utils';
const { mergeClasses } = helpers;
import styles from './button.scss';

export default class Button extends Component {
  constructor(props) {
    super(props);

    const { allowMultipleClicks = false } = props;
    this.state = {
      allowMultipleClicks,
      waitingResponse: false,
    };
  }

  render() {
    const {
      id,
      className,
      onClickHandler,
      disableButton,
      ariaLabel,
      dataTooltip,
    } = this.props;

    let rest = this.state.allowMultipleClicks
      ? false
      : this.state.waitingResponse;
    let buttonDisabled = disableButton || rest ? true : false;

    return (
      <button
        data-tooltip={dataTooltip}
        aria-label={ariaLabel}
        id={id}
        className={mergeClasses(
          className,
          this.state.waitingResponse ? styles.disabled : ''
        )}
        disabled={buttonDisabled}
        onClick={async e => {
          let { allowMultipleClicks, waitingResponse = false } = this.state;
          if (!allowMultipleClicks) {
            this.setState({
              ...this.state,
              waitingResponse: true,
            });
            waitingResponse = true;
          }

          let res = await onClickHandler(e);
          if (waitingResponse) {
            this.setState({
              ...this.state,
              waitingResponse: false,
            });
          }
        }}
      >
        {this.props.children}
      </button>
    );
  }
}
