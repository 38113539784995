export const transcriptionState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_TRANSCRIPTION_BY_REC_ID': {
      const newState = { ...state };
      const { id, transcription } = action;

      const item = { ...(state[id] || {}) };
      item.transcription = transcription;
      newState[id] = item;
      return { ...newState };
    }
    case 'INIT_TRANSCRIPTION_BY_REC_ID': {
      const newState = { ...state };
      const { id } = action;

      const item = { ...(state[id] || {}) };
      newState[id] = item;
      return { ...newState };
    }

    default:
      return state;
  }
};
