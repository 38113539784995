import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: '#fafafa',
  },
}))(Tooltip);
