import UserMonitoring from './users';
import { AppBar, Tab, Tabs, Box } from '@material-ui/core';
import { useState, default as React } from 'react';
import { connect } from 'react-redux';
import { mergeClasses } from '../../utils';
import { BoundaryError } from '../reusable_components/boundaryerror';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import styles from '../main/home.scss';
import Skills from './skills';
import Scanners from './scanners';
import Stats from './statistics';
import Broadcast from './broadcast';
import { StyledTabs } from '../reusable_components/StyledTabs';
const defaultProps = index => {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
};

export const editSkillACL = 'skills.edit';
export const viewSkillACL = 'skills.view';
export const userMonitoringACL = 'users.monitoring';
export const userCoachingACL = 'users.coaching';
export const queueViewACL = 'queue.view';

export const permisions = [
  editSkillACL,
  viewSkillACL,
  userMonitoringACL,
  userCoachingACL,
  queueViewACL,
  'supervisor',
];

const TabPanel = props => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`admin-tabpanel-${index}`}
      aria-labelledby={`admin-tab-${index}`}
      {...other}
      className={mergeClasses(styles.tabpanel)}
    >
      {value === index && (
        <Box py={1} className={mergeClasses(styles.tabpanel)}>
          {children}
        </Box>
      )}
    </div>
  );
};
const userACL = [userMonitoringACL, userCoachingACL, 'supervisor'];
const skillsACL = [editSkillACL, viewSkillACL, 'supervisor'];
const queueACL = [queueViewACL, 'supervisor'];
const AdminMain = props => {
  const { userRoles = [], flags } = props;
  let initialTab = '';
  switch (true) {
    case userACL.some(acl => userRoles.includes(acl)):
      initialTab = 'monitoring';
      break;
    case skillsACL.some(acl => userRoles.includes(acl)):
      initialTab = 'skills';
      break;
  }
  const [tab, setTab] = useState(initialTab);
  const tabHandler = newValue => () => {
    setTab(newValue);
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <AppBar className={styles.tabs} position="relative">
        <StyledTabs
          value={tab}
          aria-label="wrapped label tabs"
          style={{ backgroundColor: 'black', position: 'relative' }}
        >
          {userACL.some(acl => userRoles.includes(acl)) && (
            <Tab
              value="monitoring"
              label="user monitoring"
              {...defaultProps('monitoring')}
              onClick={tabHandler('monitoring')}
            />
          )}
          {flags.skillsManagement &&
            skillsACL.some(acl => userRoles.includes(acl)) && (
              <Tab
                value="skills"
                label="skills"
                {...defaultProps('skills')}
                onClick={tabHandler('skills')}
              />
            )}
          {flags.enableScanners && (
            <Tab
              value="scanners"
              label="call scanning"
              {...defaultProps('scanners')}
              onClick={tabHandler('scanners')}
            />
          )}
          {flags.enableStatistics && (
            <Tab
              value="statistics"
              label="team stats"
              {...defaultProps('statistics')}
              onClick={tabHandler('statistics')}
            />
          )}
          {flags.enableMessageCenter && (
            <Tab
              value="broadcast"
              label="broadcast"
              {...defaultProps('broadcast')}
              onClick={tabHandler('broadcast')}
            />
          )}
        </StyledTabs>
      </AppBar>
      <BoundaryError>
        <TabPanel value={tab} index="monitoring">
          <div className={styles.supervisor}>
            <UserMonitoring {...props} />
          </div>
        </TabPanel>
      </BoundaryError>
      <BoundaryError>
        <>
          {flags.skillsManagement && (
            <TabPanel value={tab} index="skills">
              <Skills />
            </TabPanel>
          )}
        </>
      </BoundaryError>
      <BoundaryError>
        <>
          {flags.enableScanners && (
            <TabPanel value={tab} index="scanners">
              <Scanners />
            </TabPanel>
          )}
        </>
      </BoundaryError>
      <BoundaryError>
        <>
          {flags.enableStatistics && (
            <TabPanel value={tab} index="statistics">
              <Stats />
            </TabPanel>
          )}
        </>
      </BoundaryError>
      <BoundaryError>
        <>
          {flags.enableMessageCenter && (
            <TabPanel value={tab} index="broadcast">
              <Broadcast />
            </TabPanel>
          )}
        </>
      </BoundaryError>
    </div>
  );
};

const mapStateToProps = state => {
  const { userState, integrationState } = state;
  const { roles = [] } = userState;
  const { acl = {} } = integrationState;
  const userRoles = [];
  for (const role of roles) {
    userRoles.push(role, ...((acl || {})[role] || []));
  }
  return {
    userRoles,
  };
};

const mapDispatchToProps = dispatch => ({});

export { AdminMain };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(AdminMain));
