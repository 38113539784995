import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
} from '@material-ui/core';
import { mergeClasses } from '../../utils';

import styles from './wrapup.scss';
import { updateWrapup } from '../../store/action/wrapup';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    backgroundColor: '#333',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  iconColor: {
    color: '#fff',
    opacity: 0.8,
  },
}));

const WrapupSelectGroup = props => {
  let { task, selectProps, updateWrapup, wrapupState } = props;
  let { options, id, label, required } = selectProps;
  const classes = useStyles();

  const { wrapup } = wrapupState;
  const wrapped_value = (task.wrap_up && task.wrap_up !== {}
    ? task.wrap_up
    : wrapup[task.id] || {})[id];

  return (
    <div id={id} className={mergeClasses(styles.wrapup_select_group)}>
      <div className={mergeClasses(styles.wrapup_radio_label)}>
        {label}
        {required && ' *'}
      </div>
      <FormControl
        className={mergeClasses(classes.formControl, styles.select_form)}
      >
        <Select
          MenuProps={{ classes: { paper: styles.select_form_toggled } }}
          value={wrapped_value || 'Select'}
          displayEmpty
          className={mergeClasses(styles.select_form)}
          classes={{ icon: classes.iconColor }}
          disabled={task.status !== 'ASSIGNED'}
          onChange={e => {
            e.preventDefault();
            console.log(e.target.value);

            wrapup[task.id][id] = e.target.value;
            updateWrapup({
              ...wrapupState,
            });
          }}
        >
          <MenuItem value="Select" disabled>
            Select
          </MenuItem>
          {options.map((option, index) => {
            const { value, label } = option;

            return (
              <MenuItem key={value} className={styles.menu_item} value={value}>
                {label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

const mapStateToProps = state => {
  return { wrapupState: state.wrapupState };
};

const mapDispatchToProps = dispatch => ({
  updateWrapup: state => {
    dispatch(updateWrapup(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WrapupSelectGroup);
