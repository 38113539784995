import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Collapse } from '@material-ui/core';

import styles from './call.scss';
import Participant from './participant';
import CallPanel from './callpanel';
import Addbox from './addbox';
import Endbox from './endbox';
import YouLeftMessage from './youleftmessage';
import CallError from './callError';
import IVR from './IVR';
import VM from './VM';
import FeedbackSnackbar from '../feedback/FeedbackSnackbar';

import store from '../../store';
import { BoundaryError } from '../reusable_components/boundaryerror';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const { dispatch, getState } = store;

const Call = props => {
  const {
    call,
    taskState,
    userState,
    helpers,
    expand,
    disableSwap,
    vmState,
    flags,
  } = props;

  const [feedback, setFeedback] = useState({ msg: '', severity: '' });
  const updateFeedback = (msg, severity = 'info') => {
    console.warn('Updating errro', msg, severity);
    setFeedback({ msg, severity });
  };

  const { showDelayedLeftMessage } = call;

  if (call.status !== 'active') {
    return null;
  }

  const { mergeClasses } = helpers;
  const { participants } = call;
  let { detailView, callError } = taskState;

  const agents = [];
  const otherParticipants = [];

  participants.forEach((participant, index) => {
    const participantName = participant?.id?.split('@')[0];
    const curClientUserName = userState?.id?.split('@')[0];

    // check just id and compare with user
    if (
      participant.id === userState.id ||
      participantName === curClientUserName
    ) {
      // check if this is the agent
      agents.push(participant);
    } else {
      // all other call participants

      otherParticipants.push(participant);
    }
  });

  const [agent] = agents; // this may change to some other value

  const selectedStyle = detailView === call.taskId ? styles.selected : '';

  const callType = otherParticipants.length > 1 ? 'conference' : 'single';

  let callOnHold = false;

  if ((!agent || agent.status !== 'connected') && !call.cant_connect) {
    callOnHold = true;
  }

  let isRinging =
    !taskState.events[call.id] ||
    helpers.isRinging(taskState.events, call, userState);

  if (taskState.currentCallTask) {
    if (call.id !== taskState.currentCallTask.call.id) {
      callOnHold = true;
    }
  }

  const thisCallTask = taskState.tasks.find(task => task.id === call.taskId);

  if (isRinging) {
    callOnHold = false;
  }

  if (showDelayedLeftMessage) {
    callOnHold = false;
  }

  let partJOined;
  return (
    <>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'right'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
        />
      )}
      <div
        id={`call_${call.id}`}
        className={mergeClasses(
          styles.call_item,
          styles[callType],
          selectedStyle,
          expand ? styles.expanded : styles.compacted
        )}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          const { taskState } = getState();

          dispatch({
            type: 'UPDATE_TASKS',
            taskState: {
              ...taskState,
              detailView:
                taskState.detailView === call.taskId ? null : call.taskId,
            },
          });
        }}
      >
        {agent ? (
          <Collapse in={call.id === taskState.add}>
            <Addbox {...props} callType={callType} />
          </Collapse>
        ) : (
          <></>
        )}

        {(agent || otherParticipants) &&
          call.id &&
          callError &&
          callError.find(
            currCall =>
              currCall[call.id] &&
              currCall[call.id].callId === call.id &&
              currCall[call.id].displayError
          ) && <CallError call={call} {...props} />}
        {otherParticipants.length
          ? otherParticipants.reverse().map((participant, index) => (
              <BoundaryError key={index}>
                <Participant
                  {...props}
                  participant={participant}
                  index={index}
                  callType={callType}
                  participantType="client"
                  callOnHold={callOnHold}
                  otherParticipants={otherParticipants}
                  agent={agent}
                  showDelayedLeftMessage={showDelayedLeftMessage}
                  disableSwap={disableSwap}
                  updateFeedback={updateFeedback}
                />
              </BoundaryError>
            ))
          : null}

        {agent ? (
          <Collapse in={call.id === taskState.end}>
            <Endbox {...props} participant={agent} />
          </Collapse>
        ) : (
          <></>
        )}

        <Collapse in={showDelayedLeftMessage}>
          <YouLeftMessage />
        </Collapse>

        {agent || isRinging || showDelayedLeftMessage ? (
          <>
            <CallPanel
              {...props}
              participantType="agent"
              callType={callType}
              participant={agent || {}}
              otherParticipants={otherParticipants}
              expand={expand}
              callOnHold={callOnHold}
              isRinging={isRinging}
              updateFeedback={updateFeedback}
            />
            {agent &&
              agent.status.toLowerCase() === 'connected' &&
              (call.type.toUpperCase() === 'CALL' ||
                call.type.toUpperCase() === 'FREE_FORM_CALL') &&
              callType !== 'conference' &&
              call.ivrs &&
              call.ivrs.length > 0 && <IVR {...props} ivrs={call.ivrs} />}

            {flags.enableOneClickVm &&
              vmState.length > 0 &&
              agent &&
              agent.status.toLowerCase() === 'connected' &&
              (call.type.toUpperCase() === 'CALL' ||
                call.type.toUpperCase() === 'FREE_FORM_CALL') &&
              call.direction.toUpperCase() === 'OUTBOUND' &&
              callType === 'single' &&
              otherParticipants.length > 0 &&
              otherParticipants.find(
                p => p.status === 'connected' && (partJOined = p.updated_at)
              ) && (
                <VM {...props} task={thisCallTask} partJOined={partJOined} />
              )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    taskState: state.taskState,
    userState: state.userState,
    vmState: state.vmState,
  };
};

const mapDispatchToProps = dispatch => ({
  updateConnectionFunc: state => {},
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Call));
