import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useState, default as React, useEffect } from 'react';
import { mergeClasses } from '../../../utils';
import styles from './skills.scss';
import {
  filterUsers as filterU,
  updateUserSkills as updateS,
  fetchIntegrationGroup as fetchIG,
} from '../../../services';
import { editSkillACL } from '..';
import { teamMngConnector } from './connectors';
import UserSkillRow from './UserSkillRow';
import FeedbackSnackbar from '../../feedback/FeedbackSnackbar';
import { Feedback } from '../../../utils';

export const Skills = props => {
  const {
    integration,
    team,
    teams = [],
    userRoles,
    filterUsers = filterU,
    updateUserSkills = updateS,
    fetchIntGroup = fetchIG,
    group,
    saveOtherGroupSkills,
    otherGroupSkills,
  } = props;
  const isAdmin = (userRoles || []).includes(editSkillACL);

  const [users, setUsers] = useState([]);
  const [selectedTeam, selectTeam] = useState(teams[0]);

  const [feedback, setFeedback] = useState({ msg: '', severity: '' });
  let otherGroups = [];

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  useEffect(() => {
    const handler = async () => {
      // fetch list of users
      const users = [];
      const limit = 100;
      let offset = 0;
      let chunk = null;
      try {
        while (chunk == null || chunk.length === limit) {
          chunk = await filterUsers({
            integration,
            team: selectedTeam === '' ? teams : selectedTeam,
            limit,
            offset,
          });
          chunk = chunk || [];
          offset = offset + limit;
          users.push(...chunk);
        }
        setUsers([...users]);
        users.forEach(u => {
          if (
            u.group &&
            group !== u.group &&
            !otherGroups?.includes(u.group) &&
            !otherGroupSkills[u.group]
          ) {
            otherGroups.push(u?.group);
            fetchIntGroup({ group: u.group, integration })
              .then(data => {
                const { skills = [] } = data;
                saveOtherGroupSkills({ group: u.group, skills });
              })
              .catch(err => {
                console.error(`${err.code}: ${err.desription}`, err);
                updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
              });
          }
        });
      } catch (e) {
        console.error(e);
        updateFeedback(`${e.code}: ${e.message}`, Feedback.ERROR);
      }
    };
    handler();
  }, [integration, teams, selectedTeam]);
  const updateSkills = userId => {
    return async skills => {
      try {
        const user = await updateUserSkills(userId, skills);
        setUsers(
          users.map(u => {
            if (u.id === userId) {
              return user;
            }
            return u;
          })
        );
      } catch (err) {
        console.error(err);
        updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
      }
    };
  };
  return (
    <>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'left'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
        />
      )}
      <Grid
        container
        direction="column"
        className={mergeClasses(styles.table_container)}
      >
        <TableContainer>
          <Table stickyHeader className={mergeClasses(styles.table)}>
            <TableHead>
              <TableRow>
                <TableCell className={mergeClasses(styles.cell)}>
                  Username
                </TableCell>
                <TableCell className={mergeClasses(styles.cell)}>
                  <Typography>Team</Typography>
                  {teams && teams.length > 1 ? (
                    <FormControl
                      className={mergeClasses(styles.team_selection)}
                    >
                      <Select
                        MenuProps={{
                          classes: { paper: styles.team_selection_toggled },
                        }}
                        value={selectedTeam || ''}
                        displayEmpty
                        className={mergeClasses(styles.team_selection)}
                        onChange={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          console.log('new value: ', e.target.value);
                          selectTeam(e.target.value);
                        }}
                      >
                        <MenuItem value="">All</MenuItem>
                        {teams.map(team => {
                          return (
                            <MenuItem
                              key={team}
                              className={styles.menu_item}
                              value={team}
                            >
                              {team}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <Typography>{teams[0]}</Typography>
                  )}
                </TableCell>
                <TableCell className={mergeClasses(styles.cell)}>
                  Group
                </TableCell>
                <TableCell className={mergeClasses(styles.cell)}>
                  Skills
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(users || []).map((u = {}) => (
                <UserSkillRow
                  key={u.id}
                  agent={u}
                  editable={isAdmin}
                  updateSkills={updateSkills(u.id)}
                  updateFeedback={updateFeedback}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </>
  );
};

export default teamMngConnector(Skills);
