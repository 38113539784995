import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import {
  FormControl,
  MenuItem,
  Select,
  TableHead,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { mergeClasses } from '../../../utils';
import styles from './stats.scss';
import { LightTooltip } from '../../reusable_components/LightTooltip';
import { selectTeam } from '../../../store/action/team';

const StatsHeader = props => {
  const {
    classes,
    order,
    orderBy,
    onRequestSort,
    statsHeader,
    teams,
    team,
    selectTeam,
    selectedTeam,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {statsHeader.map((heading, index) => {
          return (
            <Fragment key={index}>
              <TableCell
                className={mergeClasses(styles.cell)}
                align={heading.numeric ? 'right' : 'left'}
                padding={heading.disablePadding ? 'none' : 'default'}
                sortDirection={orderBy === heading.id ? order : false}
                aria-label={`${heading.id}`}
              >
                <LightTooltip
                  arrow
                  placement="top"
                  title={<>{heading.tooltip}</>}
                >
                  <Typography> {heading.label} </Typography>
                </LightTooltip>
                {heading.id === 'team' &&
                  (teams && teams.length > 1 ? (
                    <FormControl>
                      <Select
                        MenuProps={{
                          classes: { paper: styles.team_selection_toggled },
                        }}
                        value={selectedTeam || ''}
                        displayEmpty
                        className={mergeClasses(styles.team_selection)}
                        onChange={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          console.log('new value: ', e.target.value);
                          selectTeam(e.target.value);
                        }}
                        classes={{
                          icon: styles.icon_color,
                        }}
                      >
                        <MenuItem value="">All</MenuItem>
                        {teams.map(team => {
                          return (
                            <MenuItem
                              key={team}
                              className={styles.menu_item}
                              value={team}
                            >
                              {team}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  ) : (
                    <Typography>{teams[0]}</Typography>
                  ))}
              </TableCell>
            </Fragment>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

const mapStateToProps = state => {
  const statsHeader = [
    { id: 'user', label: 'User', tooltip: 'User' },
    { id: 'team', label: 'Team', tooltip: 'team' },
    {
      id: 'curr_status',
      label: 'Current Status',
      tooltip: 'Availability status right now',
    },
    {
      id: 'avg_call_task_complete_seconds',
      label: 'AHT',
      tooltip: '(Talk time + hold time) / total calls for the current day',
    },
    {
      id: 'call_task_talk_seconds_total',
      label: 'Talk time',
      tooltip: 'Total time on calls for the current day',
    },
    {
      id: 'dir_in_offers_total',
      label: 'Direct Inbound Calls',
      tooltip: 'Total direct inbound calls for the day',
    },
    {
      id: 'oat',
      label: 'OAT%',
      tooltip:
        'Total offers presented / total offers accepted for the current day',
    },
    {
      id: 'offers_accepted_total',
      label: 'Offers Accepted',
      tooltip: 'Total offers accepted by the agent for the current day',
    },
    {
      id: 'offers_total',
      label: 'Offers',
      tooltip: 'Total offers presented for the current day',
    },
    {
      id: 'out_call_offers_total',
      label: 'Outbound Calls',
      tooltip:
        'Total outbound calls initiated by the agent for the current day',
    },
    {
      id: 'rr_avg_accepted_seconds',
      label: 'RR Avg Accept Time',
      tooltip:
        'Average time to accept per round robin offer for the current day',
    },
    {
      id: 'rr_in_call_offers_total',
      label: 'RR Call Offers',
      tooltip: 'Total round robin call offers presented for the current day',
    },
    {
      id: 'sms_received_total',
      label: 'SMS Received',
      tooltip:
        'Total SMS messages received either direct or round robin for the current day',
    },
    {
      id: 'sms_sent_total',
      label: 'SMS Sent',
      tooltip: 'Total SMS messages replied to for the current day',
    },
    { id: 'expand', label: 'Expand', tooltip: 'Expand stats' },
  ];
  const { userState, teamState, integrationState } = state;
  const { team, teams = [] } = userState;
  const { selectedTeam = '' } = teamState;

  return {
    teams: teams && teams.length ? teams : team ? [team] : [],
    selectedTeam,
    refreshInterval: (integrationState.refreshInterval || 5) * 1000,
    statsHeader,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectTeam: team => dispatch(selectTeam(team)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StatsHeader);
