import React, { useEffect, useState } from 'react';
import styles from './participantStatus.scss';
import { connect } from 'react-redux';
import { getCallErrorMsgById, errorTypeMapping } from '../../utils';

const ParticipantStatus = ({ callError, call_info, call }) => {
  const displayError = getCallErrorMsgById(call.id, callError);
  const [callStatus, setCallStatus] = useState(null);

  useEffect(() => {
    (call_info || {}).status === 'suppressed' &&
      setCallStatus(
        <div call_id={call_info.id} className={styles.participant_status}>
          {errorTypeMapping((call_info || {}).status)}
        </div>
      );
  }, [(call_info || {}).status]);

  useEffect(() => {
    call_info &&
      call_info.events &&
      call_info.events.sort((a, b) => {
        if (a.created_at <= b.created_at) {
          return -1;
        }
        return 1;
      }) &&
      call_info.events.map(event => {
        const {
          type,
          participant_number = '',
          reason = '',
          call_id,
          id,
        } = event;
        switch (type) {
          case 'participant-leave':
            reason &&
              setCallStatus(
                <div
                  key={id}
                  call_id={call_id}
                  className={styles.participant_status}
                >
                  {participant_number && participant_number}
                  {'\n'}
                  {reason}
                </div>
              );
            break;

          case 'busy' ||
            'failed' ||
            'no-answer' ||
            'canceled' ||
            'suppressed' ||
            'call-suppressed':
            setCallStatus(
              <div
                key={id}
                call_id={call_id}
                className={styles.participant_status}
              >
                {errorTypeMapping(type)}
              </div>
            );
            break;

          default:
            break;
        }
      });
  }, [(call_info || {}).events]);

  return call.id ? (
    callError &&
      (callError.find(
        currCall => currCall[call.id] && currCall[call.id].callId === call.id
      ) ? (
        <div className={styles.participant_status}>
          {displayError ? displayError.errorType : 'Unknown Error'}
        </div>
      ) : (
        <>{callStatus && callStatus}</>
      ))
  ) : (
    <>{(call_info || {}).status === 'suppressed' ? callStatus : null}</>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { callState, taskState } = state;
  const { callError } = taskState;
  const { current_task } = ownprops;
  const task =
    (callState || []).find(t => t.id === current_task.id) || current_task;

  const { call, call_info } = task;

  return {
    call,
    call_info,
    callError,
  };
};

export default connect(mapStateToProps)(ParticipantStatus);
