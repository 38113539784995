export const skillsState = (state = {}, action) => {
  switch (action.type) {
    case 'SAVE_OTHER_GROUP_SKILLS': {
      const { skills, group } = action;
      const newState = { ...state };
      newState[group] = skills;
      return newState;
    }

    default:
      return state;
  }
};
