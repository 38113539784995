import { refreshGenesysToken } from '../../services/genesys';
import { updateGenesysConnection } from '../../store/action/genesysConnection';
import store from '../../store';
const { getState, dispatch } = store;

export const startGenesysTokenRefresher = (() => {
  let timer = null;

  return token => {
    if (!token?.expiration) {
      return;
    }

    if (timer) {
      clearTimeout(timer);
      timer = null;
    }

    // on millisecond
    const expiresIn = Math.floor(token.expiration - Date.now());

    timer = setTimeout(async () => {
      const newToken = await refreshGenesysToken();

      if (newToken && newToken.AccessToken && newToken.AccessTokenExpiresAt) {
        const currentGenesysWS = getState().genesysConnectionState.ws;
        if (currentGenesysWS) {
          currentGenesysWS.close();
        }

        dispatch(
          // 1. reset ws and wsStatus,
          // 2. udpate token,
          // 3. keep auth status authenticated
          updateGenesysConnection({
            authStatus: 'authenticated',
            wsStatus: '', // reset wsStatus and ws instance
            ws: null,
            token: {
              accessToken: newToken.AccessToken,
              expiration: newToken.AccessTokenExpiresAt,
            },
          })
        );

        // for genesysUserId
        const existingGenesysToken =
          JSON.parse(localStorage.getItem('genesys')) || {};

        localStorage.setItem(
          'genesys',
          JSON.stringify({
            accessToken: newToken.AccessToken,
            expiration: newToken.AccessTokenExpiresAt,
            genesysUserId: existingGenesysToken?.genesysUserId,
          })
        );
      }
    }, expiresIn - 60 * 1000);
    // }, 60 * 1000); // for debugging
  };
})();
