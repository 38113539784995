// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Textbox__input_disabled___oXtxF{color:rgba(255,255,255,.6) !important}.Textbox__text_box___1Ngze{width:100%}", ""]);
// Exports
exports.locals = {
	"input_disabled": "Textbox__input_disabled___oXtxF",
	"text_box": "Textbox__text_box___1Ngze"
};
module.exports = exports;
