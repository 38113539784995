import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './EmailAction.scss';
import { Button } from '../reusable_components';
import { showEmailEditor, updateEmailText } from '../../store/action/email';
import { updateDirectoryState } from '../../store/action/directory';
import { mergeClasses } from '../../utils/helpers';
import * as services from '../../services';
import { stateToHTML } from 'draft-js-export-html';
import { convertFromRaw } from 'draft-js';
import FeedbackSnackbar from '../feedback/FeedbackSnackbar';
import { Feedback, defaultType } from '../../utils/mappings';

const EmailAction = props => {
  const {
    task,
    label,
    style,
    updateState,
    emailState,
    directoryState,
    tasks,
    updateDirectoryState,
    updateEmailText,
    action,
    template_name,
    first_name,
    last_name,
    phone_number,
    email_address,
    showEmailEditor,
    emailTask,
    latestEmail,
    emailto,
    voice_number,
  } = props;

  const [subject, setSubject] = useState('');

  useEffect(() => {
    if (latestEmail) setSubject(latestEmail.subject);
  }, [latestEmail]);

  const [feedback, setFeedback] = useState({ msg: '', severity: '' });

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  let disableAction =
    (task.type === 'EMAIL' && task.email && task.email.cant_send) ||
    (task.status && task.status.toUpperCase() !== 'ASSIGNED');

  return (
    <>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'right'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
        />
      )}
      <Button
        allowMultipleClicks={false}
        className={mergeClasses(
          styles.email_action,
          disableAction ? styles.email_action_disabled : ''
        )}
        disableButton={!!disableAction}
        onClickHandler={async e => {
          e.preventDefault();
          e.stopPropagation();

          if (updateState === 'email' || updateState === 'forward') {
            const subjectPrefix = updateState === 'forward' ? 'FW: ' : 'RE: ';

            const { recipients = [], task_id = task.id, html } =
              emailTask.emailText || {};

            const getDraftEmail = (
              JSON.parse(localStorage.getItem(task.id)) || {}
            ).email;

            updateEmailText({
              ...emailState,

              [task.id]: {
                ...emailTask,
                showEmailEditor: !emailTask.showEmailEditor,
                emailText: {
                  ...emailTask.emailText,
                  task_id,
                  html:
                    html ||
                    (getDraftEmail &&
                      stateToHTML(convertFromRaw(getDraftEmail))),
                  recipients:
                    updateState === 'forward'
                      ? []
                      : (emailto.length && emailto.flat()) ||
                        (recipients.length && recipients) || [
                          {
                            email:
                              task.email &&
                              task.email.client &&
                              task.email.client.address,
                            name:
                              task.email &&
                              task.email.client &&
                              task.email.client.name,
                            type: defaultType,
                          },
                        ] ||
                        [],
                  subject: subject.toUpperCase().includes(subjectPrefix)
                    ? subject
                    : `${subjectPrefix} `.concat(subject),
                },
              },
            });
            return;
          }

          if (updateState === 'directory') {
            updateDirectoryState({
              ...directoryState,
              display: true,
              forwardTask: true,
              task,
            });
            return;
          }

          if (action) {
            const { subject, task_id, html, recipients } = emailTask.emailText;

            switch (true) {
              case !subject:
                updateFeedback(
                  `Subject required to send an email`,
                  Feedback.WARNING
                );
                return;
              case !task_id:
                updateFeedback(
                  `Task ID not found. Refresh or try again in sometime`,
                  Feedback.INFO
                );
                return;
              case !html:
                updateFeedback(`Email Body cannot be empty`, Feedback.WARNING);
                return;
              case !recipients.length:
                updateFeedback(`Add atleast one recipient`, Feedback.WARNING);
                return;

              default:
                break;
            }

            await services[action]({
              subject,
              task_id,
              html,
              recipients,
              ...(template_name && {
                template_data: {
                  template_name,
                  vars: {
                    email_body: html,
                    direct_number: phone_number,
                    voice_number, // value will be undefined if voice_number is not present
                    email_address,
                    first_name,
                    last_name,
                  },
                },
              }),
            })
              .then(res => res.json())
              .then(data => {
                console.log('Email response ', data);
                const draftEmailContent =
                  JSON.parse(localStorage.getItem(task.id)) || {};
                delete draftEmailContent.email;
                localStorage.setItem(
                  task.id,
                  JSON.stringify(draftEmailContent)
                );
                const emailTask = { ...(emailState[task.id] || {}) };

                tasks.map(task => {
                  if (task.id === task_id) {
                    const { email_list = [] } = task;
                    email_list.push(data);

                    updateEmailText({
                      ...emailState,
                      [task.id]: {
                        ...emailTask,
                        email_list,
                        showEmailEditor: !emailTask.showEmailEditor,
                        emailText: {},
                      },
                    });
                  }
                });
              })
              .catch(err => {
                console.error('Error while sending email', err);
                updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
              });

            return;
          }

          showEmailEditor({ task_id: task.id });
        }}
      >
        <div
          className={mergeClasses(
            styles.action_icon,
            styles[style || label.toLowerCase()]
          )}
        ></div>
        {(label || '').toUpperCase()}
      </Button>
    </>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { userState, emailState } = state;
  const { phone_number, first_name, last_name, voice_number } = userState;
  const { tasks } = state.taskState;
  const { task } = ownprops;
  const emailTask = { ...(emailState[task.id] || {}) };
  const { email_list = [] } = emailTask || task;
  const latestEmail =
    email_list?.length &&
    email_list.reduce((a, i) => {
      return a.created_at > i.created_at ? a : i;
    });

  let emailto = [];

  if (latestEmail) {
    const agentAddress =
      task.email && task.email.agent && task.email.agent.address;

    const allRecipientsButAgent = latestEmail.recipients.filter(
      r => r.email !== agentAddress
    );

    latestEmail.sender && latestEmail.sender.email !== agentAddress
      ? emailto.push(
          {
            name: latestEmail.sender.name,
            email: latestEmail.sender.email,
            type: defaultType,
          },
          allRecipientsButAgent
        )
      : emailto.push(allRecipientsButAgent);
  }

  return {
    emailTask,
    latestEmail,
    emailto,
    emailState: state.emailState,
    directoryState: state.directoryState,
    tasks,
    template_name: (state.templateState[task.template] || {})
      .email_template_name,
    first_name,
    last_name,
    phone_number,
    voice_number,
    email_address: ((task.email || {}).agent || {}).address,
  };
};

const mapDispatchToProps = dispatch => ({
  showEmailEditor: ({ task_id }) => {
    dispatch(showEmailEditor({ task_id }));
  },
  updateDirectoryState: directoryState => {
    dispatch(updateDirectoryState(directoryState));
  },
  updateEmailText: emailState => {
    dispatch(updateEmailText(emailState));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailAction);
