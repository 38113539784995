import {
  PARTICIPANT_ROLE,
  PARTICIPANT_STATUS,
} from '../../utils/genesys/call.mappings';

// in some cases agent's status in Genesys WS event is dialing after call connected.
// use a state machine to prevent updating agent's status to a wrong one
const agentStatusStateMachine = {
  queued: ['connected', 'disconnected', 'canceled', 'ringing', 'pending'],
  ringing: ['connected', 'disconnected', 'canceled', 'queued', 'pending'],
  pending: ['connected', 'disconnected', 'canceled', 'queued', 'ringing'],
  connected: ['disconnected', 'canceled'],
};

const isNextStatusAllowed = (currentStatus, nextStatus) => {
  if (currentStatus.toLowerCase() === nextStatus.toLowerCase()) {
    return true;
  }

  const allowedNextStatuses =
    agentStatusStateMachine[currentStatus.toLowerCase()];
  if (!allowedNextStatuses || allowedNextStatuses.length === 0) {
    // if current status not supported
    return false;
  }

  if (allowedNextStatuses.includes(nextStatus.toLowerCase())) {
    return true;
  }

  return false;
};

export const callState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_CALL_TASK': {
      const { task } = action;
      const newState = [...state];
      const index = newState.findIndex(item => item.id === task.id);
      if (index === -1) newState.push(task);
      else newState[index] = task;

      return newState;
    }
    case 'UPDATE_CALL_INFO': {
      const { call_info, task_id, call_id } = action;
      const newState = [...state];
      const id =
        task_id ||
        (newState.find(t => t.call_info && t.call_info.id === call_id) || {})
          .id;
      newState.map(t => {
        if (t.id === id || (t.call && t.call.id === call_id)) {
          t.call_info = call_info;
          t.call && !t.call.id && (t.call.id = call_id);
        }
      });
      return newState;
    }
    case 'UPDATE_GENESYS_CALL_INFO': {
      const { participants, genesysCallId } = action;
      const newState = [...state];

      // find the genesys call id from call state using genesysCallId
      const id = (
        newState.find(
          t => t.call_info && t.call_info.genesys_call_id === genesysCallId
        ) || {}
      ).id;
      newState.map(t => {
        if (
          t.id === id ||
          (t.call && t.call.genesys_call_id === genesysCallId)
        ) {
          // update participant held, muted, status from genesys
          const updatedParticipants = t.call_info.participants.map(p => {
            // NOTE. in some cases genesys_participant_id is empty due to genesys ws message arriving before controller populating genesys_participant_id
            // skip if there's no matching participant
            const genesysP = participants.find(
              gp => gp.id === p.genesys_participant_id
            );

            // TODO: Need to check with backend if we need to updaqte any other mappings

            if (genesysP) {
              const currentStatus = p.status;
              const newStatusFromGenesys = PARTICIPANT_STATUS[genesysP.state];

              const isAllowed = isNextStatusAllowed(
                currentStatus,
                newStatusFromGenesys
              );

              const newStatus = isAllowed
                ? newStatusFromGenesys
                : currentStatus;

              return {
                ...p,
                muted: genesysP.muted,
                // hold: genesysP.held, // ignore hold flag from Genesys WS
                role: PARTICIPANT_ROLE[genesysP.purpose],
                status: newStatus,
                updated_at: genesysP.endTime || Date.now(),
              };
            } else {
              return p;
            }
          });

          // replaced genesys mappings with old mappings
          t.call_info.participants = updatedParticipants;
        }
      });
      return newState;
    }
    case 'UPDATE_CALL_STATE': {
      const { callState } = action;
      return [...callState];
    }
    case 'ARCHIVE_CALL_TASK': {
      const { task } = action;
      const newState = [...state].filter(t => t.id !== task.id);

      return newState;
    }

    default:
      return state;
  }
};
