import React from 'react';
import styles from '../ComposeEmail.scss';
import StyleButton from './StyleButton';
import { INLINE_STYLES } from '../../../../utils/mappings';

const InlineStyleControls = props => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className={styles.style_button}>
      {INLINE_STYLES.map(type => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      ))}
    </div>
  );
};

export default InlineStyleControls;
