import { updateAuth, getOktaHeader } from '../helpers';
import { ResponseError } from './error';

const request = async (url, options = {}) => {
    try {
        options.headers = { ...(options.headers || {}), ...(getOktaHeader().headers) }
        const res = await fetcher(url, options);
        return res;
    } catch (err) {
        if (err.code === 401) {
            console.warn(options.headers)
            options.headers = { ...options.headers, ...getOktaHeader().headers }
        }
        if (err.code != 401 && (err.code || 0) < 500) {
            throw err;
        }
        return await fetcher(url, options);
    }
}

const fetcher = async (url, opt) => {
    try {
        let response = await fetch(url, opt);
        let body;
        try {
            body = await response.json();
        } catch (e) {
            body = await response.text();
        }
        if (response.status === 401) {
            await updateAuth();
        }
        if (response.status >= 400) {
            throw new ResponseError(
                response.statusText,
                response.status,
                body
            );
        }
        return body;
    } catch (err) {
        if (err.constructor === TypeError) {
            await updateAuth();
            throw new ResponseError('Unauthorized', 401, { error: 'Unauthorized' });
        }
        throw err;
    }
};

export { request }