export const updateScannerState = ({ scanners }) => {
  return {
    type: 'UPDATE_SCANNER_STATE',
    scanners,
  };
};

export const createNewKeyword = keyword => {
  return {
    type: 'CREATE_NEW_SCANNER_KEYWORD',
    keyword,
  };
};

export const updateNewKeyword = ({ newKeyword }) => {
  return {
    type: 'UPDATE_NEW_SCANNER',
    newKeyword,
  };
};

export const deleteKeywordById = ({ id }) => {
  return {
    type: 'DELETE_SCANNER_BY_ID',
    id,
  };
};

export const updateKeywordById = ({ id, scanner }) => {
  return {
    type: 'UPDATE_SCANNER_BY_ID',
    id,
    scanner,
  };
};
