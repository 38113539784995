import { connect } from 'react-redux';
import {
  updateTeamView,
  updateTeamWithUsers,
} from '../../../store/action/team';
import {
  updateAgentTaskId,
  updateAgentData,
  updateAgentArchives,
} from '../../../store/action/agent';
import { sortByKey } from '../../../utils';

export const mapUserListStateToProps = state => {
  const { userState, integrationState, teamState } = state;
  const { id, statusList, roles = [], team, teams = [] } = userState;
  const { acl = {}, skills = [] } = integrationState;

  const userRoles = [];
  for (const role of roles) {
    userRoles.push(role, ...((acl || {})[role] || []));
  }

  const {
    users,
    selectedTeam = '',
    teamview = 'tasks',
    sortBy,
    args = {},
  } = teamState;

  const usersShallowCopy = sortByKey(users, sortBy.name)
    .filter(
      u =>
        u.id !== userState.id &&
        (selectedTeam === '' ||
          u.team === selectedTeam ||
          (u.teams || []).includes(selectedTeam))
    )
    .map(u => {
      // const { username, online, id, status, team, active, tasks, offers } = u;
      const { id, tasks = [], offers = [] } = u || {};
      return {
        id,
        tasks,
        offers,
      };
    });

  return {
    title: team,
    id,
    statusMap: statusList,
    userRoles,
    selectedTeam,
    teams: teams.length ? teams : team ? [team] : [],
    users: usersShallowCopy,
    refreshInterval: (integrationState.refreshInterval || 5) * 1000,
    teamview,
    sortBy,
    args: {
      ...args,
      team: {
        ...args?.team,
        value:
          selectedTeam === '' || !selectedTeam
            ? teams.length
              ? teams
              : team
              ? [team]
              : []
            : [selectedTeam],
      },
    },
  };
};

const mapUserListDispatchToProps = dispatch => {
  return {
    updateTeamWithUsers: users => dispatch(updateTeamWithUsers(users)),
    updateAgentTaskId: ({ taskId }) => dispatch(updateAgentTaskId({ taskId })),
    updateAgentData: ({ data }) => dispatch(updateAgentData({ data })),
    updateTeamView: teamview => dispatch(updateTeamView(teamview)),
  };
};

export const UserListConnector = connect(
  mapUserListStateToProps,
  mapUserListDispatchToProps
);

const mapUserMonitoringStateToProps = state => {
  const {
    agentState,
    userState,
    integrationState,
    templateState,
    teamState,
  } = state;
  const { team, teams } = userState;
  const { users, selectedTeam = '', teamview = 'tasks' } = teamState;
  const filteredUsers =
    selectedTeam === ''
      ? users
      : users.filter(
          u => u.team === selectedTeam || (u.teams || []).includes(selectedTeam)
        );

  const error = agentState.error && agentState.error;

  const taskSelected =
    agentState.data &&
    agentState.data.tasks &&
    agentState.data.tasks.find(task => task.id === agentState.taskId)
      ? agentState.data.tasks.find(task => task.id === agentState.taskId)
      : agentState.data &&
        agentState.data.offers &&
        agentState.data.offers.find(
          offer => offer.task_id === agentState.taskId
        );

  const archiveSelected =
    agentState.archives &&
    agentState.archives.find(task => task.id === agentState.taskId);

  const selected = agentState.tab === 'tasks' ? taskSelected : archiveSelected;

  return {
    id: userState.id,
    agentState,
    error,
    selected,
    selectedTaskId: agentState.taskId,
    team,
    teams,
    selectedTeam,
    users: filteredUsers,
    refreshInterval: (integrationState.refreshInterval || 5) * 1000,
    template: (templateState || {})[(selected || {}).template],
    teamview,
  };
};

const mapUserMonitoringDispatchToProps = dispatch => {
  return {
    updateTeamWithUsers: users => dispatch(updateTeamWithUsers(users)),
    updateAgentData: ({ data }) => dispatch(updateAgentData({ data })),
  };
};

export const UserMonitoringConnector = connect(
  mapUserMonitoringStateToProps,
  mapUserMonitoringDispatchToProps
);

const mapArchivesStateToProps = state => {
  const { agentState, teamState } = state;
  const { filter, date, archives = [], data } = agentState || {};
  const { status, availabilityStatus, iconText, noOfTasks, user } = teamState;

  return {
    filter,
    archives,
    status,
    availabilityStatus,
    iconText,
    noOfTasks,
    user,
    date,
    id: (data || {}).id,
  };
};

const mapArchivesDispatchToProps = dispatch => {
  return {
    updateAgentArchives: ({ archives, filter }) =>
      dispatch(updateAgentArchives({ archives, filter })),
    updateAgentTaskId: ({ taskId }) => dispatch(updateAgentTaskId({ taskId })),
    updateAgentData: ({ data }) => dispatch(updateAgentData({ data })),
    updateTeamView: teamview => dispatch(updateTeamView(teamview)),
  };
};

export const ArchivesConnector = connect(
  mapArchivesStateToProps,
  mapArchivesDispatchToProps
);
