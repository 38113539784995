import React from 'react';
import { connect } from 'react-redux';
import TimeCounter from './timercounter';
import styles from './participant.scss';
import CallPanel from './callpanel';
import { BoundaryError } from '../reusable_components/boundaryerror';
import IVR from './IVR';
import IVRInfo from './IVRInfo';
import { Feedback } from '../../utils';

const Participant = props => {
  const {
    participantType,
    callType,
    participant,
    helpers,
    taskState,
    callOnHold,
    otherParticipants,
    index,
    services,
    agent,
    call,
    userState,
    calls,
    showDelayedLeftMessage,
    disableSwap,
    updateTasks,
    callState,
    currCallId,
    updateFeedback,
  } = props;

  if (showDelayedLeftMessage && participantType !== 'agent') {
    return <></>;
  }

  const {
    mergeClasses,
    getCurrentCallId,
    getCurrentCallIdsWithEvents,
  } = helpers;
  let callOriginStyle =
    participant.type === 'internal' ? styles.internal : styles.external;

  let participantName =
    (participant.metadata && participant.metadata.name) ||
    (participant.metadata && participant.metadata.label) ||
    participant.number ||
    '';

  let participantNameStyle =
    participantName.length > 20 ? styles.name_long : '';

  return (
    <div
      className={mergeClasses(
        styles.participant_item,
        callOriginStyle,
        styles[callType]
      )}
    >
      <div className={styles.participant_desc}>
        <div className={styles.participant_data}>Welcome</div>
        <div className={styles.participant_state}>
          {participant.displayStatus || participant.status || `State`}
        </div>
      </div>
      <div className={styles.participant_info}>
        <div
          className={mergeClasses(
            styles.participant_name,
            participantNameStyle
          )}
        >
          {participantName}
        </div>
        {callOnHold && !agent ? (
          index === otherParticipants.length - 1 ? (
            <button
              id={`swap_${call.id}`}
              className={mergeClasses(
                styles.swap_button,
                'swap',
                disableSwap ? styles.swap_disabled : ''
              )}
              disabled={disableSwap}
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();

                let { currentCallTask, callError, tasks } = taskState;
                // let currentCallList = getCurrentCallId(tasks, userState);

                let currentCallList = getCurrentCallIdsWithEvents(
                  taskState,
                  userState
                );

                let taskToJoinCall = helpers.getTaskByCallInfo(call, callState);

                let removeErrorList = [];
                tasks.forEach(task => {
                  callError.forEach((error, index) => {
                    if (
                      task.call_info &&
                      task.call_info.id &&
                      task.call_info.id in error
                    ) {
                      removeErrorList.push(index);
                    }
                  });
                });

                callError = callError.filter((error, index) => {
                  let res = removeErrorList.indexOf(index) > -1;

                  return !res;
                });

                taskState.currentCallTask = taskToJoinCall;
                taskState.callError = callError;
                let clicked = helpers.getClicked();
                clicked[taskToJoinCall.id] = true;
                localStorage.setItem('clicked', JSON.stringify(clicked));

                updateTasks({
                  ...taskState,
                  detailView: taskToJoinCall.id,
                  currentCallTask: taskToJoinCall,
                  callError,
                });

                if (!currCallId || currCallId === call.id) {
                  // clean up the error
                  let joinRes = await services
                    .joinCallByTask(taskToJoinCall)
                    .then(res => {
                      return res.json();
                    })
                    .catch(err => {
                      console.error(err);
                      updateFeedback(
                        `${err.code}: ${err.message}`,
                        Feedback.ERROR
                      );
                    });

                  taskState.tasks.forEach((taskItem, taskIndex) => {
                    if (taskItem.call && taskItem.call.id === joinRes.id) {
                      taskItem.call_info = joinRes;
                    }
                  });
                  updateTasks({
                    ...taskState,
                    detailView: taskToJoinCall.id,
                    currentCallTask: taskToJoinCall,
                  });
                } else {
                  updateTasks({
                    ...taskState,
                    detailView: taskToJoinCall.id,
                    currentCallTask: taskToJoinCall,
                  });

                  let res = await services
                    .swapCalls(currCallId, call.id)
                    .catch(err =>
                      updateFeedback(
                        `${err.code}: ${err.message}`,
                        Feedback.ERROR
                      )
                    );

                  if (!res) {
                    alert('Oops, something went wrong swapping calls');
                  } else {
                    let newTasks = [
                      taskToJoinCall,
                      helpers.getTaskByCallInfo(
                        { call_id: currCallId },
                        callState
                      ),
                    ];

                    let resolvedTasks = await Promise.all(
                      newTasks.map(task => {
                        return services.fetchUpdatedTaskById(task.id);
                      })
                    );

                    resolvedTasks.forEach(taskItem => {
                      taskState.tasks.forEach((item, itemIndex) => {
                        if (taskItem.call.id === res.id) {
                          taskItem.call_info = res;
                        }
                        if (item.id === taskItem.id) {
                          taskState.tasks[itemIndex] = taskItem;
                        }
                      });
                    });

                    let newState = {
                      ...taskState,
                      detailView: taskToJoinCall.id,
                      currentCallTask: taskToJoinCall,
                    };

                    updateTasks(newState);
                  }
                }
              }}
            >
              <div className={mergeClasses(styles.swap_icon)}></div>
              {calls.length > 1 ? 'SWAP' : 'JOIN'}
            </button>
          ) : null
        ) : null}
      </div>
      <div className={styles.single_meta}>
        {(call.type.toUpperCase() === 'CALL' ||
          call.type.toUpperCase() === 'FREE_FORM_CALL') &&
          call.ivrs &&
          call.ivrs.length > 0 &&
          participant &&
          participant.ivrs_result && (
            <BoundaryError>
              <IVRInfo
                mergeClasses={mergeClasses}
                ivrs_result={participant.ivrs_result}
              />
            </BoundaryError>
          )}
        <TimeCounter
          {...{
            helpers,
            start: participant.joinedAt || participant.updated_at,
            style: styles.call_duration,
          }}
        />
      </div>
      {callType === 'conference' && agent && call.type !== 'COACHING' ? (
        <>
          {taskState.expand && (!callOnHold || showDelayedLeftMessage) ? (
            <BoundaryError>
              <CallPanel
                {...props}
                callOnHold={callOnHold}
                showDelayedLeftMessage={showDelayedLeftMessage}
                updateFeedback={updateFeedback}
              />
            </BoundaryError>
          ) : null}
          {agent &&
            agent.status.toLowerCase() === 'connected' &&
            (call.type.toUpperCase() === 'CALL' ||
              call.type.toUpperCase() === 'FREE_FORM_CALL') &&
            participant &&
            participant.type &&
            participant.type.toLowerCase() !== 'internal' &&
            participant.ivrs &&
            participant.ivrs.length > 0 && (
              <BoundaryError>
                <IVR {...props} ivrs={participant.ivrs} />
              </BoundaryError>
            )}
        </>
      ) : null}
    </div>
  );
};

const mapStateToProps = state => {
  let currCallId;
  state.callState.forEach(task => {
    task &&
      task.call_info &&
      task.call_info.participants &&
      task.call_info.status !== 'completed' &&
      task.call_info.participants.forEach(participant => {
        if (
          participant.id === state.userState.id &&
          participant.status === 'connected'
        ) {
          currCallId = task.call_info.id;
        }
      });
  });
  return { ...state, currCallId };
};

const mapDispatchToProps = dispatch => ({
  updateTasks: state => {
    dispatch({
      type: 'UPDATE_TASKS',
      taskState: state,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Participant);
