const removeDuplicate = (array, key) => {
  return [...new Map(array.map(arr => [key(arr), arr])).values()];
};

export const emailState = (state = {}, action) => {
  switch (action.type) {
    case 'INCREASE_EMAIL_COUNT': {
      const { task_id } = action;
      const newState = { ...state };
      const item = { ...(state[task_id] || {}) };
      item.count ? ++item.count : (item.count = 1);
      ++newState.totalCount;
      newState[task_id] = item;
      return { ...newState };
    }
    case 'DECREASE_EMAIL_COUNT': {
      const { task_id } = action;
      const newState = { ...state };
      const item = { ...(state[task_id] || {}) };
      newState.totalCount = newState.totalCount - (item.count || 0);
      item.count = 0;
      newState[task_id] = item;
      return { ...newState };
    }
    case 'SHOW_EMAIL_EDITOR': {
      const { task_id } = action;
      const newState = { ...state };
      let item = { ...(state[task_id] || {}) };
      item = {
        ...item,
        showEmailEditor: !item.showEmailEditor,
      };

      newState[task_id] = item;
      return newState;
    }
    case 'UPDATE_EMAIL_TEXT': {
      const newState = { ...state, ...action.emailState };
      return newState;
    }
    case 'UPDATE_RECIPIENTS': {
      const { recipients } = action;
      const { emailText } = { ...state };
      const newState = { ...state, ...action.recipients };
      return newState;
    }
    case 'ADD_RECIPIENTS': {
      const { recipients, task_id, recipient_type } = action;
      const newState = { ...state };
      const item = { ...(state[task_id] || {}) };
      const { emailText = {} } = item;
      emailText.recipients = removeDuplicate(
        [...(emailText.recipients || []), recipients || []].flat().map(r =>
          r.type === 'communicator' || !r.type
            ? {
                ...r,
                type: recipient_type.toLowerCase(),
              }
            : r
        ),
        recipient => recipient.email
      );

      newState[task_id] = item;
      return newState;
    }
    case 'DELETE_RECIPIENTS': {
      const { recipients, task_id, recipient_type } = action;
      const newState = { ...state };
      const item = { ...(state[task_id] || {}) };
      const { emailText = {} } = item;
      emailText.recipients = emailText.recipients
        .filter(r => r.type.toLowerCase() !== recipient_type.toLowerCase())
        .concat(recipients);

      newState[task_id] = item;
      return newState;
    }
    case 'PUSH_EMAIL': {
      const { task_id, email } = action;
      const item = { ...(state[task_id] || {}) };
      item.email_list = [...(item.email_list || []), email];
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'INIT_EMAIL_LIST': {
      const { task_id, list } = action;
      const item = { ...(state[task_id] || {}) };
      item.email_list = (list || []).sort(
        (a, b) => a.created_at - b.created_at
      );
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'UPDATE_EMAIL_LIST': {
      const { task_id, email } = action;
      const item = { ...(state[task_id] || {}) };
      (item.email_list || []).map(
        item => item.id === email.id && (item = email)
      );
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'PUSH_EMAIL_LIST': {
      const { task_id, email_list } = action;
      const item = { ...(state[task_id] || {}) };
      item.email_list = removeDuplicate(
        [
          ...(item.email_list || []),
          ...(email_list || []).sort((a, b) => a.created_at - b.created_at),
        ],
        email => email.id
      );
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'UNSHIFT_EMAIL_LIST': {
      const { task_id, email_list } = action;
      const item = { ...(state[task_id] || {}) };
      item.email_list = removeDuplicate(
        [
          ...(email_list || []).sort((a, b) => a.created_at - b.created_at),
          ...(item.email_list || []),
        ],
        email => email.id
      );
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'UPDATE_EMAIL_REPLY': {
      const { task_id, recipients, subject } = action;
      const item = { ...(state[task_id] || {}) };
      const newState = { ...state };

      newState[task_id] = {
        ...item,
        showEmailEditor: !item.showEmailEditor,
        emailText: {
          ...item.emailText,
          task_id,
          recipients,
          subject,
        },
      };
      return { ...newState };
    }
    case 'UPDATE_EMAIL_HTML': {
      const { task_id, html } = action;
      const item = { ...(state[task_id] || {}) };
      const newState = { ...state };

      newState[task_id] = {
        ...item,
        emailText: {
          ...item.emailText,
          html,
        },
      };
      return { ...newState };
    }
    default:
      return state;
  }
};
