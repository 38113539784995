const removeDuplicate = (array, key) => {
  return [...new Map(array.map(arr => [key(arr), arr])).values()];
};

export const smsState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_SMS_TEXT': {
      const { task_id, text } = action;
      const newState = { ...state };
      const item = { ...(state[task_id] || {}) };
      item.text = text;
      newState[task_id] = item;
      return { ...newState };
    }
    case 'REMOVE_SMS_TEXT': {
      delete (state[action.task_id] || {}).text;
      return { ...state };
    }
    case 'INIT_SMS_LIST': {
      const { task_id, list } = action;
      const item = { ...(state[task_id] || {}) };
      item.sms_list = (list || []).sort((a, b) => a.created_at - b.created_at);
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'PUSH_SMS': {
      const { task_id, sms } = action;
      const item = { ...(state[task_id] || {}) };
      item.sms_list = [...(item.sms_list || []), sms];
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'PUSH_SMS_LIST': {
      const { task_id, sms_list } = action;
      const item = { ...(state[task_id] || {}) };
      item.sms_list = removeDuplicate(
        [
          ...(item.sms_list || []),
          ...(sms_list || []).sort((a, b) => a.created_at - b.created_at),
        ],
        sms => sms.id
      );
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'UNSHIFT_SMS_LIST': {
      const { task_id, sms_list } = action;
      const item = { ...(state[task_id] || {}) };
      item.sms_list = removeDuplicate(
        [
          ...(sms_list || []).sort((a, b) => a.created_at - b.created_at),
          ...(item.sms_list || []),
        ],
        sms => sms.id
      );
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'UPDATE_SMS_LIST': {
      const { task_id, sms } = action;
      const item = { ...(state[task_id] || {}) };
      (item.sms_list || []).map(item => item.id === sms.id && (item = sms));
      const newState = { ...state };
      newState[task_id] = item;
      return newState;
    }
    case 'SMS_ERROR': {
      const { task_id, error, sms_id, status } = action;
      const newSMSList = [];
      state[task_id]?.sms_list.forEach(sms => {
        if (sms.id === sms_id) {
          sms.error = error;
          sms.status = status;
        }
        newSMSList.push({ ...sms });
      });

      const newState = { ...state };
      newState[task_id] = {
        sms_list: newSMSList,
      };

      return { ...newState };
    }
    case 'UPDATE_SMS_REPLY_LABEL': {
      const { task_id, label } = action;
      const newState = { ...state };
      newState[task_id].label = label;
      return { ...newState };
    }
    case 'UNSHIFT_SMS': {
      const { task_id, list } = action;
      const newState = { ...state };
      const item = { ...(state[task_id] || {}) };
      item.sms_list = [...(list, []), ...(item.sms_list || [])];
      newState[task_id] = item;
      return newState;
    }
    case 'DELETE_SMS_DATA': {
      const { task_id } = action;
      const newState = { ...state };
      delete newState[task_id];
      return newState;
    }
    case 'INCREASE_SMS_COUNT': {
      const { task_id } = action;
      const newState = { ...state };
      const item = { ...(state[task_id] || {}) };
      item.count ? ++item.count : (item.count = 1);
      ++newState.totalCount;
      newState[task_id] = item;
      return { ...newState };
    }
    case 'DECREASE_SMS_COUNT': {
      const { task_id } = action;
      const newState = { ...state };
      const item = { ...(state[task_id] || {}) };
      newState.totalCount = newState.totalCount - (item.count || 0);
      item.count = 0;
      newState[task_id] = item;
      return { ...newState };
    }
    case 'SET_SCROLL_TO_THE_LAST_SMS': {
      return { ...state, scrollToNewSMS: true };
    }
    case 'UNSET_SCROLL_TO_THE_LAST_SMS': {
      return { ...state, scrollToNewSMS: false };
    }
    default:
      return state;
  }
};
