import * as services from '../services';
import { getAudio, playSound, stopSound } from './sounds';
import { updateOffer, userLogout } from '../services/index';
import { logoutReason } from '../utils';
import {
  archiveTask,
  offerPresented,
  cancelOffer,
  updateOfferStatus,
  updateTaskAndEvents,
  updateTasks,
} from '../store/action/task';
import { updateVMRecordings } from '../store/action/vm';
import { updateErrorMsg } from '../store/action/error';
import { updateUserActivityFlags } from '../store/action/user';
import { setOpenHotdeskModalFlag } from '../store/action/hotdesk';
import {
  initSMSList,
  increaseSMSCount,
  decreaseSMSCount,
} from '../store/action/sms';
import {
  initEmailList,
  increaseEmailCount,
  decreaseEmailCount,
} from '../store/action/email';
import { participantStatusMapping } from './mappings';
import { updateCallTask } from '../store/action/call';
import { refreshClock } from './autoLogoutClock';

export const taskAssigned = async (message, helpers, store) => {
  console.log('Task Assigned', message);
  const { getState, dispatch } = store;
  const {
    taskState,
    templateState,
    userState,
    supervisorState,
    wrapupState,
    callState,
  } = getState();
  const { tasks, events, callError } = taskState;
  const { wrapup } = wrapupState;
  const { id, template, type, task_id } = message;

  if (template && templateState[template] === undefined) {
    let newTemplate = await services.fetchTemplateWithName(
      userState.integration,
      template
    );

    if (newTemplate) {
      templateState[template] = newTemplate;
    } else {
      templateState[template] = 'N/A';
    }

    dispatch({
      type: 'UPDATE_TEMPLATE',
      templateState: {
        ...templateState,
      },
    });
  }

  (type.toUpperCase() === 'CALL' ||
    type.toUpperCase() === 'COACHING' ||
    type.toUpperCase() === 'FREE_FORM_CALL') &&
    dispatch(updateCallTask(message));

  if (
    (type.toUpperCase() === 'CALL' ||
      type.toUpperCase() === 'FREE_FORM_CALL') &&
    message.call &&
    (message.call.direction || '').toUpperCase() === 'INBOUND' &&
    message.call_info &&
    (message.call_info.status || '').toUpperCase() === 'COMPLETED' &&
    message.call.id
  ) {
    let eventLists = await services.fetchEventsByCallId(message.call.id);
    if (!eventLists.find(callEvent => callEvent.user_id === userState.id)) {
      const newError = {
        [message.call.id]: {
          errorType: helpers.errorTypeMapping('client-hung-up'),
          taskId: message.id,
          callId: message.call.id,
          displayError: true,
        },
      };

      callError.find(
        currError =>
          currError[message.call.id] &&
          currError[message.call.id].callId === message.call.id
      )
        ? callError.forEach(currError => {
            if (
              currError[message.call.id] &&
              currError[message.call.id].callId ===
                newError[message.call.id].callId &&
              currError[message.call.id].taskId ===
                newError[message.call.id].taskId
            ) {
              currError[message.call.id].displayError =
                newError[message.call.id].displayError;
            }
          })
        : callError.push(newError);
    }
  }

  const { wrapup_fields } = templateState[template];

  if (wrapup_fields && wrapup_fields.length && !wrapup[id]) {
    let wrapupMessage = helpers.getWrapupMessageFromLocalByTaskId(id);
    wrapup[id] = wrapup[id] || {
      submit: false,
      ...wrapupMessage,
    };

    dispatch({
      type: 'UPDATE_WRAPUP',
      wrapupState: {
        ...wrapupState,
        wrapup,
      },
    });
  }

  if (type === 'SMS' && !message.sms_list) {
    message.sms_list = (
      (await services.fetchUpdatedTaskById(id)) || {}
    ).sms_list;
  }

  // Show duration as informational message for missed call offer
  if (message.type.toUpperCase() === 'MISSEDCALL') {
    const parentTaskCreatedAt = JSON.parse(
      sessionStorage.getItem(message.parent_task_id)
    );
    parentTaskCreatedAt &&
      (message.duration = helpers.getDuration({
        fromTimestamp: parentTaskCreatedAt.created_at,
        toTimestamp: message.created_at,
      }));
  }

  let taskIndex;
  tasks.forEach((item, index) => {
    if (item.id === id || item.task_id === id) {
      taskIndex = index;
    }
  });

  if (taskIndex === undefined) {
    tasks.push(message);
  } else if (message.updated_at >= tasks[taskIndex].updated_at) {
    tasks[taskIndex] = {
      ...tasks[taskIndex],
      ...message,
    };
  } else {
    let fetchUpdatedTask = await services.fetchUpdatedTaskById(id);
    (fetchUpdatedTask || {}).status.toLowerCase() === 'completed' &&
      dispatch(archiveTask(message));
  }

  dispatch(updateTasks({ tasks }));

  // Join call if coaching task
  if (
    type === 'COACHING' &&
    !JSON.parse(localStorage.getItem('coaching_tasks') || '[]').find(
      ct => ct === message.id
    )
  ) {
    dispatch({
      type: 'UPDATE_SUPERVISOR',
      supervisorState: {
        ...supervisorState,
        [message.parent_task_id]: {
          ...supervisorState[message.parent_task_id],
          task_id: message.id,
        },
      },
    });

    const callId = message.call && message.call.id;
    services.joinCallAsSupervisor(callId);
  }
};

export const initializeState = async (message, helpers, store) => {
  console.info('Initializing', message);
  const { getState, dispatch } = store;

  const state = getState();
  const { templateState, supervisorState, userState } = state;
  const {
    group,
    roles,
    status,
    integration,
    tasks = [],
    team,
    teams,
    phone_number,
    sip_number,
    id,
    available,
    skills,
    username,
    capacities,
    last_name,
    first_name,
    telephony_status,
    hotdesking_eligible,
    assigned_phone,
    voice_number,
  } = message;
  let { offers = [] } = message;

  dispatch({
    type: 'UPDATE_USER',
    userState: {
      ...state.userState,
      group,
      roles,
      status,
      team,
      teams: teams || [],
      phone_number,
      sip_number,
      integration,
      id,
      available,
      skills,
      username,
      capacities,
      last_name,
      first_name,
      telephony_status,
      hotdesking_eligible,
      assigned_phone,
      voice_number,
    },
  });

  await services
    .getUserRecordings({ user_id: id })
    .then(res => res.json())
    .then(data => {
      console.log('Recording Data', data);
      data.length && dispatch(updateVMRecordings(data));
    })
    .catch(err => console.error('Error fetching recordings', err));

  tasks.length > 0 &&
    tasks.forEach(task => {
      task.type &&
        (task.type.toUpperCase() === 'CALL' ||
          task.type.toUpperCase() === 'FREE_FORM_CALL' ||
          task.type.toUpperCase() === 'COACHING') &&
        dispatch(updateCallTask(task));
    });

  for (let i = 0; i < tasks.length; i++) {
    let currTask = tasks[i];
    if (currTask.template && !templateState[currTask.template]) {
      let newTemplate = await services.fetchTemplateWithName(
        integration,
        currTask.template
      );
      if (newTemplate) {
        templateState[currTask.template] = newTemplate;
      } else {
        templateState[currTask.template] = 'N/A';
      }
    }
  }
  tasks.length &&
    dispatch({
      type: 'UPDATE_TEMPLATE',
      templateState: {
        ...templateState,
      },
    });

  // Check for session storage and Play start up sound
  try {
    const status = helpers.getSessionStatus();
    if (status) {
      playSound(getAudio.startVoiceAudio);
      dispatch(
        setOpenHotdeskModalFlag({ openHotdeskModal: hotdesking_eligible })
      );
      sessionStorage.setItem('count', 1);
    }
  } catch (e) {
    console.error('error playing sound', e);
  }

  const { taskState, environmentState, wrapupState } = state;
  const { events, callError } = taskState;
  const { wrapup } = wrapupState;

  let taskState_tasks = tasks,
    currentCallTask = null;

  let taskList = [];
  taskState_tasks.forEach(task => {
    if (task.type.toUpperCase() === 'SMS' && task.sms_list) {
      dispatch(initSMSList(task.id, task.sms_list));
    }
    if (task.type.toUpperCase() === 'EMAIL' && task.email_list) {
      dispatch(initEmailList(task.id, task.email_list));
    }
    if (task.call && task.status === 'ASSIGNED') {
      taskList.push(task.id);
      if (task.call_info && task.call_info.participants) {
        task.call_info.participants.forEach(participant => {
          if (
            participant.id !== id &&
            participant.status.toLowerCase() === 'ivr'
          ) {
            participant.displayStatus =
              participant.metadata &&
              (participant.metadata.ivr_label ||
                participant.metadata.ivr_name) &&
              participantStatusMapping({
                ivr:
                  participant.metadata.ivr_label ||
                  participant.metadata.ivr_name,
              });
          }
          if (
            participant.id === id &&
            participant.status.toLowerCase() === 'connected' &&
            task.call_info.status !== 'completed'
          ) {
            currentCallTask = task;
          }
        });
      }
    }

    let currentTemplate = templateState[task.template];
    if (currentTemplate !== 'N/A') {
      const { wrapup_fields } = currentTemplate;

      if (wrapup_fields && wrapup_fields.length) {
        let wrapupMessage = helpers.getWrapupMessageFromLocalByTaskId(task.id);
        wrapup[task.id] = wrapup[task.id] || {
          submit: false,
          ...wrapupMessage,
        };
      }

      dispatch({
        type: 'UPDATE_WRAPUP',
        wrapupState: {
          ...wrapupState,
          wrapup,
        },
      });
    }
  });

  taskList = taskList.map(id => {
    return services.fetchUpdatedTaskById(id);
  });

  let resolvedTasks = await Promise.all(taskList);

  resolvedTasks.forEach(item => {
    let index = helpers.getTaskIndexByTaskId(item.id, taskState.tasks);

    taskState.tasks[index] = item;
  });

  // get phone eventlist for all tasks. find if call initiated and ended.

  let eventListsForAllTasks = resolvedTasks
    .filter(task => {
      return task.call && task.call.id;
    })
    .map(async task => {
      return {
        task_id: task.id,
        task: task,
        events: await services.fetchEventsByCallId(task.call.id),
      };
    });

  let resolvedEvents = await Promise.all(eventListsForAllTasks);
  resolvedEvents = resolvedEvents.map((eventList, index) => {
    let currentTask = resolvedTasks[index];

    let callStarted = false;
    let callEnded = false;

    eventList.events &&
      eventList.events.forEach(event => {
        if (
          event.type === 'participant-join' &&
          event.user_id === eventList.task.user_id
        ) {
          callStarted = true;
        }
        if (
          event.type === 'participant-leave' &&
          event.user_id === eventList.task.user_id
        ) {
          callEnded = true;
        }
        if (event.type === 'conference-end') {
          callEnded = true;
        }
        if (
          event.type.toLowerCase() === 'call-suppressed' ||
          event.type.toLowerCase() === 'no-answer' ||
          event.type.toLowerCase() === 'busy' ||
          (event.type.toLowerCase() === 'canceled' &&
            event?.user_id === userState?.id) ||
          event.type.toLowerCase() === 'failed'
        ) {
          const { call_id } = event;
          const errMsg = helpers.errorTypeMapping(event.type);

          let errors = helpers.getErrorClickedInLocalStorage();

          const newError = {
            [call_id]: {
              errorType: errMsg,
              taskId: currentTask.id,
              callId: call_id,
              userId: event.user_id || '',
              displayError: errors[call_id] ? false : true,
              created_at: event.created_at,
            },
          };

          callError.length > 0 &&
          callError.find(currError => {
            return currError[call_id] && currError[call_id].callId === call_id;
          })
            ? callError.forEach(currError => {
                currError[call_id] &&
                  currError[call_id].callId === newError[call_id].callId &&
                  currError[call_id].taskId === newError[call_id].taskId &&
                  (currError[call_id].created_at =
                    newError[call_id].created_at);
              })
            : callError.push(newError);
        }
      });
    if (currentTask.call_info && currentTask.call_info.status === 'completed') {
      callEnded = true;
    }

    if (callStarted || callEnded) {
      let wrapupMessages = helpers.getWrapupMessageFromLocalByTaskId(
        eventList.task_id
      );
      wrapup[eventList.task_id] = wrapup[eventList.task_id] || {
        submit: false,
        ...wrapupMessages,
      };
      events[eventList.task.call.id] = events[eventList.task_id] || {};
      events[eventList.task.call.id] = eventList;
      events[eventList.task.call.id].callStarted = callStarted;
      events[eventList.task.call.id].callEnded = callEnded;

      wrapup[eventList.task.id].submit = callEnded;
    }

    if (!callStarted && callEnded) {
      console.warn('Call ended without starting.');
    }

    if (callStarted && !callEnded) {
      localStorage.setItem('ignoreInactivity', true);
      dispatch(
        updateUserActivityFlags({ userIncall: true, ignoreInactivity: true })
      );

      dispatch({
        type: 'UPDATE_ACTIVITY',
        activityState: {
          showCountDown: false,
          statusBeforeCountDown: userState.status,
        },
      });
      refreshClock();
    }

    return { ...eventList, callEnded, callStarted };
  });
  const newOffers = offers.filter(o => o.status === 'CREATED');
  offers = offers.filter(o => o.status !== 'CREATED');
  if (offers) {
    offers.forEach(item => {
      item.class_type = 'OFFER';
      taskState_tasks.push(item);
    });
  }

  tasks.length &&
    dispatch({
      type: 'UPDATE_WRAPUP',
      wrapupState: { ...wrapupState, wrapup },
    });

  dispatch(
    updateTaskAndEvents({ tasks: taskState_tasks, events, currentCallTask })
  );

  newOffers.forEach(offer => {
    offer.class_type = 'OFFER';
    offerCreated(offer, helpers, store);
  });

  // Initialize supervisor state
  taskState.tasks &&
    taskState.tasks.map(task => {
      task.type.toUpperCase() === 'COACHING' &&
        task.call_info &&
        task.call_info.status &&
        task.call_info.status.toLowerCase() === 'active' &&
        task.call_info.participants &&
        task.call_info.participants.find(participant => {
          return (
            participant.id === task.user_id &&
            participant.status.toLowerCase() === 'connected' &&
            participant.role.toLowerCase() === 'supervisor'
          );
        }) &&
        dispatch({
          type: 'UPDATE_SUPERVISOR',
          supervisorState: {
            ...supervisorState,
            [task.parent_task_id]: {
              ...supervisorState[task.parent_task_id],
              parent_task_id: task.parent_task_id,
              task_id: task.id,
              status: 'CONNECTED',
              supInCall: true,
              callId: task.call.id,
            },
          },
        });
    });
};

export const offerUpdated = (message, helpers, store) => {
  const { getState, dispatch } = store;
  console.log('Update Offer', message);
  const { connectionState, taskState } = store.getState();
  const { ws } = connectionState;
  const { tasks } = taskState;
  tasks.unshift(message);
  const { id, status } = message;

  ws.send(JSON.stringify({ id, type: 'offer', status })); // accept or reject here.
  dispatch(updateTasks({ tasks }));
};

export const offerCancelled = (message, helpers, store) => {
  const { dispatch } = store;
  console.log('Offer cancelled', message);
  dispatch(cancelOffer({ offer: message }));

  switch ((message.type || '').toUpperCase()) {
    case 'CALL': {
      message.direction.toUpperCase() === 'INBOUND' &&
        stopSound(getAudio.inboundRingAudio);
      break;
    }
    case 'SMS': {
      message.task_id && dispatch(decreaseSMSCount(message.task_id));
      break;
    }
    case 'EMAIL': {
      message.task_id && dispatch(decreaseEmailCount(message.task_id));
      break;
    }

    default:
      break;
  }
};

export const offerCreated = async (message, helpers, store) => {
  console.log('New Offer', message);
  const { getState, dispatch } = store;
  const { actions, task_id } = message;
  const state = getState();
  const {
    connectionState,
    taskState,
    userState,
    environmentState,
    smsState,
    callState,
  } = state;
  const { tasks } = taskState;
  const { userIncall } = userState;

  if (tasks.find(t => t.id === task_id)) return; //ignore duplicate offer
  let autoAccept = (actions || []).find(el => {
    return el === 'AUTO_ACCEPT';
  });

  const { notifications } = userState;

  const notification = notifications.find(item => {
    return item.id === 'offers';
  });

  if (message.type.toLowerCase() === 'sms' && message.task_id) {
    dispatch(increaseSMSCount(message.task_id));
  }

  if (message.type.toLowerCase() === 'email' && message.task_id) {
    dispatch(increaseEmailCount(message.task_id));
  }

  // Play inbound phone riniging for interaction offer
  if (
    message.type.toLowerCase() === 'call' &&
    !autoAccept &&
    message.direction.toLowerCase() === 'inbound' &&
    !userIncall
  ) {
    playSound(getAudio.inboundRingAudio);
    getAudio.inboundRingAudio.loop = true;
  } else {
    !userIncall ? playSound(getAudio.newTaskAudio) : null;
  }

  // Send Desktop notification when new offer is presented to agent
  if (notification.settings && !window.document.hasFocus()) {
    try {
      const taskNotification = new window.Notification(notification.title, {
        body: message.description,
        silent: true,
      });
    } catch (e) {
      console.log('Error on notification = ', e);
    }
  }

  const { id } = message;

  // Show Offer on UI and update as 'PRESENTED'.
  dispatch(offerPresented({ offer: message }));

  let res = await services.updateOfferStatus(id, 'PRESENTED');

  if (res.ok) {
    let body = await res.json();
    console.log('Sucessfully updated offer status as presented', body);
    dispatch(updateOfferStatus(body));
    // Auto Accept if user is not in active call
    if (autoAccept) {
      console.log('Offer should be auto accepted');

      let isUserIncall, ivrPlaying;

      ivrPlaying =
        callState.length > 0 &&
        callState.find(task => {
          return (
            task.call_info &&
            task.call_info.participants &&
            task.call_info.participants.length > 0 &&
            task.call_info.participants.find(p => {
              return p.status.toLowerCase() === 'ivr';
            })
          );
        });

      callState.length > 0 &&
        callState.find(task => {
          task.call_info &&
            task.call_info.status === 'active' &&
            (isUserIncall = task);
        });

      if (message.type.toLowerCase() !== 'call') {
        refreshClock();
      } else {
        localStorage.setItem('ignoreInactivity', true);
        dispatch({
          type: 'UPDATE_USER',
          userState: {
            ...userState,
            userIncall: isUserIncall ? isUserIncall : false,
            ignoreInactivity: true,
          },
        });
        dispatch({
          type: 'UPDATE_ACTIVITY',
          activityState: {
            showCountDown: false,
            statusBeforeCountDown: userState.status,
          },
        });
      }

      if (
        !isUserIncall ||
        message.type.toLowerCase() != 'call' ||
        !ivrPlaying
      ) {
        let res = await updateOffer({
          task: message,
          status: 'ACCEPTED',
        });

        if (res.ok) {
          console.log('offer is auto accepted');
          if (
            message.type &&
            (message.type.toLowerCase() === 'call' ||
              message.type.toUpperCase() === 'FREE_FORM_CALL') &&
            message.task_id
          ) {
            let newTask = await services.fetchUpdatedTaskById(message.task_id);
            // Is calling always same method
            const joinCall = await services.joinCallByTask(newTask);
            if (joinCall.ok) {
              console.log('joined outbound call successfully', joinCall);
            }
          }
        }
      }
    }
  } else {
    console.error('Failed to update offer as PRESENTED', res);
    dispatch(
      updateErrorMsg({
        error_msg: 'Failed to update offer as PRESENTED',
        error_type: 'Offer Created',
        error_severity: 'ERROR',
      })
    );
  }
};

export const socketDisconnected = (message, helpers, store) => {
  alert(
    'You have logged into another computer. Logging out this computer. If it was not you, please contact your dev team'
  );

  const { dispatch, getState } = store;

  const state = getState();
  const { connectionState } = state;

  dispatch({
    type: 'UPDATE_CONNECTION',
    connectionState: {
      ...connectionState,
      intent: false,
      timeout: null,
      interval: null,
      ws: null,
    },
  });

  userLogout(logoutReason.SOCKET_DISCONNECT);
  localStorage.removeItem('env');
  localStorage.removeItem('enable-achieve-okta');
  sessionStorage.clear();
  window.authService.logout();
};

export const taskCompleted = (message, helpers, store) => {
  console.log('Task Completed', message);
  const { dispatch } = store;

  dispatch(archiveTask(message));
  if (
    (message?.type).toUpperCase() === 'CALL' ||
    (message?.type).toUpperCase() === 'FREE_FORM_CALL'
  )
    localStorage.setItem(
      message.id,
      JSON.stringify({
        ...JSON.parse(localStorage.getItem(message.id)),
        isArchived: true,
      })
    );
};
