// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Status__status_wrapper___CBGB_{margin-left:12px;margin-right:12px;height:20px;color:#fff;white-space:nowrap;cursor:context-menu;position:relative}.Status__status_wrapper___CBGB_ .Status__display_status___3Mibb{font-weight:bold;color:green;text-transform:capitalize}.Status__status_wrapper___CBGB_ .Status__invalid___sMtbi,.Status__status_wrapper___CBGB_ .Status__suppressed___2HsB0{color:red}.Status__status_wrapper___CBGB_ .Status__event_history___2-lSF{position:absolute;padding:12px;background-color:#fff;border:1px solid #000;top:0;right:0;display:none;z-index:2}.Status__status_wrapper___CBGB_:hover .Status__event_history___2-lSF{display:block}.Status__event___N_sUq{padding:5px;color:#000}.Status__event___N_sUq .Status__email___3Y0bV{font-weight:bold}", ""]);
// Exports
exports.locals = {
	"status_wrapper": "Status__status_wrapper___CBGB_",
	"display_status": "Status__display_status___3Mibb",
	"invalid": "Status__invalid___sMtbi",
	"suppressed": "Status__suppressed___2HsB0",
	"event_history": "Status__event_history___2-lSF",
	"event": "Status__event___N_sUq",
	"email": "Status__email___3Y0bV"
};
module.exports = exports;
