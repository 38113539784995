import React from 'react';
import styles from './wrapup.scss';
import WrapupRadioButton from './wrapup_radio_button';
import { BoundaryError } from '../reusable_components/boundaryerror';
import { mergeClasses } from '../../utils';

const WrapupRadioGroup = props => {
  let { task, radioProps } = props;
  let { options, id, label, required } = radioProps;

  return (
    <div
      id="wrapup_radio_group"
      className={mergeClasses(styles.wrapup_radio_group)}
    >
      <div className={mergeClasses(styles.wrapup_radio_label)}>
        {label}
        {required && ' *'}
      </div>
      <div className={mergeClasses(styles.wrapup_radio_buttons)}>
        {options.map(item => {
          const { value, label, tooltip } = item;
          const ButtonOption = (
            <WrapupRadioButton
              key={`${id}_${value}`}
              {...props}
              buttonProps={{ key: value, value, for: id, tooltip: tooltip }}
            />
          );
          return (
            <BoundaryError key={`${id}_${value}`}>{ButtonOption}</BoundaryError>
          );
        })}
      </div>
    </div>
  );
};

export default WrapupRadioGroup;
