import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';

export const StyledMenuItem = withStyles({
  root: {
    backgroundColor: '#fafafa',
    color: '#000',
    '&:hover': {
      backgroundColor: '#979797',
      '& .MuiListItem-root, & .MuiListItemText-primary': {
        color: 'rgba(0, 0, 0, 0.08)',
      },
    },
  },
})(MenuItem);
