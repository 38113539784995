import React, { Component } from 'react';
import styles from '../ComposeEmail.scss';

class HeaderStyleDropdown extends Component {
  onToggle = event => {
    let value = event.target.value;
    this.props.onToggle(value);
  };

  render() {
    return (
      <select
        value={this.props.active}
        onChange={this.onToggle}
        className={styles.select}
      >
        <option value="paragraph" className={styles.option}>
          Paragraph
        </option>
        {this.props.headerOptions.map((heading, hIndex) => {
          return (
            <option value={heading.style} key={hIndex}>
              {heading.label}
            </option>
          );
        })}
      </select>
    );
  }
}

export default HeaderStyleDropdown;
