export const directoryState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_DIRECTORY': {
      const newState = { ...state, ...action.directoryState };
      return newState;
    }
    case 'FORWARD_THIS_TASK': {
      const { id } = action;
      return {
        ...state,
        display: true,
        forwardTask: true,
        task: { id },
      };
    }
    case 'RESET_DIRECTORY': {
      const {
        tab,
        forwardSms,
        callId,
        display,
        searchTerm,
        addNumber,
        fetchAgentInterval,
        task,
      } = action;
      const newState = {
        ...state,
        tab,
        forwardSms,
        callId,
        display,
        searchTerm,
        addNumber,
        fetchAgentInterval,
        task,
      };
      return newState;
    }
    default:
      return state;
  }
};
