import React from 'react';
import { BoundaryError } from '../../reusable_components/boundaryerror';
import AgentTask from './AgentTask';

const AgentOffers = props => {
  const { offers, user } = props;

  return (
    <BoundaryError>
      <>
        {offers &&
          offers.map(offer => {
            return (
              <AgentTask offer={offer} key={offer.id} user={user} {...props} />
            );
          })}
      </>
    </BoundaryError>
  );
};

export default AgentOffers;
