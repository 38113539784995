export const increaseEmailCount = task_id => {
  return {
    type: 'INCREASE_EMAIL_COUNT',
    task_id,
  };
};

export const decreaseEmailCount = task_id => {
  return {
    type: 'DECREASE_EMAIL_COUNT',
    task_id,
  };
};

export const showEmailEditor = ({ task_id }) => {
  return {
    type: 'SHOW_EMAIL_EDITOR',
    task_id,
  };
};

export const updateEmailText = emailState => {
  return {
    type: 'UPDATE_EMAIL_TEXT',
    emailState,
  };
};

export const updateRecipients = recipients => {
  return {
    type: 'UPDATE_RECIPIENTS',
    recipients,
  };
};

export const addRecipients = ({ recipients, task_id, recipient_type }) => {
  return {
    type: 'ADD_RECIPIENTS',
    recipients,
    task_id,
    recipient_type,
  };
};

export const deleteRecipients = ({ recipients, task_id, recipient_type }) => {
  return {
    type: 'DELETE_RECIPIENTS',
    recipients,
    task_id,
    recipient_type,
  };
};

export const initEmailList = (task_id, list) => {
  return {
    type: 'INIT_EMAIL_LIST',
    task_id,
    list,
  };
};

export const updateEmailList = (task_id, email) => {
  return {
    type: 'UPDATE_EMAIL_LIST',
    task_id,
    email,
  };
};

export const pushEmail = (task_id, email) => {
  return {
    type: 'PUSH_EMAIL',
    task_id,
    email,
  };
};

export const pushEmailList = (task_id, email_list) => {
  return {
    type: 'PUSH_EMAIL_LIST',
    task_id,
    email_list,
  };
};

export const unshiftEmailList = (task_id, email_list) => {
  return {
    type: 'UNSHIFT_EMAIL_LIST',
    task_id,
    email_list,
  };
};

export const updateEmailReply = ({ task_id, recipients, subject }) => {
  return {
    type: 'UPDATE_EMAIL_REPLY',
    task_id,
    recipients,
    subject,
  };
};

export const updateEmailHtml = ({ task_id, html }) => {
  return {
    type: 'UPDATE_EMAIL_HTML',
    task_id,
    html,
  };
};
