// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal__modal___2HyIc{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:1168px;height:calc(100% - 112px);min-height:508px;border:1px solid #404040;background:#424242;overflow:hidden;border-radius:4px;outline:none;padding:0px;z-index:400}.modal__modal___2HyIc:hover{overflow:overlay}.modal__overlay___2h0No{overflow:hidden;position:fixed;top:0px;bottom:0px;left:0px;right:0px;z-index:400;background-color:rgba(0,0,0,.75)}.modal__overlay___2h0No:hover{overflow:overlay}.modal__table___3b4YB{height:100%;width:100%}", ""]);
// Exports
exports.locals = {
	"modal": "modal__modal___2HyIc",
	"overlay": "modal__overlay___2h0No",
	"table": "modal__table___3b4YB"
};
module.exports = exports;
