export const updateTranscriptionByRecId = ({ id, transcription }) => {
  return {
    type: 'UPDATE_TRANSCRIPTION_BY_REC_ID',
    id,
    transcription,
  };
};

export const initiateTransriptionByRecId = ({ id }) => {
  return {
    type: 'INIT_TRANSCRIPTION_BY_REC_ID',
    id,
  };
};
