export const scriptState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_SCRIPT_BY_NAME': {
      const { name, html, text, scriptType, task_id } = action;
      const newState = { ...state };
      newState[name] = { html, text };
      newState.currentSelection = { name, scriptType, task_id };
      return { ...newState };
    }
    case 'UPDATE_SCRIPT_CURRENT_SELECTION': {
      const { name, scriptType, task_id } = action;
      const newState = { ...state };
      newState.currentSelection = { name, scriptType, task_id };
      return { ...newState };
    }

    default:
      return state;
  }
};
