import { BuId } from './bu.mappings';
import store from '../../store';
import { updateGenesysConnection } from '../../store/action/genesysConnection';
import { isWebapp } from '../webapp/user_access_control';
import { consts } from '../../config/consts';
const { getState, dispatch } = store;

// authStatus values: empty -> authenticating -> authenticated
// final outcome is stored genesys token data in localStorage
export const startGenesysAuthFlow = oktaToken => {
  if (!consts.telephony.isGenesys(oktaToken?.telephony)) {
    return;
  }

  const currentAuthStatus = getState().genesysConnectionState.authStatus;
  // TODO, implement retry. skip re-connecting for now if auth is failed
  if (currentAuthStatus === consts.genesys.authFailed) {
    return;
  }

  // avoid double dipping
  if (
    currentAuthStatus === consts.genesys.authing ||
    currentAuthStatus === consts.genesys.authed
  ) {
    return;
  }

  const genesysToken = JSON.parse(localStorage.getItem('genesys')) || {};
  if (!isGenesysTokenValid(genesysToken)) {
    openGenesysAuth(oktaToken);

    return;
  }

  updateGenesysAuthStatus(consts.genesys.authed, genesysToken);
};

// null -> authenticating
export const openGenesysAuth = (oktaToken, forceOpen = false) => {
  const genesysAuthStatus = getState().genesysConnectionState.authStatus;

  if (!forceOpen) {
    if (
      genesysAuthStatus === consts.genesys.authed ||
      genesysAuthStatus === consts.genesys.authing
    ) {
      return;
    }
  }

  updateGenesysAuthStatus(consts.genesys.authing);

  const envState = store.getState().environmentState;
  const vhUrl = envState.voicehubBaseUrl;
  const {
    'communicator-integration-id': integrationId,
    bsn,
    sub: email,
    legacy_username: legacyUser,
  } = oktaToken;

  const userEmail = legacyUser ? legacyUser : email;
  const buId = BuId[bsn[0]];

  if (!isWebapp()) {
    const electron = window.require('electron');
    const shell = electron.shell;

    shell.openExternal(
      `${vhUrl}/v1/genesys/auth?business_id=${buId}&group_name=${integrationId}&email=${userEmail}&redirect_url=${envState.communicatorUrl}`
    );
  } else {
    // envState.communicatorWebappUrl = 'http://localhost:8080'; // for local debugging
    window.location.href = `${vhUrl}/v1/genesys/auth?business_id=${buId}&group_name=${integrationId}&email=${userEmail}&redirect_url=${envState.communicatorWebappUrl}`;
  }
};

// authenticating -> authenticated
export const registerElectronAppAuthCallbackHandler = () => {
  const electron = window.require('electron');
  const ipcRenderer = electron.ipcRenderer;

  ipcRenderer.on('genesys', (e, msg) => {
    if (
      getState().genesysConnectionState?.authStatus === consts.genesys.authed
    ) {
      return;
    }

    const url = new URL(msg);
    const genesysToken = {
      accessToken: url.searchParams.get('access_token'),
      expiration: url.searchParams.get('expiration_at') * 1000, // convert it to milliseconds
      genesysUserId: url.searchParams.get('provider_user_id'),
    };

    if (isGenesysTokenValid(genesysToken)) {
      localStorage.setItem('genesys', JSON.stringify(genesysToken));

      updateGenesysAuthStatus(consts.genesys.authed, genesysToken);
    }
  });
};

// authenticating -> authenticated
export const handleWebappVHCallback = () => {
  if (getState().genesysConnectionState?.authStatus === consts.genesys.authed) {
    return;
  }

  const currentLocation = window.location;
  if (currentLocation.search) {
    const searchParams = new URLSearchParams(currentLocation.search);

    const genesysToken = {
      accessToken: searchParams.get('access_token'),
      expiration: searchParams.get('expiration_at') * 1000, // convert it to milliseconds
      genesysUserId: searchParams.get('provider_user_id'),
    };

    if (isGenesysTokenValid(genesysToken)) {
      localStorage.setItem('genesys', JSON.stringify(genesysToken));

      updateGenesysAuthStatus(consts.genesys.authed, genesysToken);
    }

    // remove search params after new auth state data is dispatched
    const url = window.location.href;
    const baseUrl = url.split('?')[0];
    window.history.replaceState({}, '', baseUrl);
  }
};

export const isGenesysTokenValid = token => {
  if (!token) {
    return false;
  }
  if (!token.accessToken || !token.genesysUserId || !token.expiration) {
    return false;
  }

  // NOTE.
  // expiration is normalized on milliseconds. controller EP gives milliseconds, voiceHub EP gives seconds
  if (token.expiration <= Date.now()) {
    return false;
  }

  return true;
};

const updateGenesysAuthStatus = (status, token) => {
  if (getState().genesysConnectionState.authStatus !== status) {
    dispatch(
      updateGenesysConnection({
        authStatus: status,
        token: token,
      })
    );
  }
};
