import React from 'react';
import { connect } from 'react-redux';
import styles from './wrapup.scss';
import { Button } from '../reusable_components';
import { archiveTask, removeTask } from '../../store/action/task';
import { archiveCallTask } from '../../store/action/call';
import {
  mergeClasses,
  hasUnknownWrapUp,
  saveAndArchiveTooltipMessages,
} from '../../utils';
import { getUserState, updateTask } from '../../services';
import { logger } from '../../services/server_side_logging';
import { updateUserStatus } from '../../store/action/user';
import { removeArchivedTasks } from '../main/AutoArchiveTimerHandler';

const WrapupSubmitButton = props => {
  const {
    task,
    wrapup,
    archiveTask,
    archiveCallTask,
    template,
    agentOnCall,
    agentCallMessage,
    userId,
    updateUserStatus,
  } = props;

  let disableSubmit,
    dataTooltip = null;
  if (!template) {
    disableSubmit = agentOnCall;
    dataTooltip = saveAndArchiveTooltipMessages.agentOnCall;
  } else {
    const { wrapup_fields, dont_allow_save_and_archive } = template;

    if (!wrapup_fields) {
      if (dont_allow_save_and_archive) {
        disableSubmit = dont_allow_save_and_archive;
        dataTooltip = saveAndArchiveTooltipMessages.dontAllowArchive;
      }
      if (agentOnCall) {
        disableSubmit = agentOnCall;
        dataTooltip = saveAndArchiveTooltipMessages.agentOnCall;
      }
    } else {
      const requiredFields = wrapup_fields.filter(item => {
        return item.required;
      });

      const requiredFieldsFilled =
        requiredFields.every(item => {
          return (
            wrapup[task.id] &&
            wrapup[task.id][item.id] !== undefined &&
            (item.component.toLowerCase() === 'text'
              ? (item || {}).character_limit
                ? wrapup[task.id][item.id].length &&
                  wrapup[task.id][item.id].length >=
                    (item || {}).character_limit &&
                  /[a-zA-Z]/.test(wrapup[task.id][item.id])
                : wrapup[task.id][item.id].length
              : true)
          );
        }) || requiredFields.length === 0;

      disableSubmit =
        agentOnCall ||
        !hasUnknownWrapUp({
          wrap_up: task.wrap_up || wrapup[task.id],
          wrapup_fields,
        }) ||
        !requiredFieldsFilled ||
        agentCallMessage;

      !requiredFieldsFilled &&
        (dataTooltip = saveAndArchiveTooltipMessages.requiredFieldsFilled);
      agentOnCall && (dataTooltip = saveAndArchiveTooltipMessages.agentOnCall);
      agentCallMessage &&
        (dataTooltip = saveAndArchiveTooltipMessages[agentCallMessage]);
    }
  }

  return (
    <Button
      dataTooltip={dataTooltip}
      allowMultipleClicks={false}
      className={mergeClasses(
        styles.submit_button,
        disableSubmit ? styles.submit_disabled : ''
      )}
      disableButton={disableSubmit}
      onClickHandler={async e => {
        e.preventDefault();
        e.stopPropagation();

        let isArchiveSuccessful = true;

        try {
          let metadata = {};
          if (
            task &&
            ((task.type || '').toUpperCase() === 'CALL' ||
              (task.type || '').toUpperCase() === 'FREE_FORM_CALL')
          ) {
            let cachedTask = JSON.parse(localStorage.getItem(task.id) || '{}');
            metadata.warmTransfer = cachedTask.warmTransferParticipants;
          }
          let updatedTask = await updateTask(
            task.id,
            'COMPLETED',
            wrapup[task.id],
            metadata
          );
          localStorage.setItem(
            task.id,
            JSON.stringify({
              ...JSON.parse(localStorage.getItem(task.id)),
              isArchived: true,
            })
          );
          archiveTask(updatedTask);
          updatedTask &&
            (updatedTask.type.toUpperCase() === 'CALL' ||
              updatedTask?.type?.toUpperCase() === 'FREE_FORM_CALL' ||
              updatedTask.type.toUpperCase() === 'COACHING') &&
            archiveCallTask(updatedTask);
        } catch (err) {
          // log the error to backend server
          logger.error('wrapup_submit_button onClickHandler failed', {
            taskId: task.id,
            userId: userId,
            message: err.message,
          });

          isArchiveSuccessful = false;

          // clear data relates to the task in task state
          archiveTask(task);
          if (
            task.type.toUpperCase() === 'CALL' ||
            task?.type?.toUpperCase() === 'FREE_FORM_CALL' ||
            task.type.toUpperCase() === 'COACHING'
          ) {
            // clear data relates to the task in call state
            archiveCallTask(task);
          }

          getUserState(userId)
            .then(data => {
              updateUserStatus({
                available: data.available,
                status: data.status,
                telephony_status: data.telephony_status,
              });
            })
            .catch(err => {
              logger.error('flipping user status after auto-archive failed', {
                taskId: task.id,
                userId: userId,
              });
            });
        }

        if (isArchiveSuccessful) {
          setTimeout(() => {
            // wait for a few secs and remove the cache
            localStorage.removeItem(task?.id);

            removeArchivedTasks();
          }, 3000);
        }
      }}
    >
      <div className={mergeClasses(styles.archive_icon)}></div>
      {'SAVE & ARCHIVE TASK'}
    </Button>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { templateState, callState, wrapupState, userState } = state;
  const { task } = ownprops;
  let template = templateState[task.template];
  const { wrapup } = wrapupState;

  const agent = callState
    .find(ct => ct?.id === task.id)
    ?.call_info?.participants?.find(p => p.id === userState.id);
  const agentCallMessage =
    agent?.status?.toLowerCase() !== 'connected' && agent?.status;

  const agentOnCall =
    task.type &&
    (task.type.toUpperCase() === 'CALL' ||
      task.type.toUpperCase() === 'FREE_FORM_CALL' ||
      task.type.toUpperCase() === 'COACHING') &&
    callState.find(
      t =>
        t.id === task.id &&
        ((t.call_info || {}).participants || []).some(
          p => p.id === t.user_id && p.status.toLowerCase() === 'connected'
        ) &&
        t.call_info &&
        t.call_info.status.toLowerCase() !== 'completed'
    );

  return {
    wrapup,
    wrapupState,
    agentOnCall,
    template,
    agentCallMessage,
    userId: task?.user_id,
  };
};

const mapDispatchToProps = dispatch => ({
  archiveTask: task => {
    dispatch(archiveTask(task));
  },
  archiveCallTask: task => {
    dispatch(archiveCallTask(task));
  },
  updateUserStatus: status => {
    dispatch(updateUserStatus(status));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WrapupSubmitButton);
