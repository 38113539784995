import React, { Component } from 'react';
import styles from './settings.scss';
import envVar from '../../../assets/NODE_ENV.json';
import store from '../../store';
const { dispatch, getState } = store;

class Version extends Component {
  constructor(props) {
    super(props);
    const { environmentState, services } = props;

    this.state = {
      currentVersion: environmentState.appVersion,
      lastFetched: null,
      backend: environmentState.url,
      frontend: window.location.origin,
      builtAt: environmentState.builtAt,
    };
    this.render = this.render.bind(this);
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    const { services } = this.props;

    let { lastFetched } = this.state;

    let diff = Number(new Date()) - lastFetched;

    if (!lastFetched || diff > 30000) {
      services.fetchAppVersion().then(data => {
        this.setState({
          ...this.state,
          lastFetched: Number(new Date()),
          lastestVersion: data ? data.BITBUCKET_BUILD_NUMBER : 'UNKNOWN',
        });
      });
    }
  }

  render() {
    const { mergeClasses } = this.props.helpers;

    return (
      <div className={mergeClasses(styles.version)}>
        <p className={mergeClasses(styles.version_paragraph)}>
          Communicator Version
        </p>
        <p className={mergeClasses(styles.version_paragraph)}>
          {this.state.currentVersion}
        </p>
        {this.state.lastestVersion ? (
          this.state.lastestVersion !== this.state.currentVersion ? (
            <p className={mergeClasses(styles.version_paragraph)}>
              New Version ({this.state.lastestVersion}) Available, Please
              restart the application
            </p>
          ) : this.state.lastestVersion === 'UNKNOWN' ? null : (
            <p className={mergeClasses(styles.version_paragraph)}>
              You are up to date
            </p>
          )
        ) : (
          <p className={mergeClasses(styles.version_paragraph)}>
            Checking new version...
          </p>
        )}

        {envVar?.NODE_ENV === 'local' ? (
          <p className={mergeClasses(styles.version_paragraph)}>
            You are running on local machine. Version number is not accurate for
            local environment
          </p>
        ) : null}
        <p className={mergeClasses(styles.version_paragraph)}>
          Built on server at {this.state.builtAt}
        </p>
        <p className={mergeClasses(styles.version_paragraph)}>
          frontend = {this.state.frontend}
        </p>
        <p className={mergeClasses(styles.version_paragraph)}>
          backend = {this.state.backend}
        </p>
      </div>
    );
  }
}

export default Version;
