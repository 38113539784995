import React, { useState } from 'react';
import styles from './Status.scss';
import {displayTimestamp, mergeClasses} from "../../../../utils";

export const Status = ({
  status,
  events,
  recipients,
}) => {
  const displayStatus = getDisplayStatus(status, events, recipients)
  const eventsSection = createEventsSection(events)
  return (
    <>
      {displayStatus ? (
        <div className={styles.status_wrapper}>
          Status: <span className={mergeClasses(styles[status], styles.display_status)}>{displayStatus}</span>
          {eventsSection ? (
            <div className={styles.event_history}>{eventsSection}</div>
          ) : null}

        </div>
      ) : null}
    </>
  )
};

const createEventsSection = (events) => {
  if(
    !Array.isArray(events) ||
    events.length === 0
  ) return false

  let rows = [];

  for (let i = 0; i < events.length; i++) {
    rows.push(<div className={styles.event}>
      <span className={styles.email}>[{events[i].email}]</span>
      <span className={styles.event}>{events[i].type}</span>
      <span className={styles.created_at}>at {displayTimestamp(events[i].created_at)}</span>
    </div>)
  }

  return rows
};

const getDisplayStatus = (status, events, recipients) => {
  // if status is empty don't show component
  if(!status) return false

  // show default email status if there are more then one recipient
  // or if there are no events
  if(
    !Array.isArray(recipients) ||
    recipients.length !== 1 ||
    !Array.isArray(events) ||
    events.length === 0
  ) return status

  // otherwise show last recipient event
  return events[events.length-1].type
}

export default Status;