import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Paper,
  Grid,
  Button,
} from '@material-ui/core';
import { updateTeamSortByName } from '../../../store/action/team';
import Textbox from '../../reusable_components/Textbox';
import { broadcastMessage } from '../../../services';

import { Feedback } from '../../../utils';
import FeedbackSnackbar from '../../feedback/FeedbackSnackbar';

import Messages from './messages';
import Selection from './selection';

const useStyles = makeStyles(theme => ({
  radio_root: {
    margin: theme.spacing(2),
    color: 'white',
    textTransform: 'capitalize',
  },
  grid_root: {
    height: '100%',
  },
  paper_root: {
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    color: '#fff',
    border: '1px solid',
    borderColor: 'rgba(255,255,255,0.23)',
    backgroundColor: 'transparent',
  },
  root: {
    color: '#e7474e',
  },
  button: {
    backgroundColor: '#45ae57',
    margin: theme.spacing(2),
    color: theme.palette.getContrastText('#45ae57'),
    opacity: 0.76,
    '&:hover': {
      backgroundColor: '#45ae57',
      color: theme.palette.getContrastText('#45ae57'),
      opacity: 1,
    },
  },
  checked: {
    color: '#e7474e !important',
  },
  container: {
    height: '100%',
    border: '1px solid',
    borderColor: 'rgba(255,255,255,0.54)',
  },
  message: {
    color: '#fff',
    padding: theme.spacing(2),
    paddingBottom: 0,
  },
  indicator: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  textRoot: {
    '& .MuiFormLabel-root': {
      color: 'rgba(255, 255, 255, 0.54)',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    },
  },
}));

const Broadcast = ({ sender }) => {
  const classes = useStyles();
  const selection = ['agent', 'team'];
  const [message, setMessage] = useState('');

  const [selectedOption, setOption] = useState('team');
  const handleSortName = e => {
    setOption(e.target.value);
  };

  const [feedback, setFeedback] = useState({ msg: '', severity: '' });
  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  const [selectedList, setList] = useState([]);
  const handleList = options => {
    setList(options);
  };

  const [pastMessages, setpastMessages] = useState([]);
  const handlePastMessages = messages => {
    setpastMessages(messages);
  };

  return (
    <div className={classes.container}>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'left'}
          children={feedback.msg}
          severity={feedback.severity}
          autoHideDuration={3000}
          updateFeedback={updateFeedback}
        />
      )}

      <Grid
        classes={{ root: classes.grid_root }}
        container
        direction="column"
        justify="space-between"
      >
        <Grid item>
          <Typography classes={{ root: classes.message }} variant={'h5'}>
            Broadcast Message
          </Typography>

          <Paper elevation={3} classes={{ root: classes.paper_root }}>
            <Grid container>
              <Grid item>
                <RadioGroup
                  row
                  classes={{ root: classes.radio_root }}
                  defaultValue={'team'}
                  onChange={handleSortName}
                >
                  {selection.map((sl, index) => (
                    <FormControlLabel
                      key={`${sl}_${index}`}
                      value={sl}
                      control={
                        <Radio
                          classes={{
                            root: classes.root,
                            checked: classes.checked,
                          }}
                        />
                      }
                      label={sl}
                    />
                  ))}
                </RadioGroup>
              </Grid>
              <Grid item>
                <Selection
                  selectedList={selectedList}
                  handleList={handleList}
                  label={selectedOption}
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={9}>
                <Textbox
                  label={'Message'}
                  value={message || ''}
                  multiline={true}
                  handleChange={value => {
                    setMessage(value);
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  classes={{
                    root: classes.button,
                  }}
                  onClick={async e => {
                    if (!message.length)
                      return updateFeedback(
                        'Type Message before sending',
                        Feedback.WARNING
                      );
                    if (!selectedList.length)
                      return updateFeedback(
                        'List is empty. Select agents or team before broadcasting message',
                        Feedback.WARNING
                      );

                    broadcastMessage({
                      message,
                      sender,
                      ...(selectedOption === 'team'
                        ? { teams: [...selectedList] }
                        : { user_ids: [...selectedList] }),
                    })
                      .then(res => res.json())
                      .then(data => {
                        handleList([]);
                        setMessage('');
                        (pastMessages || []).unshift(data);
                        handlePastMessages(pastMessages);
                        updateFeedback(
                          `Message broadcasted successfully`,
                          Feedback.SUCCESS
                        );
                      })
                      .catch(err =>
                        updateFeedback(
                          `${err.code}:'${err.message}`,
                          Feedback.ERROR
                        )
                      );
                  }}
                >
                  Send
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        <Grid item>
          <Messages
            pastMessages={pastMessages}
            updateFeedback={updateFeedback}
            handlePastMessages={handlePastMessages}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const { userState } = state;
  const { id = '', first_name, last_name, username } = userState;

  return {
    sender:
      first_name && last_name ? first_name + ' ' + last_name : id || username,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTeamSortByName: name => dispatch(updateTeamSortByName(name)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Broadcast);
