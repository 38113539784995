import store from '../../store';
import { request } from '../http/client';

export const getCallInfo = async callId => {
  return request(`${store.getState().environmentState.url}/v1/call/${callId}`);
};

export const getEmailList = async taskId => {
  return request(
    `${store.getState().environmentState.url}/v1/email/${taskId}/list`
  );
};

export const getSMSList = async taskId => {
  return request(
    `${store.getState().environmentState.url}/v1/sms/${taskId}/list`
  );
};
