import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import styles from './Keywords.scss';
import {
  updateScannerState,
  createNewKeyword,
  updateNewKeyword,
} from '../../../store/action/scanner';
import { createScanner, fetchScanners } from '../../../services';
import { Feedback } from '../../../utils/mappings';
import SelectTeam from './SelectTeam';
import Keyword from './Keyword';
import Textbox from '../../reusable_components/Textbox';
import FeedbackSnackbar from '../../feedback/FeedbackSnackbar';

export const Scanners = ({
  updateScannerState,
  updateNewKeyword,
  scanners,
  newKeyword,
  createNewKeyword,
  user_id,
  teams,
  integration,
  selectedTeam,
  limit,
}) => {
  useEffect(() => {
    let mounted = true;
    fetchScanners({ integration, user_id, limit })
      .then(res => res.json())
      .then(data => {
        if (mounted) updateScannerState({ scanners: data.scanners });
      })
      .catch(err => {
        if (mounted)
          updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
      });
    return () => (mounted = false);
  }, []);

  const [feedback, setFeedback] = useState({ msg: '', severity: '' });

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  return (
    <div id="keywords_main" className={styles.main}>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'left'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
        />
      )}
      <div id="team" className={styles.select_team}>
        <SelectTeam />
      </div>
      <p id="description" className={styles.description}>
        Add keyword or Phrase for scanning of future calls
      </p>
      <div id="textbox_wrapper" className={styles.textbox_wrapper}>
        <Textbox
          label={'Enter Keyword or Phrase'}
          value={newKeyword || ''}
          multiline={true}
          key={'keyword'}
          handleChange={value => updateNewKeyword({ newKeyword: value })}
        />
        <div
          id="add"
          className={styles.add_button}
          onClick={async e => {
            e.preventDefault();
            if (!selectedTeam.length && teams.length > 1) {
              updateFeedback('Select team to create keyword', Feedback.ERROR);
              return;
            }

            if (!newKeyword.length) {
              updateFeedback('Enter Keyword or Phrase', Feedback.ERROR);
            } else {
              createScanner({
                integration,
                keyword: newKeyword,
                team: selectedTeam.length ? selectedTeam : teams[0],
              })
                .then(res => res.json())
                .then(data => {
                  createNewKeyword(data);
                })
                .catch(err => updateFeedback(`${err.code}: ${err.message}`));
            }
          }}
        >
          Add
        </div>
      </div>
      {scanners.length > 0 && (
        <p className={styles.description}>List of Keywords and Phrases</p>
      )}
      <div className={styles.edit_container}>
        {(scanners || []).map(scanner => {
          return (
            <Fragment key={`fragment_${scanner.id}`}>
              {(scanner.team === selectedTeam || !selectedTeam.length) && (
                <Keyword
                  key={scanner.id}
                  scanner={scanner}
                  updateFeedback={updateFeedback}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { scannerState, userState, teamState } = state;
  const { scanners = [], newKeyword = '' } = scannerState;
  const { id, team, teams = [], integration } = userState;

  return {
    scanners,
    newKeyword,
    user_id: id,
    teams: teams.length ? teams : team ? [team] : [],
    integration,
    selectedTeam: teamState.selectedTeam || '',
    limit: 20,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateScannerState: ({ scanners }) =>
      dispatch(updateScannerState({ scanners })),
    createNewKeyword: keyword => dispatch(createNewKeyword(keyword)),
    updateNewKeyword: ({ newKeyword }) =>
      dispatch(updateNewKeyword({ newKeyword })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Scanners);
