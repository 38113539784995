import React from 'react';
import { connect } from 'react-redux';
import styles from './VoiceRecordings.scss';
import parent_styles from '../settings.scss';
import Recordings from './Recordings';

import { addNewVoiceMailRecording } from '../../../store/action/vm';
import { updateErrorMsg, deleteErrorMsg } from '../../../store/action/error';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AddBoxIcon from '@material-ui/icons/AddBox';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { LightTooltip } from '../../reusable_components/LightTooltip';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '../../reusable_components/Expansions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const VoiceRecordings = ({
  mergeClasses,
  recordingType,
  addNewVM,
  errorState,
  limit,
  recordings,
  updateErrorMsg,
  deleteErrorMsg,
  userTelephonyProvider,
}) => {
  const addStyle = null;

  const showControls = userTelephonyProvider === 'genesys' ? false : true;

  return (
    <div className={styles.voice_rec_wrapper}>
      <ExpansionPanel
        className={mergeClasses(parent_styles.expand_panel)}
        defaultExpanded={true}
        classes={{ root: parent_styles.expand_summary }}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon className={styles.expand_icon} />}
          className={mergeClasses(parent_styles.expand_summary)}
        >
          <div className={styles.header_wrapper}>
            <FormControlLabel
              className={mergeClasses(styles.add_voice_rec_form, addStyle)}
              onClick={e => {
                e.stopPropagation();
                console.log('add new recording');
                (recordings || []).length <= limit && addNewVM(recordingType);
              }}
              onFocus={e => e.stopPropagation()}
              control={
                showControls ? 
                <AddBoxIcon
                  classes={{
                    root: mergeClasses(styles.add_voice_rec, addStyle),
                  }}
                /> : <></>
              }
            />
            <div className={styles.voice_rec_heading}>
              Contact Strategy Pre-Recorded Voicemails
            </div>
            <LightTooltip
              arrow
              title={
                <div className={styles.helpText}>
                  Help automate your client voicemails. Record a message. Call
                  your client and wait for their voicemail beep. Ensure you
                  selected the right voice message. Click on leave
                  <ExitToAppIcon className={styles.icon} />. You will be dropped
                  off the call and the system will leave your message on the
                  client's voicemail recorder.
                </div>
              }
            >
              <HelpIcon
                classes={{
                  root: mergeClasses(styles.voice_rec_help),
                }}
              />
            </LightTooltip>
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          className={mergeClasses(parent_styles.expand_detail)}
        >
          <div className={styles.vm_list}>
            <Recordings recordings={recordings} limit={limit} />
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {errorState.vm_error && (
        <div
          className={styles.error}
          onClick={e => {
            deleteErrorMsg({ error_type: 'vm_error' });
          }}
        >
          <ErrorOutlineIcon className={styles.error_icon} />
          <div className={styles.error_msg}>
            Voicemail {errorState.vm_error}
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    limit: state.integrationState.recording_limit || 5,
    recordings: state.vmState,
    errorState: state.errorState,
    recordingType:
      (state.integrationState.recording_types &&
        state.integrationState.recording_types[0]) ||
      '',
    userTelephonyProvider: state.userState.telephonyProvider,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addNewVM: recordingType => {
      dispatch(addNewVoiceMailRecording(recordingType));
    },
    deleteErrorMsg: ({ error_type }) => {
      dispatch(deleteErrorMsg({ error_type }));
    },
    updateErrorMsg: ({ error_msg, error_type }) => {
      dispatch(updateErrorMsg({ error_msg, error_type }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoiceRecordings);
