export const genesysConnectionState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_GENESYS_CONNECTION': {
      const newState = { ...state, ...action.connectionState };

      if (state.token && action.connectionState?.token) {
        // token from refreshing EP doesn't have all the fields.
        // copy field that exists in current state.token and not in the new state.token
        for (const [key, oldValue] of Object.entries(state.token)) {
          const newValue = action.connectionState.token[key];

          if (!newValue && oldValue) {
            newState.token[key] = oldValue;
          }
        }
      }

      return newState;
    }
  }

  return state;
};
