export const agentState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_AGENT': {
      const newState = { ...state, ...action.agentState };
      return newState;
    }
    case 'UPDATE_AGENT_TASK_ID': {
      const newState = { ...state };
      const { taskId } = action;
      return { ...newState, taskId };
    }
    case 'UPDATE_AGENT_DATA': {
      const newState = { ...state };
      const { data } = action;
      return { ...newState, data };
    }
    case 'UPDATE_AGENT_SELECTED_TASK': {
      const newState = { ...state };
      const { task, tab, user } = action;
      const { data } = newState;
      data &&
        data.id === user &&
        (data.tasks =
          data.tasks && data.tasks.length
            ? data.tasks.map(t => (t.id === task.id ? task : t))
            : Array.of(task));
      const taskId = newState.taskId === task.id ? null : task.id;

      return { ...newState, data, tab, taskId };
    }
    case 'UPDATE_AGENT_SELECTED_OFFER': {
      const newState = { ...state };
      const { offer, tab, user } = action;
      const { data } = newState;
      data &&
        data.id === user &&
        (data.offers =
          data.offers && data.offers.length
            ? data.offers.map(o => (o.task_id === offer.task_id ? offer : o))
            : Array.of(offer));
      const taskId = newState.taskId === offer.task_id ? null : offer.task_id;

      return { ...newState, data, tab, taskId };
    }
    case 'UPDATE_AGENT_ARCHIVES': {
      const newState = { ...state };
      const { archives, filter } = action;

      return { ...newState, archives, filter };
    }

    default:
      return state;
  }
};
