import React from 'react';
import styles from './IVRresults.scss';
import { IVRresultMapping, mergeClasses } from '../../utils';

const IVRresults = ({ results, helpers }) => {
  const ivr_participants = Object.keys(results);

  const ivr_results_details = ivr_participants.map((p, pIndex) => {
    const ivr_name =
      results[p] &&
      results[p].results &&
      Object.keys(results[p].results).map((ivr, iIndex) => {
        const number_pressed =
          results[p] &&
          results[p].results[ivr] &&
          Object.values(results[p].results[ivr])
            .reverse()
            .map((number, nIndex) => {
              const ivr_result =
                IVRresultMapping[number] || ` PRESSED ${number}`;

              return (
                <div
                  key={nIndex}
                  className={mergeClasses(
                    styles.ivr_details_wrapper,
                    styles[number]
                  )}
                >
                  <div
                    className={mergeClasses(styles.ivr_name, styles[number])}
                    ivr={ivr}
                  >
                    {results[p].results[ivr].label || ivr}
                  </div>
                  <div
                    key={p}
                    participant={p}
                    className={mergeClasses(styles.participant, styles[number])}
                  >
                    Participant [{results[p].name || results[p].number || p}]
                  </div>
                  <div
                    className={mergeClasses(
                      styles.number_pressed,
                      styles[number]
                    )}
                  >
                    {ivr_result}
                  </div>
                </div>
              );
            });

        return <div key={iIndex}>{number_pressed}</div>;
      });

    return (
      <div key={pIndex} className={styles.participant_wrapper}>
        {ivr_name}
      </div>
    );
  });

  return (
    <div className={styles.details}>
      IVR Results: {`\n`} {ivr_results_details}
    </div>
  );
};

export default IVRresults;
