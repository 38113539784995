// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".countdown__modal_outer_overlay___S2VHC{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%);width:100%;height:100%;background:rgba(0,0,0,.4);overflow:hidden;outline:none;padding:0px;z-index:400}.countdown__modal_overlay___3JBAg{display:flex;position:fixed;flex-direction:row;justify-content:center;align-items:center;background-color:#7f716f;width:350px;height:160px;top:50%;left:50%;transform:translate(-50%, -50%);z-index:400}.countdown__countdown_container___3_JNj{display:flex;flex-direction:column;justify-content:flex-start;align-items:center;width:100%;height:100%;color:#fff}.countdown__countdown_header___Gj7CS{background-color:#fcf37d;color:#000 !important;width:100%;height:45px;border:1px solid #000;display:flex;flex-direction:row;justify-content:center;align-items:center}.countdown__countdown_body___1cRIm{width:100%;height:100%;border:1px solid #000;display:flex;flex-direction:column;justify-content:space-evenly;align-items:center}.countdown__countdown_count___28PLK{font-weight:700;font-size:26px}.countdown__countdown_button___3AgRQ{background-color:#d05d41;color:#fff;height:30px;width:200px;border:1px solid #000;font-size:16px}", ""]);
// Exports
exports.locals = {
	"modal_outer_overlay": "countdown__modal_outer_overlay___S2VHC",
	"modal_overlay": "countdown__modal_overlay___3JBAg",
	"countdown_container": "countdown__countdown_container___3_JNj",
	"countdown_header": "countdown__countdown_header___Gj7CS",
	"countdown_body": "countdown__countdown_body___1cRIm",
	"countdown_count": "countdown__countdown_count___28PLK",
	"countdown_button": "countdown__countdown_button___3AgRQ"
};
module.exports = exports;
