// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/create-white-18dp.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../assets/check-white-18dp.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".Subject__email_info_wrapper___18Jpa{display:flex;flex-direction:column;font-size:16px;border-top:1px solid #424242;padding-left:12px;padding-bottom:12px}.Subject__edit___1Fx8i{width:18px;height:18px;cursor:pointer;object-fit:contain;background-repeat:no-repeat;margin:2px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.Subject__edit___1Fx8i.Subject__check___2XQ7n{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.Subject__subject_wrapper___2WKoU{display:flex;padding:8px 4px 8px 0px;justify-content:flex-start;align-items:center}.Subject__input___XtYgR{background-color:#212121;width:100%;border:1px solid #424242;font-weight:bold;padding:8px 4px;font-size:16px;margin-left:3px;color:#fff}.Subject__nonedit_subject_wrapper___1_eLE{display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between;width:100%}.Subject__subject___1w1qu{font-weight:bold;font-size:16px;color:#fff;width:fit-content;font-family:unset}", ""]);
// Exports
exports.locals = {
	"email_info_wrapper": "Subject__email_info_wrapper___18Jpa",
	"edit": "Subject__edit___1Fx8i",
	"check": "Subject__check___2XQ7n",
	"subject_wrapper": "Subject__subject_wrapper___2WKoU",
	"input": "Subject__input___XtYgR",
	"nonedit_subject_wrapper": "Subject__nonedit_subject_wrapper___1_eLE",
	"subject": "Subject__subject___1w1qu"
};
module.exports = exports;
