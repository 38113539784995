import envVar from '../assets/NODE_ENV.json';
import React from 'react';
import { connect } from 'react-redux';
import { Main, SetEnv } from './containers';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';

import { createWS, closeWS } from './utils';
import getOktaConfig from './config/okta';
import * as helpers from './utils';
import * as services from './services';
import { openReLoginDialog } from './store/action/connection';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import getLaunchdarklyConfig from './config/launchdarkly';

import { isWebapp } from './utils/webapp/user_access_control';

let showTaskFromWebpage = '';

if (!isWebapp()) {
  const ipcRenderer = window.require('electron').ipcRenderer;
  const { getLink } = window.require('electron').remote.require('./index.js');
  showTaskFromWebpage = (getLink() || '').toString().split('taskId=');

  ipcRenderer.on('app-close', () => {
    window.localStorage.removeItem('env');
    localStorage.removeItem(`${new Date().toDateString()}`);
    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('enable-achieve-okta');
    sessionStorage.clear();

    window.authService.logout();
  });
} else {
  // TODO figure out a way to clear localStorage on closing
  // window.onbeforeunload = function(e) {
  //   // e.preventDefault();
  //   console.log('test');
  //   return false;
  // };
}

const App = props => {
  const { store, environmentState, openReLoginDialog } = props;
  const { getState, dispatch } = store;

  if (!environmentState.appVersion) {
    if (!environmentState.appVersionLoading) {
      dispatch({
        type: 'UPDATE_ENVIRONMENT',
        environmentState: {
          ...environmentState,
          appVersionLoading: true,
        },
      });
      services.fetchAppVersion().then(data => {
        dispatch({
          type: 'UPDATE_ENVIRONMENT',
          environmentState: {
            ...environmentState,
            appVersion: data ? data.BITBUCKET_BUILD_NUMBER : 'UNKNOWN',
            appVersionLoading: false,
            builtAt: data ? data.CREATED_AT : 'UNKNOWN',
          },
        });
      });
    }
  }

  return (
    <Router id="router">
      {!environmentState.env && envVar.NODE_ENV !== environmentState.env ? (
        <SetEnv {...props} helpers={helpers} servives={services} />
      ) : (
        <Security
          // FFN Okta is decommissioned
          {...getOktaConfig(environmentState.env, true)}
          onSessionExpired={() => openReLoginDialog(true)}
        >
          <Switch>
            <Route
              exact
              path="/oktacallback"
              render={props => {
                return <LoginCallback {...props} />;
              }}
            />
            <SecureRoute
              path="/"
              render={props => {
                let newProps = {
                  ...props,
                  helpers,
                  services,
                  taskIdFromWebpage: showTaskFromWebpage[1],
                };

                return (
                  <Main {...newProps} createWS={createWS} closeWS={closeWS} />
                );
              }}
            />
          </Switch>
        </Security>
      )}
    </Router>
  );
};

const mapStateToProps = state => {
  return { environmentState: state.environmentState };
};

const mapDispatchToProps = dispatch => ({
  updateConnectionFunc: state => {
    dispatch(updateConnection(state));
  },
  openReLoginDialog: open => {
    dispatch(openReLoginDialog(open));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withLDProvider({
    ...getLaunchdarklyConfig(),
  })(App)
);
