import React from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import styles from './stats.scss';

const calendarTheme = createMuiTheme({
  overrides: {
    MuiInputBase: {
      input: {
        color: '#fff',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#000',
        color: '#979797',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#979797',
      },
      iconButton: {
        backgroundColor: '#000',
        '&:hover': {
          backgroundColor: '#3c6dbd',
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: '#979797',
        backgroundColor: '#000',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: '#54698d',
        },
      },
      current: {
        color: '#00ff00',
      },
      daySelected: {
        backgroundColor: '#3c6dbd',
        '&:active': {
          backgroundColor: '#54698d',
        },
        '&:hover': {
          backgroundColor: '#54698d',
        },
      },
      current: {
        color: '',
      },
    },
    MuiButtonBase: {
      root: {
        color: 'white !important',
      },
    },
  },
});

const Calendar = ({ handleDateChange, selectedDate }) => {
  return (
    <div className={styles.calendar}>
      <div className={styles.helper_text}>Select Date:</div>
      <MuiThemeProvider theme={calendarTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            views={['month', 'date']}
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            value={selectedDate}
            onChange={handleDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  );
};

export default Calendar;
