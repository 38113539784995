import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import {
  createMuiTheme,
  MuiThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Button,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  TextField,
  Popover,
} from '@material-ui/core';

import { fetchArchives } from '../../../services';
import { types, buildArchiveQuery, isPhoneNumberValid } from '../../../utils';
import task_styles from '../tasks.scss';
import styles from './filter.scss';
import CallFilter from './CallFilter';
import SMSFilter from './SMSFilter';
import Box from '@material-ui/core/Box';
import { updateArchivedTasksFromDtFilter } from '../../../store/action/task';
import { updateAgentArchives } from '../../../store/action/agent';

const calendarTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#184895',
    },
    success: { main: '#45ae57' },
    error: { main: '#e7474e' },
    secondary: { main: '#ff9404' },
  },
  overrides: {
    MuiInputBase: {
      input: {
        color: '#fff',
      },
    },
    MuiInput: {
      underline: {
        borderBottomColor: '#424242',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#000',
        color: '#979797',
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#979797',
      },
      iconButton: {
        backgroundColor: '#000',
        color: '#979797',
        '&:hover': {
          color: '#fff',
        },
      },
    },
    MuiPickersDay: {
      day: {
        color: '#979797',
        backgroundColor: '#000',
        borderRadius: '20px',
        '&:hover': {
          backgroundColor: '#54698d',
        },
      },
      current: {
        color: '#00ff00',
      },
      dayDisabled: {
        color: '#424242 !important',
      },
      // current: {
      //   color: '',
      // },
    },
    // MuiButtonBase: {
    //   root: {
    //     color: 'white !important',
    //   },
    // },
    MuiDialogActions: {
      root: {
        backgroundColor: '#000',
      },
    },
    MuiPickersClockNumber: {
      clockNumber: {
        color: 'white',
        opacity: 0.87,
      },
    },
    MuiFormLabel: {
      root: {
        color: 'rgba(255, 255, 255, 0.54) !important',
        '&$focused': {
          color: 'white',
        },
      },
    },
    MuiPaper: {
      root: {
        color: 'rgba(255, 255, 255, 0.87)',
        backgroundColor: '#424242',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: 'rgba(255, 255, 255, 0.54)',
      },
    },
  },
});

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    color: '#fff',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  goButton: {
    alignSelf: 'flex-end',
    width: 'fit-content',
    marginTop: 5,
    marginLeft: 5,
  },
  paper: {
    minWidth: 388,
  },
}));

const TaskFilter = ({
  uId,
  tab,
  flags,
  updateArchivedTasksFromDtFilter,
  updateAgentArchives,
}) => {
  const classes = useStyles();
  const [offset, setOffset] = useState(0);
  const [expandFilters, setExpandFilters] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState('');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [clientCallNumber, setClientCallNumber] = useState('');
  const [clientSMSNumber, setClientSMSNumber] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
    setExpandFilters(prev => !prev);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setExpandFilters(false);
  };

  const handleTaskTypeFilterChange = e => {
    const selectedTypes = e.target.value;
    setSelectedTypes(e.target.value);
    if(!selectedTypes?.includes('SMS')){
      setClientSMSNumber('');
    }
    if(!selectedTypes?.includes('CALL') &&
      !selectedTypes?.includes('FREE_FORM_CALL') &&
      !selectedTypes?.includes('COACHING')){
      setClientCallNumber('');
    }
  };

  const handleToDateChange = async date => {
    if (date !== '') {
      setSelectedToDate(date);
    }
  };

  const handleFromDateChange = async date => {
    if (date !== '') {
      setSelectedFromDate(date);
    }
  };

  const handleOnclose = async () => {
    let createdAtFrom, createdAtTo;
    if (!selectedFromDate || !selectedToDate) return;
    createdAtFrom = Date.parse(selectedFromDate);
    createdAtTo = Date.parse(selectedToDate);
    if (!(createdAtTo > createdAtFrom))
      return alert('To date must be after From date');
    else if (createdAtTo === createdAtFrom)
      return alert('Selected date & time cannot be same');
  };

  const handleReset = () => {
    setSelectedFromDate(null);
    setSelectedToDate(null);
    setSelectedTaskId('');
    setSelectedTypes([]);
    setClientCallNumber('');
    setClientSMSNumber('');

    buildQueryAndFetchArchives({});
  };

  const buildQueryAndFetchArchives = async ({
    taskIds,
    createdAtFrom,
    createdAtTo,
    clientSMSNumber,
    clientCallNumber,
    types,
  }) => {
    // filter always resets offset
    let limit = 20, offset = 0,
      archiveData = [];

    const query = buildArchiveQuery({
      taskIds,
      clientSMSNumber,
      clientCallNumber,
      createdAtFrom,
      createdAtTo,
      types,
      limit,
      offset,
    });

    await fetchArchives(uId, query)
      .then(data => {
        (archiveData || []).push(...data);
      })
      .catch(err => {
        console.error(`Error fetching archive list with query: ${query}`, err);
      })
      .finally(() => setExpandFilters(false));


      const filters = {
        taskIds: taskIds,
          types: types,
          fromDate:createdAtFrom,
          toDate:createdAtTo,
          clientCallNumber: clientCallNumber,
          clientSMSNumber: clientSMSNumber,
      };

      tab === 'archived' ?
        updateArchivedTasksFromDtFilter({
          tab: tab,
          archiveFilter: filters,
          archives: archiveData,
        }) :
        updateAgentArchives({
          tab: tab,
          archiveFilter: filters,
          archives: archiveData,
        });
  };

  return (
    <>
      <MuiThemeProvider theme={calendarTheme}>
        {flags.enableArchiveFilters && (
          <div className={task_styles.task_filter}>
            <div>Filter Options</div>
            <Button
              classes={{ root: classes.buttonRoot }}
              onClick={handleClick}
            >
              {expandFilters ? 'Hide' : 'Show'}
            </Button>
          </div>
        )}
        <Popover
          classes={{ paper: classes.paper }}
          id={uId}
          open={expandFilters}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={styles.filter_box}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                disableFuture={true}
                variant="inline"
                autoOk={true}
                format="yyyy/MM/dd hh:mm a"
                value={selectedFromDate}
                onChange={handleFromDateChange}
                label="From"
                onClose={handleOnclose}
              />
              <KeyboardDateTimePicker
                disableFuture={true}
                variant="inline"
                autoOk={true}
                format="yyyy/MM/dd hh:mm a"
                value={selectedToDate}
                onChange={handleToDateChange}
                label="To"
                onClose={handleOnclose}
              />
            </MuiPickersUtilsProvider>
            <TextField
              value={selectedTaskId}
              onChange={e => {
                setSelectedTaskId(e.target.value);
              }}
              label="Task ID"
              variant="standard"
              multiline
            />
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="filter-archived-tasks">Type</InputLabel>
              <Select
                id="filter-archived-task-select"
                value={selectedTypes}
                onChange={handleTaskTypeFilterChange}
                label="Type"
                multiple
              >
                {types.map((item, index) => (
                  <MenuItem key={index} value={item.type}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {selectedTypes?.includes('CALL') ||
            selectedTypes?.includes('FREE_FORM_CALL') ||
            selectedTypes?.includes('COACHING') ? (
              <CallFilter
                setClientCallNumber={setClientCallNumber}
                clientCallNumber={clientCallNumber}
              />
            ) : null}
            {selectedTypes?.includes('SMS') ? (
              <SMSFilter
                setClientSMSNumber={setClientSMSNumber}
                clientSMSNumber={clientSMSNumber}
              />
            ) : null}
            <Box classes={{ root: classes.buttonGroup }}>
              <Button
                classes={{ root: classes.goButton }}
                variant="contained"
                onClick={() => {
                  handleReset();
                }}
              >
                Reset
              </Button>
              <Button
                classes={{ root: classes.goButton }}
                variant="contained"
                onClick={e => {
                  const clientSMSNumberInput = clientSMSNumber.replace(/[^\d]/g, '');
                  const clientCallNumberInput = clientCallNumber.replace(/[^\d]/g, '');

                  if (selectedTypes?.includes('SMS')) {
                    if (!isPhoneNumberValid(clientSMSNumberInput)) {
                      return alert('SMS phone number is not valid');
                    }

                  }
                  if (
                    selectedTypes?.includes('CALL') ||
                    selectedTypes?.includes('FREE_FORM_CALL') ||
                    selectedTypes?.includes('COACHING')
                  ) {
                    if (!isPhoneNumberValid(clientCallNumberInput))
                      return alert('call phone number is not valid');
                  }

                  let taskIds = [];
                  if (selectedTaskId) {
                    taskIds = selectedTaskId.split(/\r\n|\r|\n|\,/);
                    taskIds.forEach((taskId, index) => {
                      if (taskId && (taskId.length < 32 || taskId.length > 36))
                        return alert('Invalid Task Id');
                    });
                  }

                  buildQueryAndFetchArchives({
                    taskIds,
                    clientSMSNumber: clientSMSNumberInput,
                    clientCallNumber: clientCallNumberInput,
                    createdAtFrom: Date.parse(selectedFromDate),
                    createdAtTo: Date.parse(selectedToDate),
                    types: selectedTypes,
                  });
                }}
              >
                GO
              </Button>
            </Box>
          </div>
        </Popover>
      </MuiThemeProvider>
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { taskState, agentState } = state;

  return {
    archiveFilters: ownProps.tab === 'archived' ? taskState.archiveFilters : agentState.archiveFilters,
    archives: ownProps.tab === 'archived' ? taskState.archived : agentState.archives,
  };
};

const mapDispatchToProps = dispatch => ({
  updateArchivedTasksFromDtFilter: ({ tab, archiveFilter, archives }) => {
    dispatch(updateArchivedTasksFromDtFilter({
      tab,
      archiveFilter,
      archives,
    }));
  },
  updateAgentArchives: ({ tab, archiveFilter, archives }) => {
    dispatch(updateAgentArchives({
      tab,
      filter: archiveFilter,
      archives,
    }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withLDConsumer()(TaskFilter));
