import React, { useState, useEffect, Fragment } from 'react';
import styles from './AddOrSelectEmailIds.scss';
import { connect } from 'react-redux';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Spinner } from '../../../reusable_components/Spinner';
import { fetchUserFromDirectory } from '../../../../services';
import {
  addRecipients,
  deleteRecipients,
} from '../../../../store/action/email';
import { mergeClasses } from '../../../../utils/helpers';

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  inputBase: { color: '#fff' },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    backgroundColor: '#212121',
    '&[data-focus="true"]': {
      backgroundColor: '#333',
    },
  },
  listbox: { backgroundColor: '#212121' },
  loading: {
    color: 'rgba(255,255,255,0.6)',
    backgroundColor: '#212121',
  },
  paper: { borderRadius: '0px' },
  input: { color: '#fff' },
  groupLabel: {
    color: '#000',
    backgroundColor: '#F8F8F8',
  },
  noOptions: {
    color: '#e7474e',
    backgroundColor: '#212121',
  },
  cssLabel: {
    color: 'rgba(255, 255, 255, 0.6);',
    '&$cssFocused': {
      color: 'white',
    },
  },
  cssFocused: { color: 'white' },
  deleteIcon: { fill: '#e7474e' },
  outlined: { border: '1px solid rgba(255, 255, 255, 0.23)', color: '#fff' },
  underline: {
    '&::before': {
      borderBottom: '1px solid rgba(255,255,255,0.23)',
    },
  },
  inputRoot: {
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #21b0e8',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #184895',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottom: '2px solid #e7474e',
    },
  },
}));

const AddOrSelectEmailId = ({
  type,
  defaultValue,
  deleteRecipients,
  addRecipients,
  task_id,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(defaultValue);
  const [searchParam, setSearchParam] = useState('');
  const [error, setError] = useState(false);
  let loading = open && options.length === 0;

  const cleanOptions = array => {
    return array
      .flat()
      .map(item => {
        return item.emails.map(el => {
          return {
            name: item.name,
            email: el.address,
            type: el.type,
          };
        });
      })
      .flat();
  };

  useEffect(() => {
    let active = true;

    if (!open) {
      setOptions([]);
      return;
    }

    const delaySearch = setTimeout(async () => {
      searchParam.length &&
        (await fetchUserFromDirectory(searchParam)
          .then(res => res.json())
          .then(data => {
            if (active) {
              setOptions(cleanOptions(data.users));
              setError(false);
            }
          })
          .catch(err => setError(true)));
    }, 500);

    return () => {
      active = false;
      clearTimeout(delaySearch);
    };
  }, [searchParam, open]);

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        classes={{
          option: classes.option,
          listbox: classes.listbox,
          loading: classes.loading,
          noOptions: classes.noOptions,
          paper: classes.paper,
          groupLabel: classes.groupLabel,
          input: classes.input,
        }}
        id="email_id"
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        onChange={(e, value, reason) => {
          if (reason === 'select-option')
            addRecipients({ task_id, recipients: value, recipient_type: type });
          if (reason === 'remove-option')
            deleteRecipients({
              task_id,
              recipients: value,
              recipient_type: type,
            });
        }}
        options={options}
        limitTags={2}
        disableClearable={true}
        groupBy={option => option.name}
        renderOption={option => {
          return (
            <div className={styles.option}>
              {/* <span className={styles.name}>{option.name}</span> */}
              <div className={styles.email_addresses}>
                <span className={styles[option.type]} />
                <span
                  className={mergeClasses(
                    styles.address,
                    option.type !== 'communicator' ? styles.external_type : ''
                  )}
                >
                  {option.email}
                </span>
              </div>
            </div>
          );
        }}
        getOptionSelected={(option, value) => option.email === value.email}
        getOptionLabel={option => {
          return option.name || `Couldn't find associated name`;
        }}
        defaultValue={defaultValue}
        renderTags={(value, getTagProps) =>
          value.flat().map((option, index) => {
            return (
              <Chip
                variant="outlined"
                classes={{
                  deleteIcon: classes.deleteIcon,
                  outlined: classes.outlined,
                }}
                label={
                  option.name ||
                  option.email ||
                  `Oops!! Can't find name or email`
                }
                {...getTagProps({ index })}
              />
            );
          })
        }
        noOptionsText="User not found"
        // loading={loading}
        renderInput={params => (
          <TextField
            {...params}
            classes={{ root: classes.inputRoot }}
            className={classes.inputBase}
            label={(type || '').toUpperCase()}
            placeholder="Add email"
            onChange={e => {
              setSearchParam(e.target.value);
            }}
            error={error}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: { underline: classes.underline },
              endAdornment: (
                <>
                  {loading ? <Spinner size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  addRecipients: ({ task_id, recipients, recipient_type }) => {
    dispatch(addRecipients({ task_id, recipients, recipient_type }));
  },
  deleteRecipients: ({ task_id, recipients, recipient_type }) => {
    dispatch(deleteRecipients({ task_id, recipients, recipient_type }));
  },
});

export default connect(null, mapDispatchToProps)(AddOrSelectEmailId);
