import React, { useState } from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Button } from '@material-ui/core';
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@material-ui/icons';

import { mergeClasses } from '../../utils';
import { fetchBroadcastMessages } from '../../services';
import { updateBroadcastMessages } from '../../store/action/broadcast';

import Message from './message';
import Details from './details';

const useStyles = makeStyles(theme => ({
  paper_root: {
    color: '#fff',
    backgroundColor: 'transparent',
    minHeight: 200,
    overflow: 'hidden',
    height: '100%',
    '&:hover': {
      overflow: 'scroll',
    },
  },
  broadcast_root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#333',
    minWidth: 388,
    height: '100%',
    border: '2px solid #424242',
  },
  message_selected: {
    borderRight: 'none',
  },
  header: {
    position: 'sticky',
    top: 0,
    left: 0,
    height: 68,
    borderBottom: '2px solid #757575',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    backgroundColor: '#333',
  },
  messages_container: {
    height: '90%',
  },
  messages: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    color: '#fff',
  },
  h6: {
    color: '#fff',
  },
  time: {
    opacity: 0.45,
  },
  message: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  colorDefault: {
    backgroundColor: '#ff9404',
    color: theme.palette.getContrastText('#ff9404'),
  },
  button: {
    backgroundColor: '#184895',
    width: '100%',
    color: theme.palette.getContrastText('#184895'),
    borderRadius: 0,
    '&:hover': {
      backgroundColor: '#113369',
      color: theme.palette.getContrastText('#113369'),
    },
  },
  disabled: {
    opacity: 0.8,
    color: 'rgba(0, 0, 0, 0.26)',
  },
  info: {
    height: '100%',
    width: 17,
    minWidth: 17,
    backgroundColor: '#333',
  },
  selected: {
    backgroundColor: '#184895',
  },
}));

const Broadcast = ({
  messages = [],
  selectedId,
  user_id,
  updateBroadcastMessages,
}) => {
  const classes = useStyles();
  const [limit, setLimt] = useState(5);
  const [page_num, setPage] = useState(2);
  const [eol, setEol] = useState(false);
  const [sortBy, setsortBy] = useState(-1);

  return (
    <>
      <div
        className={mergeClasses(
          classes.broadcast_root,
          selectedId && classes.message_selected
        )}
      >
        <div id={'messages'} className={classes.messages_container}>
          <div className={classes.header}>
            <Typography variant={'h6'} classes={{ h6: classes.h6 }}>
              Broadcast Messages
            </Typography>
            <div className={classes.messages}>
              <Typography>SORT By: Created dt</Typography>
              {sortBy < 0 ? (
                <ArrowDownwardIcon onClick={e => setsortBy(1)} />
              ) : (
                <ArrowUpwardIcon onClick={e => setsortBy(-1)} />
              )}
            </div>
          </div>
          <Paper elevation={0} square classes={{ root: classes.paper_root }}>
            {(
              messages.sort((a, b) => {
                const keyA = a.created_at || 0;
                const keyB = b.created_at || 0;
                if (keyA < keyB) return sortBy * -1;
                if (keyA > keyB) return sortBy * 1;
                return 0;
              }) || []
            ).map(message => (
              <Message key={message.id} message={message} />
            ))}
          </Paper>
        </div>
        <Button
          disabled={eol}
          classes={{ root: classes.button, disabled: classes.disabled }}
          onClick={async e => {
            fetchBroadcastMessages({ user_id, limit, page_num })
              .then(res => res.json())
              .then(data => {
                console.log('Broadcast messages:', data);
                data.length && data.length == limit
                  ? setPage(page_num => page_num + 1)
                  : setEol(true);

                if (data.length) {
                  messages.push(...data);
                  data.length && updateBroadcastMessages(messages);
                }
              });
          }}
        >
          {messages.length
            ? eol
              ? 'End of List'
              : 'Load More'
            : 'Fetch messages'}
        </Button>
      </div>
      <div
        className={mergeClasses(classes.info, selectedId && classes.selected)}
      />
      <Details />
    </>
  );
};

const mapStateToProps = state => {
  const { broadcastState, userState } = state;
  const { messages = [], selectedId = '' } = broadcastState;
  const { id = '' } = userState;

  return {
    messages,
    selectedId,
    user_id: id,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateBroadcastMessages: data => {
      dispatch(updateBroadcastMessages(data));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Broadcast);
