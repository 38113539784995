import React, { Component } from 'react';
import { render } from 'react-dom';

import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import App from './App';

import store from './store';
import styles from './style.scss';

const Root = props => {
  const { store } = props;

  return (
    <Provider id="provider" store={store}>
      <App id="app" {...props} />
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object,
};

let el = document.getElementById('root');
el.classList.add(styles.root);
render(<Root store={store} id="root" />, el);
