export const connectionState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_CONNECTION': {
      const newState = { ...state, ...action.connectionState };
      return newState;
    }
    case "SHOW_RE_LOGIN_DIALOG": {
      const newState = { ...state, showReLogin: action.open }
      return newState
    }
  }

  return state;
};
