export const BuId = {
  fdr: 'BUSINESS_ID_FDR',
  fplus: 'BUSINESS_ID_FPLUS',
  cplus: 'BUSINESS_ID_CPLUS',
  lendage: 'BUSINESS_ID_LENDAGE',
  partners: 'BUSINESS_ID_PARTNERS',
  ffn: 'BUSINESS_ID_FFN',
  bills: 'BUSINESS_ID_BILLS',
  turnbull: 'BUSINESS_ID_TURNBULL',
  achieve: 'BUSINESS_ID_ACHIEVE',
  achieveLoans: 'BUSINESS_ID_ACHIEVE_LOANS',
  achieveHomeLoans: 'BUSINESS_ID_ACHIEVE_HOME_LOANS',
};
