// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".VM__error___2BnyU{justify-content:center;padding:5%;align-self:center;display:flex;cursor:pointer}.VM__error_icon___3lCCR{margin-right:10px}.VM__error_icon___3lCCR>path{color:#f04242 !important}.VM__error_msg___3vtyH{padding:2px;text-align:center;color:#f04242;font-size:16px;align-self:center;font-size:16px;font-weight:normal;font-stretch:normal;font-style:normal;line-height:1.38;letter-spacing:.5px}.VM__vm_container___10mG6{display:flex;justify-content:space-between;padding:2%}.VM__vm_dropup_wrapper___2ipke{background-color:#f4e76d;color:#000;width:40%;align-items:center;justify-content:space-between;display:flex;padding:5px;border-radius:6px}.VM__vm_dropup_name___3UwXV{color:#000;font-family:Rubik;font-stretch:normal;font-style:normal;line-height:1.43;letter-spacing:.25px}.VM__vm_dropup_icon___GHIDB>path{color:#000 !important}.VM__vm_dropup_icon___GHIDB :hover{cursor:pointer}.VM__leave_vm_wrapper___1PbFO{padding:5px;display:flex;align-items:center}.VM__leave_vm_icon___jziai{margin-left:10px}.VM__leave_vm_icon___jziai>path{color:#de5437 !important}.VM__leave_vm_icon___jziai :hover{cursor:pointer}.VM__leave_vm_msg____ibOT{font-family:Rubik;font-stretch:normal;font-style:normal;line-height:1.43;letter-spacing:.25px}.VM__timer___2Xwlx{color:orange;align-self:center;font-family:Rubik;font-stretch:normal;font-style:normal;line-height:1.43;letter-spacing:.25px;font-size:12px}", ""]);
// Exports
exports.locals = {
	"error": "VM__error___2BnyU",
	"error_icon": "VM__error_icon___3lCCR",
	"error_msg": "VM__error_msg___3vtyH",
	"vm_container": "VM__vm_container___10mG6",
	"vm_dropup_wrapper": "VM__vm_dropup_wrapper___2ipke",
	"vm_dropup_name": "VM__vm_dropup_name___3UwXV",
	"vm_dropup_icon": "VM__vm_dropup_icon___GHIDB",
	"leave_vm_wrapper": "VM__leave_vm_wrapper___1PbFO",
	"leave_vm_icon": "VM__leave_vm_icon___jziai",
	"leave_vm_msg": "VM__leave_vm_msg____ibOT",
	"timer": "VM__timer___2Xwlx"
};
module.exports = exports;
