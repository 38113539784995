import React, { Component } from 'react';
import { Chip, TableCell, TableRow } from '@material-ui/core';
import { AddCircleOutlined as AddIcon, Voicemail } from '@material-ui/icons';
import ForwardIcon from '@material-ui/icons/Forward';
import { connect } from 'react-redux';
import styles from './directory.scss';
import { resetDirectory } from '../../store/action/directory';
import { findDOMNode } from 'react-dom';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const fieldsMap = {
  Username: ['id', 'username'],
  Name: ['name', 'username'],
  'Integration Id': ['integration'],
  Role: ['roles'],
  Status: ['available'],
  Online: ['online'],
  Group: ['group'],
};

const getValue = (field, value) => {
  if (field === 'Status') {
    return value ? 'Available' : 'Not available';
  }

  if (field === 'Online') {
    return value ? 'true' : 'false';
  }

  return value;
};

class AtoZRow extends Component {
  componentDidMount() {
    if (this.props.forwardedRef) {
      const el = findDOMNode(this);
    }
  }
  constructor(props) {
    super(props);
    this.state = { addingParticipant: false };
    this.addParticipant = this.addParticipant.bind(this);
    this.redirectToVoicemail = this.redirectToVoicemail.bind(this);
    this.render = this.render.bind(this);
  }
  addParticipant = async (e, next) => {
    const {
      user,
      services,
      directoryState,
      taskState,
      resetDirectoryValues,
      updateTaskState,
      updateCallError,
      taskId,
    } = this.props;
    if (user.online) {
      this.setState({ addingParticipant: true });
      console.log('CallId', directoryState.callId);

      let res = await services
        .warmTransfer(directoryState.callId, {
          user_filters: [{ ids: [user.id] }],
          distribution_type: 'DIRECT',
          metadata: {
            label: user.username,
          },
          drop: !user.available && true,
        })
        .catch(e => {
          console.error('Error transfering your call', e);
          alert('Oops, something went wrong adding an agent');
          updateCallError({ taskState, text: res.statusText });
        });

      console.warn('Transfer call', res);

      if (taskId) {
        let cachedTask = JSON.parse(localStorage.getItem(taskId) || '{}');
        let warmTransferParticipants =
          cachedTask.warmTransferParticipants || [];
        warmTransferParticipants.unshift({
          user: user.username,
          initiated_at: Date.now(),
        });
        cachedTask.warmTransferParticipants = warmTransferParticipants;
        localStorage.setItem(taskId, JSON.stringify(cachedTask));
      }

      updateTaskState({ taskState });
      clearInterval(directoryState.fetchAgentInterval);
      resetDirectoryValues({
        tab: 0,
        callId: null,
        display: false,
        searchTerm: null,
        addNumber: null,
        fetchAgentInterval: null,
      });
    }
  };

  redirectToVoicemail = async (e, next) => {
    const {
      user,
      services,
      directoryState,
      callState,
      taskState,
      resetDirectoryValues,
      updateTaskState,
      updateCallError,
    } = this.props;
    const { callId } = directoryState;
    const curCallTask = callState.filter(item => {
      return (
        item.call &&
        (item.type === 'CALL' || item.type === 'FREE_FORM_CALL') &&
        item.call.id === callId &&
        item.call_info &&
        item.call_info.participants
      );
    })[0];
    this.setState({ addingParticipant: true });
    console.log('CallId', directoryState.callId);

    let res = await services
      .redirectCallToUser(directoryState.callId, curCallTask.id, user.id)
      .catch(e => {
        console.error('Error redirecting your call', e);
        alert('Oops, something went wrong redirecting to the agent');
        updateCallError({ taskState, text: res.statusText });
      });

    console.warn('Transfer call', res);

    updateTaskState({ taskState });
    clearInterval(directoryState.fetchAgentInterval);
    resetDirectoryValues({
      tab: 0,
      callId: null,
      display: false,
      searchTerm: null,
      addNumber: null,
      fetchAgentInterval: null,
    });
  };

  fwdParticipant = async (e, next) => {
    const {
      user,
      services,
      directoryState,
      taskState,
      resetDirectoryValues,
      updateTaskState,
    } = this.props;
    const { available, skills, group, integration, id } = user;
    this.setState({ addingParticipant: true });
    console.log('forward task to this participant ', user);
    let forwardTask = await services
      .forwardTaskById({
        distribution_type: 'DIRECT',
        task_id: directoryState.task.id,
        available,
        skills,
        group,
        integration,
        user_id: id,
        type: directoryState.task.type,
      })
      .then(response => response.json())
      .catch(e => {
        console.error('Error forwarding task', e);
        alert(
          `Oops, something went wrong while forwarding task to user: ${id}`
        );
      });

    console.log('forward task response: ', forwardTask);

    clearInterval(directoryState.fetchAgentInterval);

    resetDirectoryValues({
      tab: 0,
      forwardSms: false,
      forwardTask: false,
      display: false,
      searchTerm: null,
      fetchAgentInterval: null,
      task: null,
    });

    const { tasks } = taskState;
    tasks.forEach(
      task =>
        task.id === forwardTask.id &&
        (task.type === 'SMS'
          ? (task.sms = forwardTask.sms)
          : task.type === 'EMAIL' && (task.email = forwardTask.email)) &&
        (task.metadata = forwardTask.metadata) &&
        (task.metadata.user_id = user.id)
    );
    updateTaskState({ taskState });
  };

  render = () => {
    const {
      user,
      index,
      helpers,
      directoryState,
      fields,
      forwardedRef,
      pendingParticipants,
    } = this.props;
    const { online, available } = user;
    const { addingParticipant } = this.state;
    const { mergeClasses } = helpers;

    const disableStyle =
      pendingParticipants.find(p => p === user.username) && styles.disable;

    return (
      <TableRow
        hover
        key={index}
        tabIndex={-1}
        classes={{ root: styles.table_row }}
        ref={forwardedRef}
      >
        {fields.map((field, index) => {
          const attribute = fieldsMap[field].find(attr => {
            return attr in user;
          });

          let onlineStyle = '';
          let availabilityStyle = '';
          if (field === 'Username' || field === 'Status') {
            availabilityStyle = !online
              ? styles.offline
              : available
              ? styles.available
              : styles.unavailable;
          }

          return (
            <TableCell
              key={index}
              align="left"
              classes={{
                root: mergeClasses(styles.cell, availabilityStyle),
                body: mergeClasses(styles.cell_body),
              }}
            >
              {field === 'Username' ? (
                directoryState.forwardSms || directoryState.forwardTask ? (
                  <ForwardIcon
                    classes={{
                      root: mergeClasses(styles.fwd_icon),
                    }}
                    onClick={!addingParticipant ? this.fwdParticipant : null}
                  />
                ) : online ? (
                  <AddIcon
                    classes={{
                      root: mergeClasses(
                        styles.add_icon,
                        styles.online,
                        disableStyle
                      ),
                    }}
                    disabled={addingParticipant || !online}
                    onClick={!addingParticipant ? this.addParticipant : null}
                  />
                ) : (
                  <>
                    {this.props.flags && this.props.flags.enableColdTransfer && (
                      <Voicemail
                        classes={{
                          root: mergeClasses(styles.add_icon, styles.online),
                        }}
                        onClick={
                          !addingParticipant ? this.redirectToVoicemail : null
                        }
                      >
                        {' '}
                      </Voicemail>
                    )}
                  </>
                )
              ) : null}
              {getValue(field, user[attribute]) || 'NULL'}

              {field === 'Username' ? 
                user.telephony_provider === 'TELEPHONY_PROVIDER_GENESYS' ? (
                <Chip color="primary" label="G" className={styles.telephoy_provider}></Chip>
              ) : <Chip color="primary" label="T" className={styles.telephoy_provider}></Chip> : ''}
            </TableCell>
          );
        })}
      </TableRow>
    );
  };
}

const mapStateToProps = state => {
  return { callState: state.callState };
};

const mapDispatchToProps = dispatch => {
  return {
    resetDirectoryValues: ({
      tab,
      forwardSms,
      callId,
      display,
      searchTerm,
      addNumber,
      fetchAgentInterval,
      task,
    }) => {
      dispatch(
        resetDirectory({
          tab,
          forwardSms,
          callId,
          display,
          searchTerm,
          addNumber,
          fetchAgentInterval,
          task,
        })
      );
    },
    updateTaskState: ({ taskState }) => {
      dispatch({
        type: 'UPDATE_TASKS',
        taskState: {
          ...taskState,
          add: null,
        },
      });
    },
    updateCallError: ({ taskState, text }) => {
      dispatch({
        type: 'UPDATE_TASKS',
        taskState: {
          ...taskState,
          callError: text,
        },
      });
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(AtoZRow));
