import React, { useEffect, useState } from 'react';
import styles from './header.scss';
import Nav from './nav';
import Connection from './connection';
import { updateConnection } from '../../store/action/connection';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import CountDown from '../main/countdown';
import countdownStyles from '../main/countdown.scss';
import store from '../../store';
import { expandSidebar } from '../../store/action/sidebar';
import AgentQueue from './AgentQueue';
import { Grid } from '@material-ui/core';
import Message from './message';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { BoundaryError } from '../reusable_components/boundaryerror';
import HeaderStats from './HeaderStats';
import TaskQueue from './TaskQueue';
Modal.setAppElement('#root');

const Header = props => {
  const {
    activityState,
    helpers,
    userState,
    expandSidebar,
    message,
    flags = {},
    expand,
    tasks_queue,
  } = props;

  return (
    <div id="header" className={styles.header}>
      {/* <Grid item container direction="row" xs={1}> */}
        <div
          id="menu-icon"
          className={styles.menu_icon}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();

            expandSidebar({ expand: !expand });
          }}
        ></div>
      {/* </Grid> */}
      {/* <Grid container direction="row" style={{ width: 10 }}>
        <BoundaryError>
          <>{message?.text && <Message message={message} />}</>
        </BoundaryError>
      </Grid> */}
      <Grid
        container
        direction="row"
        justify="space-between"
        spacing={4}
        alignItems="center"
        style={{width: 'calc(100% - 40px)'}}
      >

          <Grid item style={{ marginLeft: -20, minWidth: 388 }}>
            {flags.enableTaskQueues && (
              <BoundaryError>
                <Grid
                  container
                  direction="row"
                  item
                  style={{ width: 'fit-content', height: 75 }}
                  className={tasks_queue.length ? styles['queue-block'] : ''}
                >
                  {tasks_queue.map((task_queue, index) => {
                    return <TaskQueue task_queue={task_queue} key={index} />;
                  })}
                </Grid>
              </BoundaryError>
              )}
          </Grid>

<Grid 
      container
        direction="row"
        justify="flex-end"
        spacing={4}
        alignItems="center"
        classes={{['spacing-xs-4']:styles.grid_spacing }}

>

        {flags.enableStatsHeader && (
          <Grid item xs={8}>
            <BoundaryError>
              <HeaderStats />
            </BoundaryError>
          </Grid>
        )}


        {flags.enableGroupQueueHeader && (
          <Grid item>
            <BoundaryError>
              <AgentQueue />
            </BoundaryError>
          </Grid>
        )}

        <Grid item>
          <Connection className={`${styles.header_children}`} />
        </Grid>
        </Grid>
      </Grid>
      {activityState.showCountDown && (
        <Modal
          isOpen={activityState.showCountDown}
          className={helpers.mergeClasses(countdownStyles.modal_overlay)}
          overlayClassName={countdownStyles.modal_outer_overlay}
          shouldCloseOnOverlayClick={false}
        >
          <CountDown
            {...props}
            userState={userState}
            store={store}
            activityState={activityState}
          />
        </Modal>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    userState = {},
    teamState = {},
    integrationState = {},
    broadcastState = {},
    sidebarState = {},
  } = state;
  const { team, teams = [] } = userState;
  const { selectedTeam = '' } = teamState;
  const { message = {} } = broadcastState;
  const { expand = false } = sidebarState;
  const { tasks_queue = [] } = integrationState;

  return {
    connectionState: state.connectionState,
    expand,
    activityState: state.activityState,
    userState: state.userState,
    teams,
    selectedTeam,
    message,
    tasks_queue: (tasks_queue || []).slice(0, 2),
  };
};

const mapDispatchToProps = dispatch => ({
  expandSidebar: expand => {
    dispatch(expandSidebar(expand));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Header));
