import React from 'react';

import styles from '../home.scss';

import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';

const OktaLogin = props => {
  const { authService, authState, environmentState } = props;

  if (authState.isPending) {
    return (
      <div id="setenv" className={styles.setenv_login}>
        Trying to Login... Please wait...
      </div>
    );
  }

  // NOTE! this could be a bug on Okta side
  // sometimes authState.isAuthenticated is true but accessToken is still null
  // We should show the login screen when it happens because all subsequent Genesys/Controller auth/ws connection rely on the accessToken
  return (
    <div className={styles.setenv_login}>
      <div className={styles.login_wrap}>
        <div className={styles.desktop_icon}></div>

        <button
          className={styles.login_button}
          onClick={() => {
            authService.login();
          }}
        >
          Login
        </button>
      </div>

      <p style={{ color: '#fff' }}>
        {environmentState.appVersion
          ? `Version ${environmentState.appVersion} from ${window.location.hostname}`
          : environmentState.appVersionLoading
          ? 'Fetching Version...'
          : 'Failed to fetch version '}
      </p>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    environmentState: state.environmentState,
  };
};

export default withOktaAuth(connect(mapStateToProps)(OktaLogin));
