// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/swap-calls-white.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".participant__participant_item___1izq2{display:flex;flex-direction:column;justify-content:flex-start;padding-top:4px;padding-left:8px;padding-right:8px}.participant__participant_item___1izq2.participant__conference___1Ofa_{border-bottom:1px solid #fff;border-top:1px solid #fff}.participant__participant_desc___3A2Lc{display:flex;flex-direction:row;justify-content:space-between;font-size:14px}.participant__call_duration___2PWYJ{align-self:flex-end}.participant__participant_info___3_70C{display:flex;flex-direction:row;justify-content:space-between;margin-bottom:5px}.participant__participant_name___3vMAC{font-size:34px}.participant__call_duration___2PWYJ{font-size:24px}.participant__single_meta___1lmi-{display:flex;flex-direction:row;justify-content:space-between}.participant__swap_button___qdawt{min-width:96px;width:96px;height:40px;background-color:#d8a200;color:#fff;display:flex;flex-direction:row;border:none;justify-content:center;align-items:center;font-size:14px;font-weight:normal;font-stretch:normal;font-style:normal;line-height:1.43;letter-spacing:.25px;text-align:center}.participant__swap_icon___ob-it{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-size:cover;width:20px;height:20px;margin-right:5px}.participant__name_long___1JxXu{font-size:24px}.participant__swap_disabled___3j2gk{background-color:#828a9f}", ""]);
// Exports
exports.locals = {
	"participant_item": "participant__participant_item___1izq2",
	"conference": "participant__conference___1Ofa_",
	"participant_desc": "participant__participant_desc___3A2Lc",
	"call_duration": "participant__call_duration___2PWYJ",
	"participant_info": "participant__participant_info___3_70C",
	"participant_name": "participant__participant_name___3vMAC",
	"single_meta": "participant__single_meta___1lmi-",
	"swap_button": "participant__swap_button___qdawt",
	"swap_icon": "participant__swap_icon___ob-it",
	"name_long": "participant__name_long___1JxXu",
	"swap_disabled": "participant__swap_disabled___3j2gk"
};
module.exports = exports;
