import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../supervisor.scss';
import {
  mergeClasses,
  completeTaskData,
  getArchiveQuery,
  buildArchiveQuery,
} from '../../../utils';
import { fetchArchives } from '../../../services';
import AgentTasks from './AgentTasks';
import AgentOffers from './AgentOffers';
import TaskList from './TaskList';
import UserStatus from './UserStatus';
import UserDetails from './UserDetails';

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '../../reusable_components/Expansions';
import { LightTooltip } from '../../reusable_components/LightTooltip';
import { FormControlLabel } from '@material-ui/core';
import store from '../../../store';
const { dispatch, getState } = store;
import {
  updateAgentTaskId,
  updateAgentData,
} from '../../../store/action/agent';
import { updateTeamView } from '../../../store/action/team';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const User = ({
  user,
  tasks,
  offers,
  availabilityStatus,
  index,
  updateTeamView,
  updateAgentTaskId,
  changeExpanded,
  handleAgent,
  agent,
  expanded,
  archives,
  flags,
  showActiveCallUsers,
}) => {
  const [openAllTasks, setOpenAllTasks] = useState(false);
  const [showCallButton, setShowCallButton] = useState(true);
  const [updatedTasks, setUpdatedTasks] = useState(tasks);
  const [available, setAvailable] = useState(user.available);

  const handleAvailableFlag = newFlag => {
    setAvailable(newFlag);
  };

  const handleChange = (panel, id) => (event, newExpanded) => {
    changeExpanded(panel, newExpanded);
    agent && agent === id ? null : handleAgent(id);
    setShowCallButton(!showCallButton);
  };

  useEffect(() => {
    (tasks || []).length &&
      completeTaskData(tasks).then(data => {
        setUpdatedTasks(
          (data || []).sort((a, b) => a.created_at - b.created_at)
        );
      });
  }, [tasks]);

  let activeCallTasks = [],
    activeCallUsers = [];

  updatedTasks &&
    updatedTasks.forEach(task => {
      if (
        task.type === 'CALL' &&
        task.call_info &&
        task.call_info.status === 'active' &&
        task.call_info.participants.length > 0 &&
        task.call_info.participants.some(
          p => p.id === user.id && p.status.toLowerCase() === 'connected'
        )
      ) {
        activeCallTasks.push({ task });
        showActiveCallUsers &&
          !activeCallUsers?.includes(task?.user_id) &&
          activeCallUsers?.push(task?.user_id);
      }
    });

  const remainingLength =
    activeCallTasks.length > 0 && user.activetasks - activeCallTasks.length;

  const Userblock = () => (
    <div key={user.id} id={`parent_${user.id}`}>
      <ExpansionPanel
        expanded={expanded === `panel${index}`}
        onChange={handleChange(`panel${index}`, user.id)}
      >
        <ExpansionPanelSummary>
          <div
            key={user.username}
            user={user.username}
            index={index}
            id={`user_${user.username}`}
            className={styles.user_block}
          >
            <div className={styles.user_basic_view}>
              <LightTooltip
                arrow
                title={
                  <UserDetails
                    capacities={user.capacities}
                    max_priority={user.max_priority}
                    active={user.active}
                  />
                }
              >
                <div className={styles.profile}>
                  <span
                    className={mergeClasses(
                      styles.icon_text,
                      available && styles.available,
                      styles[availabilityStatus]
                    )}
                  >
                    {user.iconText}
                  </span>
                  {user.activetasks === 0 ? (
                    <span
                      className={mergeClasses(
                        styles.notification,
                        styles['no_tasks'],
                        styles[availabilityStatus]
                      )}
                    >
                      {user.activetasks}
                    </span>
                  ) : (
                    <span
                      className={mergeClasses(
                        styles.notification,
                        available && styles.available,
                        styles[availabilityStatus]
                      )}
                    >
                      {user.activetasks}
                    </span>
                  )}
                </div>
              </LightTooltip>
              <div
                className={mergeClasses(
                  styles.name_status,
                  styles[availabilityStatus]
                )}
              >
                <span className={styles.username} id={user.username}>
                  {user.username}
                </span>
                <span className={styles.user_status}>
                  {user.online ? (
                    <UserStatus
                      user_id={user.id}
                      group={user.group}
                      integration={user.integration}
                      available={user.available}
                      handleAvailableFlag={handleAvailableFlag}
                      status={
                        user.status.charAt(0).toUpperCase() +
                        user.status.slice(1)
                      }
                    />
                  ) : (
                    'Offline'
                  )}
                </span>
              </div>
            </div>
            {/* <div className={styles.task_list}> */}
            {!expanded && (
              <FormControlLabel
                className={styles.task_list}
                onClick={event => {
                  event.stopPropagation();
                  agent && agent === user.id ? null : handleAgent(user.id);
                  // fetchAgentStateById(user.id);
                  console.log('clicked');
                }}
                onFocus={event => event.stopPropagation()}
                control={
                  <>
                    <ActiveCalls
                      activeCallTasks={activeCallTasks}
                      user={user}
                    />
                    {user.activetasks > 2 && (
                      <>{remainingLength && `${remainingLength}+`}</>
                    )}
                  </>
                }
              />
            )}
          </div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <div className={styles.user_expand_view}>
            <div className={styles.expand_tasks}>
              <AgentTasks
                tasks={(updatedTasks || []).filter(
                  task => task.type === 'CALL'
                )}
                user={user.id}
              />
              <AgentOffers offers={offers} user={user.id} />
              {openAllTasks && (
                <AgentTasks
                  tasks={(updatedTasks || []).filter(
                    task => task.type !== 'CALL'
                  )}
                  user={user.id}
                />
              )}
            </div>
            <button
              className={styles.expand_button}
              onClick={async e => {
                openAllTasks ? setOpenAllTasks(false) : setOpenAllTasks(true);
              }}
            >
              {openAllTasks ? <>SHOW CALLS ONLY</> : <>SHOW ALL TASKS</>}
            </button>
            <button
              className={styles.expand_button}
              onClick={async e => {
                e.preventDefault();
                // handleShowArchives();
                updateTeamView('archives');
                updateAgentTaskId({ taskId: null });

                // get the user archives
                console.log('Getting archives');
                dispatch({
                  type: 'UPDATE_TEAM',
                  teamState: {
                    ...getState().teamState,
                    user: user.username,
                    status: user.status,
                    noOfTasks: user.activetasks,
                    iconText: user.iconText,
                    availabilityStatus,
                  },
                });
                let query,
                  archiveData = archives || [];

                query = flags.enableSupervisorArchiveFilters
                  ? buildArchiveQuery({})
                  : getArchiveQuery({
                      offset: archiveData.length,
                    });
                await fetchArchives(user.id, query)
                  .then(archives => {
                    archiveData.push(...archives);
                  })
                  .catch(err => {
                    console.error(
                      `Error fetching archive list with query: ${query}`,
                      err
                    );
                  });
                dispatch({
                  type: 'UPDATE_AGENT',
                  agentState: {
                    archives: archiveData,
                    date: new Date(),
                  },
                });
              }}
            >
              SHOW ARCHIVES
            </button>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );

  return (
    <>
      {showActiveCallUsers ? (
        activeCallUsers.includes(user.id) && <Userblock />
      ) : (
        <Userblock />
      )}
    </>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { teamState, agentState } = state;
  const { userId } = ownprops;
  const { showActiveCallUsers, users } = teamState;
  const user = users.find(u => u.id === userId);
  const { archives } = agentState;

  const {
    username,
    online,
    available,
    id,
    status,
    team,
    tasks,
    offers,
    group,
    integration,
    capacities,
    max_priority,
    active,
  } = user;
  return {
    user: {
      archives,
      username,
      online,
      id,
      status,
      team,
      activetasks: (tasks || []).length + (offers || []).length,
      iconText: username.slice(0, 2).toUpperCase(),
      available,
      group,
      integration,
      capacities,
      max_priority,
      active,
      telephonyProvider: user.telephony_provider,
    },
    showActiveCallUsers,
    tasks: tasks,
    offers: offers,
    availabilityStatus: online ? 'online' : 'offline',
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAgentTaskId: ({ taskId }) => dispatch(updateAgentTaskId({ taskId })),
    updateAgentData: ({ data }) => dispatch(updateAgentData({ data })),
    updateTeamView: teamview => dispatch(updateTeamView(teamview)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(User));

const ActiveCalls = props => {
  const { activeCallTasks, user } = props;
  return (
    <>
      {activeCallTasks.slice(0, 2).map((item, index) => {
        return <TaskList key={item.task.id} task={item.task} user={user} />;
      })}
    </>
  );
};
