import React, { useState } from 'react';
import styles from './home.scss';
import taskStyles from './tasks.scss';
import { connect } from 'react-redux';
import Task from './task';
import TaskFilter from './TaskFilter/index';
import Call from './call';
import {
  Box,
  Typography,
  Chip,
  Switch,
  FormControlLabel,
} from '@material-ui/core';

import { BoundaryError } from '../reusable_components/boundaryerror';
import { makeStyles } from '@material-ui/core/styles';
import {
  removeTaskFromWeb,
  updateExpand,
  updateTaskStateSort,
  updateArchivedTasks,
} from '../../store/action/task';
import { buildArchiveQuery, Feedback } from '../../utils';
import FeedbackSnackbar from '../feedback/FeedbackSnackbar';
import GroupedTasks from './groupedTasks';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { Select, MenuItem } from '@material-ui/core';

const titleMap = {
  tasks: 'All Tasks',
  archived: 'Archived Tasks',
  sms: 'SMS Tasks',
  email: 'e-mail Tasks',
  call: 'Call Tasks',
  direct: 'Direct Tasks',
};

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#212121',
  },
  select: {
    color: '#fff',
    marginLeft: '3px',
  },
  SelectIcon: {
    color: '#fff',
  },
}));

const sortTypes = {
  age: 'age',
  weight: 'weight',
  getDefaultSortType: tab => {
    if (tab === 'tasks') return sortTypes.weight;
    return sortTypes.age;
  },
};

const Tasks = props => {
  const classes = useStyles();
  const {
    events,
    tasks,
    archived,
    filterTaskType,
    detailView,
    callError,
    helpers,
    services,
    callState,
    showWrapUpRequired,
    tab,
    expand,
    updateExpand,
    sort,
    updateTaskStateSort,
    updateArchivedTasks,
    removeTaskFromWeb,
    id,
    archiveFilter,
    flags,
  } = props;

  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({ msg: '', severity: '' });
  const [groupByType, setGroupByType] = useState(false);

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  const defaultSortType = flags.enableSortByWeight
    ? sortTypes.getDefaultSortType(tab)
    : sortTypes.age;
  const [sortBy, setSortBy] = useState(defaultSortType);

  const displayList =
    helpers.getTaskList({ tasks, archived, filterTaskType }) || [];

  displayList.sort(
    flags.enableSortByWeight && sortBy === sortTypes.weight
      ? helpers.sortByWeight(sort)
      : helpers.sortByAge(sort)
  );

  const { mergeClasses, isOkayToSwapCall } = helpers;

  const calls = [];
  callState.forEach((item, index) => {
    let isCallAndHasParticipants =
      item.call &&
      (item.type === 'CALL' ||
        item.type === 'COACHING' ||
        item.type === 'FREE_FORM_CALL') &&
      item.call_info &&
      item.call_info.participants &&
      item.call_info.participants.length;

    if (!isCallAndHasParticipants) {
      return;
    }

    let isAgentInCall = item.call_info.participants.some(participant => {
      return participant.id === id && participant.status === 'connected';
    });

    let canAgentConnect = item.call ? !item.call.cant_connect : false;

    let showDelayedLeftMessage = false;

    let nonAgentCount = item.call_info.participants.filter(
      part => part.id !== id
    ).length;

    if (nonAgentCount > 1) {
      if (
        events[item.call.id] &&
        events[item.call.id].participantLeft !== null &&
        events[item.call.id].participantLeft !== undefined
      ) {
        showDelayedLeftMessage = true;
      }
    }

    // call.cant_connect=true leads to canAgentConnect=false
    // the flag indicates an agent has disconnected from the call and cannot connect again
    let shouldAddToCallList =
      canAgentConnect && (isAgentInCall || showDelayedLeftMessage);

    if (shouldAddToCallList) {
      calls.push({
        taskId: item.id,
        ivrs: item.ivrs,
        ...item.call_info,
        ...item.call,
        type: item.type,
        showDelayedLeftMessage,
      });
    }
  });

  const expandStyle = (calls || []).length > 0 ? taskStyles.call_expanded : '';

  const disableSwap = !isOkayToSwapCall({
    events,
    tasks: callState,
    callError,
    id,
  });

  return (
    <>
      {feedback?.msg?.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'right'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
        />
      )}
      <div
        id="tasks"
        className={mergeClasses(
          taskStyles.tasks,
          detailView ? taskStyles.selected_task : ''
        )}
      >
        <div
          id="task_section"
          className={mergeClasses(taskStyles.tasks_section)}
        >
          <div id="task_title" className={taskStyles.task_title}>
            <div id="task_title_heading" className={taskStyles.task_title_text}>
              {titleMap[tab]}
            </div>
            <div className={taskStyles.task_sort}>
              <div className={taskStyles.task_sort_by}>
                SORT :
                <>
                  {filterTaskType === 'archived' &&
                  tab === 'archived' &&
                  (archived || []).length == 1 ? (
                    <div className={classes.root}>
                      <Chip
                        label="Search By TaskId"
                        variant="outlined"
                        onDelete={e => {
                          e.preventDefault();
                          console.info('You clicked the delete icon.');
                          removeTaskFromWeb({
                            tab: 'tasks',
                            filterTaskType: 'tasks',
                          });
                        }}
                      />
                    </div>
                  ) : // only All tasks view support order by weight
                  tab === 'tasks' && flags.enableSortByWeight ? (
                    <>
                      <Select
                        value={sortBy}
                        classes={{
                          icon: classes.SelectIcon,
                          root: classes.select,
                        }}
                        onChange={event => {
                          setSortBy(event.target.value);
                        }}
                      >
                        <MenuItem value={sortTypes.weight}>Weight</MenuItem>
                        <MenuItem value={sortTypes.age}>Age</MenuItem>
                      </Select>
                    </>
                  ) : (
                    <>
                      <div> Task Age</div>
                    </>
                  )}
                </>
              </div>
              <>
                {filterTaskType === 'archived' &&
                tab === 'archived' &&
                (archived || []).length == 1 ? (
                  <div>{archived[0].id}</div>
                ) : (
                  <div
                    className={mergeClasses(
                      taskStyles.task_arrow,
                      sort > 0 ? taskStyles.task_arrow_reverse : ''
                    )}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      updateTaskStateSort(sort);
                    }}
                  ></div>
                )}
              </>
              <>
                {tab == 'archived' && flags.enableArchiveFilters ? (
                  <FormControlLabel
                    control={
                      <Switch
                        checked={groupByType}
                        onChange={e => {
                          setGroupByType(e.target.checked);
                        }}
                      />
                    }
                    label="Group tasks"
                  />
                ) : (
                  ''
                )}
              </>
            </div>
            {tab?.toLowerCase() === 'archived' && (
              <TaskFilter uId={id} tab={tab} />
            )}
          </div>
          <div id="task_list" className={taskStyles.task_list}>
            {(displayList || []).length ? (
              groupByType ? (
                <GroupedTasks displayList={displayList} {...props} />
              ) : (
                displayList.map((task, index) => {
                  return (
                    <BoundaryError
                      key={`
                      ${
                        task?.type?.toUpperCase() === 'TASK'
                          ? task.id
                          : task.task_id
                      }_boundary_${index}`}
                    >
                      <Task
                        key={`${
                          task?.type?.toUpperCase() === 'TASK'
                            ? task.id
                            : task.task_id
                        }_${index}`}
                        task={task}
                        index={index}
                        {...props}
                        tab={tab}
                      />
                    </BoundaryError>
                  );
                })
              )
            ) : (
              'No Task'
            )}
            {(tab || '').toLowerCase() === 'archived' &&
            (displayList || []).length &&
            displayList.length >= 20 ? (
              <button
                id="archive_load_button"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();

                  // let noDispatch = true;
                  let query = buildArchiveQuery({
                    taskIds: archiveFilter.taskIds,
                    createdAtFrom: archiveFilter.fromDate,
                    createdAtTo: archiveFilter.toDate,
                    offset: archived.length,
                    types: archiveFilter.types,
                    clientSMSNumber: archiveFilter.clientSMSNumber,
                    clientCallNumber: archiveFilter.clientCallNumber,
                  });
                  setLoading(true);
                  services
                    .fetchArchives(id, query)
                    .then(archives => {
                      updateArchivedTasks({
                        tab: 'archived',
                        archives,
                      });
                    })
                    .catch(err =>
                      updateFeedback(
                        `${err.code}: ${err.message}`,
                        Feedback.ERROR
                      )
                    )
                    .finally(() => setLoading(false));
                }}
                className={taskStyles.archive_load_button}
              >
                {loading ? <>Loading...</> : <>Load More</>}
              </button>
            ) : null}
          </div>
        </div>

        <div id="call_section" className={taskStyles.calls}>
          <div
            id="expand"
            className={mergeClasses(taskStyles.call_expand, expandStyle)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if ((calls || []).length > 0) {
                updateExpand(expand);
              }
            }}
          >
            {(calls || []).length
              ? expand
                ? 'COMPACT CONFERENCE LINES'
                : 'EXPAND CONFERENCE LINES'
              : ''}
          </div>
          {(calls || []).length
            ? calls.map((call, index) => {
                let noneAgentCount = 0;
                let agentCount = 0;
                call.participants.forEach(part => {
                  if (part.id !== id) {
                    noneAgentCount++;
                  } else {
                    agentCount++;
                  }
                });
                const { showDelayedLeftMessage } = call;
                return (
                  <BoundaryError key={index}>
                    <Call
                      {...props}
                      call={call}
                      key={index}
                      index={index}
                      expand={expand}
                      calls={calls}
                      showDelayedLeftMessage={showDelayedLeftMessage}
                      disableSwap={disableSwap}
                    />
                  </BoundaryError>
                );
              })
            : !showWrapUpRequired
            ? 'No Active Call'
            : null}
          {showWrapUpRequired ? (
            <Box
              className={mergeClasses(taskStyles['wrap-up-required'])}
              flexGrow="1"
            >
              <Typography align="center" color="inherit">
                WRAPUP REQUIRED
              </Typography>
            </Box>
          ) : null}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  const { taskState, callState, userState, templateState } = state;
  const { id } = userState;

  const {
    detailView = '',
    tab = 'tasks',
    expand,
    sort,
    archived = [],
    filterTaskType,
    tasks = [],
    callError,
    events,
    archiveFilter,
  } = taskState;
  const selectedTask = callState.find(task => task.id === detailView) || {};
  const currTemplate = templateState[selectedTask.template || ''];
  const requiredWrapUp = ((currTemplate || {}).wrapup_fields || []).some(
    f => f.required
  );

  const showWrapUpRequired =
    requiredWrapUp &&
    (!selectedTask.call_info ||
      selectedTask.call_info.status === 'COMPLETED' ||
      !(selectedTask.call_info.participants || []).some(p => p.id === id));

  return {
    events,
    tasks,
    archived,
    filterTaskType,
    callError,
    detailView,
    callState,
    showWrapUpRequired,
    tab,
    expand,
    sort,
    id,
    archiveFilter,
  };
};

const mapDispatchToProps = dispatch => ({
  updateExpand: expand => {
    dispatch(updateExpand(expand));
  },
  updateTaskStateSort: sort => {
    dispatch(updateTaskStateSort(sort));
  },
  updateArchivedTasks: ({ tab, archives }) => {
    dispatch(updateArchivedTasks({ tab, archives }));
  },
  removeTaskFromWeb: ({ tab, filterTaskType }) => {
    dispatch(removeTaskFromWeb({ tab, filterTaskType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Tasks));
