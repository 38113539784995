import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Timer from 'react-compound-timer';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import styles from './Recording.scss';

import {
  Close as DeleteVM,
  Adjust as RecordVM,
  PlayCircleFilled as PlayVM,
  PauseCircleFilled as PauseVM,
  Stop as StopVM,
  ArrowDropDown as DropDown,
  Edit as EditVM,
  Save as SaveVM,
} from '@material-ui/icons';
import Menu from '@material-ui/core/Menu';

import Textbox from '../../reusable_components/Textbox';
import { Spinner } from '../../reusable_components/Spinner';
import { StyledMenuItem } from '../../reusable_components/StyledMenuItem';

import { mergeClasses } from '../../../utils/helpers';
import {
  deleteVMById,
  deleteVMByName,
  updateVMLabel,
} from '../../../store/action/vm';
import { updateErrorMsg } from '../../../store/action/error';
import {
  startNewVMRecording,
  endCall,
  deleteVMRecording,
  editUserRecording,
} from '../../../services/index';

const Recording = props => {
  const {
    vIndex,
    name,
    recording_id,
    type,
    url,
    created_at,
    recording,
    countdown,
    user_id,
    sip_number,
    deleteVMById,
    deleteVMByName,
    updateErrorMsg,
    recording_types,
    recordingError,
    phoneError,
    userTelephonyProvider,
    flags,
  } = props;
  const [callId, setCallId] = useState();
  const [customName, setcustomName] = useState(name);
  const [recordingType, setRecordingType] = useState(type);
  const [recordButtonLoading, setRecordButtonLoading] = useState(false);
  const [stopButtonLoading, setStopButtonLoading] = useState(false);
  const [deleteButtonLoading, setDeleteButtonLoading] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const recordStyle = !url ? null : styles.dont_allow;
  const playStyle = !url ? styles.dont_allow : null;
  const editStyle = !url ? styles.dont_allow : null;
  const saveStyle = !url ? styles.dont_allow : null;
  const closeStyle = !url ? styles.dont_allow : null;
  const disableVMNameAndType = canEdit ? false : !!url;

  const showVMEditingControls = userTelephonyProvider !== 'genesys';

  useEffect(() => {
    setShowTimer(false);
    setCallId(undefined);
  }, [recordingError, phoneError, url]);

  const handleMenuClick = e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = e => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, rt) => {
    e.preventDefault();
    console.log('selected rt: ', rt);
    setRecordingType(rt);
    setAnchorEl(null);
  };

  return (
    <>
      {flags.enableRecordingTypes && (
        <div className={styles.type_wrapper}>
          <div className={styles.dropdown_wrapper}>
            <div className={styles.dropdown_name}>{recordingType}</div>
            {recording_types.length > 0 && !disableVMNameAndType && (
              <DropDown
                onClick={handleMenuClick}
                className={styles.dropdown_icon}
              />
            )}
          </div>
          <Menu
            id="vm-type-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {recording_types.map(rt => {
              return (
                <StyledMenuItem
                  key={rt}
                  onClick={e => handleMenuItemClick(e, rt)}
                >
                  {rt}
                </StyledMenuItem>
              );
            })}
          </Menu>
          {showTimer && callId && (
            <div className={styles.timer}>
              <Timer
                initialTime={countdown}
                direction="backward"
                checkpoints={[
                  {
                    time: 0,
                    callback: async () => {
                      console.log('Timer end');
                      setShowTimer(false);

                      if (callId) {
                        setStopButtonLoading(true);
                        await endCall(callId)
                          .then(res => setStopButtonLoading(false))
                          .catch(err => {
                            console.log('Error stop recording', err);
                            setStopButtonLoading(false);
                            updateErrorMsg({
                              error_msg: `cant stop recording`,
                              error_type: 'vm_error',
                            });
                          });
                      }
                    },
                  },
                ]}
              >
                <Timer.Minutes
                  formatValue={value => (value == 0 ? null : `${value}m`)}
                />
                <Timer.Seconds
                  formatValue={value => (value == 0 ? null : `${value}s`)}
                />
              </Timer>
            </div>
          )}
        </div>
      )}
      <div className={styles.add_voice_recording_wrapper}>
        <Textbox
          label={undefined}
          value={customName || ''}
          multiline={false}
          key={vIndex}
          maxlength={30}
          disabled={disableVMNameAndType}
          handleChange={value => {
            setcustomName(value);
          }}
        />
        {disableVMNameAndType ? (
          showVMEditingControls && 
            <EditVM
            className={mergeClasses(styles.edit, editStyle)}
            onClick={async e => {
              e.preventDefault();
              console.log('Edit VM');
              // setEditButtonLoading(true);
              setCanEdit(true);
              setShowSave(true);
            }}
          />
        ) : (
          <>
            {showSave ? (
              <>
                {saveButtonLoading ? (
                  <Spinner size={20} />
                ) : (
                  <SaveVM
                    className={mergeClasses(styles.save, saveStyle)}
                    onClick={async e => {
                      e.preventDefault();
                      console.log('Save VM');
                      setSaveButtonLoading(true);
                      await editUserRecording({
                        name: customName,
                        type: recordingType,
                        user_id,
                        url,
                        recording_id,
                      })
                        .then(res => res.json())
                        .then(rec => {
                          setShowSave(false);
                          setCanEdit(false);
                          setSaveButtonLoading(false);
                          console.log('Edited recording', rec);
                        })
                        .catch(err => {
                          updateErrorMsg({
                            error_msg: `Error saving new name/type`,
                            error_type: 'vm_error',
                          });
                          console.error('Error saving new name/type', err);
                          setCanEdit(false);
                          setSaveButtonLoading(false);
                        });
                    }}
                  />
                )}
              </>
            ) : (
              <>
                {recordButtonLoading ? (
                  <Spinner size={20} />
                ) : (
                  showVMEditingControls && 
                  <RecordVM
                    className={mergeClasses(styles.record, recordStyle)}
                    onClick={async e => {
                      e.preventDefault();
                      console.log('Recording started');
                      setRecordButtonLoading(true);
                      setShowTimer(true);
                      await startNewVMRecording({
                        name: customName,
                        type: recordingType || 'test',
                        user_id: user_id,
                        sip: sip_number,
                      })
                        .then(res => res.json())
                        .then(rec => {
                          setRecordButtonLoading(false);
                          setCallId(rec.id);
                          console.log('Recording details', rec, callId);
                        })
                        .catch(err => {
                          updateErrorMsg({
                            error_msg: `Error recoding custom VM`,
                            error_type: 'vm_error',
                          });
                          console.error('Error recoding custom VM', err);
                          setRecordButtonLoading(false);
                          setShowTimer(false);
                        });
                    }}
                  />
                )}
                {stopButtonLoading ? (
                  <Spinner size={20} />
                ) : (
                  <StopVM
                    className={mergeClasses(styles.record, recordStyle)}
                    onClick={async e => {
                      console.log('Stop recording', callId);
                      if (callId) {
                        setStopButtonLoading(true);
                        setShowTimer(false);
                        await endCall(callId)
                          .then(res => setStopButtonLoading(false))
                          .catch(err => {
                            console.log('Error stop recording', err);
                            setStopButtonLoading(false);
                            updateErrorMsg({
                              error_msg: `cant stop recording`,
                              error_type: 'vm_error',
                            });
                          });
                      } else {
                        updateErrorMsg({
                          error_msg: `recording not in progress`,
                          error_type: 'vm_error',
                        });
                      }
                    }}
                  />
                )}
              </>
            )}
          </>
        )}
        {!playing ? (
          <PlayVM
            className={mergeClasses(styles.play, playStyle)}
            onClick={e => {
              recording.play();
              recording.onended = e => setPlaying(false);
              setPlaying(true);
            }}
          />
        ) : (
          <PauseVM
            className={mergeClasses(styles.play, playStyle)}
            onClick={e => {
              recording.pause();
              setPlaying(false);
            }}
          />
        )}
        {deleteButtonLoading ? (
          <Spinner size={20} />
        ) : (
          showVMEditingControls && 
          <DeleteVM
            className={mergeClasses(styles.close, closeStyle)}
            onClick={e => {
              e.preventDefault();
              console.log('close clicked');
              setDeleteButtonLoading(true);
              if (recording_id) {
                deleteVMById({ recording_id });
                deleteVMRecording({ user_id, recording_id })
                  .then(res => res.json())
                  .then(data => {
                    console.log(data);
                    setDeleteButtonLoading(false);
                  })
                  .catch(err => {
                    setDeleteButtonLoading(false);
                    updateErrorMsg({
                      error_msg: `Error while deleting recording(id: ${recording_id})`,
                      error_type: 'vm_error',
                    });
                    console.error('Error while deleting recording', err);
                  });
              } else {
                deleteVMByName({ name: customName });
                setDeleteButtonLoading(false);
              }
            }}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    sip_number: state.userState.sip_number,
    user_id: state.userState.id,
    countdown: (state.integrationState.recording_length_limit || 10) * 1000,
    recording_types: state.integrationState.recording_types || [],
    recordingError: state.errorState.vm_error,
    phoneError: state.errorState.phone_recording_error,
    userTelephonyProvider: state.userState.telephonyProvider,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteVMById: ({ recording_id }) => {
      dispatch(deleteVMById({ recording_id }));
    },
    deleteVMByName: ({ name }) => {
      dispatch(deleteVMByName({ name }));
    },
    updateLabel: ({ name, label }) => {
      dispatch(updateVMLabel({ name, label }));
    },
    updateErrorMsg: ({ error_msg, error_type }) => {
      dispatch(updateErrorMsg({ error_msg, error_type }));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Recording));
