import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
import InputAdornment from '@material-ui/core/InputAdornment';
import { mergeClasses } from '../../utils';
import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import styles from './SendReply.scss';
import {
  updateSMSText,
  removeSMSText,
  pushSMS,
  setScrollToTheLastSMS,
} from '../../store/action/sms';
import { logger } from '../../services/server_side_logging';

const muiStyle = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: '100%',
    color: 'rgba(255, 255, 255, 0.6);',
  },

  cssLabel: {
    color: 'rgba(255, 255, 255, 0.6);',
    '&$cssFocused': {
      color: 'white',
    },
  },

  cssOutlinedInput: {
    color: 'rgba(255, 255, 255, 0.6);',
    fontSize: '16px',
    '&$cssFocused $notchedOutline': {
      borderColor: `white !important`,
      color: 'white',
    },
  },

  cssFocused: {
    color: 'white',
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(255, 255, 255, 0.6) !important',
  },
});

const SendReply = props => {
  let { classes: muiClasses } = props;
  const {
    task,
    smsState,
    character_limit,
    detailView,
    sendSMSbyTaskId,
    updateSMSText,
    removeSMSText,
    pushSMS,
    setScrollToTheLastSMS,
  } = props;

  const replymessage =
    (smsState[task.id] && smsState[task.id].text) ||
    (JSON.parse(localStorage.getItem(task.id)) || '').sms ||
    '';
  const [remainingLength, setRemainingLength] = useState(
    replymessage.length || 0
  );

  useEffect(() => {
    setRemainingLength(replymessage.length);
  }, [detailView]);

  const handleMsgCaching = ({ id, text, method }) => {
    setRemainingLength((text || '').length);
    let smsReply = JSON.parse(localStorage.getItem(id) || '{}');
    if (method === 'update') {
      updateSMSText(id, text);
      smsReply.sms = text;
    } else {
      delete smsReply.sms;
      removeSMSText(id);
    }
    localStorage.setItem(id, JSON.stringify(smsReply));
  };

  const disable_send_icon = task.sms.cant_send ? styles.disable : styles.enable;
  const getSMSInput = () => {
    const text =
      (smsState[task.id] && smsState[task.id].text) ||
      JSON.parse(localStorage.getItem(task.id) || '{}').sms;

    return text?.trim();
  };

  return (
    <div id="sms-reply" className={mergeClasses(styles.sms_reply_row)}>
      <TextField
        label={
          (smsState[task.id] && smsState[task.id].label) || 'Write a Reply'
        }
        id="outlined-multiline-flexible"
        className={mergeClasses(styles.sms_reply)}
        disabled={!!task.sms.cant_send}
        multiline
        key={`text_${task.id}`}
        value={replymessage}
        onChange={e => {
          e.preventDefault();
          e.stopPropagation();

          handleMsgCaching({
            id: task.id,
            text: e.target.value,
            method: 'update',
          });
        }}
        inputProps={{
          maxLength: character_limit || 1000,
        }}
        InputLabelProps={{
          classes: {
            root: muiClasses.cssLabel,
            focused: muiClasses.cssFocused,
            disabled: styles.input_disabled,
          },
        }}
        InputProps={{
          classes: {
            root: muiClasses.cssOutlinedInput,
            focused: muiClasses.cssFocused,
            notchedOutline: muiClasses.notchedOutline,
            disabled: styles.input_disabled,
          },
          inputMode: 'numeric',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                disabled={!!task.sms.cant_send || !getSMSInput()}
                onClick={async e => {
                  e.preventDefault();

                  const text = getSMSInput();
                  const task_id = task.id;
                  const name =
                    JSON.parse(localStorage.getItem('okta-token-storage')) &&
                    JSON.parse(localStorage.getItem('okta-token-storage'))
                      .idToken &&
                    JSON.parse(localStorage.getItem('okta-token-storage'))
                      .idToken.claims &&
                    JSON.parse(localStorage.getItem('okta-token-storage'))
                      .idToken.claims.name;

                  const resp = await sendSMSbyTaskId({
                    task_id,
                    text,
                    name,
                  }).catch(err => {
                    logger.error('send sms error', {
                      err,
                      taskId: task_id,
                      smsText: text,
                    });
                  });

                  if (resp?.status === 200) {
                    const respPayload = await resp.json();
                    console.log(respPayload);
                    if (respPayload.id) {
                      // show the SMS text immediately, update its status after
                      pushSMS(task_id, respPayload);
                      setScrollToTheLastSMS();
                    }
                  }

                  handleMsgCaching({
                    id: task.id,
                    method: 'delete',
                  });
                }}
                aria-label="send"
              >
                <SendIcon
                  classes={{
                    root: mergeClasses(styles.send_icon, disable_send_icon),
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
        variant="outlined"
      />
      {character_limit > 0 && task.id === detailView && (
        <div className={styles.limit}>
          {remainingLength}/{character_limit}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { task } = ownprops;
  const { templateState, taskState } = state;
  const { character_limit = 0 } = templateState[task.template];
  const { detailView } = taskState;
  return {
    smsState: state.smsState,
    character_limit,
    detailView,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateSMSText: (task_id, text) => {
      dispatch(updateSMSText(task_id, text));
    },
    removeSMSText: task_id => {
      dispatch(removeSMSText(task_id));
    },
    pushSMS: (task_id, sms) => {
      dispatch(pushSMS(task_id, sms));
    },
    setScrollToTheLastSMS: () => {
      dispatch(setScrollToTheLastSMS());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(muiStyle)(SendReply));
