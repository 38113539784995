import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Avatar } from '@material-ui/core';
import { NotificationImportant as NotificationImportantIcon } from '@material-ui/icons';

import { displayTimestamp, mergeClasses } from '../../utils';
import { selectMessageByID } from '../../store/action/broadcast';

import Copy from '../reusable_components/Copy';

const useStyles = makeStyles(theme => ({
  paper_root: {
    padding: theme.spacing(2),
    color: '#fff',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#6e6e6e',
      color: theme.palette.getContrastText('#6e6e6e'),
    },
  },
  colorDefault: {
    backgroundColor: '#ff9404',
    color: theme.palette.getContrastText('#ff9404'),
  },
  message_root: {
    display: 'flex',
    alignItems: 'center',
  },
  message: {
    width: 300,
    paddingLeft: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  message_container: {
    display: 'flex',
    flexDirection: 'column',
  },
  selected: {
    backgroundColor: '#184895',
    color: theme.palette.getContrastText('#184895'),
    '&:hover': {
      backgroundColor: '#113369',
      color: theme.palette.getContrastText('#113369'),
    },
  },
}));

const Message = ({ message, id, selectMessageByID, selectedId }) => {
  const classes = useStyles();

  return (
    <Paper
      square
      className={mergeClasses(
        classes.message_container,
        classes.paper_root,
        selectedId === message?.id && classes.selected
      )}
    >
      <div
        className={classes.message_root}
        key={message?.id}
        onClick={async e => {
          console.log('Clicked', message?.id, id);
          selectMessageByID(message?.id);
        }}
      >
        <Avatar classes={{ colorDefault: classes.colorDefault }}>
          {message?.sender === id ? (
            <NotificationImportantIcon />
          ) : (
            <>{message?.sender[0].toUpperCase()}</>
          )}
        </Avatar>

        <div className={classes.message}>
          <Typography variant={'subtitle1'}>
            {message?.sender === id ? 'You' : message?.sender}
          </Typography>
          <Typography variant={'caption'}>
            {displayTimestamp(message?.created_at)}
          </Typography>
          <Typography noWrap>{message?.text}</Typography>
        </div>
      </div>
      <div className={classes.message_root}>
        <Typography variant={'caption'}>{message?.id}</Typography>
        <Copy textToCopy={message?.id} tooltipBeforeCopy="Copy ID" />
      </div>
    </Paper>
  );
};

const mapStateToProps = state => {
  const { selectedId = '' } = state.broadcastState || {};
  const { id } = state.userState || {};
  return { id, selectedId };
};

const mapDispatchToProps = dispatch => {
  return {
    selectMessageByID: id => dispatch(selectMessageByID(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Message);
