import React from 'react';
import { connect } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import { Typography, Paper, Divider } from '@material-ui/core';
import { displayTimestamp, mergeClasses } from '../../utils';

const useStyles = makeStyles(theme => ({
  paper_root: {
    padding: theme.spacing(2),
    color: '#fff',
    backgroundColor: 'transparent',
    background: '#333',
    border: '2px solid #424242',
    minWidth: '566px',
    height: '100%',
    width: '100%',
  },
  divider: {
    backgroundColor: 'rgba(255, 255, 255, 0.12)',
    margin: theme.spacing(2),
    marginLeft: 0,
    marginRight: 0,
  },
}));

const Details = ({ message, id }) => {
  const classes = useStyles();
  return (
    <Paper square classes={{ root: classes.paper_root }}>
      <Typography variant={'subtitle1'}>
        FROM: {id === message?.sender ? 'You' : message?.sender}
      </Typography>
      <Typography variant={'caption'}>
        {message?.created_at && displayTimestamp(message?.created_at)}
      </Typography>
      <Divider classes={{ root: classes.divider }} />
      <Typography>{message?.text}</Typography>
    </Paper>
  );
};

const mapStateToProps = state => {
  const { selectedId = '', messages = [] } = state.broadcastState || {};
  const { id } = state.userState || {};
  const message = messages.find(m => m.id === selectedId);
  return { id, message };
};

const mapDispatchToProps = dispatch => {
  return {
    selectMessageByID: id => dispatch(selectMessageByID(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);
