import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './EmailInfo.scss';
import Sender from './Sender';
import Subject from './Subject';
import Recipients from './Recipients';
import { mergeClasses } from '../../../../utils/helpers';

export const EmailInfo = ({
  recipients,
  task_id,
  enable_participants_editing,
  enable_subject_editing,
}) => {
  return (
    <div className={styles.email_info_wrapper}>
      <Subject
        mergeClasses={mergeClasses}
        editable={enable_subject_editing}
        task_id={task_id}
      />
      {/* <Sender mergeClasses={mergeClasses} /> */}
      <Recipients
        recipients={recipients}
        editable={enable_participants_editing}
        task_id={task_id}
      />
    </div>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { emailState, templateState } = state;
  const { task_id, template } = ownprops;

  const { email_configuration = {} } = templateState[template] || {};

  const {
    enable_subject_editing,
    enable_participants_editing,
  } = email_configuration;
  const email_task = { ...(emailState[task_id] || {}) };
  let recipients = [];
  if (email_task.emailText) recipients = email_task.emailText.recipients;

  return { recipients, enable_subject_editing, enable_participants_editing };
};

const mapDispatchToProps = dispatch => ({
  deleteErrorMsg: ({ error_type }) => {
    dispatch(deleteErrorMsg({ error_type }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailInfo);
