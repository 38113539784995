import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './settings.scss';
import store from '../../store';
import VoiceRecordings from './VMRecordings/VoiceRecordings';
import Copy from '../reusable_components/Copy';

const { dispatch, getState } = store;

import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Version from './version';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '../reusable_components/Expansions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { setOpenHotdeskModalFlag } from '../../store/action/hotdesk';
import { isWebapp } from '../../utils/webapp/user_access_control';

const RRSwitch = withStyles(theme => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#000',
        border: '1px solid #424242',
        opacity: 1,
      },
    },
    '&$focusVisible $thumb': {
      color: '#212121',
      border: '6px solid #424242',
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: '#424242',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const Settings = props => {
  const {
    userState,
    environmentState,
    agentSkills,
    emailList,
    displayedSkills,
    helpers,
    flags,
    setOpenHotdeskModalFlag,
    hotdesking_eligible,
    canViewSkills,
  } = props;
  const { mergeClasses } = helpers;

  let offerNotificationSetting =
    userState.notifications.find(item => {
      return item.settings && item.id === 'offers';
    }) || false;

  const handleChange = event => {
    localStorage.setItem('receiveNotifications', event.target.checked);

    // Call user/status api to update notification settings ? Should probably store the settings in users table
    dispatch({
      type: 'UPDATE_ROUND_ROBIN_NOTIFICATION',
      id: 'offers',
      setting: event.target.checked,
    });
  };

  let autoLogoutTimerSetting = false || userState.isAutoLogoutEnabled;

  const handleAutoLogoutChange = event => {
    localStorage.setItem('isAutoLogoutEnabled', event.target.checked);
    dispatch({
      type: 'UPDATE_AUTO_LOGOUT_SETTING',
      isAutoLogoutEnabled: event.target.checked,
    });
  };

  const handleChangePhone = () => {
    setOpenHotdeskModalFlag({ openHotdeskModal: true });
  };

  const handleReset = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  return (
    <div className={styles.settings_wrapper}>
      <div className={styles.main}>
        <ExpansionPanel
          className={mergeClasses(styles.expand_panel)}
          defaultExpanded={true}
          classes={{ root: styles.expand_summary }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon className={styles.expand_icon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={mergeClasses(styles.expand_summary)}
          >
            Contact Details
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={mergeClasses(styles.expand_detail)}>
            <div
              className={mergeClasses(styles.expand_detail_child)}
              data-tooltip={
                userState.assigned_phone
                  ? userState.assigned_phone
                  : userState.phone_number
              }
            >
              <div>
                {userState.assigned_phone ? 'Extension (HD)' : 'Extension'}
              </div>
              <Grid item container justify="flex-end">
                {userState.phone_number}
                <Copy textToCopy={userState.phone_number || ''} />
              </Grid>
            </div>
            {hotdesking_eligible && (
              <Button
                variant="contained"
                onClick={handleChangePhone}
                classes={{ root: styles.changePhone }}
              >
                Change Phone
              </Button>
            )}
            <div className={mergeClasses(styles.expand_detail_child)}>
              <div>Telephony Provider</div>
              <Grid item justify="flex-end">
                <span>
                  {userState.telephonyProvider === 'genesys'
                    ? 'Genesys'
                    : 'Twilio'}
                </span>
              </Grid>
            </div>

            {flags.enableEmail &&
              emailList.map((email, index) => {
                return (
                  <div
                    className={mergeClasses(styles.expand_detail_child)}
                    key={email}
                  >
                    {index === 0 && <div>Email</div>}
                    <Grid item container justify="flex-end">
                      {email || ''}
                      <Copy textToCopy={email || ''}></Copy>
                    </Grid>
                  </div>
                );
              })}

            <div className={mergeClasses(styles.expand_detail_child)}>
              <Grid item container>
                Voice Number
              </Grid>
              <Grid item container justify="flex-end">
                {userState.voice_number}
                <Copy textToCopy={userState.voice_number || ''} />
              </Grid>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        {flags.enableUserSkills && canViewSkills && (
          <ExpansionPanel
            className={mergeClasses(styles.expand_panel)}
            defaultExpanded={true}
            classes={{ root: styles.expand_summary }}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon className={styles.expand_icon} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              className={mergeClasses(styles.expand_summary)}
            >
              Skills
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              className={mergeClasses(styles.expand_detail)}
            >
              <FormGroup row>
                {displayedSkills
                  .sort((a, b) =>
                    (a.name || '').toUpperCase() > (b.name || '').toUpperCase()
                      ? 1
                      : -1
                  )
                  .map(s => {
                    return (
                      <FormControlLabel
                        key={s.name}
                        control={
                          <Checkbox
                            checked={!!agentSkills[s.name]}
                            name={s.name}
                            color="default"
                            disabled
                          />
                        }
                        label={s.name}
                        className={mergeClasses(styles.skill)}
                      />
                    );
                  })}
              </FormGroup>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        )}
        <ExpansionPanel
          className={mergeClasses(styles.expand_panel)}
          defaultExpanded={true}
          classes={{ root: styles.expand_summary }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon className={styles.expand_icon} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={mergeClasses(styles.expand_summary)}
          >
            Notifications
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={mergeClasses(styles.expand_detail)}>
            <div className={mergeClasses(styles.expand_detail_child)}>
              <div>Desktop Notifications Off/On</div>
              <RRSwitch
                checked={offerNotificationSetting.settings}
                onChange={handleChange}
                value="NotifyOnRoundRobin"
              />
            </div>
            {userState &&
              userState.roles &&
              userState.roles.find(role => {
                return role.toLowerCase() === 'supervisor';
              }) && (
                <div className={mergeClasses(styles.expand_detail_child)}>
                  <div>Auto-logout timer Off/On</div>
                  <RRSwitch
                    checked={autoLogoutTimerSetting}
                    onChange={handleAutoLogoutChange}
                    value="EnableAutoLogout"
                  />
                </div>
              )}

            <div className={mergeClasses(styles.expand_detail_child)}>
              <div>Clear App cache and reload</div>
              <Button
                variant="contained"
                onClick={handleReset}
                classes={{ root: styles.changePhone }}
              >
                Reset
              </Button>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>

        {environmentState.appVersion ? <Version {...props} /> : null}
      </div>
      {flags.enableOneClickVm && (
        <VoiceRecordings mergeClasses={mergeClasses} />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { userState = {}, environmentState = {}, integrationState = {} } =
    state || {};
  const { skills = [], email_domains = [] } = integrationState || {};
  const { id = '', username = '', hotdesking_eligible } = userState;
  const agentSkills = userState.skills || [];
  const displayedSkills = [...(skills || [])];
  for (const skill of agentSkills) {
    if (!displayedSkills.find(s => s.name === skill)) {
      displayedSkills.push({
        name: skill,
      });
    }
  }
  const domain = (id || '').split('@')[1];
  const domains =
    email_domains.includes(domain) || !domain || domain === ''
      ? email_domains.sort()
      : [...email_domains, domain].sort();
  const emailList = domains.map(d => `${username}@${d}`);
  const agentSkillsMap = {};
  (agentSkills || []).forEach(s => {
    agentSkillsMap[s] = true;
  });

  const canViewSkills =
    userState.roles &&
    userState.roles.find(item => {
      return item === 'supervisor' || item === 'admin' || item === 'sales_lead';
    });

  return {
    userState,
    environmentState,
    displayedSkills,
    agentSkills: agentSkillsMap,
    emailList,
    hotdesking_eligible,
    canViewSkills,
  };
};

const mapDispatchToProps = dispatch => ({
  setOpenHotdeskModalFlag: ({ openHotdeskModal }) => {
    dispatch(setOpenHotdeskModalFlag({ openHotdeskModal }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Settings));
