import React, { forwardRef, useState } from 'react';
import { connect } from 'react-redux';
import styles from './wrapup.scss';
import { mergeClasses } from '../../utils';
import { updateWrapup } from '../../store/action/wrapup';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    backgroundColor: '#fafafa',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    color: '#fafafa',
  },
}));

const WrapupRadioButtonDeRef = props => {
  const classes = useStyles();
  const [showTooltip, setTooltip] = useState(false);
  let { task, buttonProps, wrapupState, updateWrapup } = props;
  let { wrapup } = wrapupState;
  let taskWrapup =
    task.wrap_up && task.wrap_up != {}
      ? task.wrap_up
      : wrapupState.wrapup[task.id] || {};
  let isSelected = taskWrapup[buttonProps.for] === buttonProps.key;

  return (
    <Tooltip
      placement={'top'}
      title={buttonProps?.tooltip}
      arrow
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
      onOpen={() => setTooltip(true)}
      onClose={() => setTooltip(false)}
      open={!!buttonProps?.tooltip && showTooltip}
    >
      <button
        key={buttonProps.key}
        id={`wrapup_radio_button_${buttonProps.key}`}
        value={buttonProps.key}
        disabled={task.wrap_up || isSelected}
        className={mergeClasses(
          styles.wrapup_button,
          isSelected ? styles.selected_code : '',
          false ? styles.disabled_button : ''
        )}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          taskWrapup[buttonProps.for] = e.target.value;
          const newwrapup = { ...wrapup };
          newwrapup[task.id] = taskWrapup;
          updateWrapup({
            ...wrapupState,
            wrapup: newwrapup,
          });
        }}
      >
        {buttonProps.value}
      </button>
    </Tooltip>
  );
};

const WrapupRadioButton = forwardRef((props, ref) => {
  return <WrapupRadioButtonDeRef {...props} ref={ref} />;
});

const mapStateToProps = state => {
  return { wrapupState: state.wrapupState };
};

const mapDispatchToProps = dispatch => ({
  updateWrapup: state => {
    dispatch(updateWrapup(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WrapupRadioButton);
