import React, { useState } from 'react';

import Task from './task';
import taskStyles from './tasks.scss';
import { Collapse } from '@material-ui/core';

const taskTypeMapping = {
  'GENERAL': 'General Tasks',
  'CALL': 'Call Tasks',
  'MISSEDCALL': 'Missed Call Tasks',
  'VOICEMAIL': 'Voice Mail Tasks',
  'COACHING': 'Coaching Tasks',
  'SMS': 'SMS Tasks',
  'EMAIL': 'Email Tasks',
  'SCANNER': 'Scanner Tasks',
  'FREE_FORM_CALL': 'Free Form Call Tasks'
}

const GroupedTasks = props => {
  const {tab, displayList, helpers, sort} = props;

  let tasksMapByType = new Map();
  displayList.map(task => {
    let existingTasks = tasksMapByType.get(task.type);
    existingTasks ? existingTasks.push(task) : tasksMapByType.set(task.type, [task]);
  })
  for (let [key, tasks] of tasksMapByType) {
    tasks.sort(helpers.sortByAge(sort));
  }

  return (
    <>
      {Array.from(tasksMapByType).map((value, index) => {
        return (<CollapsibleTasks
          tab={tab}
          taskType={value[0]}
          subTasks={value[1]}
          {...props}
        />)
      })}
    </>
  )
}

const CollapsibleTasks = props => {
  const [open, setOpen] = useState(false);

  const {tab, taskType, subTasks} = props;

  return (
    subTasks ? (
    <div className={taskStyles.task}>
      <div
        className={taskStyles.sub_tasks}
        onClick={() => setOpen(!open)}
      >
        <div className={open ? taskStyles.expand_tasks : taskStyles.collapse_tasks}/>
        <div>{taskTypeMapping[taskType]}</div>
      </div>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {subTasks.map((task, index) => {
          return (<Task
            key={`${
              task?.type?.toUpperCase() === 'TASK'
                ? task.id
                : task.task_id
              }_${index}`}
            task={task}
            index={index}
            {...props}
            tab={tab}
          />)
        })}
      </Collapse>
    </div>) : 'No Task'
  );
};

export default GroupedTasks;
