export const autoLogoutState = (state = {}, action) => {
  switch (action.type) {
    case 'SET_TRIGGER_AUTO_LOGOUT': {
      const newState = { ...state, triggerAutoLogout: true };
      return newState;
    }
    case 'UNSET_TRIGGER_AUTO_LOGOUT': {
      const newState = { ...state, triggerAutoLogout: false };
      return newState;
    }
    case 'SET_REFRESH_CLOCK': {
      const newState = { ...state, refreshClock: true };
      return newState;
    }
    case 'UNSET_REFRESH_CLOCK': {
      const newState = { ...state, refreshClock: false };
      return newState;
    }
  }

  return state;
};
