import React  from 'react';
import { TextField } from '@material-ui/core';

const CallFilter = ({
  setClientCallNumber,
  clientCallNumber,
}) => {
  return (
    <TextField
      value={clientCallNumber}
      onChange={e => {
        e.preventDefault();
        e.stopPropagation();
        setClientCallNumber(e.target.value);
      }}
      id="caller"
      label="Call participant"
      variant="standard"
    />
  );
};

export default CallFilter;
