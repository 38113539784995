export const errorState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_ERROR_MESSAGE': {
      const { error_msg, error_type, error_severity } = action;
      const newState = { ...state };
      newState.error_type = error_type;
      newState.error_msg = error_msg;
      newState.error_severity = error_severity;
      return newState;
    }

    case 'DELETE_ERROR_MESSAGE': {
      const { error_type } = action;
      const newState = { ...state };
      delete newState[error_type];
      return newState;
    }

    default:
      return state;
  }
};
