// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SendReply__sms_reply_row___13p3e{display:flex;flex-direction:column;justify-content:space-between;align-items:center;margin-left:2%;margin-right:2%}.SendReply__sms_reply___H3Bbr{margin:12px !important;width:100%}.SendReply__input_disabled___3ghWI{color:rgba(255,255,255,.6) !important}.SendReply__send_icon___3K1sP{vertical-align:middle}.SendReply__send_icon___3K1sP.SendReply__disable___2FhXv>path{color:gray !important}.SendReply__send_icon___3K1sP.SendReply__enable___2lNke>path{color:#fff !important}.SendReply__limit___1BFHB{align-self:flex-end;color:#979797;margin:1%}", ""]);
// Exports
exports.locals = {
	"sms_reply_row": "SendReply__sms_reply_row___13p3e",
	"sms_reply": "SendReply__sms_reply___H3Bbr",
	"input_disabled": "SendReply__input_disabled___3ghWI",
	"send_icon": "SendReply__send_icon___3K1sP",
	"disable": "SendReply__disable___2FhXv",
	"enable": "SendReply__enable___2lNke",
	"limit": "SendReply__limit___1BFHB"
};
module.exports = exports;
