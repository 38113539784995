import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './IVR.scss';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Menu from '@material-ui/core/Menu';
import { StyledMenuItem } from '../reusable_components/StyledMenuItem';

const useStyles = makeStyles({
  buttonProgress: {
    color: '#ffce00 !important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -10,
  },
});

const IVR = ({
  call,
  ivrs,
  services,
  taskState,
  participant,
  helpers,
  callState,
}) => {
  const classes = useStyles();
  const { mergeClasses } = helpers;

  let ivr_name, IVRNamePlaying;
  const [loading, setLoading] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedLabel, setSelectedLabel] = useState();
  const open = Boolean(anchorEl);

  const isIVRPlaying =
    call &&
    call.taskId &&
    callState.find(task => {
      return (
        task.id === call.taskId &&
        task.call_info &&
        task.call_info.participants &&
        task.call_info.participants.length > 0 &&
        task.call_info.participants.find(p => {
          p.status.toLowerCase() === 'ivr' &&
            (IVRNamePlaying = p.metadata.ivr_name || undefined);
        })
      );
    });

  useEffect(() => {
    isIVRPlaying ? setLoading(true) : setLoading(false);
  }, [isIVRPlaying]);

  const handleMenuClick = e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = e => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, option, label) => {
    e.preventDefault();
    setSelectedOption(option);
    setSelectedLabel(label);
    setAnchorEl(null);
  };

  const handlePlayIVRClick = async (e, call, participant) => {
    e.preventDefault();
    const call_id = call.id;
    const participant_id =
      (participant && participant.id) ||
      (call.participants &&
        call.participants.length > 0 &&
        call.participants.find(p => {
          return p.ivrs && p.ivrs.length > 0;
        }).id);
    const task_id = call.taskId;

    setLoading(true);
    const playIVR = await services.playIVRbyCallId(
      call_id,
      participant_id,
      ivr_name,
      task_id
    );

    console.log(playIVR);
  };

  const IVRName = ({ index, loading, name, label, call, participant }) => {
    return (
      <div key={index} id={name} className={styles.ivr_container}>
        <div
          className={mergeClasses(
            styles.ivr_wrapper,
            IVRNamePlaying ? styles.inactive : ''
          )}
        >
          <div className={styles.IVR_play_icon} />
          <button
            id={label}
            className={styles.IVR_name}
            onMouseOver={async e => {
              ivr_name = name;
            }}
          >
            {label}
          </button>
          {loading && IVRNamePlaying === name && (
            <CircularProgress size={20} className={classes.buttonProgress} />
          )}
        </div>
        {!IVRNamePlaying ? (
          <div
            className={styles.ivr_overlay}
            onClick={e => handlePlayIVRClick(e, call, participant)}
          >
            <div className={styles.ivr_start}>START</div>
          </div>
        ) : null}
      </div>
    );
  };

  const compo = ivrs.map((ivr, index) => {
    let dropdown, simpleButton;
    if (ivr.length > 1) {
      dropdown = (
        <div id={selectedOption} className={styles.IVR_dropdown_wrapper}>
          <IVRName
            index={index}
            loading={loading}
            name={selectedOption ? selectedOption : ivr[0].name}
            label={selectedLabel ? selectedLabel : ivr[0].label}
            call={call}
            participant={participant}
          />
          {!IVRNamePlaying && (
            <div className={styles.dropdown} onClick={handleMenuClick} />
          )}
          <Menu
            id="ivr-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleMenuClose}
          >
            {ivr.map(iv => {
              return (
                <StyledMenuItem
                  key={iv.label}
                  onClick={e => handleMenuItemClick(e, iv.name, iv.label)}
                >
                  {iv.label}
                </StyledMenuItem>
              );
            })}
          </Menu>
        </div>
      );
    } else {
      ivr.map((item, index) => {
        simpleButton = (
          <IVRName
            index={index}
            loading={loading}
            name={item.name}
            label={item.label}
            call={call}
            participant={participant}
          />
        );
      });
    }

    return (
      <>
        {dropdown}
        {simpleButton}
      </>
    );
  });

  return <div className={styles.IVR}>{compo}</div>;
};

const mapStateToProps = state => {
  return { callState: state.callState };
};

export default connect(mapStateToProps)(IVR);
