import React, { Component, createRef, forwardRef } from 'react';
import { findDOMNode } from 'react-dom';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Grid,
  IconButton,
  Icon,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import AtoZRow from './atozRow';
import styles from './directory.scss';
import { Spinner } from '../reusable_components/Spinner';
import store from '../../store';
const { getState } = store;

const cstyles = {
  table: {
    minWidth: 650,
    color: 'white',
  },
  container: {
    backgroundColor: 'inherit',
  },
  cell: {
    backgroundColor: 'inherit',
    color: 'white',
  },
  letter: {
    color: 'white',
  },
};

const fields = [
  'Username',
  'Name',
  'Integration Id',
  'Role',
  'Status',
  'Group',
];
const abcMap = {
  A: 1,
  B: 2,
  C: 3,
  D: 4,
  E: 5,
  F: 6,
  G: 7,
  H: 8,
  I: 9,
  J: 10,
  K: 11,
  L: 12,
  M: 13,
  N: 14,
  O: 15,
  P: 16,
  Q: 17,
  R: 18,
  S: 19,
  T: 20,
  U: 21,
  V: 22,
  W: 23,
  X: 24,
  Y: 25,
  Z: 26,
};

const GoToRef = forwardRef((props, ref) => {
  <div ref={ref} />;
});

class AtoZ extends Component {
  async componentDidMount() {
    const { services, filter = {} } = this.props;
    const { limit, offset } = this.state;
    const { group = '', groups = [] } = filter;
    try {
      const opts = {
        ...filter,
        limit,
        offset,
        group: [...new Set([group, ...groups])],
      };
      if (opts.available) {
        opts.online = true;
      }
      delete opts.groups;
      const userList = await services.filterUsers(opts);

      this.setState({
        userList,
        lastLetter: userList.length
          ? userList[userList.length - 1].id[0].toUpperCase()
          : '',
        more: userList.length === limit,
        offset: offset + limit,
        loading: false,
        interval: setInterval(this.updateUserList, 5000),
      });
    } catch (err) {
      console.error(err);
    }
  }
  componentDidUpdate(prevProps, prevState) {
    const { loading, more } = this.state;
    var el = (this.moreRef.current || {}).parentElement;

    if (
      this.moreRef.current &&
      !loading &&
      more &&
      el.offsetParent.offsetHeight + el.scrollTop >=
        this.moreRef.current.offsetTop
    ) {
      this.nextUsersPage();
    }
    if (prevProps.search != this.props.search) {
      this.search();
    }
    if (this.state.goToRef && prevState.loading && !this.state.loading) {
      this.goToRef(this.state.goToRef);
    }
    if (
      prevState.goToRef !== this.state.goToRef &&
      !this.state.goToRef &&
      prevState.goToRef
    ) {
      const ref = this[`${prevState.goToRef}ref`];
      if (ref.current) {
        this.baseRef.current.scroll(0, ref.current.offsetTop);
      }
    }
    if (prevState.goToRef !== this.state.goToRef && this.state.goToRef) {
      this.setState({ goToRef: undefined });
    }
  }
  componentWillUnmount() {
    const { interval } = this.state;
    clearInterval(interval);
  }
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      limit: 20,
      offset: 0,
      more: false,
      loading: false,
    };
    this.moreRef = createRef();
    this.render = this.render.bind(this);
    this.updateUserList = this.updateUserList.bind(this);
    this.nextUsersPage = this.nextUsersPage.bind(this);
    this.fetchOnScroll = this.fetchOnScroll.bind(this);
    this.search = this.search.bind(this);
    this.goTo = this.goTo.bind(this);
    this.baseRef = createRef();
    this.Aref = createRef();
    this.Bref = createRef();
    this.Cref = createRef();
    this.Dref = createRef();
    this.Eref = createRef();
    this.Fref = createRef();
    this.Gref = createRef();
    this.Href = createRef();
    this.Iref = createRef();
    this.Jref = createRef();
    this.Lref = createRef();
    this.Mref = createRef();
    this.Nref = createRef();
    this.Oref = createRef();
    this.Pref = createRef();
    this.Qref = createRef();
    this.Rref = createRef();
    this.Sref = createRef();
    this.Tref = createRef();
    this.Uref = createRef();
    this.Vref = createRef();
    this.Wref = createRef();
    this.Xref = createRef();
    this.Yref = createRef();
    this.Zref = createRef();
  }
  render = () => {
    const {
      taskState,
      helpers,
      directoryState,
      classes,
      services,
    } = this.props;
    const { callState } = getState();
    const { tasks } = taskState;
    const { callId } = directoryState;
    const state = getState();
    const { userState } = state;
    const myId = userState.id;
    const curCallTask = callState.filter(item => {
      return (
        item.call &&
        (item.type === 'CALL' || item.type === 'FREE_FORM_CALL') &&
        item.call.id === callId &&
        item.call_info &&
        item.call_info.participants
      );
    })[0];
    console.log('refs', this.refs);
    let agentsInCall = [],
      pendingParticipants = [];
    if (curCallTask) {
      agentsInCall = (curCallTask.call_info.participants || [])
        .filter(p => p.type === 'internal')
        .map(p => p.id);
      pendingParticipants = (curCallTask.call_info.participants || [])
        .filter(
          p =>
            (p.status || '').toLowerCase() === 'pending' &&
            (p.type || '').toLowerCase() === 'internal'
        )
        .map(p => p.metadata && p.metadata.label);
    } else {
      agentsInCall = [myId];
    }
    const { mergeClasses } = helpers;
    console.log('state: ', this.state);
    const { more, loading, goToRef } = this.state;
    const userList = (this.state.userList || []).filter(user =>
      agentsInCall.every(id => id != user.id)
    );
    let currLetter = null;
    const fetchedLetters = {};
    return (
      <div
        id="a2z-list"
        onScroll={event => console.log(event)}
        style={{ overflowY: 'auto' }}
        onScroll={this.fetchOnScroll}
        ref={this.baseRef}
      >
        <div className={styles.message}>
          {this.state.loading ? (
            <Spinner size={24} />
          ) : (
            <>{!userList.length && <>No users matching the filter</>}</>
          )}
        </div>
        <TableContainer
          classes={{ root: classes.container }}
          component={Paper}
          style={{ marginLeft: '40px' }}
        >
          <Table className={classes.table} stickyHeader>
            <TableHead>
              <TableRow classes={{ root: styles.head_row }}>
                {fields.map((item, index) => {
                  return (
                    <TableCell
                      key={index}
                      classes={{
                        root: mergeClasses(classes.cell, styles.head_cell),
                      }}
                    >
                      {item}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {userList
                .filter(user => {
                  return !agentsInCall.some(u => {
                    return u.id === user.id;
                  });
                })
                .map((user, index) => {
                  const initial = user.id[0].toUpperCase();
                  const ref =
                    initial !== currLetter ? this[`${initial}ref`] : undefined;
                  currLetter = initial !== currLetter ? initial : currLetter;
                  fetchedLetters[initial] = true;
                  return (
                    <AtoZRow
                      hover
                      key={index}
                      index={index}
                      tabIndex={-1}
                      classes={{ root: styles.table_row }}
                      user={{
                        ...user,
                        ...((user?.first_name || user?.last_name) && {
                          name: user?.first_name + ' ' + user?.last_name,
                        }),
                      }}
                      helpers={helpers}
                      services={services}
                      directoryState={directoryState}
                      taskState={taskState}
                      fields={fields}
                      forwardedRef={
                        !ref
                          ? undefined
                          : el =>
                              (this[`${initial}ref`] = {
                                current: findDOMNode(el),
                              })
                      }
                      pendingParticipants={pendingParticipants}
                      taskId={(curCallTask || {}).id}
                    ></AtoZRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {more && (
          <Button
            id="more"
            onClick={!loading && more ? this.nextUsersPage : () => undefined}
            disabled={!more || loading}
            ref={this.moreRef}
          >
            {loading ? 'LOADING...' : 'MORE'}
          </Button>
        )}
        <Grid
          container
          direction="column"
          style={{
            overflowY: 'auto',
            width: '20px',
            maxHeight: '100%',
            position: 'fixed',
            top: '50%',
            transform: 'translate(0, -50%)',
            left: 0,
          }}
        >
          <div style={{ overflowY: 'auto', height: '100%' }}>
            {Object.keys(abcMap).map(letter => {
              return (
                <IconButton
                  key={letter}
                  size="small"
                  classes={{
                    root: mergeClasses(styles['go-to-letter'], classes.letter),
                  }}
                  className="go-to-letter"
                  disabled={
                    ((abcMap[letter] || 100) < abcMap[currLetter] || !more) &&
                    !fetchedLetters[letter]
                  }
                  onClick={() => this.goTo(letter)}
                >
                  <Icon style={{ fontSize: '0.7rem' }}>
                    <p>{letter}</p>
                  </Icon>
                </IconButton>
              );
            })}
          </div>
        </Grid>
      </div>
    );
  };
  search = async () => {
    const { services, filter, search } = this.props;
    const { loading } = this.state;
    if (loading) {
      return;
    }
    this.setState({ loading: true });
    try {
      const { group = '', groups = [] } = filter;
      const params = {
        ...filter,
        limit,
        offset: 0,
        group: [...new Set([group, ...groups])],
      };
      if (search && search != '') {
        params.search = search;
      }
      if (params.available) {
        params.online = true;
      }
      delete params.groups;
      const userList = await services.filterUsers(params);
      this.setState({
        loading: false,
        offset: userList.length,
        more: userList.length !== limit,
        userList,
      });
    } catch (err) {
      this.setState({ loading: false });
      console.error(err);
    }
  };
  fetchOnScroll = e => {
    const el = e.currentTarget;
    const { loading, more } = this.state;
    if (
      this.moreRef.current &&
      !loading &&
      more &&
      el.offsetParent.offsetHeight + el.scrollTop >=
        this.moreRef.current.offsetTop
    ) {
      this.nextUsersPage();
    }
  };
  updateUserList = async () => {
    const { limit } = this.state;
    const { filter = {}, services, search } = this.props;
    const userList = [];
    this.setState({ loading: true });
    let newState = { loading: false };
    const { group = '', groups = [] } = filter;
    const params = {
      ...filter,
      limit,
      group: [...new Set([group, ...groups])],
    };
    if (search && search != '') {
      params.search = search;
    }
    if (params.available) {
      params.online = true;
    }
    delete params.groups;
    try {
      let more = false;
      for (let i = 0; i < this.state.offset; i = i + limit) {
        const list = await services.filterUsers({
          ...params,
          offset: i,
        });
        userList.push(...list);
        more = list.length === limit;
      }
      newState = { ...newState, userList, more };
    } catch (err) {
      console.log(err);
    } finally {
      this.setState(newState);
    }
  };
  nextUsersPage = async () => {
    const { limit, offset } = this.state;
    const { filter, services, search } = this.props;
    this.setState({ loading: true });
    let newState = { loading: false };
    const { group = '', groups = [] } = filter;

    const opts = {
      ...filter,
      limit,
      offset,
      search,
      group: [...new Set([group, ...groups])],
    };

    delete opts.groups;
    try {
      const list = await services.filterUsers(opts);
      const { userList } = this.state;
      newState = {
        ...newState,
        offset: offset + limit,
        more: list.length === limit,
        userList: [...userList, ...list],
      };
    } catch (err) {
      console.error(err);
    } finally {
      this.setState(newState);
    }
  };

  goTo = async letter => {
    const { loading, limit } = this.state;
    if (loading) {
      this.setState({ goToRef: letter });
      return;
    }
    let { more, userList, offset } = this.state;
    const { services, filter, search } = this.props;
    let lastLetter = userList.length
      ? userList[userList.length - 1].id[0].toUpperCase()
      : undefined;
    if (!lastLetter) {
      return;
    }
    const thisRef = this[`${letter}ref`];

    if (abcMap[lastLetter] >= abcMap[letter] && thisRef.current) {
      const el = findDOMNode(thisRef.current);
      console.log(letter, { goToRef: thisRef });
      this.setState({ goToRef: letter });
      return;
    } else if (abcMap[lastLetter] >= abcMap[letter] || !more) {
      return;
    }
    this.setState({ loading: true });
    const newState = {};
    for (; abcMap[lastLetter] < abcMap[letter] && more; ) {
      try {
        const { group = '', groups = [] } = filter;
        const opts = {
          ...filter,
          limit,
          offset,
          search,
          group: [...new Set([group, ...groups])],
        };
        delete opts.groups;

        const list = await services.filterUsers(opts);
        offset = offset + limit;
        userList.push(...list);
        more;
        lastLetter = list.length
          ? list[list.length - 1].id[0].toUpperCase()
          : lastLetter;
        more = list.length === limit;
        newState.offset = offset;
        newState.userList = userList;
        if (abcMap[lastLetter] >= abcMap[letter]) {
          newState.goToRef = letter;
        }
      } catch (err) {
        console.error(err);
      } finally {
        console.log(letter, newState);
        this.setState(newState);
      }
    }
  };
}

// export default connect(mapStateToProps, mapDispatchToProps)(AtoZ);

export default withStyles(cstyles)(AtoZ);
