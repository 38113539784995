import React, { useState, useEffect } from 'react';
import styles from './sidebar.scss';
import { connect } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { showTaskFromWeb } from '../../store/action/task';
import { environments } from '../../utils/mappings';
import FeedbackSnackbar from '../feedback/FeedbackSnackbar';
import { BoundaryError } from '../reusable_components/boundaryerror';
import Dialpad from './Dialpad';
import {
  updateBroadcastMessages,
  markAllMessagesAsRead,
} from '../../store/action/broadcast';
import { collapseSidebar } from '../../store/action/sidebar';

import * as services from '../../services';
import store from '../../store';
import Supervisor from './Supervisor';
import { isWebapp } from '../../utils/webapp/user_access_control';
const { dispatch, getState } = store;

const Sidebar = props => {
  const {
    taskState,
    helpers,
    expand,
    userState,
    smsState,
    taskIdFromWebpage,
    emailState,
    reportCallQuality,
    flags,
    showTaskFromWeb,
    env,
    updateBroadcastMessages,
    collapseSidebar,
    unreadMessages,
    markAllMessagesAsRead,
  } = props;
  const { mergeClasses } = helpers;
  const { tasks } = taskState;

  // const [taskfrom, setTaskfrom] = useState(undefined);
  const [loaded, _] = useState(true);
  const [feedback, setFeedback] = useState({ msg: '', severity: '' });

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };
  const loadTask = async fetchthisone => {
    let filteredTask = [];
    if (fetchthisone && fetchthisone !== (taskState.archived[0] || {}).id) {
      return services
        .fetchUpdatedTaskById(fetchthisone)
        .then(task => {
          if (task) {
            filteredTask.push(task);
            taskState.tab !== 'archived' &&
              taskState.filterTaskType !== 'archived' &&
              showTaskFromWeb({
                id: fetchthisone,
                filteredTask:
                  fetchthisone !== (taskState.archived[0] || []).id
                    ? filteredTask
                    : taskState.archived[0],
                tab: 'archived',
                filterTaskType: 'archived',
              });
          } else {
            console.error('Invalid taskid / Task not found');
          }
          return true;
        })
        .catch(err => console.error(err));

      // useHistory().push('/');
      // taskState.tab !== 'archived' &&
      //   taskState.filterTaskType !== 'archived' &&
      //   showTaskFromWeb({
      //     id: fetchthisone,
      //     filteredTask:
      //       fetchthisone !== (taskState.archived[0] || []).id
      //         ? filteredTask
      //         : taskState.archived[0],
      //     tab: 'archived',
      //     filterTaskType: 'archived',
      //   });
    }
  };
  console.log('Sidebar');
  useEffect(() => {
    if (!isWebapp()) {
      const ipcRenderer = window.require('electron').ipcRenderer;

      // there's a field on SF for link to open task on communicator client
      // opening the link triggers the ping function registered on ipcRenderer and passes task id to here
      ipcRenderer.on('ping', (e, msg) => {
        const url = new URL(msg);
        if (url.pathname.includes(environments[env])) {
          url.searchParams.forEach((value, key) => {
            if (key === 'taskId') {
              console.log(msg);
              // setTaskfrom(value);
              loadTask(value).then(ok => {
                if (ok) {
                  // useHistory().push('/');
                }
              });
              // useHistory().push('/');
            }
          });
        } else {
          updateFeedback(`Env not Allowed ${url.pathname})`, 'error');
        }
      });
      return () => {
        console.log('unmount Sidebar');
      };
    }
  }, [loaded]);

  let newCount = 0;
  tasks.forEach(task => {
    let clicked = helpers.getClicked();
    if (task && !clicked[task.id]) {
      newCount++;
    }
  });

  const [notificationCount, setnotificationCount] = useState(0);

  useEffect(() => {
    setnotificationCount(newCount);
    return () => {
      console.log('unmount Sidebar 2');
    };
  }, [newCount]);

  const [disableCallQuality, setdisableCallQuality] = useState();

  const callQualityStyle = disableCallQuality ? 'inactive' : null;
  useEffect(() => {
    setdisableCallQuality(
      reportCallQuality &&
        reportCallQuality.call_info &&
        reportCallQuality.call_info.quality_issue_reported
    );
    return () => {
      console.log('unmount Sidebar 3');
    };
  }, [reportCallQuality]);

  useEffect(() => {
    if (taskIdFromWebpage) {
      loadTask(taskIdFromWebpage).then(ok => {
        if (ok) {
          // useHistory().push('/');
        }
      });
      // useHistory().push('/');
    }
    return () => {
      console.log('unmount Sidebar 4');
    };
  }, [taskIdFromWebpage]);

  const isSupervisor =
    props.userState.roles &&
    props.userState.roles.find(item => {
      return item === 'supervisor';
    });
  const isAdmin =
    props.userState.roles &&
    props.userState.roles.find(item => {
      return item === 'admin';
    });
  const isSalesLead =
    props.userState.roles &&
    props.userState.roles.find(item => {
      return item === 'sales_lead';
    });
  return (
    <>
      <div
        id="sidebar"
        className={mergeClasses(
          styles.sidebar,
          expand ? styles.sidebar_hover : ''
        )}
      >
        <div id="sidebar_top" className={styles.sidebar_top}>
          <NavLink to="/" className={mergeClasses(styles.navlink)}>
            <div
              id="tasks_tab"
              className={mergeClasses(styles.tab)}
              onClick={e => {
                if (expand) collapseSidebar();
                dispatch({
                  type: 'UPDATE_TASKS',
                  taskState: {
                    ...getState().taskState,
                    tab: 'tasks',
                    detailView: null,
                    filterTaskType: 'tasks',
                  },
                });
              }}
            >
              <div id="tasks_icon_wrap" className={styles.icon_wrap}>
                {notificationCount !== 0 && (
                  <div
                    id="new_task_counts"
                    className={mergeClasses(styles.new_task_counts)}
                  >
                    {notificationCount}
                  </div>
                )}
                <div
                  id="tasks_icon"
                  className={mergeClasses(
                    styles.sidebar_icons,
                    styles.tasks_icon
                  )}
                ></div>
              </div>
              <div id="tasks_tab_text" className={styles.tab_text}>
                All Tasks
              </div>
            </div>
          </NavLink>
          {flags.enableDirectTasksTab && (
            <NavLink to="/direct" className={mergeClasses(styles.navlink)}>
              <div
                id="direct_tab"
                className={mergeClasses(styles.tab)}
                onClick={e => {
                  if (expand) collapseSidebar();

                  dispatch({
                    type: 'UPDATE_TASKS',
                    taskState: {
                      ...taskState,
                      tab: 'direct',
                      detailView: null,
                      filterTaskType: 'direct',
                    },
                  });
                }}
              >
                <div id="direct_icon_wrap" className={styles.icon_wrap}>
                  <div
                    id="direct_icon"
                    className={mergeClasses(
                      styles.sidebar_icons,
                      styles.direct_icon
                    )}
                  ></div>
                </div>
                <div id="direct_tab_text" className={styles.tab_text}>
                  Direct Tasks
                </div>
              </div>
            </NavLink>
          )}

          {flags.enableCallTasksTab && (
            <NavLink to="/call" className={mergeClasses(styles.navlink)}>
              <div
                id="call_tab"
                className={mergeClasses(styles.tab)}
                onClick={e => {
                  if (expand) collapseSidebar();

                  dispatch({
                    type: 'UPDATE_TASKS',
                    taskState: {
                      ...taskState,
                      tab: 'call',
                      detailView: null,
                      filterTaskType: 'CALL',
                    },
                  });
                }}
              >
                <div id="call_icon_wrap" className={styles.icon_wrap}>
                  <div
                    id="call_icon"
                    className={mergeClasses(
                      styles.sidebar_icons,
                      styles.call_icon
                    )}
                  ></div>
                </div>
                <div id="direct_tab_text" className={styles.tab_text}>
                  Call Tasks
                </div>
              </div>
            </NavLink>
          )}

          {props.integrationState.enable_sms && (
            <NavLink to="/" className={mergeClasses(styles.navlink)}>
              <div
                id="sms_tab"
                className={styles.tab}
                onClick={e => {
                  e.preventDefault();
                  const { tab } = taskState;
                  if (expand) collapseSidebar();

                  dispatch({
                    type: 'UPDATE_TASKS',
                    taskState: {
                      ...taskState,
                      tab: 'sms',
                      detailView: null,
                      filterTaskType: 'SMS',
                    },
                  });

                  let state = getState();
                  const { userState } = state;
                }}
              >
                <div id="sms_icon_wrap" className={styles.icon_wrap}>
                  {smsState.totalCount !== 0 && (
                    <div
                      id="new_sms_counts"
                      className={mergeClasses(styles.new_task_counts)}
                    >
                      {smsState.totalCount}
                    </div>
                  )}
                  <div
                    id="sms_icon"
                    className={mergeClasses(
                      styles.sidebar_icons,
                      styles.sms_icon
                    )}
                  ></div>
                </div>
                <div id="sms_tab_text" className={styles.tab_text}>
                  SMS Tasks
                </div>
              </div>
            </NavLink>
          )}
          {flags.enableEmail && (
            <NavLink to="/" className={mergeClasses(styles.navlink)}>
              <div
                id="email_tab"
                className={styles.tab}
                onClick={e => {
                  e.preventDefault();
                  if (expand) collapseSidebar();

                  dispatch({
                    type: 'UPDATE_TASKS',
                    taskState: {
                      ...taskState,
                      tab: 'email',
                      detailView: null,
                      filterTaskType: 'EMAIL',
                    },
                  });
                }}
              >
                <div id="email_icon_wrap" className={styles.icon_wrap}>
                  {emailState.totalCount !== 0 && (
                    <div
                      id="new_email_counts"
                      className={mergeClasses(styles.new_task_counts)}
                    >
                      {emailState.totalCount}
                    </div>
                  )}
                  <div
                    id="email_icon"
                    className={mergeClasses(
                      styles.sidebar_icons,
                      styles.email_icon
                    )}
                  ></div>
                </div>
                <div id="email_tab_text" className={styles.tab_text}>
                  EMAIL Tasks
                </div>
              </div>
            </NavLink>
          )}

          <NavLink to="/" className={mergeClasses(styles.navlink)}>
            <div
              id="archives_tab"
              className={mergeClasses(styles.tab, styles.archives_tab)}
              onClick={e => {
                if (expand) collapseSidebar();

                // Fetch archives with default params i.e. no query
                services.fetchArchives(userState.id).then(archives => {
                  dispatch({
                    type: 'UPDATE_TASKS',
                    taskState: {
                      ...taskState,
                      archived: archives,
                      detailView: null,
                      tab: 'archived',
                      filterTaskType: 'archived',
                    },
                  });
                });
              }}
            >
              <div id="archives_icon_wrap" className={styles.icon_wrap}>
                <div
                  id="archives_icon"
                  className={mergeClasses(
                    styles.sidebar_icons,
                    styles.archives_icon
                  )}
                ></div>
              </div>
              <div id="archive_tab_text" className={styles.tab_text}>
                Archived Tasks
              </div>
            </div>
          </NavLink>

          {flags.enableMessageCenter && (
            <NavLink to="/messages" className={mergeClasses(styles.navlink)}>
              <div
                id="messages_tab"
                className={mergeClasses(styles.tab)}
                onClick={async e => {
                  if (expand) collapseSidebar();

                  await services
                    .fetchBroadcastMessages({ user_id: userState.id, limit: 5 })
                    .then(res => res.json())
                    .then(data => {
                      console.log('Broadcast messages:', data);
                      data.length && updateBroadcastMessages(data);
                    })
                    .finally(() => markAllMessagesAsRead());
                }}
              >
                <div id="messages_icon_wrap" className={styles.icon_wrap}>
                  {unreadMessages?.length ? (
                    <div
                      id="broadcast_message_count"
                      className={mergeClasses(styles.new_task_counts)}
                    >
                      {unreadMessages?.length}
                    </div>
                  ) : null}
                  <div
                    id="messages_icon"
                    className={mergeClasses(
                      styles.sidebar_icons,
                      styles.messages_icon
                    )}
                  ></div>
                </div>
                <div id="messages_tab_text" className={styles.tab_text}>
                  Broadcast Messages
                </div>
              </div>
            </NavLink>
          )}

          {(isSupervisor || isAdmin || isSalesLead) && (
            <NavLink
              to="/supervisor"
              id="supervisor_tab"
              className={mergeClasses(styles.tab, styles.supervisor_tab)}
              onClick={e => {
                dispatch({
                  type: 'UPDATE_TASKS',
                  taskState: {
                    ...getState().taskState,
                    tab: 'tasks',
                    filterTaskType: 'tasks',
                  },
                });
                if (expand) collapseSidebar();
              }}
            >
              <Supervisor roles={props.userState.roles} />
            </NavLink>
          )}
        </div>
        <div id="sidebar_bottom" className={styles.sidebar_bottom}>
          {flags.enableDialPad && (
            <BoundaryError>
              <Dialpad updateFeedback={updateFeedback} />
            </BoundaryError>
          )}
          {reportCallQuality && (
            <span className={styles.call_quality_button}>
              <button
                disabled={disableCallQuality}
                className={styles.call_quality_wrapper}
                onClick={async e => {
                  e.preventDefault();
                  e.stopPropagation();
                  console.log('call quality clicked');
                  const callId = reportCallQuality.call.id;

                  let code_quality = await services.reportCallQualityByCallId(
                    callId
                  );

                  if (code_quality) {
                    console.log('successfully reported call quality');
                    const { tasks } = taskState;
                    setdisableCallQuality(true);
                    tasks.forEach(
                      task =>
                        task.id === reportCallQuality.id &&
                        task.call_info &&
                        (task.call_info.quality_issue_reported =
                          code_quality.quality_issue_reported)
                    );

                    dispatch({
                      type: 'UPDATE_TASKS',
                      taskState: {
                        ...taskState,
                      },
                    });
                  } else {
                    alert(
                      'Oops!! something went wrong while reporting call error'
                    );
                  }
                }}
              >
                <div
                  className={mergeClasses(
                    styles.call_quality_icon,
                    styles[callQualityStyle]
                  )}
                />
                <div
                  className={mergeClasses(
                    styles.call_quality,
                    styles[callQualityStyle]
                  )}
                >
                  {'CALL\nQLTY.'}
                </div>
              </button>
              <div
                id="setting_tab_text"
                className={mergeClasses(
                  styles.tab_text,
                  styles.setting_tab_text
                )}
              >
                Report Call Quality
              </div>
            </span>
          )}
          <NavLink
            to="/settings"
            id="setting_tab"
            className={mergeClasses(styles.setting_tab, styles.tab)}
            onClick={e => {
              if (expand) collapseSidebar();
            }}
          >
            <div
              id="setting_icon_wrap"
              className={mergeClasses(
                styles.icon_wrap,
                styles.setting_icon_wrap
              )}
            >
              <div
                id="setting_icon"
                className={mergeClasses(
                  styles.sidebar_icons,
                  styles.setting_icon
                )}
              ></div>
            </div>
            <div
              id="setting_tab_text"
              className={mergeClasses(styles.tab_text, styles.setting_tab_text)}
            >
              Settings
            </div>
          </NavLink>
        </div>
      </div>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'left'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
          autoHideDuration={2000}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  const {
    callState,
    taskState,
    environmentState,
    sidebarState,
    broadcastState,
  } = state;
  const reportCallQuality =
    taskState.detailView &&
    taskState.tab === 'tasks' &&
    callState.find(
      task =>
        task.id === taskState.detailView &&
        (task.type.toUpperCase() === 'CALL' ||
          task.type.toUpperCase() === 'FREE_FORM_CALL' ||
          task.type.toUpperCase() === 'COACHING') &&
        task.call &&
        task.call.id
    );

  const { env } = environmentState;
  const { expand = false } = sidebarState;
  const { unreadMessages = [] } = broadcastState;

  return {
    taskState: state.taskState,
    userState: state.userState,
    smsState: state.smsState,
    integrationState: state.integrationState,
    emailState: state.emailState,
    reportCallQuality,
    env,
    expand,
    unreadMessages,
  };
};

const mapDispatchToProps = dispatch => ({
  showTaskFromWeb: ({ id, filteredTask, tab, filterTaskType }) => {
    dispatch(showTaskFromWeb({ id, filteredTask, tab, filterTaskType }));
  },
  updateBroadcastMessages: data => {
    dispatch(updateBroadcastMessages(data));
  },
  collapseSidebar: () => {
    dispatch(collapseSidebar());
  },
  markAllMessagesAsRead: () => {
    dispatch(markAllMessagesAsRead());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Sidebar));
