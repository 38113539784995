// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".filter__filter_box___1MM2k{display:flex;flex-direction:column;border-top:2px solid #757575;padding:16px 8px}", ""]);
// Exports
exports.locals = {
	"filter_box": "filter__filter_box___1MM2k"
};
module.exports = exports;
