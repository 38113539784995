import { fetcher } from '.';
import * as helpers from '../utils/helpers';

import store from '../store';
const { dispatch, getState } = store;

import { getOktaHeader } from '../utils';
import { openReLoginDialog } from '../store/action/connection';
import { ResponseError } from '../utils/http/error';
import { updateErrorMsg } from '../store/action/error';

export const getUserCount = async filter => {
  const payload = {};
  if (filter) {
    Object.keys(filter).forEach(key => {
      // skills, ids, team, group are array in the backend
      const arrayFields = ['skills', 'ids', 'team', 'group'];
      if (arrayFields.includes(key)) {
        let arrParam = [];
        if (Array.isArray(filter[key])) {
          arrParam = filter[key].filter(param => (param ? true : false));
        } else {
          if (filter[key]) {
            arrParam.push(filter[key]);
          }
        }

        payload[key] = arrParam;
      } else {
        if (filter[key]) {
          payload[key] = filter[key];
        }
      }
    });
  }
  const url = `${getState().environmentState.url}/v1/users/count`;
  const userCount = await fetcher(url, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .catch(err => {
      if (err.code === 401) {
        return fetcher(url, {
          ...getOktaHeader(),
          method: 'POST',
          body: JSON.stringify(payload),
        }).catch(err => {
          if (err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(response => {
      // NOTE, this returns a promise which will be consumed by the await to give the actual value
      return response.json();
    });

  return userCount;
};

export const filterUsers = async filter => {
  const params = [];
  if (filter) {
    Object.keys(filter).forEach(key => {
      switch (key) {
        case 'skills':
        case 'ids':
        case 'team':
        case 'group':
          if (Array.isArray(filter[key])) {
            filter[key].forEach(v => {
              if (v != undefined || v != null) {
                params.push(`${key}=${v}`);
              }
            });
          } else {
            filter[key] !== null && params.push(`${key}=${filter[key]}`);
          }
          break;
        default:
          filter[key] !== null && params.push(`${key}=${filter[key]}`);
      }
    });
  }
  const url = params.length
    ? `${getState().environmentState.url}/v1/users?${params.join('&')}`
    : `${getState().environmentState.url}/v1/users`;
  const users = await fetcher(url, { ...getOktaHeader() })
    .catch(err => {
      if (err.code === 401) {
        return fetcher(url, { ...getOktaHeader() }).catch(err => {
          if (err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(response => {
      return response.json();
    })
    .catch(err => {
      console.error('Error fetching user list', err);
      return [];
    });

  if (!users || users.error) {
    console.error('Error fetching user list', users);
    return [];
  }

  return users;
};

export const updateUserSkills = async (user_id, skills = []) => {
  return fetcher(
    `${getState().environmentState.url}/v1/user/${user_id}/skills`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
      body: JSON.stringify({ skills }),
    }
  )
    .then(async res => {
      try {
        return await res.json();
      } catch (err) {
        return await res.text();
      }
    })
    .catch(err => {
      if (err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/user/${user_id}/skills`,
          {
            ...getOktaHeader(),
            method: 'PATCH',
            body: JSON.stringify({ skills }),
          }
        ).catch(err => {
          if (err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    });
};

export const getUserRecordings = async ({ user_id }) => {
  return fetcher(
    `${getState().environmentState.url}/v1/user/${user_id}/recordings`,
    {
      ...getOktaHeader(),
    }
  ).catch(err => {
    if (err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/user/${user_id}/recordings`,
        {
          ...getOktaHeader(),
        }
      ).catch(err => {
        if (err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

// Get detailed agent status from controller service
export const fetchAgentStateById = async agentId => {
  let data = await fetcher(
    `${getState().environmentState.url}/v1/user/${agentId}/state`,
    getOktaHeader()
  )
    .catch(err => {
      if (err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/user/${agentId}/state`,
          getOktaHeader()
        ).catch(err => {
          if (err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(async response => {
      const state = await response.json();
      if (!state || !state.tasks || !state.tasks.length) {
        return state;
      }
      const tasks = await helpers.completeTaskData((state || {}).tasks);
      state.tasks = tasks;
      return state;
    })
    .catch(err => {
      console.error('Error fetching Agent State', err);
      return null;
    });

  if (!data || data.error) {
    console.error('Error fetching Agent state', data);
    return null;
  }

  dispatch({
    type: 'UPDATE_AGENT',
    agentState: {
      data,
    },
  });

  return data;
};

export const getUserState = async userId => {
  let userState = await fetcher(
    `${getState().environmentState.url}/v1/user/${userId}/state`,
    getOktaHeader()
  )
    .catch(err => {
      if (err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/user/${userId}/state`,
          getOktaHeader()
        ).catch(err => {
          if (err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(async response => {
      const state = await response.json();
      return state;
    })
    .catch(err => {
      console.error('Error fetching user State', err);
      return null;
    });

  if (!userState || userState.error) {
    console.error('Error fetching user state', userState);
    return null;
  }

  return userState;
};

// Fetch complete users/agents list
export const fetchTeam = async args => {
  let limit = args?.limit?.value || 50;
  let offsetValue = args?.offset?.value || 0;
  let users = [];
  let userList = null;

  while (userList == null || userList.length === limit) {
    userList = await getTeamData({
      ...args,
      offset: { ...args.offset, value: offsetValue },
    });
    userList = userList || [];
    offsetValue = offsetValue + limit;
    users.push(...userList);
  }
  return users;
};

// Get users/agents list from controller service
const getTeamData = async args => {
  const params = [];
  if (args) {
    Object.keys(args).forEach(key => {
      switch (key) {
        case 'skills':
        case 'ids':
        case 'team':
        case 'group':
          if (Array.isArray(args[key]?.value)) {
            args[key]?.value?.forEach(v => {
              if (v.length && (v != undefined || v != null)) {
                params.push(`${key}=${v}`);
              }
            });
          } else {
            args[key]?.value !== null &&
              params.push(`${key}=${args[key]?.value}`);
          }
          break;
        default:
          args[key]?.value !== null &&
            params.push(`${key}=${args[key]?.value}`);
      }
    });
  }

  const url = params.length
    ? `${getState().environmentState.url}/v1/supervisor/users?${params.join(
        '&'
      )}`
    : `${getState().environmentState.url}/v1/supervisor/users`;

  let users = await fetcher(url, getOktaHeader())
    .catch(err => {
      if (err.code === 401) {
        return fetcher(url, getOktaHeader()).catch(err => {
          if (err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(response => {
      return response.json();
    })

    .catch(err => {
      console.error('Error fetching user list', err);
      dispatch(
        updateErrorMsg({
          error_msg: `Error fetching user list, ${err.message}`,
          error_type: 'Team fetch',
          error_severity: 'error',
        })
      );
      return null;
    });

  if (users?.length === 0) {
    dispatch(
      updateErrorMsg({
        error_msg: `No users found with matching criteria`,
        error_type: 'Team fetch',
        error_severity: 'info',
      })
    );
    dispatch(updateTeamWithUsers([]));
  }

  if (!users || users.error) {
    console.error('Error fetching user list', users);
    dispatch(
      updateErrorMsg({
        error_msg: `Error fetching user list`,
        error_type: 'Team fetch',
        error_severity: 'error',
      })
    );
    return null;
  }
  return users;
};

export const fetchAgents = async () => {
  let users = await fetcher(
    `${getState().environmentState.url}/v1/users`,
    getOktaHeader()
  )
    .catch(err => {
      if (err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/users`,
          getOktaHeader()
        ).catch(err => {
          if (err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(response => {
      return response.json();
    })

    .catch(err => {
      console.error('Error fetching user list', err);
      return null;
    });

  if (!users || users.error) {
    console.error('Error fetching user list', users);
    return null;
  }
  dispatch({
    type: 'UPDATE_TEAM',
    teamState: {
      users: users || [],
    },
  });

  return users;
};

export const userLogout = async (logout_reason, attempts = 0) => {
  let res = await fetcher(`${getState().environmentState.url}/v1/user`, {
    ...getOktaHeader(),
    method: 'PATCH',
    body: JSON.stringify({ online: false, logout_reason }),
  }).catch(err => {
    if (err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/user`, {
        ...getOktaHeader(),
        method: 'PATCH',
        body: JSON.stringify({ online: false }),
      }).catch(err => {
        if (err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });

  if (res.ok) {
    console.log('User successfully logout');
    //
  } else {
    console.error(`Failed to logout from server ${attempts} times`);

    if (attempts < 10) {
      setTimeout(() => {
        userLogout(logout_reason, attempts + 1);
      }, 1000);
    } else {
      console.error(`Failed to logout ${attempts} times. Retry stopped`);
    }
  }
};

export const updateUserStatus = async (
  status,
  attempts = 0,
  trigger = 'LOGOUT_REASON_INVALID'
) => {
  let state = getState();
  let statusList =
    (state.userState.statusList.length > 1 && state.userState.statusList) ||
    JSON.parse(sessionStorage.getItem(statusList));

  let body;
  statusList.forEach(item => {
    if (
      ((item || {}).label || '').toLowerCase() === (status || '').toLowerCase()
    ) {
      body = { ...item, status, trigger };
    }
  });

  /*
    {
  "available": false,
  "online": true,
  "status": "unavailable"
}
  */

  if (!body) {
    alert('Oops, something went wrong updating userstatus Please try again');
    return;
  }

  let res = await fetcher(`${getState().environmentState.url}/v1/user`, {
    ...getOktaHeader(),
    method: 'PATCH',
    body: JSON.stringify(body),
  }).catch(err => {
    if (err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/user`, {
        ...getOktaHeader(),
        method: 'PATCH',
        body: JSON.stringify(body),
      }).catch(err => {
        if (err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });

  if (res.ok) {
    console.log('User status update success ' + trigger);
    //
  } else {
    console.error(`Failed to update user state in server ${attempts} times`);

    if (attempts < 10) {
      setTimeout(() => {
        updateUserStatus(status, attempts + 1);
      }, 1000);
    } else {
      console.error(`Failed to update status ${attempts} times. Retry stopped`);
    }
  }

  return res;
};
