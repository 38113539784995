import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  TableCell,
  TableRow,
  Collapse,
  IconButton,
  Box,
} from '@material-ui/core';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
} from '@material-ui/icons';
import { mergeClasses, Feedback, formatTime, sortByKey } from '../../../utils';
import { getUserMetrics, getUserStatusMetrics } from '../../../services';
import styles from './stats.scss';
import Metric from './Metric';
import FeedbackSnackbar from '../../feedback/FeedbackSnackbar';
import { updateMetricsByUserID } from '../../../store/action/metrics';
import { getMetricsRange } from '../../../utils/helpers';

const displayedMetrics = [
  {
    id: 'avg_call_task_complete_seconds',
    label: 'AHT',
    tooltip: '(Talk time + hold time) / total calls for the current day',
  },
  {
    id: 'call_task_talk_seconds_total',
    label: 'Talk time',
    tooltip: 'Total time on calls for the current day',
  },
  {
    id: 'dir_in_offers_total',
    label: 'Direct Inbound Calls',
    tooltip: 'Total direct inbound calls for the day',
  },
  {
    id: 'oat',
    label: 'OAT%',
    tooltip:
      'Total offers presented / total offers accepted for the current day',
  },
  {
    id: 'offers_accepted_total',
    label: 'Offers Accepted',
    tooltip: 'Total offers accepted by the agent for the current day',
  },
  {
    id: 'offers_total',
    label: 'Offers',
    tooltip: 'Total offers presented for the current day',
  },
  {
    id: 'out_call_offers_total',
    label: 'Outbound Calls',
    tooltip:
      'Total outbound calls initiated by the agent for the current day',
  },
  {
    id: 'rr_avg_accepted_seconds',
    label: 'RR Avg Accept Time',
    tooltip:
      'Average time to accept per round robin offer for the current day',
  },
  {
    id: 'rr_in_call_offers_total',
    label: 'RR Call Offers',
    tooltip: 'Total round robin call offers presented for the current day',
  },
  {
    id: 'sms_received_total',
    label: 'SMS Received',
    tooltip:
      'Total SMS messages received either direct or round robin for the current day',
  },
  {
    id: 'sms_sent_total',
    label: 'SMS Sent',
    tooltip: 'Total SMS messages replied to for the current day',
  }
];

const User = props => {
  const {
    agent,
    selectUser,
    refreshInterval,
    date,
    updateMetricsByUserID,
  } = props;
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState({ msg: '', severity: '' });
  const [metrics, setMetrics] = useState([]);
  const [metricMap, setMetricMap] = useState({});
  const [statusMetrics, setStatusMetrics] = useState({});

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  const updateMetrics = () => {
    getUserMetrics({
      integration: agent.integration,
      group: agent.group,
      from: {
        seconds: Math.floor(Date.parse(new Date(date).toDateString()) / 1000),
      },
      team: agent.team,
      user_id: agent.id,
    })
      .then(res => res.json())
      .then(u => {
        const offers_presented = u.find(n => n.name === 'offers_total') || { name: 'offers_total', value: 0 };
        const offers_accepted = u.find(n => n.name === 'offers_accepted_total') || { name: 'offers_accepted_total', value: 0 };
        const oat =
          ((offers_accepted.value || 0) / (offers_presented.value || 1)) * 100;
        u.push({ name: 'oat', value: oat });
        const mMap = u.reduce((agg, curr) => {
          agg[curr.name] = curr;
          return agg;
        }, {})
        setMetricMap(mMap)
        setMetrics(sortByKey(u, 'name'));
      })
      .catch(err => {
        updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
      });
  };

  const updateStatusMetrics = () => {
    getUserStatusMetrics({
      range: getMetricsRange({
        seconds: Math.floor(Date.parse(new Date(date).toDateString()) / 1000),
      }),
      user_id: agent.id,
    })
      .then(res => res.json())
      .then(u => {
        setStatusMetrics(u);
      })
      .catch(err => {
        updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
      });
  };

  useEffect(() => {
    if (!metrics.length) updateMetrics();
    if (!statusMetrics.curr_status) updateStatusMetrics();

    const interval = setInterval(() => {
      updateMetrics();
      updateStatusMetrics();
    }, refreshInterval);

    return () => {
      clearInterval(interval);
    };
  }, [date]);

  useEffect(() => {
    updateMetrics();
    updateStatusMetrics();
  }, [date]);

  return (
    <>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'left'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
        />
      )}
      <TableRow>
        <TableCell
          className={mergeClasses(styles.cell)}
          aria-label={`${agent.id}`}
          onClick={async e => {
            selectUser(agent.id);
            updateMetricsByUserID({ id: agent.id, metrics, statusMetrics });
          }}
        >
          {agent.first_name && agent.last_name
            ? agent.first_name + ' ' + agent.last_name
            : agent.username}
        </TableCell>
        <TableCell
          className={mergeClasses(styles.cell)}
          aria-label={`${agent.id}_team`}
        >
          {agent.team}
        </TableCell>
        <TableCell
          className={mergeClasses(styles.cell)}
          aria-label={`${agent.id}_status`}
        >
          {((statusMetrics || {}).curr_status || {}).status}
        </TableCell>
        {(displayedMetrics).map((v, i) => {
          const m = metricMap[v.id] || {}
          return (
            <TableCell
              key={v.id}
              className={mergeClasses(styles.cell)}
              aria-label={`${agent.id}_${m.name}`}
            >
              {(m.name || '').includes('seconds')
                ? formatTime(m.value || 0)
                : Math.floor(m.value || 0)}
            </TableCell>
          );
        })}
        <TableCell aria-label={`${agent.id}_expand`}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Metric userMetrics={metrics} userStatusMetrics={statusMetrics} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { integrationState } = state;

  return {
    refreshInterval: (integrationState.refreshInterval || 15) * 1000,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMetricsByUserID: ({ id, metrics, statusMetrics }) => {
      dispatch(updateMetricsByUserID({ id, metrics, statusMetrics }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(User);
