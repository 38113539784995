import callStart from '../../assets/sounds/Call_start.mp3';
import hangUp from '../../assets/sounds/hangup.mp3';
import partipantJoin from '../../assets/sounds/participant_join.mp3';
// import phoneRing from '../../assets/sounds/phone_ringing.mp3';
import startVoice from '../../assets/sounds/Plugin_start_voice1.mp3';
import newTask from '../../assets/sounds/new_task_notification.mp3';
import inboundRing from '../../assets/sounds/Inbound_ringing.mp3';

export const getAudio = {
  callStartAudio: new Audio(callStart),
  hangUpAudio: new Audio(hangUp),
  partipantJoinAudio: new Audio(partipantJoin),
  // phoneRingAudio: new Audio(phoneRing),
  startVoiceAudio: new Audio(startVoice),
  newTaskAudio: new Audio(newTask),
  inboundRingAudio: new Audio(inboundRing),
};

export const playSound = audioFile => {
  if (!audioFile) {
    throw 'Error in Audio file to play sound';
  }

  try {
    audioFile.play();
  } catch (err) {
    console.warn('Error playing audio', err);
  }
};

export const stopSound = audioFile => {
  if (!audioFile) {
    throw 'Error in Audio file to pause';
  }
  try {
    audioFile.pause();
    audioFile.currentTime = 0;
  } catch (err) {
    console.warn('Error in audio', err);
  }
};
