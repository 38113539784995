export const timeMap = [
  {
    value: 0,
    label: '12a',
  },
  {
    value: 1,
    label: '1a',
  },
  {
    value: 2,
    label: '2a',
  },
  {
    value: 3,
    label: '3a',
  },
  {
    value: 4,
    label: '4a',
  },
  {
    value: 5,
    label: '5a',
  },
  {
    value: 6,
    label: '6a',
  },
  {
    value: 7,
    label: '7a',
  },
  {
    value: 8,
    label: '8a',
  },
  {
    value: 9,
    label: '9a',
  },
  {
    value: 10,
    label: '10a',
  },
  {
    value: 11,
    label: '11a',
  },
  {
    value: 12,
    label: '12p',
  },
  {
    value: 13,
    label: '1p',
  },
  {
    value: 14,
    label: '2p',
  },
  {
    value: 15,
    label: '3p',
  },
  {
    value: 16,
    label: '4p',
  },
  {
    value: 17,
    label: '5p',
  },
  {
    value: 18,
    label: '6p',
  },
  {
    value: 19,
    label: '7p',
  },
  {
    value: 20,
    label: '8p',
  },
  {
    value: 21,
    label: '9p',
  },
  {
    value: 22,
    label: '10p',
  },
  {
    value: 23,
    label: '11p',
  },
  {
    value: 23.98,
    label: '11:59p',
  },
];
