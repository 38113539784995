import React, { useState, useEffect } from 'react';
import styles from './AddressBook.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Spinner } from '../reusable_components/Spinner';
import { fetchUserFromDirectory } from '../../services';
import { mergeClasses } from '../../utils';

const useStyles = makeStyles(theme => ({
  root: {
    width: '300px',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
  inputBase: { color: '#fff' },
  option: {
    minHeight: 'auto',
    alignItems: 'flex-start',
    backgroundColor: '#212121',
    '&[data-focus="true"]': {
      backgroundColor: '#333',
    },
  },
  listbox: { backgroundColor: '#212121' },
  loading: {
    color: 'rgba(255,255,255,0.6)',
    backgroundColor: '#212121',
  },
  paper: { borderRadius: '0px' },
  input: { color: '#fff' },
  groupLabel: {
    color: '#000',
    backgroundColor: '#F8F8F8',
  },
  noOptions: {
    color: '#e7474e',
    backgroundColor: '#212121',
  },
  cssLabel: {
    color: 'rgba(255, 255, 255, 0.6);',
    '&$cssFocused': {
      color: 'white',
    },
  },
  cssFocused: { color: 'white' },
  deleteIcon: { fill: '#e7474e' },
  outlined: { border: '1px solid rgba(255, 255, 255, 0.23)', color: '#fff' },
  underline: {
    '&::before': {
      borderBottom: '1px solid rgba(255,255,255,0.23)',
    },
  },
  inputRoot: {
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #21b0e8',
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #184895',
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottom: '2px solid #e7474e',
    },
  },
}));

const AddressBook = ({ handleSetNumber }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initLoad, setInitLoad] = useState(false);

  const cleanOptions = array => {
    return array
      .flat()
      .map(item => {
        return item.phones.map(el => {
          return {
            name: item.name,
            number: el.number,
            type: el.type,
          };
        });
      })
      .flat();
  };

  const fetchUsers = async (searchParam, cursor) => {
    const data = await fetchUserFromDirectory(searchParam)
      .then(res => res.json())
      .catch(err => setError(true));
    return data;
  };

  useEffect(() => {
    let active = true;

    const delaySearch = setTimeout(async () => {
      if (searchParam.length) {
        setLoading(true);
        await fetchUserFromDirectory(searchParam)
          .then(res => res.json())
          .then(data => {
            if (active) {
              const displayOptions = cleanOptions(data.users);
              setOptions(displayOptions);
              if (!displayOptions.length) setInitLoad(false);
              setError(false);
            }
          })
          .catch(err => setError(true))
          .finally(() => setLoading(false));
      }
    }, 500);

    return () => {
      active = false;
      clearTimeout(delaySearch);
    };
  }, [searchParam]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <div className={classes.root}>
      <Autocomplete
        classes={{
          option: classes.option,
          listbox: classes.listbox,
          loading: classes.loading,
          noOptions: classes.noOptions,
          paper: classes.paper,
          groupLabel: classes.groupLabel,
          input: classes.input,
        }}
        id="phone_number"
        open={open}
        onOpen={() => {
          setInitLoad(true);
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
          setInitLoad(false);
        }}
        onChange={(e, value, reason) => {
          if (reason === 'select-option') handleSetNumber(value[0]?.number);
          if (reason === 'remove-option') handleSetNumber('');
        }}
        options={options}
        disableClearable={true}
        groupBy={option => option.name}
        renderOption={(option, state) => {
          return (
            <div className={styles.option}>
              <div className={styles.email_addresses}>
                <span className={styles[option.type]} />
                <span
                  className={mergeClasses(
                    styles.address,
                    option.type !== 'communicator' ? styles.external_type : ''
                  )}
                >
                  {option.number}
                </span>
              </div>
            </div>
          );
        }}
        getOptionSelected={(option, value) => {
          option.number === value.number && handleSetNumber(option.number);
          return option.number === value.number;
        }}
        getOptionLabel={option => {
          return option.name || `Couldn't find associated name`;
        }}
        noOptionsText="No Match"
        loading={initLoad}
        renderInput={params => (
          <TextField
            {...params}
            classes={{ root: classes.inputRoot }}
            className={classes.inputBase}
            label={'Search Directory'}
            placeholder="Type number"
            onChange={e => {
              setSearchParam(e.target.value);
            }}
            error={error}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              ...params.InputProps,
              classes: { underline: classes.underline },
              endAdornment: (
                <>
                  {loading ? <Spinner size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

export default AddressBook;
