import React from 'react';
import { TextField } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import WrapupButtonRow from './wrapup_button_row';
import WrapupRadioRow from './wrapup_radio_row';
import WrapupTextRow from './wrapup_text_row';
import WrapupSelectRow from './wrapup_select_row';
import { mergeClasses } from '../../utils';

import styles from './wrapup.scss';
import { connect } from 'react-redux';

const muiStyle = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: '100%',
    color: 'rgba(255, 255, 255, 0.6);',
  },

  cssLabel: {
    color: 'rgba(255, 255, 255, 0.6);',
    '&$cssFocused': {
      color: 'white',
    },
  },

  cssOutlinedInput: {
    color: 'rgba(255, 255, 255, 0.6);',
    fontSize: '16px',
    '&$cssFocused $notchedOutline': {
      borderColor: `white !important`,
      color: 'white',
    },
  },

  cssFocused: {
    color: 'white',
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(255, 255, 255, 0.6) !important',
  },
});

const componentMap = {
  button: WrapupButtonRow,
  radio: WrapupRadioRow,
  text: WrapupTextRow,
  select: WrapupSelectRow,
};

const Wrapup = props => {
  const { task, templateState, classes: muiClasses } = props;

  let template = templateState[task.template];
  if (template === 'N/A' || !template) {
    return <></>;
  }
  let wrapupFields = template.wrapup_fields;
  if (!wrapupFields) {
    return <></>;
  }

  const requiredFields = wrapupFields.filter(item => {
    return item.required;
  });

  let wrapupGroups = {};
  let wrapupCopy = wrapupFields.slice();
  wrapupCopy.sort((a, b) => {
    return a.component.localeCompare(b.component);
  });
  wrapupCopy.forEach(item => {
    wrapupGroups[item.component] = wrapupGroups[item.component] || [];
    wrapupGroups[item.component].push(item);
  });

  let fieldTypes = Object.keys(wrapupGroups);

  return (
    <div
      className={mergeClasses(styles.wrapup)}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {fieldTypes.map(key => {
        if (key.toLowerCase() in componentMap && wrapupGroups[key].length) {
          let Component = componentMap[key.toLowerCase()];

          return <Component key={key} task={task} list={wrapupGroups[key]} />;
        } else {
          console.warn('Unhandled component', key);
          return <></>;
        }
      })}
    </div>
  );
};

const mapStateToProps = state => {
  return { templateState: state.templateState };
};

export default connect(mapStateToProps)(withStyles(muiStyle)(Wrapup));
