export const metricsState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_METRICS_BY_USER_ID': {
      const { id, metrics, statusMetrics } = action;
      const newState = { ...state };
      const item = { ...(state[id] || {}) };
      item.metrics = metrics;
      item.statusMetrics = statusMetrics;
      newState[id] = item;
      return { ...newState };
    }

    default:
      return state;
  }
};
