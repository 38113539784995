import store from '../../store';
const { getState } = store;
import { logger } from '../../services/server_side_logging';
import { sendGenesysWSMessage } from '../../services';

const extractIdsFromStateByGenesysCallId = genesysCallId => {
  if (!genesysCallId) {
    return;
  }

  const state = getState();

  const callIds = state.callState
    .filter(c => c.call_info?.genesys_call_id === genesysCallId)
    .map(c => c.call?.id);
  const callId = callIds.length === 1 ? callIds[0] : '';
  const taskIds = state.taskState.tasks
    .filter(t => t.call?.id === callId)
    .map(t => t.id);
  const taskId = taskIds.length === 1 ? taskIds[0] : '';
  const userId = state.userState.id;

  return { callId, taskId, userId };
};

export const sendMessageToController = (() => {
  const defaultThrottleInterval = 1000; // milliseconds

  const throttler = {
    interval: defaultThrottleInterval,
    timeLastSend: new Date(),
    messages: {}, // callId -> message
    sendMessageTimer: null, // timer for sending messages
  };

  const startSendMessageHandlerTimer = () => {
    clearTimeout(throttler.sendMessageTimer);

    throttler.sendMessageTimer = setTimeout(() => {
      throttler.timeLastSend = new Date();

      // send messages
      for (const [callId, msg] of Object.entries(throttler.messages)) {
        sendGenesysWSMessage(msg);
      }

      throttler.messages = {};
    }, throttler.interval);
  };

  // main func
  return message => {
    const state = getState();
    throttler.interval =
      state.loggingState.sendGenesysWSMessageInterval !== undefined
        ? state.loggingState.sendGenesysWSMessageInterval
        : defaultThrottleInterval;

    if (throttler.interval === -1) {
      return; // ignore all events
    }

    const genesysCallId = message.eventBody?.id;
    if (!genesysCallId) {
      return; // skip if no Genesys callId
    }

    const { callId, taskId, userId } = extractIdsFromStateByGenesysCallId(
      genesysCallId
    );
    message.task_id = taskId;
    message.user_id = userId;
    message.received_at = new Date();

    // no throttling, send the message right away
    if (throttler.interval === 0) {
      sendGenesysWSMessage(message);
      return;
    }

    // with throttling, start the timer when the stack is empty
    if (Object.keys(throttler.messages).length === 0) {
      startSendMessageHandlerTimer();
    }

    // push message in the stack
    throttler.messages[genesysCallId] = message;
  };
})();

export const logGenesysWSMessage = (loglevel, desc, parsedGeneysysWSEvent) => {
  const state = getState();
  if (!state.loggingState.logGenesysWSMessage) {
    return;
  }

  const { callId, taskId, userId } = extractIdsFromStateByGenesysCallId(
    parsedGeneysysWSEvent.eventBody?.id
  );
  logger[loglevel](desc, {
    userId,
    callId,
    taskId,
    event: parsedGeneysysWSEvent,
  });
};
