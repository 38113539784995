import React from 'react';
import styles from './call.scss';

import { connect } from 'react-redux';
import { mergeClasses } from '../../utils';
import store from '../../store';

const YouLeftMessage = props => {
  return (
    <div className={mergeClasses(styles.you_left)}>
      <div className={mergeClasses(styles.you_left_title)}>
        YOU LEFT THE CALL
      </div>
      <div className={mergeClasses(styles.you_left_message)}>
        All other lines are now speaking with one another.
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return { taskState: state.taskState };
};

const mapDispatchToProps = dispatch => ({
  updateConnectionFunc: state => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(YouLeftMessage);
