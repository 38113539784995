export const broadcastMessage = ({ text, sender, created_at, id }) => {
  return {
    type: 'BROADCAST_MESSAGE',
    text,
    sender,
    created_at,
    id,
  };
};

export const updateBroadcastMessages = data => {
  return {
    type: 'UPDATE_BROADCAST_MESSAGES',
    data,
  };
};

export const selectMessageByID = id => {
  return {
    type: 'SELECT_MESSAGE_BY_ID',
    id,
  };
};

export const markMessageReadById = id => {
  return {
    type: 'BROADCAST_MESSAGE_READ_BY_ID',
    id,
  };
};

export const markAllMessagesAsRead = () => {
  return {
    type: 'MARK_ALL_MESSAGES_AS_READ',
  };
};
