import { Grid, Icon, Typography } from '@material-ui/core';
import { Group } from '@material-ui/icons';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getUserCount } from '../../services';
import { mergeClasses } from '../../utils';
import styles from './header.scss';

export const GroupDetail = props => {
  const {
    integration,
    capacity,
    priority,
    user_filters = [],
    label,
    type,
    ext_queue_id,
  } = props;
  const [count, setCount] = useState(0);
  const getCounters = async () => {
    let groupUserCount = 0;
    try {
      if (type === 'external' && ext_queue_id) {
        groupUserCount = await getUserCount({
          type,
          queue_id: ext_queue_id,
        });
      } else {
        for (let i = 0; i < user_filters.length; i++) {
          const count = await getUserCount({
            ...user_filters[i],
            integration,
            free_capacity: capacity,
            max_priority: priority,
          });
          groupUserCount += count;
        }
      }
    } catch (err) {
      console.error(err.message);
    }

    setCount(groupUserCount);
  };
  useEffect(() => {
    getCounters();
    const interval = setInterval(getCounters, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [integration, capacity, priority, user_filters]);

  return (
    <Grid
      item
      style={{ width: 'fit-content' }}
      classes={{ root: styles.queue_item }}
      container
      direction="row"
      spacing={2}
    >
      <Grid item>
        <Typography>
          {label}: {count}
        </Typography>
      </Grid>
      <Grid item>
        <Icon className={mergeClasses(styles['user-queue'])}>
          <Group
            className={mergeClasses(
              count > 0 ? styles.available : styles['not-available']
            )}
          />
        </Icon>
      </Grid>
    </Grid>
  );
};

const AgentQueue = props => {
  const { groups = [] } = props;

  return (
    <Grid
      container
      direction="column"
      item
      style={{ width: 'fit-content' }}
      className={groups.length ? mergeClasses(styles['queue-block']) : ''}
    >
      {groups.map((g, index) => {
        return <GroupDetail key={index} {...g} />;
      })}
    </Grid>
  );
};

const mapStateToProps = state => {
  const { integrationState } = state;
  const { queues = {} } = integrationState;
  const { groups = [] } = queues || {};
  const queueGroups = (groups || []).slice(0, 2);
  return {
    groups: queueGroups,
  };
};

export default connect(mapStateToProps)(AgentQueue);
