// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../../assets/create-white-18dp.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../../../assets/check-white-18dp.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".EmailInfo__email_info_wrapper___2jJF2{display:flex;flex-direction:column;font-size:16px;border-top:1px solid #424242;padding-left:12px;padding-bottom:12px}.EmailInfo__edit___1nHtw{width:18px;height:18px;cursor:pointer;object-fit:contain;background-repeat:no-repeat;margin:2px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.EmailInfo__edit___1nHtw.EmailInfo__check___PvLbi{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}", ""]);
// Exports
exports.locals = {
	"email_info_wrapper": "EmailInfo__email_info_wrapper___2jJF2",
	"edit": "EmailInfo__edit___1nHtw",
	"check": "EmailInfo__check___PvLbi"
};
module.exports = exports;
