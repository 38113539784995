'use strict';

import store from '../store';
import { openReLoginDialog } from '../store/action/connection';
import { getOktaHeader, getCurrentCallId, updateAuth } from '../utils/index.js';
import { archiveTask, removeTask, updateCallError } from '../store/action/task';
import { updateCallInfo } from '../store/action/call';
import { updateErrorMsg } from '../store/action/error';
import { initSMSList, decreaseSMSCount } from '../store/action/sms';
import { initEmailList, decreaseEmailCount } from '../store/action/email';
import * as helpers from '../utils/helpers';
import { logger } from './server_side_logging.js';
const { dispatch, getState } = store;

export const fetchUpdatedTaskById = async taskId => {
  let res = await fetcher(
    `${getState().environmentState.url}/v1/task/${taskId}`,
    {
      ...getOktaHeader(),
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(`${getState().environmentState.url}/v1/task/${taskId}`, {
          ...getOktaHeader(),
        }).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => data.json())
    .catch(err => {
      console.error('Error Fetching Task', taskId, err);
      return null;
    });

  if (!res || res.error) {
    console.error('Error Fetching Updated Task', res);
    return null;
  }

  return res;
};

export const updateOfferStatus = (id, status) => {
  const url = `${getState().environmentState.url}/v1/offer/${id}`;
  const options = {
    ...getOktaHeader('PATCH'),
    body: JSON.stringify({ status }),
  };
  const apiCall = (url, options, retries) => {
    return fetcher(url, options).catch(err => {
      if (
        err.constructor === ResponseError &&
        [401, 504].includes(err.code) &&
        retries > 0
      ) {
        setTimeout(() => apiCall(url, options, retries - 1), 5000);
      } else {
        dispatch(
          updateErrorMsg({
            error_msg: `Failed to update Offer status as presented. Offer id:${id}, 
              \nError: ${err.message}. Removing from the list`,
            error_type: 'Offer Presented',
            error_severity: 'error',
          })
        );
        dispatch(removeTask(id));
        return Promise.reject(err);
      }
    });
  };
  return apiCall(url, options, 2);
};

export const joinCallByTask = async task => {
  const { userState, taskState } = getState();
  let oktaStorage = localStorage.getItem('okta-token-storage')
    ? JSON.parse(localStorage.getItem('okta-token-storage'))
    : null;
  let claimName =
    oktaStorage && oktaStorage.idToken && oktaStorage.idToken.claims
      ? oktaStorage.idToken.claims.name || ''
      : '';
  const body = {
    sip: userState.sip_number,
    metadata: {
      name: claimName,
      label: userState.username,
    },
  };
  let res;
  if (task.call && task.call.id) {
    res = await fetcher(
      `${getState().environmentState.url}/v1/call/${task.call.id}/join`,
      {
        ...getOktaHeader(),
        method: 'POST',
        body: JSON.stringify({ ...body }),
      }
    )
      .catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          return fetcher(
            `${getState().environmentState.url}/v1/call/${task.call.id}/join`,
            {
              ...getOktaHeader(),
              method: 'POST',
              body: JSON.stringify({ ...body }),
            }
          ).catch(err => {
            if (err.constructor === ResponseError && err.code === 401) {
              dispatch(openReLoginDialog(true));
            }
            return Promise.reject(err);
          });
        }
        return Promise.reject(err);
      })
      .catch(e => {
        console.error('Error joining call', e);
        return null;
      });

    if (!res || res.error) {
      console.error('Error joining call', res);
      return null;
    }
  } else if (task.call) {
    res = await fetcher(`${getState().environmentState.url}/v1/call`, {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify({
        ...body,
        task_id: task.task_id || task.id,
      }),
    })
      .catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          return fetcher(`${getState().environmentState.url}/v1/call`, {
            ...getOktaHeader(),
            method: 'POST',
            body: JSON.stringify({
              ...body,
              task_id: task.task_id || task.id,
            }),
          }).catch(err => {
            if (err.constructor === ResponseError && err.code === 401) {
              dispatch(openReLoginDialog(true));
            }
            return Promise.reject(err);
          });
        }
        return Promise.reject(err);
      })
      .catch(e => {
        console.error('Error joining call', e);
        const call_info = JSON.parse(e?.message);
        const { id, status } = call_info;
        if (status === 'suppressed') {
          dispatch(
            updateCallInfo({
              call_info,
              task_id: task.task_id || task.id,
              call_id: id,
            })
          );

          const errMsg = helpers.errorTypeMapping(status);
          let errors = helpers.getErrorClickedInLocalStorage();

          const newError = {
            [id]: {
              errorType: errMsg,
              taskId: task.task_id || task.id,
              callId: id,
              displayError: errors[id] ? false : true,
            },
          };
          const { callError } = taskState;
          callError.length > 0 &&
          callError.find(currError => {
            return currError[id] && currError[id].callId === id;
          })
            ? callError.forEach(currError => {
                currError[id] &&
                  currError[id].callId === newError[id].callId &&
                  currError[id].taskId === newError[id].taskId &&
                  (currError[id].created_at = newError[id].created_at);
              })
            : callError.push(newError);

          dispatch(updateCallError({ callError }));
        }
        return null;
      });

    if (!res || res.error) {
      console.error('Error joining call', res);
      return null;
    }
  } else {
    console.error('Cannot join the call. Task has no call information', task);
    return null;
  }

  return res;
};

/**
 * Call a participant
 * @param {object} participant        participant to add
 * @param {string} participant.number number number of participant
 */
export const addToCall = async (callId, participant) => {
  let res = await fetcher(
    `${getState().environmentState.url}/v1/call/${callId}/invite`,
    {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify(participant),
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/call/${callId}/invite`,
          {
            ...getOktaHeader(),
            method: 'POST',
            body: JSON.stringify(participant),
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .catch(e => {
      console.error('Error adding participant to call', e);
      return null;
    });

  if (!res || res.error) {
    console.error('Error joining call', res);
    return null;
  }

  return res;
};

/**
 *
 * @param {string} callId
 * @param {object} distribution Distribution info for doing warm transfer
 * @param {string} [distribution.integration] integration that will owns the task
 * @param {string} distribution.user_filters distribution user filter,
 * @param {string} distribution.distribution_type distribution type (ROUND_ROUND)
 */
export const warmTransfer = async (callId, participant) => {
  let res = await fetcher(
    `${getState().environmentState.url}/v1/call/${callId}/transfer`,
    {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify(participant),
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/call/${callId}/transfer`,
          {
            ...getOktaHeader(),
            method: 'POST',
            body: JSON.stringify(participant),
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .catch(e => {
      console.error('Error doing warn transfer', e);
    });
  if (!res || res.error) {
    console.error('Error adding user to call', res);
    return null;
  }

  return res;
};

/**
 * Redirect a call to an iunternal agent
 * @param {string} callId
 * @param {string} taskId parent task id of call task
 * @param {string} userId user id to redirect the call
 */
export const redirectCallToUser = async (callId, task_id, user_id) => {
  let body = { task_id, user_id };
  let res = await fetcher(
    `${getState().environmentState.url}/v1/call/${callId}/cold_transfer`,
    {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify(body),
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/call/${callId}/cold_transfer`,
          {
            ...getOktaHeader(),
            method: 'POST',
            body: JSON.stringify(body),
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .catch(e => {
      console.error('Error redirecting to agent', e);
    });
  if (!res || res.error) {
    console.error('Error transfering call to user', res);
    return null;
  }

  return res;
};
export const acceptOffer = async ({ task, status, index, res, state }) => {
  if (res.ok) {
    console.log('Offer Accept Confirm');

    let updatedTask = await fetchUpdatedTaskById(task.task_id);

    console.log('UPDATED TASK', updatedTask);

    (updatedTask.type &&
      updatedTask.type.toUpperCase() === 'SMS' &&
      updatedTask.sms_list &&
      dispatch(initSMSList(updatedTask.id, updatedTask.sms_list))) ||
      (updatedTask.type.toUpperCase() === 'EMAIL' &&
        updatedTask.email_list &&
        dispatch(initEmailList(updatedTask.id, updatedTask.email_list)));

    let { taskState } = state;
    let { tasks } = taskState;

    tasks = tasks.map(item => {
      if (item.id === updatedTask.id) {
        return updatedTask;
      }

      return item;
    });

    // dispatch(updateTasksWithDetailView({ tasks, detailView: task.task_id }));

    let currentCallList = getCurrentCallId(state.callState, state.userState);

    const [currentCallId] = currentCallList;
    if (currentCallList.length) {
      if (task.direction.toLowerCase() === 'outbound') {
        let leaveResponse = await leaveCallAndPutCallOnHold(currentCallId);

        if (leaveResponse) {
          let postResponse = await postOutboundCallByTaskId(task.task_id);
          return postResponse;
        }
        return leaveResponse;
      } else {
        let res = await swapCalls(currentCallId, updatedTask.call.id);
        return res;
      }
    } else {
      let call =
        updatedTask.type === 'CALL' && (await joinCallByTask(updatedTask));
      return call;
    }
  } else {
    let tasks = state.taskState.tasks.slice();
    alert('Offer expired. Removing from your list');
    dispatch(removeTask(task.id));
    console.error('Failed to accept offer', res);
  }
};

export const rejectOffer = async ({ task, status, index, res, state }) => {
  if (res.ok) {
    // remove and archive
    console.log('Reject Success');

    dispatch(removeTask(task.id));
  } else {
    console.log('Failed to reject offer', res);
  }
};

export const completeTask = async ({ task, status, index, res, state }) => {
  console.log('Complete Task', res);
  if (res.ok) {
    // state.taskState.archived.unshift(state.taskState.tasks[index]);
    // state.taskState.tasks.splice(index, 1);

    // dispatch({
    //   type: 'UPDATE_TASKS',
    //   taskState: {
    //     ...state.taskState,
    //   },
    // });
    dispatch(archiveTask(task));
    console.log('Complete Response', res);
  } else if (res.status === 404) {
    alert('Something when wrong. We could not locate the task', task.id);
    // state.taskState.archived.unshift(state.taskState.tasks[index]);
    // state.taskState.tasks.splice(index, 1);

    // dispatch({
    //   type: 'UPDATE_TASKS',
    //   taskState: {
    //     ...state.taskState,
    //   },
    // });
    dispatch(archiveTask(task));
    console.log('Failed to Complete Task', res);
  }
};

export const sendToVoicemail = async ({ task, status, index, res, state }) => {
  if (res.ok) {
    // have panel to get voicemail change status to voice mail'
    console.log('Voice Mail Success');
    // state.taskState.archived.unshift(state.taskState.tasks[index]);

    // state.taskState.tasks.splice(index, 1);
    // dispatch({
    //   type: 'UPDATE_TASKS',
    //   taskState: {
    //     ...state.taskState,
    //   },
    // });
    dispatch(archiveTask(task));
  } else {
    console.log('Failed to send to voicemail');
  }
};
export const taskActionMap = {
  ACCEPTED: acceptOffer,
  REJECTED: rejectOffer,
  SENT_TO_VOICEMAIL: sendToVoicemail,
  COMPLETED: completeTask,
};

export const updateOffer = async args => {
  let { task, status, index } = args;
  let state = args.state || getState();

  let type = task.class_type === 'OFFER' ? 'offer' : 'task';

  let res = await fetcher(
    `${getState().environmentState.url}/v1/${type}/${task.id}`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
      body: JSON.stringify({
        status,
      }),
    }
  )
    .catch(err => {
      dispatch(
        updateErrorMsg({
          error_msg: `Failed to update ${type.toUpperCase()} id:${task.id}, ${
            err.message
          }. \n
          Removing from the list `,
          error_type: 'Offer Updated',
          error_severity: 'error',
        })
      );
      dispatch(removeTask(task.id));
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/${type}/${task.id}`,
          {
            ...getOktaHeader(),
            method: 'PATCH',
            body: JSON.stringify({
              status,
            }),
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .catch(e => {
      console.error('Faild to accept offer', e);
    });

  let resBody = await res.json();

  console.log('Update Response', resBody);

  if (resBody.error) {
    const { description } = resBody.error;
    alert(description[1]);
  }

  return res;
};

export const updateTask = async (task_id, status, wrap_up, metadata) => {
  const body = JSON.stringify({ status, wrap_up, metadata });
  const endPoint = `${getState().environmentState.url}/v1/task/${task_id}`;
  let res = await fetcher(endPoint, {
    ...getOktaHeader('PATCH'),
    body,
  })
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(endPoint, {
          ...getOktaHeader('PATCH'),
          body,
        }).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => {
      console.log(data);
      return data.json();
    });

  (res.type || '').toUpperCase() === 'SMS' &&
    dispatch(decreaseSMSCount(res.id));
  (res.type || '').toUpperCase() === 'EMAIL' &&
    dispatch(decreaseEmailCount(res.id));

  return res;
};

export const userLogout = async (
  logout_reason,
  attempts = 0,
  trigger = 'LOGOUT_REASON_INVALID'
) => {
  let res = await fetcher(`${getState().environmentState.url}/v1/user`, {
    ...getOktaHeader(),
    method: 'PATCH',
    body: JSON.stringify({
      online: false,
      trigger: logout_reason || 'LOGOUT_REASON_INVALID',
    }),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/user`, {
        ...getOktaHeader(),
        method: 'PATCH',
        body: JSON.stringify({ online: false }),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });

  if (res.ok) {
    console.log('User successfully logout');
    //
  } else {
    console.error(`Failed to logout from server ${attempts} times`);

    if (attempts < 10) {
      setTimeout(() => {
        userLogout(logout_reason, attempts + 1, trigger);
      }, 1000);
    } else {
      console.error(`Failed to logout ${attempts} times. Retry stopped`);
    }
  }
};

export const fetchStatusConfig = async ({ integration, group }) => {
  let groupStatus = await fetcher(
    `${
      getState().environmentState.url
    }/v1/config/${integration}/group/${group}/statuses`,
    getOktaHeader()
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${
            getState().environmentState.url
          }/v1/config/${integration}/group/${group}/statuses`,
          getOktaHeader()
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => data.json())
    .catch(e => {
      console.error('Error fetching status config', e);
      return null;
    });

  if (!groupStatus || groupStatus.error) {
    console.error('Error fetching status config', groupStatus);
    return null;
  }
  return groupStatus;
};

export const fetchIntegrationConfig = async integration => {
  if (!integration) {
    throw new Error('Integration is required for fetching configuration');
  }
  let config = await fetcher(
    `${getState().environmentState.url}/v1/config/${integration}/info`,
    getOktaHeader()
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/config/${integration}/info`,
          getOktaHeader()
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => {
      return data.json();
    });

  if (!config) {
    throw new Error('No config returned');
  }
  if (config.error) {
    throw new Error('Response Error');
  }

  return config;
};

export const fetchIntegrationGroup = async ({ integration, group }) => {
  if (!integration) {
    throw new Error('Integration is required for fetching configuration');
  }
  if (!group) {
    throw new Error('Group is required for fetching configuration');
  }
  let intGroup = await fetcher(
    `${
      getState().environmentState.url
    }/v1/config/${integration}/group/${group}`,
    getOktaHeader()
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${
            getState().environmentState.url
          }/v1/config/${integration}/group/${group}`,
          getOktaHeader()
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(response => {
      return response.json();
    });

  if (!intGroup) {
    throw new Error('No group returned');
  }
  if (intGroup.error) {
    throw new Error('Response Error');
  }

  return intGroup;
};

export const fetchEventsByCallId = async callId => {
  let res = await fetcher(
    `${getState().environmentState.url}/v1/call/${callId}/events`,
    getOktaHeader()
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/call/${callId}/events`,
          getOktaHeader()
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => data.json());

  if (!res || res.error) {
    console.error('Error fetching event list for call', callId);
    return null;
  }

  return res;
};

export const postWrapupWithId = async taskId => {
  let { wrapupState } = getState();

  const { wrapup } = wrapupState;

  let payload = { ...wrapup[taskId] };
  delete payload.submit;
  delete payload.timeout;
  let res = await fetcher(
    `${getState().environmentState.url}/v1/task/${taskId}/wrapup`,
    {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify(payload),
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/task/${taskId}/wrapup`,
          {
            ...getOktaHeader(),
            method: 'POST',
            body: JSON.stringify(payload),
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => data.json());

  if (!res || res.error) {
    console.error('Error posting wrapup with task id', taskId);
    return null;
  }

  return res;
};

// Get agent archives
export const fetchArchives = async (userId, query) => {
  let archives = await fetcher(
    `${getState().environmentState.url}/v1/tasks/archive/${userId}${
      query ? query : ''
    }`,
    getOktaHeader()
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/tasks/archive/${userId}${
            query ? query : ''
          }`,
          getOktaHeader()
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(async response => {
      console.log('FETCH ARCHIVE', response);
      let tasks = await response.json();
      return await helpers.completeTaskData(tasks);
    })
    // .then(archives => {
    //   if (!noDispatch) {
    //     dispatch({
    //       type: 'UPDATE_AGENT',
    //       agentState: {
    //         archives,
    //       },
    //     });
    //   }
    //   return archives;
    // })
    .catch(err => {
      console.error('Error fetching agent archives', err);
      return null;
    });

  return archives;
};

export const fetchTemplateWithName = async (integration, templateName) => {
  let res = await fetcher(
    `${
      getState().environmentState.url
    }/v1/config/${integration}/template/${templateName}`,
    {
      ...getOktaHeader(),
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${
            getState().environmentState.url
          }/v1/config/${integration}/template/${templateName}`,
          {
            ...getOktaHeader(),
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => data.json())
    .catch(err => {
      console.error('Error fetching template', integration, templateName, err);
      return null;
    });

  if (!res || res.error) {
    console.error('Error fetching template', integration, templateName, res);
    return null;
  }

  return res;
};

export const coachTaskbyId = async taskId => {
  if (!taskId) {
    console.error('Task id cannot be empty');
  }

  let { supervisorState } = getState();

  dispatch({
    type: 'UPDATE_SUPERVISOR',
    supervisorState: {
      ...supervisorState,
      [taskId]: {
        ...supervisorState[taskId],
        parent_task_id: taskId,
        status: '...CONNECTING',
      },
    },
  });

  let res = await fetcher(
    `${getState().environmentState.url}/v1/supervisor/coach/${taskId}`,
    {
      ...getOktaHeader(),
      method: 'POST',
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/supervisor/coach/${taskId}`,
          {
            ...getOktaHeader(),
            method: 'POST',
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .catch(err => {
      console.error('Error coaching call', err);
      return { error: `${err.code}: ${err.message}` };
    });

  if (!res || res.error) {
    console.error('Error in coaching task', taskId, (res || {}).error);
    dispatch({
      type: 'UPDATE_SUPERVISOR',
      supervisorState: {
        ...supervisorState,
        connecting: false,
        error: {
          message:
            (res || {}).error || `Error while coaching Task ID: ${taskId}`,
          taskId,
        },
        shouldSwitchView: false,
      },
    });
  }

  return res;
};

export const joinCallAsSupervisor = async callId => {
  if (!callId) {
    console.error('Insufficient arguments passed');
  }

  let { userState, supervisorState } = getState();
  const { sip_number } = userState;

  let oktaStorage = localStorage.getItem('okta-token-storage')
    ? JSON.parse(localStorage.getItem('okta-token-storage'))
    : null;
  let claimName =
    oktaStorage && oktaStorage.idToken && oktaStorage.idToken.claims
      ? oktaStorage.idToken.claims.name || ''
      : '';
  const body = {
    sip: userState.sip_number,
    metadata: {
      name: claimName,
      label: userState.username,
    },
  };
  let res = await fetcher(
    `${getState().environmentState.url}/v1/supervisor/call/${callId}/join`,
    {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify({ ...body }),
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${
            getState().environmentState.url
          }/v1/supervisor/call/${callId}/join`,
          {
            ...getOktaHeader(),
            method: 'POST',
            body: JSON.stringify({ ...body }),
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(response => {
      dispatch({
        type: 'UPDATE_SUPERVISOR',
        supervisorState: {
          ...supervisorState,
          shouldSwitchView: true,
        },
      });

      return response.json();
    })
    .catch(err => {
      console.error('Error in connecting supervisor to the call', err);
    });

  if (!res || res.error) {
    dispatch({
      type: 'UPDATE_SUPERVISOR',
      supervisorState: {
        ...supervisorState,
        error: {
          callId,
          message: `Error in connecting supervisor to call id: ${callId}`,
        },
        shouldSwitchView: false,
      },
    });
    console.error('Error in connecting supervisor to the call', callId);
  }

  return res;
};

export const disconnectUserByCallId = async taskId => {
  let { supervisorState, userState, taskState } = getState();
  const userId = userState.id;
  const key = Object.keys(supervisorState);
  const coachingTask =
    key &&
    key.find(item => {
      return (
        supervisorState[item] &&
        supervisorState[item].parent_task_id &&
        supervisorState[item].parent_task_id === taskId
      );
    });
  const callId = coachingTask && supervisorState[coachingTask].callId;

  let res = await fetcher(
    `${getState().environmentState.url}/v1/call/${callId}/disconnect/${userId}`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${
            getState().environmentState.url
          }/v1/call/${callId}/disconnect/${userId}`,
          {
            ...getOktaHeader(),
            method: 'PATCH',
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .catch(err => {
      console.error('Error in disconnecting supervisor from call', err);
    });

  if (!res || res.error) {
    console.error('Error in disconnecting supervisor from call', taskId);
  }

  dispatch({
    type: 'UPDATE_SUPERVISOR',
    supervisorState: {
      ...supervisorState,
      [coachingTask]: {
        ...supervisorState[coachingTask],
        status: false,
        supInCall: false,
        callId,
      },
    },
  });

  return res;
};

export const swapCalls = async (fromCallId, toCallId) => {
  if (!(fromCallId && toCallId)) {
    console.error('Error swapping call');
    if (!fromCallId) console.error('Invalid old call ID', fromCallId);
    if (!toCallId) console.error('Invalid new call ID', toCallId);
    return null;
  }

  let res = await fetcher(
    `${getState().environmentState.url}/v1/call/${fromCallId}/swap/${toCallId}`,
    { ...getOktaHeader(), method: 'PATCH' }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${
            getState().environmentState.url
          }/v1/call/${fromCallId}/swap/${toCallId}`,
          { ...getOktaHeader(), method: 'PATCH' }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => {
      return data.json();
    })
    .catch(e => {
      console.error('Error swapping calls', fromCallId, toCallId, e);
      return null;
    });

  if (!res || res.error) {
    console.error('Error swapping calls', fromCallId, toCallId, res);
    alert(res.error.description[1] + '\nTry again');
  }

  return res;
};

export const leaveCallAndPutCallOnHold = async callId => {
  let res = fetcher(
    `${getState().environmentState.url}/v1/call/${callId}/leave`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/call/${callId}/leave`,
          {
            ...getOktaHeader(),
            method: 'PATCH',
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => data.json())
    .catch(err => {
      console.error('Error leaving a call', err);
      return null;
    });

  if (!res || res.error) {
    console.error('Error leaving a call', callId, res);
    return null;
  }
  return res;
};

export const postOutboundCallByTaskId = async taskId => {
  const { environmentState, userState } = getState();

  const { sip_number, phone_number } = userState;

  if (!sip_number && !phone_number) {
    console.error(
      'Cannot make a call because user has no sip/phone number',
      userState
    );
    return null;
  }

  let payload = { task_id: taskId };
  if (sip_number) {
    payload.sip = sip_number;
  } else {
    payload.number = phone_number;
  }

  let res = await fetcher(`${environmentState.url}/v1/call`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  })
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return ffetcher(`${environmentState.url}/v1/call`, {
          ...getOktaHeader(),
          method: 'POST',
          body: JSON.stringify(payload),
        }).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => data.json())
    .catch(err => {
      console.error('Error posting a call for task', err);
      return null;
    });

  if (!res) {
    console.error('Error posting a call for a task', taskId);
    return null;
  }

  return res;
};

export const fetchEnableAchieveOktaFlag = async () => {
  return await fetcher(`/api/enableAchieveOkta`)
    .then(data => data.json())
    .catch(err => {
      console.error('Error fetching enableAchieveCommunicator flag', err);
      return null;
    });
};

export const fetchEnableFeedbackFlag = async () => {
  return await fetcher(`/api/enableFeedback`)
    .then(data => data.json())
    .catch(err => {
      console.error('Error fetching enableFeedback flag', err);
      return null;
    });
};

export const fetchAppVersion = () => {
  return fetcher(`/api/appVersion`)
    .then(data => data.json())
    .catch(err => {
      console.error('Error fetching app version', err);
      return null;
    });
};

export const reportCallQualityByCallId = async callId => {
  if (!callId) {
    throw new Error("Unknown call id. Can't report call quality");
  }

  let res = await fetcher(
    `${getState().environmentState.url}/v1/call/${callId}/quality_issue`,
    {
      ...getOktaHeader(),
      method: 'POST',
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${getState().environmentState.url}/v1/call/${callId}/quality_issue`,
          {
            ...getOktaHeader(),
            method: 'POST',
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => data.json())
    .catch(err => {
      console.error('Error while reporting call quality', err);
    });

  if (!res || res.error) {
    console.error('Error reporting call quality', callId, res);
    throw new Error(`Can't report call quality for this ${callId}`);
  }
  return res;
};

export const toggledMutedParticipant = async (
  call_id,
  participant_id,
  muted
) => {
  const payload = { muted: true }; // example
  return fetcher(
    `${getState().environmentState.url}/v1/call/${call_id}/${
      muted ? 'unmute' : 'mute'
    }/${participant_id}`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
      payload,
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/call/${call_id}/${
          muted ? 'unmute' : 'mute'
        }/${participant_id}`,
        {
          ...getOktaHeader(),
          method: 'PATCH',
          payload,
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const toggledHoldParticipant = async (call_id, participant_id, hold) => {
  const payload = { hold: true, participantId: participant_id }; // example

  return fetcher(
    `${getState().environmentState.url}/v1/call/${call_id}/${
      hold ? 'unhold' : 'hold'
    }/${participant_id}`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
      payload,
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/call/${call_id}/${
          hold ? 'unhold' : 'hold'
        }/${participant_id}`,
        {
          ...getOktaHeader(),
          method: 'PATCH',
          payload,
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const endCall = async call_id => {
  return fetcher(`${getState().environmentState.url}/v1/call/${call_id}/end`, {
    ...getOktaHeader(),
    method: 'PATCH',
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/call/${call_id}/end`,
        {
          ...getOktaHeader(),
          method: 'PATCH',
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const disconnectParticipant = async (call_id, participant_id) => {
  return fetcher(
    `${
      getState().environmentState.url
    }/v1/call/${call_id}/disconnect/${participant_id}`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${
          getState().environmentState.url
        }/v1/call/${call_id}/disconnect/${participant_id}`,
        {
          ...getOktaHeader(),
          method: 'PATCH',
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const playIVRbyCallId = async (
  call_id,
  participant_id,
  ivr_name,
  task_id
) => {
  const payload = {
    ivr_name,
    participant_id,
    task_id,
  };
  return fetcher(
    `${getState().environmentState.url}/v1/call/${call_id}/ivr/play`,
    {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify(payload),
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/call/${call_id}/ivr/play`,
        {
          ...getOktaHeader(),
          method: 'POST',
          body: JSON.stringify(payload),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const forwardTaskById = async ({
  distribution_type,
  task_id,
  available,
  group,
  user_id,
  skills,
  type,
}) => {
  if (!type || !task_id) {
    console.error('Insufficient args provided');
    return null;
  }

  const payload = {
    distribution_type,
    user_filters: [
      {
        available,
        group,
        ids: [user_id],
      },
    ],
    skills,
  };

  return fetcher(
    `${
      getState().environmentState.url
    }/v1/${type.toLowerCase()}/${task_id}/forward`,
    {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify(payload),
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${
          getState().environmentState.url
        }/v1/${type.toLowerCase()}/${task_id}/forward`,
        {
          ...getOktaHeader(),
          method: 'POST',
          body: JSON.stringify(payload),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const sendSMSbyTaskId = async ({ text, name, task_id }) => {
  const payload = {
    metadata: {
      name,
    },
    task_id,
    text,
  };

  return fetcher(`${getState().environmentState.url}/v1/sms`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/sms`, {
        ...getOktaHeader(),
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const fetchListByTaskId = async ({
  task_id,
  limit,
  start_time,
  end_time,
  type,
}) => {
  const args = [`limit=${limit || 10}`];
  if (start_time) {
    args.push(`start_time=${start_time}`);
  }
  if (end_time) {
    args.push(`end_time=${end_time}`);
  }
  let res = await fetcher(
    `${
      getState().environmentState.url
    }/v1/${type.toLowerCase()}/${task_id}/list?${args.join('&')}`,
    {
      ...getOktaHeader(),
    }
  )
    .catch(err => {
      if (err.constructor === ResponseError && err.code === 401) {
        return fetcher(
          `${
            getState().environmentState.url
          }/v1/${type.toLowerCase()}/${task_id}/list?limit=${limit}&end_time=${end_time}`,
          {
            ...getOktaHeader(),
          }
        ).catch(err => {
          if (err.constructor === ResponseError && err.code === 401) {
            dispatch(openReLoginDialog(true));
          }
          return Promise.reject(err);
        });
      }
      return Promise.reject(err);
    })
    .then(data => data.json());

  return res.reverse();
};

export const startNewVMRecording = async ({ type, name, user_id, sip }) => {
  const payload = {
    name,
    sip,
    type,
  };

  return fetcher(
    `${getState().environmentState.url}/v1/user/${user_id}/recording/start`,
    {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify(payload),
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/user/${user_id}/recording/start`,
        {
          ...getOktaHeader(),
          method: 'POST',
          body: JSON.stringify(payload),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const playVMRecording = async ({ recording_id, task_id }) => {
  const payload = {
    recording_id,
    task_id,
  };

  return fetcher(`${getState().environmentState.url}/v1/call/recording/play`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/call/recording/play`,
        {
          ...getOktaHeader(),
          method: 'POST',
          body: JSON.stringify(payload),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const deleteVMRecording = async ({ user_id, recording_id }) => {
  return fetcher(
    `${
      getState().environmentState.url
    }/v1/user/${user_id}/recording/${recording_id}`,
    {
      ...getOktaHeader(),
      method: 'DELETE',
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${
          getState().environmentState.url
        }/v1/user/${user_id}/recording/${recording_id}`,
        {
          ...getOktaHeader(),
          method: 'DELETE',
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const editUserRecording = async ({
  user_id,
  recording_id,
  name,
  type,
  url,
}) => {
  const payload = {
    name,
    type,
    url,
  };
  return fetcher(
    `${
      getState().environmentState.url
    }/v1/user/${user_id}/recording/${recording_id}`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
      body: JSON.stringify(payload),
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${
          getState().environmentState.url
        }/v1/user/${user_id}/recording/${recording_id}`,
        {
          ...getOktaHeader(),
          method: 'PATCH',
          body: JSON.stringify(payload),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const sendEmailByTaskId = async ({
  subject,
  task_id,
  html,
  recipients,
  template_data,
}) => {
  const payload = {
    subject,
    task_id,
    html,
    recipients,
    ...(template_data && { template_data }),
  };

  return fetcher(`${getState().environmentState.url}/v1/email`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/email`, {
        ...getOktaHeader(),
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const fetchEmailById = async ({ email_id }) => {
  return fetcher(`${getState().environmentState.url}/v1/email/${email_id}`, {
    ...getOktaHeader(),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/email/${email_id}`,
        {
          ...getOktaHeader(),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const fetchUserFromDirectory = async (searchString, cursor) => {
  const qsArr = [];
  if (searchString) {
    qsArr.push(`search=${searchString}`);
  }
  if (cursor) {
    qsArr.push(`cursor=${cursor}`);
  }
  let qs = '';
  if (qsArr.length) {
    qs = `?${qsArr.join('&')}`;
  }

  return fetcher(`${getState().environmentState.url}/v1/directory${qs}`, {
    ...getOktaHeader(),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/directory${qs}`, {
        ...getOktaHeader(),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const createScanner = async ({ integration, keyword, team }) => {
  const payload = {
    integration,
    keyword,
    team,
  };

  return fetcher(`${getState().environmentState.url}/v1/scanners`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/scanners`, {
        ...getOktaHeader(),
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const fetchScanners = async ({
  integration,
  cursor,
  limit,
  teams,
  user_id,
}) => {
  const payload = {
    integration,
    pagination: {
      cursor,
      limit,
    },
    teams,
    user_id,
  };

  return fetcher(`${getState().environmentState.url}/v1/scanners/search`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/scanners/search`, {
        ...getOktaHeader(),
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const fetchScannerById = async scanner_id => {
  return fetcher(
    `${getState().environmentState.url}/v1/scanners/${scanner_id}`,
    {
      ...getOktaHeader(),
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/scanners/${scanner_id}`,
        {
          ...getOktaHeader(),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const deleteScannerById = async scanner_id => {
  return fetcher(
    `${getState().environmentState.url}/v1/scanners/${scanner_id}`,
    {
      ...getOktaHeader(),
      method: 'DELETE',
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/scanners/${scanner_id}`,
        {
          ...getOktaHeader(),
          method: 'DELETE',
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const updateScannerById = async ({
  scanner_id,
  id,
  integration,
  keyword,
  team,
  user_id,
}) => {
  const payload = {
    id,
    integration,
    keyword,
    team,
    user_id,
  };
  return fetcher(
    `${getState().environmentState.url}/v1/scanners/${scanner_id}`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
      body: JSON.stringify(payload),
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/scanners/${scanner_id}`,
        {
          ...getOktaHeader(),
          method: 'PATCH',
          body: JSON.stringify(payload),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const fetchAudioTransription = async rec_id => {
  return fetcher(
    `${getState().environmentState.url}/v1/transcription/${rec_id}`,
    {
      ...getOktaHeader(),
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/transcription/${rec_id}`,
        {
          ...getOktaHeader(),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const getUserMetrics = async ({
  integration,
  group,
  from,
  team,
  user_id,
}) => {
  const range = helpers.getMetricsRange(from);
  const payload = {
    integration,
    group,
    range,
    team,
    user_id,
  };
  return fetcher(`${getState().environmentState.url}/v1/stats/metrics`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/stats/metrics`, {
        ...getOktaHeader(),
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const getUserStatusMetrics = async ({
  integration,
  group,
  team,
  user_id,
  range,
  metrics,
}) => {
  const payload = { integration, group, team, user_id, range, metrics };

  return fetcher(`${getState().environmentState.url}/v1/stats/user/status`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/stats/user/status`,
        {
          ...getOktaHeader(),
          method: 'POST',
          body: JSON.stringify(payload),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const updateAgentStatus = async ({ payload, user_id }) => {
  return fetcher(
    `${getState().environmentState.url}/v1/supervisor/user/${user_id}`,
    {
      ...getOktaHeader(),
      method: 'PATCH',
      body: JSON.stringify(payload),
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/supervisor/user/${user_id}`,
        {
          ...getOktaHeader(),
          method: 'PATCH',
          body: JSON.stringify(payload),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const getCallScript = async ({ script_name }) => {
  return fetcher(
    `${getState().environmentState.url}/v1/call/script/${script_name}`,
    {
      ...getOktaHeader(),
      method: 'POST',
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/call/script/${script_name}`,
        {
          ...getOktaHeader(),
          method: 'POST',
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const createDialTask = async ({ called, caller }) => {
  const payload = { called, caller };
  return fetcher(`${getState().environmentState.url}/v1/dialpad/dial`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/dialpad/dial`, {
        ...getOktaHeader(),
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const playDtmf = async ({ dtmf, participant_id, task_id, call_id }) => {
  const payload = { dtmf, participant_id, task_id };
  return fetcher(
    `${getState().environmentState.url}/v1/call/${call_id}/dtmf/play`,
    {
      ...getOktaHeader(),
      method: 'POST',
      body: JSON.stringify(payload),
    }
  ).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(
        `${getState().environmentState.url}/v1/call/${call_id}/dtmf/play`,
        {
          ...getOktaHeader(),
          method: 'POST',
          body: JSON.stringify(payload),
        }
      ).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const broadcastMessage = async ({
  message,
  sender,
  teams,
  user_ids,
}) => {
  const payload = { message, sender, teams, user_ids };
  return fetcher(`${getState().environmentState.url}/v1/message/send`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/message/send`, {
        ...getOktaHeader(),
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const fetchBroadcastMessages = async ({ user_id, limit, page_num }) => {
  let params = [];
  if (limit) params.push(`limit=${limit}`);
  if (page_num) params.push(`page_num=${page_num}`);

  let url = params.length
    ? `${
        getState().environmentState.url
      }/v1/user/${user_id}/messages?${params.join('&')}`
    : `${getState().environmentState.url}/v1/user/${user_id}/messages`;

  return fetcher(url, {
    ...getOktaHeader(),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(url, {
        ...getOktaHeader(),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const getTaskQueues = async ({
  integration,
  group,
  task_type,
  task_direction,
  distribution_type,
}) => {
  let url = `${
    getState().environmentState.url
  }/v1/tasks/queue?integration=${integration}&group=${group}&task_type=${task_type}&task_direction=${task_direction}&distribution_type=${distribution_type}`;

  return fetcher(url, {
    ...getOktaHeader(),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(url, {
        ...getOktaHeader(),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const getAvailableHDPhones = async ({ user_id }) => {
  let url = `${
    getState().environmentState.url
  }/v1/hotdesking/${user_id}/phones`;

  return fetcher(url, {
    ...getOktaHeader(),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(url, {
        ...getOktaHeader(),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const assignHDPhone = async ({ user_id, phone_id }) => {
  let url = `${
    getState().environmentState.url
  }/v1/hotdesking/${user_id}/assign/${phone_id}`;

  return fetcher(url, {
    ...getOktaHeader(),
    method: 'POST',
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(url, {
        ...getOktaHeader(),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

/**
 * send Genesys WS message to controller
 * @param {string} taskId
 * @param {string} userId
 * @param {Date} receivedAt
 * @param {object} message
 */
export const sendGenesysWSMessage = async message => {
  const payload = {
    event: message,
  };

  return fetcher(`${getState().environmentState.url}/v1/genesys/wsevent`, {
    ...getOktaHeader(),
    method: 'POST',
    body: JSON.stringify(payload),
  }).catch(err => {
    if (err.constructor === ResponseError && err.code === 401) {
      return fetcher(`${getState().environmentState.url}/v1/genesys/wsevent`, {
        ...getOktaHeader(),
        method: 'POST',
        body: JSON.stringify(payload),
      }).catch(err => {
        if (err.constructor === ResponseError && err.code === 401) {
          dispatch(openReLoginDialog(true));
        }
        return Promise.reject(err);
      });
    }
    return Promise.reject(err);
  });
};

export const fetcher = async (...args) => {
  try {
    let response = await fetch(...args);
    if (response.status >= 400) {
      let body;
      try {
        body = await response.json();
      } catch (e) {
        if (response) {
          body = await response.text();
        }
      }
      throw new ResponseError(
        response.status || 500,
        (body.error || {}).description ||
          JSON.stringify(body) ||
          'Unknown Error',
        body || response
      );
    }
    return response;
  } catch (err) {
    if (err.code === 401) {
      await updateAuth();
      throw new ResponseError(401, 'Unauthorized', 'Unauthorized');
    } else {
      logger.error('request failed', {
        error: err,
        request: args,
      });
      throw err;
    }
  }
};

class ResponseError extends Error {
  get code() {
    return this._code;
  }
  get body() {
    return this._body;
  }
  get message() {
    return this._message;
  }
  constructor(code, message, body) {
    super(message);
    this._code = code;
    this._body = body;
    this._message = message;
  }
}
export * from './user.js';
