import React from 'react';

import styles from '../home.scss';

import { connect } from 'react-redux';
import { openGenesysAuth } from '../../../utils/genesys/auth';
import { withOktaAuth } from '@okta/okta-react';

import jwtDecode from 'jwt-decode';

// Genesys login page should be placed after Okta, assuming okta accessToken is already obtained
const GenesysLogin = props => {
  const { authState, environmentState } = props;

  const oktaToken = jwtDecode(authState.accessToken);

  return (
    <div className={styles.setenv_login}>
      <div className={styles.login_wrap}>
        <div className={styles.genesys_icon}></div>
        <button
          className={styles.login_button}
          onClick={() => {
            openGenesysAuth(oktaToken, true);
          }}
        >
          Login to Genesys
        </button>
      </div>

      <p style={{ color: '#fff' }}>
        {environmentState.appVersion
          ? `Version ${environmentState.appVersion} from ${window.location.hostname}`
          : environmentState.appVersionLoading
          ? 'Fetching Version...'
          : 'Failed to fetch version '}
      </p>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    environmentState: state.environmentState,
  };
};

export default withOktaAuth(connect(mapStateToProps)(GenesysLogin));
