import React from 'react';

import styles from './tasks.scss';
import { mergeClasses } from '../../utils';

import InbouncCallIcon from '../../../assets/svgs/inbound_call.svg';
import OutbouncCallIcon from '../../../assets/svgs/outbound_call.svg';
import MissedCallIcon from '../../../assets/svgs/missed_call.svg';

const callIcons = {
  CALL: {
    INBOUND: {
      icon: InbouncCallIcon,
      color: 'rgb(32, 193, 34)',
    },
    OUTBOUND: {
      icon: OutbouncCallIcon,
      color: 'rgb(255, 224, 89)',
    },
  },
  FREE_FORM_CALL: {
    INBOUND: {
      icon: InbouncCallIcon,
      color: 'rgb(32, 193, 34)',
    },
    OUTBOUND: {
      icon: OutbouncCallIcon,
      color: 'rgb(255, 224, 89)',
    },
  },
  MISSEDCALL: {
    INBOUND: {
      icon: MissedCallIcon,
      color: 'red',
    },
    OUTBOUND: {
      icon: MissedCallIcon,
      color: 'red',
    },
  },
};

const TaskIcon = props => {
  const { task, template } = props;

  const { task_icon } = task || template || { task_icon: '' };

  const type = task.type.toUpperCase();
  const direction = task.call?.direction
    ? task.call.direction.toUpperCase()
    : task.direction
    ? task.direction.toUpperCase()
    : '';
  // currently the new SVG supports call/missedCall/freeFormCall
  const SVGTaskIcon = callIcons[type]?.[direction]?.icon;

  // use existing displaying logic if
  // 1. task_icon is configured in task template
  // 2. type/direction of the task is not on the list
  if (task_icon || !SVGTaskIcon) {
    return (
      <div
        style={
          task_icon?.match(/\.(jpeg|jpg|svg|png)$/) != null
            ? { backgroundImage: `url('${task_icon}'` }
            : {}
        }
        className={mergeClasses(
          styles.task_icon,
          styles[task_icon] || '',
          styles[type] || '',
          styles[direction] || ''
        )}
      />
    );
  }

  return (
    // NOTE!
    // Wrap the svg with div AND use dummy classes for the DOM selector FDR QA regression is using to work. @Prathyusha
    <div
      className={mergeClasses(
        styles.svg_task_icon,
        `tasks__${type}`,
        `tasks__${direction}`,
        styles[task_icon] || ''
      )}
    >
      <SVGTaskIcon
        style={{
          color: callIcons[type][direction].color,
        }}
      />
    </div>
  );
};

export default TaskIcon;
