export const consts = {
  phoneType: {
    assignedPhont: 0,
    softPhone: 1,
    hotdesking: 2,

    isSoftphone: value => {
      return value === consts.phoneType.softPhone;
    },
  },
  telephony: {
    genesys: 'genesys',
    twilio: 'twilio',

    isGenesys: value => {
      return value?.toLowerCase() === consts.telephony.genesys;
    },
  },
  genesys: {
    authed: 'authenticated',
    authing: 'authenticating',
    authFailed: 'failed',
  },
  app: {
    webapp: 'webapp',
    electronApp: 'electronapp',

    isWebapp: value => {
      return value?.toLowerCase() === consts.app.webapp;
    },
  },
};
