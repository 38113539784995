import React from 'react';
import TeamHeader from './TeamHeader';
import Userlist from './Userlist';
import Archives from './Archives';
import Details from '../../details/index';
import Wrapup from '../../wrapup';

import styles from '../supervisor.scss';
import { mergeClasses } from '../../../utils';
import { BoundaryError } from '../../reusable_components/boundaryerror';
import { UserMonitoringConnector } from './connectors';
import Copy from '../../reusable_components/Copy';

const UserMonitoring = props => {
  let { template, error, selected, selectedTaskId, teamview } = props;
  let limit = 20;
  const taskID =
    selected && (selected.task_id ? selected.task_id : selected.id);

  return (
    <>
      <div
        className={
          selected
            ? mergeClasses(styles.mainview, styles['selected'])
            : styles.mainview
        }
      >
        <BoundaryError>
          <TeamHeader />
        </BoundaryError>
        <BoundaryError>
          {teamview === 'tasks' ? (
            <Userlist limit={limit} />
          ) : (
            <Archives limit={limit} />
          )}
        </BoundaryError>
      </div>
      <div
        className={
          selected
            ? mergeClasses(styles.agent_detail, styles['selected'])
            : mergeClasses(styles.agent_detail, styles['not_selected'])
        }
      />
      <div
        className={
          selected
            ? mergeClasses(styles.detailView, styles['selected'])
            : styles.detailView
        }
      >
        {selectedTaskId && (
          <>
            <BoundaryError>
              <Details
                task={selected}
                selectedTask={selected}
                error={error}
                view={'supervisor'}
                template={template}
              />
            </BoundaryError>
            {selected && selected.wrap_up && selected.wrap_up != {} ? (
              <BoundaryError>
                <Wrapup task={selected} />
              </BoundaryError>
            ) : null}
            {taskID && (
              <div className={styles.details_task}>
                <div className={styles.key}>Task ID:</div>
                <div className={styles.value}>{taskID}</div>
                <Copy textToCopy={taskID} />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default UserMonitoringConnector(UserMonitoring);
