import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { StyledTableCell } from '../reusable_components/Expansions';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const Participants = props => {
  const classes = useStyles();
  const { detailViewItem } = props;

  return (
    <>
      {detailViewItem.call_info && detailViewItem.call_info.participants && (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="Participants Table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left">Name</StyledTableCell>
                <StyledTableCell align="left">Role</StyledTableCell>
                <StyledTableCell align="left">Type</StyledTableCell>
                <StyledTableCell align="left">Number</StyledTableCell>
                <StyledTableCell align="left">Status</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detailViewItem.call_info.participants.map(participant => {
                return (
                  <TableRow key={participant.id}>
                    <StyledTableCell component="th" scope="row" align="left">
                      {participant.user_id
                        ? participant.user_id
                        : participant.id}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {participant.role}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {participant.type}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {participant.number}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {participant.status}
                    </StyledTableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default Participants;
