import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './Dialpad.scss';
import parentStyles from './sidebar.scss';

import { Feedback, dialpadNumbers, mergeClasses } from '../../utils';
import { createDialTask, playDtmf } from '../../services';
import { makeStyles } from '@material-ui/core/styles';
import Textbox from '../reusable_components/Textbox';
import { Spinner } from '../reusable_components/Spinner';
import AddressBook from './AddressBook';
import {
  Popover,
  Backdrop,
  Grow,
  Grid,
  Button,
  Avatar as NumberPad,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import {
  Dialpad as DialpadIcon,
  ContactPhoneOutlined as ContactsIcon,
  CallRounded as CallIcon,
  BackspaceRounded as ClearIcon,
} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#184895',
    color: theme.palette.getContrastText('#184895'),
    border: '2px solid #fff',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '700px',
    height: '300px',
  },
  container: {
    width: '30%',
  },
  contacts: {
    width: '1.5em',
    height: '1.5em',
  },
  outlined: {
    textTransform: 'none',
  },
  disabled: {
    color: '#fff !important',
    opacity: 0.26,
  },
  button_root: {
    color: theme.palette.getContrastText('#184895'),
  },
  clearIcon: {
    cursor: 'pointer',
  },
  dialpadIcon: {
    color: theme.palette.getContrastText('#333'),
  },
  number: {
    color: theme.palette.getContrastText('#fff'),
    backgroundColor: '#fff',
  },
}));

const supportedTaskTypes = ['FREE_FORM_CALL', 'CALL'];

const Dialpad = ({
  updateFeedback,
  phone_number,
  isUserInActiveCall,
  task_id,
  call_id,
  participant_id,
  isClientConnected,
  type,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [dtmfNumber, setDtmfNumber] = useState({
    loading: false,
    index: undefined,
  });
  const [dtmf, setDtmf] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openAddressBook, setOpenAddressBook] = useState(false);
  const [number, setNumber] = useState('');

  const handleOpen = event => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleDialogConfirmation = () => {
    setOpenDialog(false);
    dialNumber({ called: number, caller: phone_number });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAddressBook(false);
    setNumber('');
    setDtmf('');
    setAnchorEl(null);
  };

  const handleSetNumber = number => {
    setNumber(number);
  };

  const dialNumber = ({ called, caller }) => {
    setLoading(true);
    createDialTask({ called, caller })
      .then(res => res.json())
      .then(data => {
        console.log('Dial task', data);
        handleClose();
      })
      .catch(err =>
        updateFeedback(
          `${err.code}: ${err.description || err.message}`,
          Feedback.ERROR
        )
      )
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let active = true;

    const delaydtmf = setTimeout(async () => {
      setDtmfNumber({ loading: true });
      if (dtmf.length) {
        if (!supportedTaskTypes.includes(type))
          return updateFeedback(
            `Can't play dtmf for the selected Task type`,
            Feedback.WARNING
          );
        if (!task_id || !call_id || !isClientConnected) {
          let msg = '';
          if (!isClientConnected)
            msg =
              'Client is not connected to the active call. Cannot play dtmf';
          if (!task_id) msg = 'Task ID not found';
          if (!call_id) msg = 'Call id not found';
          setDtmf('');
          setNumber('');
          return updateFeedback(msg, Feedback.WARNING);
        }

        if (dtmf?.length)
          await playDtmf({
            task_id,
            dtmf,
            participant_id,
            call_id,
          })
            .then(res => res.json())
            .then(
              data =>
                active && updateFeedback(`DTMF is successful`, Feedback.SUCCESS)
            )
            .catch(
              err =>
                active &&
                updateFeedback(
                  `${err.code}: ${err.description || err.message}`,
                  Feedback.ERROR
                )
            )
            .finally(() => {
              if (active) {
                setDtmfNumber({
                  loading: false,
                  index: undefined,
                });
                setDtmf('');
                setNumber('');
              }
            });
      }
    }, 1000);

    return () => {
      active = false;
      clearTimeout(delaydtmf);
    };
  }, [dtmf]);

  return (
    <div className={styles.dialpad}>
      <DialpadIcon
        classes={{ root: classes.dialpadIcon }}
        className={mergeClasses(parentStyles.setting_tab, parentStyles.tab)}
        onClick={handleOpen}
      />
      <Popover
        aria-labelledby="Dialpad"
        className={classes.modal}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Grow in={open}>
          <div className={classes.paper}>
            <Textbox
              label={'Enter Number'}
              value={number || ''}
              multiline={false}
              handleChange={value => {
                setNumber(value);
                isUserInActiveCall && setDtmf(value);
              }}
              endAdornment={
                <ClearIcon
                  classes={{ root: classes.clearIcon }}
                  onClick={async e => {
                    setNumber(number => number.slice(0, -1));
                  }}
                />
              }
            />

            <div className={styles.break} />
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                {openAddressBook ? (
                  <AddressBook handleSetNumber={handleSetNumber} />
                ) : (
                  <Grid
                    container
                    classes={{ container: classes.container }}
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    {dialpadNumbers.map((item, index) => {
                      return (
                        <Grid item xs={4} key={index}>
                          <NumberPad
                            className={classes.number}
                            onClick={async e => {
                              setNumber(number => number.concat(item));

                              if (isUserInActiveCall) {
                                setDtmf(dtmf => dtmf.concat(item));
                              }
                            }}
                          >
                            {dtmfNumber?.loading &&
                            dtmfNumber.index === index ? (
                              <Spinner size={20} />
                            ) : (
                              <>{item}</>
                            )}
                          </NumberPad>
                        </Grid>
                      );
                    })}
                    <div className={styles.break} />
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  direction="column"
                >
                  <Grid item>
                    {loading ? (
                      <Spinner size={20} />
                    ) : (
                      <Button
                        disabled={isUserInActiveCall}
                        classes={{
                          outlined: classes.outlined,
                          root: classes.button_root,
                          disabled: classes.disabled,
                        }}
                        onClick={async e => {
                          if (!phone_number) {
                            updateFeedback(
                              `Caller phone number not found/empty`,
                              Feedback.WARNING
                            );
                            return;
                          }

                          if (number === '911') {
                            setOpenDialog(true);
                            return;
                          }

                          if (!number.length)
                            return updateFeedback(
                              `Dialed number cannot be empty`,
                              Feedback.WARNING
                            );

                          dialNumber({ called: number, caller: phone_number });
                        }}
                      >
                        <CallIcon classes={{ root: classes.contacts }} />
                      </Button>
                    )}
                  </Grid>
                  <Grid item>
                    <Button
                      classes={{
                        outlined: classes.outlined,
                        root: classes.button_root,
                      }}
                      onClick={e => {
                        setOpenAddressBook(!openAddressBook);
                      }}
                    >
                      <ContactsIcon classes={{ root: classes.contacts }} />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Dialog open={openDialog} onClose={handleDialogClose}>
              <DialogTitle id="confirm-911">
                Are you sure you want to call 911?
              </DialogTitle>
              <DialogActions>
                <Button onClick={handleDialogClose}>No</Button>
                <Button onClick={handleDialogConfirmation} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grow>
      </Popover>
    </div>
  );
};

const mapStateToProps = state => {
  const { userState, callState, taskState } = state;
  const { phone_number, id } = userState;
  const { detailView } = taskState;

  const currentTask = callState.find(el => el.id === detailView) || {};

  const activeCallTask =
    currentTask.call_info &&
    (currentTask.call_info.status || '').toLowerCase() !== 'completed' &&
    currentTask.call_info.participants &&
    currentTask.call_info.participants.length &&
    currentTask.call_info.participants.find(
      p => p.id === id && (p.status || '').toLowerCase() === 'connected'
    );

  const participant =
    activeCallTask &&
    currentTask?.call_info?.participants &&
    currentTask.call_info.participants.length &&
    currentTask.call_info.participants.find(
      p => p.role === 'client' || p.role === 'external'
    );

  const isClientConnected = !!(participant?.status === 'connected');

  return {
    phone_number,
    task_id: detailView,
    call_id: currentTask?.call_info?.id,
    isUserInActiveCall: !!activeCallTask,
    participant_id: id, // for DTMF. use id of the DTMF initiator, which is always the agent
    isClientConnected,
    type: currentTask?.type,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Dialpad);
