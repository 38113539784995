import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import styles from './SMSList.scss';
import SMS from './SMS';
import { BoundaryError } from '../reusable_components/boundaryerror';
import {
  pushSMSList,
  unsetScrollToTheLastSMS,
  unshiftSMSList,
} from '../../store/action/sms';
import { Spinner } from '../reusable_components/Spinner';
import { scrollToRef } from '../../utils/helpers';
import { logger } from '../../services/server_side_logging';
class SMSList extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.getNextSMSs = this.getNextSMSs.bind(this);
    this.getPrevSMSs = this.getPrevSMSs.bind(this);
    this.prevSmsRef = createRef();
    this.nextSmsRef = createRef();
    this.scrollHandler = this.scrollHandler.bind(this);
    this.state = { moreOld: true, prevHeight: 0 };
    this.scrolledTo = 0;
  }

  componentDidMount() {
    const { scroller = scrollToRef } = this.props;
    scroller(this.nextSmsRef.current);
    if (this.props.detailViewItem && !this.props.detailViewItem.sms_list) {
      this.getNextSMSs();
    }
  }

  componentDidUpdate(prevProps) {
    const { loadingPrev, moreOld } = this.state;
    const {
      scroller = scrollToRef,
      scrollToNewSMS,
      unsetScrollToTheLastSMS,
    } = this.props;
    if (this.props.detailViewItem && !this.props.detailViewItem.sms_list) {
      this.getNextSMSs();
    }
    if (this.props.detailViewItem.id !== prevProps.detailViewItem.id) {
      scroller(this.nextSmsRef);

      this.setState({ moreOld: true });
    }
    if (scrollToNewSMS) {
      scroller(this.nextSmsRef.current);
      unsetScrollToTheLastSMS();
    }

    if (
      moreOld &&
      !loadingPrev &&
      this.nextSmsRef.current &&
      this.nextSmsRef.current.parentElement.scrollTop <= 200 &&
      this.nextSmsRef.current.parentElement.scrollHeight -
        this.state.prevHeight >=
        200
    ) {
      this.nextSmsRef.current.parentElement.scrollTop =
        this.nextSmsRef.current.parentElement.scrollHeight -
        this.state.prevHeight;
      this.getPrevSMSs();
    }
    const prevSMSlist = prevProps.sms_list || [];
    const smsList = this.props.sms_list || [];
    if (
      smsList.length - prevSMSlist.length === 1 &&
      smsList[prevSMSlist.length].created_at >
        (prevSMSlist[prevSMSlist.length - 1] || { created_at: 0 }).created_at
    ) {
      this.nextSmsRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }

  scrollHandler = event => {
    const { loadingPrev, moreOld, loadingNext } = this.state;
    const dH = this.scrolledTo - event.currentTarget.scrollTop;
    this.scrolledTo = event.currentTarget.scrollTop;
    this.setState({ prevHeight: event.currentTarget.scrollHeight });

    if (
      dH > 0 &&
      moreOld &&
      !loadingPrev &&
      event.currentTarget.scrollTop <= 20
    ) {
      this.getPrevSMSs();
      event.currentTarget.scrollTop =
        this.nextSmsRef.current.parentElement.scrollHeight -
        this.state.prevHeight;
    }
    if (
      dH < 0 &&
      !loadingNext &&
      event.currentTarget.scrollTop +
        this.nextSmsRef.current.parentElement.offsetHeight +
        200 >=
        this.nextSmsRef.current.offsetTop
    ) {
      this.getNextSMSs();
    }
  };

  render() {
    const {
      groupMessagesByDate,
      sms_list,
      mergeClasses,
      displayTimestamp,
      detailViewItem,
    } = this.props;
    const { loadingNext, loadingPrev } = this.state;
    const msgGrouping = sms_list && groupMessagesByDate(sms_list);

    return (
      <div
        className={styles.sms_list}
        onScroll={this.scrollHandler}
        style={{ height: '100%', maxHeight: '100%', overflowY: 'scroll' }}
      >
        <div ref={this.prevSmsRef} id="sms_top_list">
          {loadingPrev && <Spinner size={24} />}
        </div>
        {msgGrouping &&
          (Object.keys(msgGrouping) || []).map((item, dIndex) => {
            let messages = (msgGrouping[item] || []).map(msg => {
              const {
                id,
                direction,
                text,
                sender,
                recipient,
                status,
                created_at,
                metadata,
                error,
              } = msg;

              return (
                <BoundaryError key={`boundaryKey${id}`}>
                  <SMS
                    key={id}
                    mergeClasses={mergeClasses}
                    direction={direction}
                    text={text}
                    name={metadata && metadata.name}
                    status={status}
                    sender={sender}
                    recipient={recipient}
                    created_at={created_at}
                    displayTimestamp={displayTimestamp}
                    taskCreated={detailViewItem.created_at}
                    error={error}
                  />
                </BoundaryError>
              );
            });
            return (
              <React.Fragment key={`fragment${dIndex}`}>
                <div key={dIndex} className={styles.date_separator} />
                <div key={`date${dIndex}`} className={styles.date}>
                  {item}
                </div>
                {messages}
              </React.Fragment>
            );
          })}
        <div ref={this.nextSmsRef} id="next_sms">
          {loadingNext && <Spinner size={24} />}
        </div>
      </div>
    );
  }

  getNextSMSs = async () => {
    const { loadingNext } = this.state;
    const {
      detailViewItem,
      nextSince,
      fetchListByTaskId,
      limit,
      pushSMS,
    } = this.props;
    if (loadingNext) {
      return;
    }
    this.setState({ loadingNext: true });

    try {
      const list = await fetchListByTaskId({
        task_id: detailViewItem.id,
        start_time: nextSince,
        limit,
        type: detailViewItem.type,
      });
      list.length > 0 && pushSMS(detailViewItem.id, list);
      this.setState({ loadingNext: false });
    } catch (err) {
      logger.error('getNextSMSs - fetchListByTaskId failed', {
        err,
        taskId: detailViewItem.id,
        startTime: nextSince,
        type: detailViewItem.type,
      });
      this.setState({ loadingNext: false });
    }
  };

  getPrevSMSs = async () => {
    const { moreOld, loadingPrev } = this.state;
    const {
      detailViewItem,
      prevFrom,
      fetchListByTaskId,
      limit,
      unshiftSMS,
    } = this.props;
    if (!moreOld || loadingPrev) {
      return;
    }
    this.setState({ loadingPrev: true });

    try {
      const list = await fetchListByTaskId({
        task_id: detailViewItem.id,
        end_time: prevFrom,
        limit,
        type: detailViewItem.type,
      });
      list.length > 0 && unshiftSMS(detailViewItem.id, list);
      this.setState({
        loadingPrev: false,
        moreOld: list.length === limit,
      });
    } catch (err) {
      logger.error('getPrevSMSs - fetchListByTaskId failed', {
        err,
        taskId: detailViewItem.id,
        endTime: prevFrom,
        type: detailViewItem.type,
      });
      this.setState({ loadingPrev: false });
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  const { smsState } = state;
  const { detailViewItem, view } = ownProps;
  const sms_list =
    view === 'supervisor'
      ? ((detailViewItem.sms_list && detailViewItem.sms_list) || []).sort(
          (a, b) => a.created_at - b.created_at
        )
      : (smsState[detailViewItem.id] &&
          smsState[detailViewItem.id].sms_list &&
          smsState[detailViewItem.id].sms_list.length > 0 &&
          smsState[detailViewItem.id].sms_list) ||
        ((detailViewItem.sms_list && detailViewItem.sms_list) || []).sort(
          (a, b) => a.created_at - b.created_at
        );
  const nextSince = sms_list.length
    ? sms_list[sms_list.length - 1].created_at + 1
    : detailViewItem.sms.last_sms_at || detailViewItem.sms.start_at;
  const prevFrom = sms_list.length
    ? sms_list[0].created_at - 1
    : (detailViewItem.sms.last_sms_at || detailViewItem.sms.start_at) - 1;
  const limit = ownProps.limit || 10;

  return {
    limit,
    sms_list,
    nextSince,
    prevFrom,
    scrollToNewSMS: smsState.scrollToNewSMS,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    pushSMS: (task_id, sms_list) => {
      dispatch(pushSMSList(task_id, sms_list));
    },
    unshiftSMS: (task_id, sms_list) => {
      dispatch(unshiftSMSList(task_id, sms_list));
    },
    unsetScrollToTheLastSMS: () => {
      dispatch(unsetScrollToTheLastSMS());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SMSList);
