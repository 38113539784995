import React from 'react';
import WrapupSelectGroup from './wrapup_select_group';
import styles from './wrapup.scss';
import { mergeClasses } from '../../utils';

const WrapupSelectRow = props => {
  let { list, task } = props;

  return (
    <div
      id="wrapup_select_row"
      className={mergeClasses(styles.wrapup_select_row)}
    >
      {list.map((item, index) => {
        return <WrapupSelectGroup {...props} key={index} selectProps={item} />;
      })}
    </div>
  );
};

export default WrapupSelectRow;
