// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto|Rubik&display=swap);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap);"]);
// Module
exports.push([module.id, "*{margin:0px;box-sizing:border-box;font-family:\"Rubik\",\"Roboto\",\"Open Sans\",sans-serif}html,body{height:100%;background:#333;overflow:hidden}body:hover{overflow:scroll}::-webkit-scrollbar{width:6px;height:6px}::-webkit-scrollbar-track{background:transparent}::-webkit-scrollbar-thumb{background:rgba(255,255,255,.5);border-radius:6px}::-webkit-scrollbar-thumb:hover{background:#555;border-radius:6px}.style__root___3VHe9{display:flex;height:100%;flex-direction:column;align-content:stretch}.style__root___3VHe9>div{display:flex;flex-direction:column;height:100%}.style__root___3VHe9>div>div{display:flex;flex-direction:column;height:100%}.style__root___3VHe9>.style__list____NK2W{background-color:gray}#style__root___3VHe9{display:flex;height:100%;flex-direction:column;align-content:stretch}a{color:#fff}button{color:#000}.style__body___2xdi7{position:relative;display:flex;flex-direction:row;align-items:stretch;height:100%}#style__wrapper___2Jyo2{display:flex;flex-direction:row;height:100%}[data-tooltip]:before{position:absolute;content:attr(data-tooltip);opacity:0;transition:all .15s ease;padding:10px;border-radius:20px;z-index:100}[data-tooltip]:hover:before{opacity:1;background:#e3e3e8;color:#212121;margin-top:-50px;margin-left:20px;transition-delay:700ms;transition-property:opacity}", ""]);
// Exports
exports.locals = {
	"root": "style__root___3VHe9",
	"list": "style__list____NK2W",
	"body": "style__body___2xdi7",
	"wrapper": "style__wrapper___2Jyo2"
};
module.exports = exports;
