import React, { Fragment } from 'react';
import styles from '../details.scss';

import { mergeClasses, formatTime } from '../../../utils/helpers';

const AudioText = ({ transcription, currentTime, handleTextClick, who }) => {
  const NANOS = 1000000000;
  return (
    <div className={styles.audio_text}>
      <div id={who} className={styles.who}>
        {who}{' '}
      </div>
      {transcription.map((el, index) => {
        const startTime = ((el || {}).start_time || {}).nanos
          ? (((el || {}).start_time || {}).seconds || 0) +
            (((el || {}).start_time || {}).nanos || 0) / NANOS
          : ((el || {}).start_time || {}).seconds || 0;
        const endTime = ((el || {}).end_time || {}).nanos
          ? (((el || {}).end_time || {}).seconds || 0) +
            (((el || {}).end_time || {}).nanos || 0) / NANOS
          : ((el || {}).end_time || {}).seconds || 0;

        return (
          <Fragment key={`Fragment${index}`}>
            {index === 0 && (
              <div className={styles.timestamp}>{formatTime(startTime)}</div>
            )}
            <div
              key={index}
              className={mergeClasses(
                styles.word,
                currentTime >= startTime && currentTime < endTime
                  ? styles.highlight
                  : ''
              )}
              onClick={async e => {
                e.preventDefault();
                handleTextClick(startTime);
              }}
            >
              {el.word}
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default AudioText;
