import React from 'react';
import WrapupButton from './wrapup_button';
import styles from './wrapup.scss';
import { BoundaryError } from '../reusable_components/boundaryerror';
import { mergeClasses } from '../../utils';

const WrapupButtonRow = props => {
  const { list, task } = props;
  const codes = Object.keys(task.wrap_up || {});
  const btnList =
    task.status === 'ASSIGNED'
      ? list
      : list.filter(item =>
          ((task.wrap_up || {}).wrapup_code_list || []).includes(item.value)
        );

  return (
    <div
      id="wrapup_button_row"
      className={mergeClasses(styles.wrapup_button_row)}
    >
      {btnList.map((item, index) => {
        return (
          <BoundaryError key={index}>
            <WrapupButton key={index} {...props} buttonProps={item} />
          </BoundaryError>
        );
      })}
    </div>
  );
};

export default WrapupButtonRow;
