import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from '../supervisor.scss';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';

import {
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Zoom,
  IconButton,
} from '@material-ui/core';
import { selectTeam } from '../../../store/action/team';
import { mergeClasses } from '../../../utils';
import TeamFilter from './TeamFilter';
import TeamSorter from './TeamSorter';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    backgroundColor: '#424242',
  },
  root: {
    marginLeft: theme.spacing(3),
    color: '#fff',
    padding: 0,
  },
  grid_root: {
    display: 'flex',
  },
  typography_root: {
    opacity: 0.7,
  },
}));

const TeamHeader = ({ teams, selectedTeam, selectTeam, sortBy }) => {
  const classes = useStyles();
  const [openSortBox, setOpenSortBox] = useState(false);
  const [openFilterBox, setOpenFilterBox] = useState(false);

  useEffect(() => {
    teams.length && selectTeam(teams[0]);
  }, []);

  return (
    <div className={styles.team_heading} id="team_heading">
      <Grid container justify="space-between">
        <Typography>Team</Typography>
        {teams && teams.length > 1 ? (
          <FormControl
            className={mergeClasses(classes.formControl, styles.team_selection)}
          >
            <Select
              MenuProps={{ classes: { paper: styles.team_selection_toggled } }}
              value={selectedTeam || ''}
              displayEmpty
              className={mergeClasses(styles.team_selection)}
              onChange={e => {
                e.preventDefault();
                e.stopPropagation();
                console.log('new value: ', e.target.value);
                selectTeam(e.target.value);
              }}
            >
              <MenuItem value="">All</MenuItem>
              {teams.map(team => {
                return (
                  <MenuItem
                    key={team}
                    className={styles.menu_item}
                    value={team}
                  >
                    {team}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        ) : (
          <Typography>{teams[0]}</Typography>
        )}
      </Grid>
      <Grid container justify="space-between" className={styles.sort}>
        <Grid item classes={{ root: classes.grid_root }}>
          <Typography classes={{ root: classes.typography_root }}>
            Sort By:{' '}
          </Typography>
          <div className={styles.sortBy}> {sortBy?.label} </div>
          <IconButton
            classes={{ root: classes.root }}
            onClick={async e => {
              setOpenSortBox(!openSortBox);
              setOpenFilterBox(false);
            }}
          >
            {openSortBox ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </Grid>
        <Grid item classes={{ root: classes.grid_root }}>
          <Typography classes={{ root: classes.typography_root }}>
            Filter By:{' '}
          </Typography>
          <IconButton
            classes={{ root: classes.root }}
            onClick={async e => {
              setOpenSortBox(false);
              setOpenFilterBox(!openFilterBox);
            }}
          >
            {openFilterBox ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </Grid>
      </Grid>
      {openSortBox && (
        <Zoom in={openSortBox}>
          <TeamSorter />
        </Zoom>
      )}
      {openFilterBox && (
        <Zoom in={openFilterBox}>
          <TeamFilter />
        </Zoom>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { userState, teamState } = state;
  const { team, teams = [] } = userState;

  const { users, selectedTeam = '', sortBy } = teamState;
  return {
    sortBy,
    selectedTeam,
    teams: teams && teams.length ? teams : team ? [team] : [],
    users:
      selectedTeam === ''
        ? users
        : (users || []).filter(
            u =>
              u.team === selectedTeam || (u.teams || []).includes(selectedTeam)
          ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectTeam: team => dispatch(selectTeam(team)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamHeader);
