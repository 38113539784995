import React, { Component, createRef } from 'react';
import styles from './EmailList.scss';
import Email from './Email';
import ComposeEmail from './ComposeEmail';

import { connect } from 'react-redux';
import { BoundaryError } from '../../reusable_components/boundaryerror';
import { Spinner } from '../../reusable_components/Spinner';

import { pushEmailList, unshiftEmailList } from '../../../store/action/email';
import { scrollToRef } from '../../../utils/helpers';
class EmailList extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.getNextEmail = this.getNextEmail.bind(this);
    this.getPrevEmail = this.getPrevEmail.bind(this);
    this.prevEmailRef = createRef();
    this.nextEmailRef = createRef();
    this.scrollHandler = this.scrollHandler.bind(this);
    this.state = {
      mount: true,
      loadingPrev: false,
      loadingNext: false,
      empty_list: false,
      expand: true,
      moreOld: true,
      prevHeight: 0,
    };
    this.scrolledTo = 0;
  }

  componentDidMount() {
    const { scroller = scrollToRef } = this.props;
    scroller(this.nextEmailRef);
    // console.log('component did mount');
    // if (
    //   this.props.detailViewItem &&
    //   (this.props.detailViewItem.status || '').toUpperCase() !== 'COMPLETED'
    // )
    //   this.nextEmailRef.current.scrollIntoView();
    // else this.nextEmailRef.current.scrollIntoView(false);

    if (
      this.props.detailViewItem &&
      !this.props.detailViewItem.email_list &&
      !this.state.empty_list
    ) {
      this.getNextEmail();
    }
  }

  componentDidUpdate(prevProps) {
    const { loadingPrev, moreOld } = this.state;
    const { scroller = scrollToRef } = this.props;
    scroller(this.nextEmailRef);
    if (
      this.props.detailViewItem &&
      !this.props.detailViewItem.email_list &&
      !this.state.empty_list
    ) {
      this.getNextEmail();
    }
    if (this.props.detailViewItem.id !== prevProps.detailViewItem.id) {
      // if (
      //   (this.props.detailViewItem.status || '').toUpperCase() !== 'COMPLETED'
      // )
      //   this.nextEmailRef.current.scrollIntoView();
      // else this.nextEmailRef.current.scrollIntoView(false);
      scroller(this.nextEmailRef);
      this.setState({ moreOld: true });
    }
    if (
      moreOld &&
      !loadingPrev &&
      this.nextEmailRef.current &&
      this.nextEmailRef.current.parentElement.scrollTop <= 200 &&
      this.nextEmailRef.current.parentElement.scrollHeight -
        this.state.prevHeight >=
        200
    ) {
      this.nextEmailRef.current.parentElement.scrollTop =
        this.nextEmailRef.current.parentElement.scrollHeight -
        this.state.prevHeight;
      this.getPrevEmail();
    }
    const prevEmaillist = prevProps.email_list || [];
    const emailList = this.props.email_list || [];
    if (
      emailList.length - prevEmaillist.length === 1 &&
      emailList[prevEmaillist.length].created_at >
        (prevEmaillist[prevEmaillist.length - 1] || { created_at: 0 })
          .created_at
    ) {
      scroller(this.nextEmailRef);
      // this.nextEmailRef.current.scrollIntoView({
      //   behavior: 'smooth',
      //   block: 'end',
      //   inline: 'nearest',
      // });
    }
  }

  render() {
    const { loadingNext, loadingPrev } = this.state;
    const { detailViewItem, email_list, showEmailEditor, view } = this.props;

    const {
      0: first,
      [email_list.length - 2]: last_but_one,
      [email_list.length - 1]: last,
    } = email_list;

    const renderList =
      email_list.length > 3 && this.state.expand
        ? [first, email_list.length, last_but_one, last]
        : email_list;

    return (
      <div
        className={styles.email_list}
        onScroll={this.scrollHandler}
        style={{ height: '100%', maxHeight: '100%', overflowY: 'scroll' }}
      >
        <div ref={this.prevEmailRef} className={styles.next_ref}>
          {loadingPrev && <Spinner size={24} />}
        </div>

        {renderList.map((email, eIndex) => {
          const {
            id,
            sender,
            recipients,
            subject,
            events,
            html,
            text,
            status,
            created_at,
            direction,
            attachments,
          } = email;
          return (
            <BoundaryError key={`${eIndex}_${id}`}>
              {id ? (
                <Email
                  task_id={detailViewItem.id}
                  key={id}
                  id={id}
                  sender={sender}
                  recipients={recipients}
                  subject={subject}
                  events={events}
                  html={html}
                  text={text}
                  status={status}
                  created_at={created_at}
                  direction={direction}
                  attachments={attachments}
                  isLatestEmail={
                    renderList.length - 1 === eIndex ? true : false
                  }
                />
              ) : (
                <>
                  <div id={eIndex} className={styles.seperator_wrapper}>
                    <div
                      className={styles.remaining_list}
                      onClick={e => {
                        this.setState({ expand: !this.state.expand });
                      }}
                    >
                      {email_list.length - 3}
                    </div>
                    <div className={styles.lines_seperator}>
                      <div className={styles.seperator} />
                      <div className={styles.seperator} />
                      <div className={styles.seperator} />
                    </div>
                  </div>
                </>
              )}
            </BoundaryError>
          );
        })}
        <div
          id="email_next_ref"
          ref={this.nextEmailRef}
          className={styles.next_ref}
        >
          {loadingNext && <Spinner size={24} />}
        </div>
        <BoundaryError>
          {(detailViewItem.status || '').toUpperCase() !== 'COMPLETED' &&
            view !== 'supervisor' && (
              <ComposeEmail
                task={detailViewItem}
                showEmailEditor={showEmailEditor}
              />
            )}
        </BoundaryError>
      </div>
    );
  }

  scrollHandler = event => {
    const { loadingPrev, moreOld, loadingNext } = this.state;
    const dH = this.scrolledTo - event.currentTarget.scrollTop;
    this.scrolledTo = event.currentTarget.scrollTop;
    this.setState({ prevHeight: event.currentTarget.scrollHeight });

    if (
      dH > 0 &&
      moreOld &&
      !loadingPrev &&
      event.currentTarget.scrollTop <= 20
    ) {
      this.getPrevEmail();
      event.currentTarget.scrollTop =
        this.nextEmailRef.current.parentElement.scrollHeight -
        this.state.prevHeight;
    }
    if (
      dH < 0 &&
      !loadingNext &&
      event.currentTarget.scrollTop +
        this.nextEmailRef.current.parentElement.offsetHeight +
        200 >=
        this.nextEmailRef.current.offsetTop
    ) {
      this.getNextEmail();
    }
  };

  getNextEmail = async () => {
    const { loadingNext } = this.state;
    const {
      detailViewItem,
      nextSince,
      fetchListByTaskId,
      limit,
      pushEmail,
    } = this.props;
    if (loadingNext) {
      return;
    }
    this.setState({ loadingNext: true });

    try {
      const list = await fetchListByTaskId({
        task_id: detailViewItem.id,
        start_time: nextSince,
        limit,
        type: detailViewItem.type,
      });

      if (list.length > 0) {
        pushEmail(detailViewItem.id, list);
        !detailViewItem.email_list && this.setState({ empty_list: true });
      }
      !list.length &&
        !detailViewItem.email_list &&
        this.setState({ empty_list: true });
      this.setState({ loadingNext: false });
    } catch (err) {
      console.error(err);
      this.setState({ loadingNext: false });
    }
  };

  getPrevEmail = async () => {
    const { moreOld, loadingPrev } = this.state;
    const {
      detailViewItem,
      prevFrom,
      fetchListByTaskId,
      limit,
      unshiftEmail,
    } = this.props;
    if (!moreOld || loadingPrev) {
      return;
    }
    this.setState({ loadingPrev: true });

    try {
      const list = await fetchListByTaskId({
        task_id: detailViewItem.id,
        end_time: prevFrom,
        limit,
        type: detailViewItem.type,
      });
      list.length > 0 && unshiftEmail(detailViewItem.id, list);
      this.setState({
        loadingPrev: false,
        moreOld: list.length === limit,
      });
    } catch (err) {
      console.error(err);
      this.setState({ loadingPrev: false });
    }
  };
}

const mapStateToProps = (state, ownprops) => {
  const { emailState } = state;
  const { detailViewItem, view } = ownprops;

  const email_list =
    view === 'supervisor'
      ? ((detailViewItem.email_list && detailViewItem.email_list) || []).sort(
          (a, b) => a.created_at - b.created_at
        )
      : (emailState[detailViewItem.id] &&
          emailState[detailViewItem.id].email_list &&
          emailState[detailViewItem.id].email_list.length > 0 &&
          emailState[detailViewItem.id].email_list) ||
        ((detailViewItem.email_list && detailViewItem.email_list) || []).sort(
          (a, b) => a.created_at - b.created_at
        );

  const nextSince = email_list.length
    ? email_list[email_list.length - 1].created_at + 1
    : detailViewItem.email.last_email_at || detailViewItem.email.start_at;
  const prevFrom = email_list.length
    ? email_list[0].created_at - 1
    : (detailViewItem.email.last_email_at || detailViewItem.email.start_at) - 1;
  const limit = ownprops.limit || 10;
  const showEmailEditor = (emailState[detailViewItem.id] || {}).showEmailEditor;
  return {
    limit,
    email_list,
    nextSince,
    prevFrom,
    showEmailEditor,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    pushEmail: (task_id, email_list) => {
      dispatch(pushEmailList(task_id, email_list));
    },
    unshiftEmail: (task_id, email_list) => {
      dispatch(unshiftEmailList(task_id, email_list));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailList);
