import store from '../store';
import envVar from '../../assets/NODE_ENV.json';
import { toJSONObj, getOktaHeader, logout } from './index.js';
import * as helpers from './helpers';
import { updateUserStatus, updateTelephonyStatus } from '../store/action/user';
import { broadcastMessage } from '../store/action/broadcast';
import {
  offerCreated,
  offerUpdated,
  offerCancelled,
  taskAssigned,
  initializeState,
  taskCompleted,
  socketDisconnected,
} from './websocket_helpers';
import { callEvent } from './ws_events/call.events';
import { smsEvent } from './ws_events/sms.events';
import { emailEvent } from './ws_events/email.events';
import { recordingEvent } from './ws_events/recordings.events';
import { userLogout } from '../services';
import { logoutReason } from '../utils';
import { logger } from '../services/server_side_logging.js';
const { getState, dispatch } = store;

const stateMap = ['Disconnect', 'Connected', 'Disconnected', 'Disconnected'];
const statusMap = ['CONNECTING', 'OPEN', 'CLOSING', 'CLOSED'];
const taskHandleMap = {
  offer: {
    created: offerCreated,
    update: offerUpdated,
    cancelled: offerCancelled,
    timeout: offerCancelled,
    declined: offerCancelled,
  },
  task: {
    assigned: taskAssigned,
    completed: taskCompleted,
  },
  state: initializeState,
  call: callEvent,
  sms: {
    received: smsEvent,
    sent: smsEvent,
    delivered: smsEvent,
    invalid: smsEvent,
    suppressed: smsEvent,
    withheld: smsEvent,
    undelivered: smsEvent,
  },
  email: {
    received: emailEvent,
  },
  recordings: recordingEvent,
  service: {
    disconnected: socketDisconnected,
  },
};

const logControllerWSMessage = message => {
  if (!getState().loggingState.logControllerWSMessage) {
    return;
  }

  logger.info('controller ws message', {
    userId: getState().userState.id,
    message,
  });
};

const handleTask = (type, action) => {
  if (!(type in taskHandleMap)) {
    return message => {
      console.warn('Unhandled Type:', type, message);
    };
  }
  if (type === 'state' || type === 'recordings' || type === 'call')
    return taskHandleMap[type];

  if (!(action in taskHandleMap[type])) {
    return message => {
      console.warn('Unhandled Action:', action, message);
    };
  }

  return taskHandleMap[type][action];
};

export const closeWS = () => {
  const state = getState();

  const { ws, interval, timeout } = state.connectionState;
  clearTimeout(timeout);
  clearInterval(interval);

  dispatch({
    type: 'UPDATE_CONNECTION',
    connectionState: {
      ...state.connectionState,
      intent: false,
      timeout: null,
      interval: null,
      ws: null,
    },
  });
  if (ws) ws.close();

  return ws;
};

const unusedCallEvents = [
  'participant-mute',
  'participant-unmute',
  'participant-hold',
  'participant-unhold',
];

export const createWS = () => {
  const { connectionState, environmentState } = getState();

  if (
    !connectionState.sockets.some(socket => {
      return socket.readyState === 1 || socket.readyState === 0;
    })
  ) {
    const { attempts = 0 } = connectionState;
    let timestamp = Number(new Date());

    let storage = localStorage.getItem('okta-token-storage');
    if (
      (!storage || (storage && !JSON.parse(storage).accessToken)) &&
      attempts > 0
    ) {
      let keys = Object.keys(localStorage).filter(key => {
        return key.indexOf('okta') > -1;
      });

      keys.forEach(key => {
        localStorage.removeItem(key);
      });

      alert('Oops, your session expired, please login again');

      // window.location.href = window.location.origin;

      return null;
    }

    let ws = new WebSocket(
      `${environmentState.socketURL}/ws?token=${
        JSON.parse(localStorage.getItem('okta-token-storage')).accessToken
          .accessToken
      }&timestamp=${timestamp}`
    );
    connectionState.sockets.push(ws);

    dispatch({
      type: 'UPDATE_CONNECTION',
      connectionState: {
        ...connectionState,
        intent: true,
        attempts: attempts + 1,
      },
    });

    ws.onmessage = async e => {
      let message = toJSONObj(e.data);
      let state = getState();

      if (message) {
        let { method } = message;
        if (!method) {
          console.error("Unknown 'method' in message", message);
          return;
        }

        if (method === 'PONG') {
          ws.misses = 0;
          console.log(method);
          return false;
        }

        logControllerWSMessage(message);

        console.log(message);
        method = method.toLowerCase();
        const { taskState, callState } = state;
        const { tasks } = taskState;
        let status =
          (message.data.status && message.data.status.toLowerCase()) ||
          message.data.event_type ||
          (message.data.action && message.data.action.toLowerCase());

        message.data.class_type = method.toUpperCase();

        switch (method.toLowerCase()) {
          case 'offer': {
            let getOfferfromStorage = JSON.parse(
              sessionStorage.getItem(message.data.task_id)
            );

            let addOfferToStorage = {
              status: message.data.status,
              created_at: message.data.created_at,
            };

            sessionStorage.setItem(
              [message.data.task_id],
              JSON.stringify({ ...getOfferfromStorage, ...addOfferToStorage })
            );
            break;
          }
          case 'state': {
            const tasks = await helpers.completeTaskData(
              (message.data || {}).tasks
            );
            message.data = { ...(message.data || {}), tasks };
            break;
          }
          case 'call': {
            if (
              message.data &&
              message.data.call_id &&
              message.data.event_type
            ) {
              taskState.events[message.data.call_id] = taskState.events[
                message.data.call_id
              ] || { events: [] };

              if (unusedCallEvents.indexOf(message.data.event_type) < 0) {
                if (message.data.task_id) {
                  if (!taskState.events[message.data.call_id].task_id) {
                    taskState.events[message.data.call_id].task_id =
                      message.data.task_id;
                    taskState.events[
                      message.data.call_id
                    ].task = helpers.getTaskByCallInfo(message.data, callState);
                  }
                }

                taskState.events[message.data.call_id].events.push(
                  message.data
                );
              }

              dispatch({
                type: 'UPDATE_TASKS',
                taskState: {
                  ...taskState,
                },
              });
            }
            break;
          }
          case 'user-updated': {
            dispatch({
              type: 'UPDATE_USER',
              userState: message.data,
            });
            break;
          }
          case 'user-status': {
            const { data = {} } = message.data;
            const { supervisor, user } = data;
            const { status, available, telephony_status } = user;

            dispatch(updateUserStatus({ available, status, telephony_status }));

            if ((supervisor || '').toLowerCase() !== 'system')
              try {
                const statusNotification = new window.Notification(
                  'change in status',
                  {
                    body: `${supervisor} changed your status to ${status}`,
                  }
                );
              } catch (e) {
                console.log('Error on notification = ', e);
              }

            break;
          }
          case 'user-telephony-status': {
            const { data = {} } = message.data;
            const { user } = data;
            const { telephony_status } = user;
            dispatch(updateTelephonyStatus({ telephony_status }));
            break;
          }
          case 'message-center-msg': {
            const { data = {} } = message;
            const { text = '', sender = '', id = '', created_at = 0 } = data;
            dispatch(broadcastMessage({ id, text, sender, created_at }));
          }
          default: {
            console.log('message: ', message);
          }
        }

        handleTask(method, status)(message.data, helpers, store);

        localStorage.setItem(
          `${new Date().toDateString()}`,
          JSON.stringify({
            ...JSON.parse(localStorage.getItem(new Date().toDateString())),
            [new Date().toTimeString()]: message,
          })
        );

        return;
      }
    };

    ws.onopen = event => {
      ws.misses = 0;

      let interval = setInterval(() => {
        try {
          ws.send(JSON.stringify({ method: 'PING' }));
        } catch (e) {
          console.log('Ping Error', e);
          if (!ws) {
            clearInterval(interval);
          }
          ws.misses++;

          if (ws.misses >= 3) {
            console.warn('Closing Connection. Reason: ', e.message);

            try {
              closeWS();
            } catch (e2) {
              //
            }
          }
        }
      }, 3000);

      dispatch({
        type: 'UPDATE_CONNECTION',
        connectionState: {
          connection: stateMap[ws.readyState],
          ws,
          interval,
          intent: true,
          attempts: 0,
        },
      });
    };

    ws.onclose = event => {
      console.log('Socket Close', event);

      let state = getState();

      let { intent, interval, attempts, timeout } = state.connectionState;
      ws = null;
      clearInterval(interval);

      if (intent) {
        // intent to connect
        if (attempts % 10 === 0 && attempts > 0) {
          // 50 fails;

          if (
            confirm(
              'Connection attempts failed 50 times, would you like to keep trying? Otherwise you will be logged out'
            )
          ) {
            // want to connect after 50 fails
            let timeout = setTimeout(() => {
              createWS();
            }, Math.random() * 2000 + 1000);
            dispatch({
              type: 'UPDATE_CONNECTION',
              connectionState: {
                ...state.connectionState,
                connection: 'Disconnected',
                ws: null,
                interval: null,
                intent: true,
                attempts: 0,
                timeout,
              },
            });
          } else {
            // disconnect after 50 fails
            userLogout(logoutReason.SOCKET_CLOSED);
            closeWS();
            sessionStorage.clear();
            localStorage.removeItem('env');
            localStorage.removeItem('enable-achieve-okta');
            window.authService.logout();
          }
        } else {
          // less than 50 fails
          let timeout = setTimeout(() => {
            createWS();
          }, Math.random() * 2000 + 1000);
          dispatch({
            type: 'UPDATE_CONNECTION',
            connectionState: {
              ...state.connectionState,
              connection: 'Disconnected',
              ws: null,
              interval: null,
              attempts: attempts + 1,
              timeout,
            },
          });
        }
      } else {
        // closed by intention
        clearTimeout(timeout);
        dispatch({
          type: 'UPDATE_CONNECTION',
          connectionState: {
            ...state.connectionState,
            connection: 'Disconnected',
            ws: null,
            interval: null,
            intent: false,
            timeout: null,
          },
        });
      }
    };

    ws.onerror = event => {
      console.error(
        `Socket Error Occured.
      Status: ${statusMap[ws.readyState]}
      Url: ${ws.url}`
      );
      console.error('Error: ', event);
    };

    return ws;
  }
};

if (envVar.NODE_ENV === 'development') {
  window.createWS = createWS;
  window.closeWS = closeWS;
}
