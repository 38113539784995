import React, { Component } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { AddCircleOutlined as AddIcon } from '@material-ui/icons';

import styles from './directory.scss';
import store from '../../store';
const { dispatch } = store;
class GroupRow extends Component {
  getCounters = async () => {
    try {
      const [available, total] = await Promise.all([
        this.getAvailableGroupUsers(),
        this.getTotalGroupUsers(),
      ]);
      this.setState({
        available: available ? available.length || 0 : 0,
        total: total ? total.length || 0 : 0,
      });
    } catch (e) {
      console.error(e);
    }
  };
  componentDidMount() {
    this.getCounters();
    this.interval = setInterval(this.getCounters, 5000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  constructor(props) {
    super(props);
    this.state = { calling: false };
    this.getAvailableGroupUsers = this.getAvailableGroupUsers.bind(this);
    this.getTotalGroupUsers = this.getTotalGroupUsers.bind(this);
    this.call = this.call.bind(this);
    this.render = this.render.bind(this);
    this.interval = null;
  }
  render = () => {
    const { group, task, helpers, directoryState, callState } = this.props;
    const { available, total, calling } = this.state;
    const { mergeClasses } = helpers;

    const callTask = callState.find(t => t.id === task.id);
    const pendingGroups =
      (callTask &&
        callTask.call_info &&
        callTask.call_info.participants &&
        callTask.call_info.participants.length &&
        callTask.call_info.participants.filter(
          p =>
            (p.status || '').toLowerCase() === 'pending' &&
            (p.type || '').toLowerCase() === 'internal'
        )) ||
      [];

    const disableStyle =
      pendingGroups.find(p => p.metadata && p.metadata.label === group.label) &&
      styles.disable;

    return (
      <TableRow hover classes={{ root: styles.table_row }}>
        <TableCell
          align="left"
          classes={{
            root: mergeClasses(styles.cell, styles.group, disableStyle),
            body: mergeClasses(styles.cell_body),
          }}
          onClick={(e, next) => {
            console.log('CallId', directoryState.callId);
            console.log(group.group);
          }}
        >
          <AddIcon
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();
              if (!this.state.calling) {
                this.call();
              }
            }}
            classes={{
              root: mergeClasses(
                styles.add_icon_group,
                styles.online,
                disableStyle
              ),
            }}
            disabled={calling || available === 0}
          />
          {group.label}
        </TableCell>
        <TableCell
          align="center"
          classes={{
            root: mergeClasses(styles.cell, styles.group),
            body: mergeClasses(styles.cell_body),
          }}
        >
          {group.type === 'GROUP' ? available : '-'}
        </TableCell>
        <TableCell
          align="center"
          classes={{
            root: mergeClasses(styles.cell, styles.group),
            body: mergeClasses(styles.cell_body),
          }}
        >
          {group.type === 'GROUP' ? total : '-'}
        </TableCell>
      </TableRow>
    );
  };
  getAvailableGroupUsers = async () => {
    const { group, services } = this.props;
    const {
      integration,
      user_filter,
      type,
      capacity,
      priority,
      user_filters = [],
    } = group;
    if (type === 'EXTERNAL') {
      return '';
    }
    if (user_filters.length === 0) {
      user_filters.push(user_filter);
    }
    const users_tiers = await Promise.all(
      user_filters.map(user_filter => {
        let args = {
          ...user_filter,
          integration,
          available: true,
          online: true,
          limit: 100,
        };
        if (priority > 0) {
          args.max_priority = priority;
        }
        if (capacity >= 0) {
          args.free_capacity = capacity;
        }
        return services.filterUsers(args);
      })
    );
    const users = users_tiers.reduce((acc, cur) => {
      Object.values(cur).forEach(v => {
        acc[v.id] = v;
      });
      return acc;
    });
    return Object.values(users || {});
  };
  getTotalGroupUsers = async () => {
    const { group, services } = this.props;
    const { integration, user_filter, user_filters = [], type } = group;
    if (type === 'EXTERNAL') {
      return '';
    }
    if (user_filters.length === 0) {
      user_filters.push(user_filter);
    }
    const users_tiers = await Promise.all(
      user_filters.map(user_filter => {
        return services.filterUsers({
          ...user_filter,
          integration,
          available: false,
          online: true,
          limit: 100,
        });
      })
    );
    const users = users_tiers.reduce((acc, cur) => {
      Object.values(cur).forEach(v => {
        acc[v.id] = v;
      });
      return acc;
    });
    return Object.values(users || {});
  };

  call = async () => {
    const { services, directoryState, group, taskState, task } = this.props;

    this.setState({ calling: true });
    try {
      let res;
      if (group.type !== 'GROUP') {
        res = await services.addToCall(directoryState.callId, {
          number: group.number,
          metadata: {
            label: group.label,
          },
        });
      } else {
        const { user_filter = {}, user_filters = [] } = group;
        if (user_filters.length === 0) {
          user_filters.push(user_filter);
        }
        res = await services.warmTransfer(directoryState.callId, {
          integration: group.integration,
          user_filters: user_filters,
          distribution_type: 'ROUND_ROBIN',
          metadata: {
            label: group.label,
          },
        });

        let cachedTask = JSON.parse(localStorage.getItem(task.id) || '{}');
        let warmTransferParticipants =
          cachedTask.warmTransferParticipants || [];
        warmTransferParticipants.unshift({
          group: group.label,
          initiated_at: Date.now(),
        });
        cachedTask.warmTransferParticipants = warmTransferParticipants;
        localStorage.setItem(task.id, JSON.stringify(cachedTask));
      }
      dispatch({
        type: 'UPDATE_TASKS',
        taskState: {
          ...taskState,
          add: null,
        },
      });
      dispatch({
        type: 'UPDATE_DIRECTORY',
        directoryState: {
          ...directoryState,
          tab: 0,
          callId: null,
          display: false,
          searchTerm: null,
          addNumber: null,
          fetchAgentInterval: false,
        },
      });
    } catch {
      this.setState({ calling: false });
    }
  };
}

export default GroupRow;
