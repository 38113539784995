import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './FeedbackSnackbar.scss';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

const Alert = props => {
  const classes = useStyles();
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      {...props}
      classes={{
        filledSuccess: classes.filledSuccess,
        filledInfo: classes.filledInfo,
        filledWarning: classes.filledWarning,
        filledError: classes.filledError,
      }}
    />
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  filledSuccess: {
    backgroundColor: '#45ae57',
    color: theme.palette.getContrastText('#45ae57'),
  },
  filledInfo: {
    backgroundColor: '#184895',
    color: theme.palette.getContrastText('#184895'),
  },
  filledWarning: {
    backgroundColor: '#ff9404',
    color: theme.palette.getContrastText('#ff9404'),
  },
  filledError: {
    backgroundColor: '#e7474e',
    color: theme.palette.getContrastText('#e7474e'),
  },
}));

const FeedbackSnackbar = ({
  severity,
  children,
  updateFeedback,
  vertical = 'bottom',
  horizontal = 'left',
  autoHideDuration = 6000,
}) => {
  const [open, setOpen] = useState(true);
  let mounted = true;

  useEffect(() => {
    return () => {
      mounted = false;
    };
  }, []);

  const handleClose = (event, reason) => {
    switch (reason) {
      case 'clickaway':
        return null;
      case 'timeout':
        updateFeedback('');
        break;

      default:
        updateFeedback('');
        break;
    }

    mounted && setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical, horizontal }}
    >
      <Alert onClose={handleClose} severity={severity || 'info'}>
        {children}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = state => {
  return { errorState: state.errorState };
};

const mapDispatchToProps = dispatch => ({
  deleteErrorMsg: ({ error_type }) => {
    dispatch(deleteErrorMsg({ error_type }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackSnackbar);
