import * as services from '../../services';
import {
  updateSMSList,
  smsError,
  updateSMSReplyLabel,
  pushSMS,
  increaseSMSCount,
} from '../../store/action/sms';
import { updateTask } from '../../store/action/task';
import * as url from '../../../assets/task_sms_red.png';

const updateTaskState = async (message, helpers, store) => {
  const { task_id } = message;
  const { getState, dispatch } = store;
  const { taskState } = getState();
  let { tasks } = taskState;
  let updatedTask = await services.fetchUpdatedTaskById(task_id);

  tasks = tasks.map(item => {
    if (item.id === updatedTask.id) {
      return updatedTask;
    }

    return item;
  });
  taskState.tasks = tasks;

  dispatch({
    type: 'UPDATE_TASKS',
    taskState: {
      ...taskState,
    },
  });
};

export const smsEvent = (message, helpers, store) => {
  const {
    task_id,
    text,
    sms_id,
    business_id,
    direction,
    sender,
    recipient,
    event_type,
    created_at,
    metadata,
  } = message;
  console.log(`sms ${event_type}`, message);
  const { getState, dispatch } = store;
  const { smsState, taskState } = getState();

  const sms = {
    id: sms_id,
    business_id,
    direction,
    metadata,
    sender,
    recipient,
    text,
    status: event_type,
    created_at,
  };

  smsState[task_id] &&
  smsState[task_id].sms_list &&
  smsState[task_id].sms_list.find(
    sms => sms.id.toString() === sms_id.toString()
  )
    ? dispatch(updateSMSList(task_id, sms))
    : dispatch(pushSMS(task_id, sms));

  switch (event_type.toLowerCase()) {
    case 'received': {
      console.log('received');
      const { detailView, tasks } = taskState;
      if (task_id !== detailView) {
        dispatch(increaseSMSCount(task_id));
      }

      smsState[task_id] &&
        smsState[task_id].label &&
        dispatch(
          updateSMSReplyLabel({
            task_id,
            label: 'Write a Reply',
          })
        );

      try {
        const notification = new window.Notification('New SMS', {
          body: `${message.sender}: ${message.text}`,
          icon: url,
        });
      } catch (e) {
        console.log('Error on sms notification = ', e);
      }

      tasks.map(task => {
        task.id === task_id && (task.updated_at = created_at);
      });

      dispatch(updateTask(taskState));
      break;
    }
    case 'sent': {
      console.log('sent');
    }
    case 'delivered': {
      console.log('delievered');
      const { tasks } = taskState;
      tasks.map(task => {
        task.id === task_id &&
          smsState[task_id] &&
          !task.sms_list &&
          (task.sms_list = smsState[task_id].sms_list);
      });
      dispatch(updateTask(taskState));
      break;
    }
    case 'invalid': {
      dispatch(
        smsError({
          task_id,
          error: 'INVALID SMS TEXT',
          sms_id,
          status: event_type.toLowerCase(),
        })
      );
      break;
    }
    case 'undelivered': {
      dispatch(
        smsError({
          task_id,
          error: 'SMS UNDELIVERED ',
          sms_id,
          status: event_type.toLowerCase(),
        })
      );
      break;
    }
    case 'suppressed': {
      dispatch(
        updateSMSReplyLabel({
          task_id,
          label: 'SMS SUPPRESSED, NOT DELIVERED',
        })
      );
      dispatch(
        smsError({
          task_id,
          error: 'SMS SUPPRESSED, NOT DELIVERED',
          sms_id,
          status: event_type.toLowerCase(),
        })
      );
      break;
    }
    case 'withheld': {
      dispatch(
        updateSMSReplyLabel({
          task_id,
          label: 'SMS WITHHELD',
        })
      );
      dispatch(
        smsError({
          task_id,
          error: 'SMS WITHHELD, NOT DELIVERED',
          sms_id,
          status: event_type.toLowerCase(),
        })
      );
      break;
    }

    default:
      updateTaskState(message, helpers, store);
      break;
  }
};
