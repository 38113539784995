import React, { useEffect, useState } from 'react';
import { connect, useStore } from 'react-redux';

import styles from './Keywords.scss';
import Textbox from '../../reusable_components/Textbox';
import { deleteScannerById, updateScannerById } from '../../../services';
import {
  updateKeywordById,
  deleteKeywordById,
} from '../../../store/action/scanner';

const Keyword = ({
  scanner,
  updateKeywordById,
  user_id,
  teams,
  updateFeedback,
  integration,
  deleteKeywordById,
  selectedTeam,
}) => {
  const [edit, setEdit] = useState(true);
  const [keyword, setKeyword] = useState(scanner.keyword);

  return (
    <div
      id="keyword_edit_wrapper"
      className={styles.edit_wrapper}
      key={scanner.id}
    >
      <Textbox
        value={keyword || scanner.keyword}
        disabled={edit ? true : false}
        multiline={true}
        key={`${scanner.id}_textbox`}
        handleChange={value => {
          setKeyword(value);
        }}
      />
      {edit ? (
        <div
          id="edit"
          className={styles.add_button}
          onClick={async e => {
            e.preventDefault();
            setEdit(!edit);
          }}
        >
          Edit
        </div>
      ) : (
        <>
          <div
            className={styles.save_button}
            onClick={async e => {
              e.preventDefault();

              if (!selectedTeam.length && teams.length > 1) {
                updateFeedback('Select team to update keyword', 'error');
              } else {
                keyword === scanner.keyword
                  ? updateFeedback('No change in keyword to update', 'info')
                  : updateScannerById({
                      scanner_id: scanner.id,
                      id: scanner.id,
                      team: selectedTeam.length ? selectedTeam : teams[0],
                      keyword,
                      integration,
                      user_id,
                    })
                      .then(res => res.json())
                      .then(data => {
                        updateKeywordById({ id: data.id, scanner: data });
                      })
                      .catch(err => updateFeedback(err, 'error'));

                setEdit(!edit);
              }
            }}
          >
            Save
          </div>
          <div
            className={styles.delete_button}
            onClick={async e => {
              e.preventDefault();
              deleteScannerById(scanner.id)
                .catch(err => console.error(err))
                .finally(() => {
                  deleteKeywordById({ id: scanner.id });
                });
              setEdit(!edit);
            }}
          >
            Delete
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { scannerState, userState, teamState } = state;
  const { keywords = [], newKeyword = '' } = scannerState;
  const { id, team, teams, integration } = userState;

  return {
    keywords,
    newKeyword,
    user_id: id,
    teams: teams.length ? teams : team ? [team] : [],
    newKeyword,
    integration,
    selectedTeam: teamState.selectedTeam || '',
  };
};

const mapDispatchToProps = dispatch => {
  return {
    deleteKeywordById: ({ id }) => dispatch(deleteKeywordById({ id })),
    updateKeywordById: ({ id, scanner }) =>
      dispatch(updateKeywordById({ id, scanner })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Keyword);
