import {
  updateLogControllerWSMessage,
  updateLogGenesysWSMessage,
  updateSendGenesysWSMessage,
} from '../../store/action/logging';

import store from '../../store';
const { getState, dispatch } = store;

export const updateLoggingFlags = flags => {
  const loggingState = getState().loggingState;

  if (
    flags.enableLoggingControllerWsMessage &&
    loggingState.logControllerWSMessage !==
      flags.enableLoggingControllerWsMessage
  ) {
    dispatch(
      updateLogControllerWSMessage(flags.enableLoggingControllerWsMessage)
    );
  }

  if (
    flags.enableLoggingGenesysWsMessage &&
    loggingState.logGenesysWSMessage !== flags.enableLoggingGenesysWsMessage
  ) {
    dispatch(updateLogGenesysWSMessage(flags.enableLoggingGenesysWsMessage));
  }

  if (
    flags.enableSendGenesysEventToController !== undefined && // the flag may give 0
    loggingState.sendGenesysWSMessageInterval !==
      flags.enableSendGenesysEventToController
  ) {
    dispatch(
      updateSendGenesysWSMessage(flags.enableSendGenesysEventToController)
    );
  }
};
