import React, { useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
import { connect } from 'react-redux';
import { timeMap } from './Options';
import store from '../../store';
const { dispatch } = store;

const useStyles = makeStyles(theme => ({
  root: {
    width: 90 + theme.spacing(3) * 2,
  },
  margin: {
    height: theme.spacing(1),
  },
}));

const TimeSlider = withStyles({
  root: {
    color: '#424242',
    height: 3,
  },
  thumb: {
    height: 30,
    width: 30,
    fontSize: 10,
    backgroundColor: '#000',
    color: '#fff',
    border: '1px solid #757575',
    marginTop: -12,
    marginLeft: -13,
    '&:focus,&:hover,&$active': {
      boxShadow: '#ccc 0px 2px 3px 1px',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
    color: '#3c6dbd',
  },
  track: {
    height: 30,
    color: '#757575',
    marginTop: -12,
  },
  rail: {
    height: 2,
    borderRadius: 10,
    color: '#fff',
  },
})(Slider);

const CustomThumb = props => {
  const { 'aria-valuenow': valueNow } = props;

  const display = timeMap.find(time => {
    return time.value === valueNow;
  });

  return <span {...props}>{(display || {}).label} </span>;
};

const Time = ({ agentState, user }) => {
  const classes = useStyles();
  const [value, setValue] = useState([5, 19]);

  const handleChange = (e, newvalue) => {
    setValue(newvalue);
    const found = agentState.archives.filter(fil => {
      const filterTime = new Date(fil.created_at);
      const selectedTime = filterTime.getHours() + filterTime.getMinutes() / 60;
      return selectedTime >= value[0] && selectedTime <= value[1];
    });
    dispatch({
      type: 'UPDATE_AGENT',
      agentState: {
        ...agentState,
        filter: {
          found: found,
          agent: user,
        },
      },
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.margin} />
      <TimeSlider
        onChange={handleChange}
        min={0}
        max={23.98}
        defaultValue={[5, 19]}
        ThumbComponent={CustomThumb}
      />
    </div>
  );
};

const mapStateToProps = state => {
  return { agentState: state.agentState };
};

export default connect(mapStateToProps)(Time);
