import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { LightTooltip } from '../../../reusable_components/LightTooltip';
import { StyledMenuItem } from '../../../reusable_components/StyledMenuItem';
import styles from './Sender.scss';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import AttachmentIcon from '@material-ui/icons/Attachment';
import { updateEmailReply } from '../../../../store/action/email';
import { forwardTask } from '../../../../store/action/directory';

export const Sender = ({
  sender,
  timestamp,
  attachments,
  showEmailEditor,
  task_id,
  subject,
  recipients,
  forwardTask,
  updateEmailReply,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const defaultType = 'to';

  const handleMenuClick = e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = e => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, ea) => {
    e.preventDefault();
    e.stopPropagation();
    console.log('selected ea: ', ea);
    switch (ea) {
      case 'Reassign Task':
        forwardTask({ id: task_id });
        break;

      case 'Reply':
        let emailTo = [];
        emailTo.push({ ...sender, type: defaultType });
        updateEmailReply({
          task_id,
          recipients: emailTo,
          subject,
        });
        break;

      case 'Reply All':
        console.log('reply all');

        updateEmailReply({ task_id, recipients, subject });
        break;

      default:
        break;
    }
    setAnchorEl(null);
  };

  return (
    <>
      <div className={styles.sender_wrapper}>
        <LightTooltip
          arrow
          placement="top"
          title={<div className={styles.email_tooltip}>{sender.email}</div>}
        >
          <div className={styles.sender}>{sender.name || sender.email}</div>
        </LightTooltip>
        <div className={styles.action_wrapper}>
          {attachments && <AttachmentIcon />}
          <div className={styles.timestamp}>{timestamp}</div>
          <div className={styles.dropdown_wrapper}>
            {!showEmailEditor && (
              <MoreVertIcon
                onClick={handleMenuClick}
                className={styles.dropdown_icon}
              />
            )}
          </div>
          <Menu
            id="email-reply-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            {['Reply', 'Reply All', 'Reassign Task'].map(ea => {
              return (
                <StyledMenuItem
                  key={ea}
                  onClick={e => handleMenuItemClick(e, ea)}
                >
                  {ea}
                </StyledMenuItem>
              );
            })}
          </Menu>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { task_id, email_id, sender } = ownprops;
  const { emailState, taskState } = state;
  const { tasks } = taskState;

  const emailTask = emailState[task_id] || {};
  const agentEmailAddress = (
    ((tasks.find(task => task.id === task_id) || {}).email || {}).agent || {}
  ).address;
  const { showEmailEditor, email_list = [] } = emailTask;
  const email = email_list.find(e => e.id === email_id) || {};

  const subject = email
    ? (email.subject || '').toUpperCase().includes('RE:')
      ? email.subject
      : 'Re: '.concat(email.subject)
    : '';
  const { recipients = [] } = email;
  const allRecipientsButAgent = recipients.filter(
    r => r.email !== agentEmailAddress
  );

  let emailTo = [];
  const defaultType = 'to';

  emailTo.push({
    ...allRecipientsButAgent,
    ...(sender &&
      sender.email !== agentEmailAddress && {
        name: sender.name,
        email: sender.email,
        type: defaultType,
      }),
  });

  return {
    showEmailEditor,
    subject,
    recipients: emailTo,
  };
};

const mapDispatchToProps = dispatch => ({
  updateEmailReply: emailState => {
    dispatch(updateEmailReply(emailState));
  },
  forwardTask: id => {
    dispatch(forwardTask(id));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Sender);
