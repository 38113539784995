import { useState, default as React, useEffect, Fragment } from 'react';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
  InputBase,
} from '@material-ui/core';
import { Search as SearchIcon, Cancel as CancelIcon } from '@material-ui/icons';

import { connect } from 'react-redux';
import { mergeClasses, Feedback, sortByKey } from '../../../utils';

import styles from './stats.scss';
import {
  filterUsers,
  getUserMetrics,
  getUserStatusMetrics,
} from '../../../services';
import { updateMetricsByUserID } from '../../../store/action/metrics';
import FeedbackSnackbar from '../../feedback/FeedbackSnackbar';
import Metric from './Metric';
import { makeStyles, fade } from '@material-ui/core/styles';
import User from './User';
import StatsHeader from './StatsHeader';
import Calendar from './Calendar';

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: '#184895',
    color: theme.palette.getContrastText('#184895'),
    width: '100px',
    alignSelf: 'flex-end',
    margin: '8px',
    '&:hover': {
      backgroundColor: '#113369',
      color: theme.palette.getContrastText('#113369'),
    },
  },
  root: {
    color: '#fff',
    overflow: 'hidden',
  },
  container: {
    maxHeight: '500px',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    margin: theme.spacing(2),
    width: 300,
    alignItems: 'center',
    justifyContent: 'center',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 1),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  searchBar: {
    display: ' flex',
    flexDirection: 'row',
  },
  cancelIcon: {
    marginRight: theme.spacing(2),
  },
}));

export const Stats = props => {
  const {
    integration,
    teams,
    selectedTeam,
    updateMetricsByUserID,
    refreshInterval,
  } = props;
  const classes = useStyles();

  const [users, setUsers] = useState([]);
  const [selectedUser, selectUser] = useState('');
  const [feedback, setFeedback] = useState({ msg: '', severity: '' });

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  useEffect(() => {
    let mounted = true;

    const handler = async () => {
      const users = [];
      const limit = 100;
      let offset = 0;
      let chunk = null;
      try {
        while (chunk == null || chunk.length === limit) {
          chunk = await filterUsers({
            integration,
            team: selectedTeam === '' ? teams : selectedTeam,
            limit,
            offset,
          });
          chunk = chunk || [];
          offset = offset + limit;

          users.push(...chunk);
        }
        if (mounted) {
          setUsers([...users]);
        }
      } catch (err) {
        console.error(err);
        updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
      }
    };

    handler();

    return () => {
      mounted = false;
    };
  }, [integration, teams, selectedTeam]);

  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = React.useState('asc');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageno, setPageno] = useState(0);
  const [search, setSearch] = useState('');

  const updateMetrics = async () => {
    const userMetrics = await getUserMetrics({
      from: {
        seconds: Math.floor(
          Date.parse(new Date(selectedDate).toDateString()) / 1000
        ),
      },
      user_id: selectedUser,
    })
      .then(res => res.json())
      .catch(err => {
        updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
      });

    const offers_presented = userMetrics.find(n => n.name === 'offers_total');
    const offers_accepted = userMetrics.find(
      n => n.name === 'offers_accepted_total'
    );
    const oat =
      ((offers_accepted.value || 0) / (offers_presented.value || 1)) * 100;
    userMetrics.push({ name: 'oat', value: oat });

    return sortByKey(userMetrics, 'name');
  };

  const updateStatusMetrics = async () => {
    const range = helpers.getMetricsRange({
      seconds: Math.floor(
        Date.parse(new Date(selectedDate).toDateString()) / 1000
      ),
    });
    return await getUserStatusMetrics({
      range,
      user_id: selectedUser,
    })
      .catch(err => {
        updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
      })
      .then(res => res.json());
  };

  useEffect(() => {
    let mounted = true;

    const interval = setInterval(async () => {
      if (selectedUser && mounted) {
        const selectedUserMetrics = await updateMetrics();
        const selectedUserStatusMetrics = await updateStatusMetrics();

        updateMetricsByUserID({
          id: selectedUser,
          metrics: selectedUserMetrics,
          statusMetrics: selectedUserStatusMetrics,
        });
      }
    }, refreshInterval);

    return () => {
      clearInterval(interval);
      mounted = false;
    };
  }, [selectedUser, selectedDate]);

  const handleDateChange = async date => {
    if (date !== '') {
      setSelectedDate(date);
      console.log(date);
    }
  };

  const usersToDisplay = array => {
    const regex = new RegExp(`${search}`, 'gi');
    const filtered = array.filter(u => {
      return (
        u.id.match(regex) ||
        u.username.match(regex) ||
        u.first_name.match(regex) ||
        u.last_name.match(regex)
      );
    });

    return filtered.slice(
      pageno * rowsPerPage,
      pageno * rowsPerPage + rowsPerPage
    );
  };

  const handleChangePage = (e, newPage) => {
    setPageno(newPage);
  };

  return (
    <div className={styles.stats_container}>
      <div className={styles.filters}>
        <Calendar
          handleDateChange={handleDateChange}
          selectedDate={selectedDate}
        />
        <div className={mergeClasses(classes.search, classes.searchBar)}>
          <div className={mergeClasses(classes.searchIcon)}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search Users…"
            classes={{
              root: classes.inputRoot,
              input: mergeClasses(classes.inputInput, styles.search_field),
            }}
            value={search || ''}
            onChange={e => {
              e.preventDefault();
              setSearch(e.target.value);
            }}
          />

          <CancelIcon
            className={mergeClasses(classes.cancelIcon, styles.search_cancel)}
            onClick={e => {
              e.preventDefault();
              setSearch('');
            }}
          />
        </div>
      </div>
      <Grid container direction="column" className={mergeClasses(styles.stats)}>
        {feedback.msg.length > 0 && (
          <FeedbackSnackbar
            vertical={'bottom'}
            horizontal={'left'}
            children={feedback.msg}
            severity={feedback.severity}
            updateFeedback={updateFeedback}
          />
        )}
        {selectedUser ? (
          <div className={styles.user_metrics}>
            {selectedUser && (
              <Typography classes={{ root: styles.user }}>
                {selectedUser}
              </Typography>
            )}
            <div className={styles.metrics_container}>
              <Metric selectedUser={selectedUser} />
            </div>
            <Button
              className={classes.button}
              onClick={async e => {
                selectUser('');
              }}
            >
              Go back
            </Button>
          </div>
        ) : (
          <div className={styles.table_stats}>
            <TableContainer classes={{ root: classes.container }}>
              <Table stickyHeader className={mergeClasses(styles.table)}>
                <StatsHeader />
                <TableBody>
                  {(usersToDisplay(users) || []).map((u = {}, index) => (
                    <User
                      key={`${u.username}_${index}`}
                      agent={u}
                      selectUser={selectUser}
                      date={selectedDate}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              classes={{ root: classes.root }}
              component="div"
              count={users.length}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[]}
              page={pageno}
              onChangePage={handleChangePage}
            />
          </div>
        )}
      </Grid>
    </div>
  );
};

const mapStateToProps = state => {
  const { userState, teamState, integrationState } = state;
  const { integration, team, teams = [] } = userState;
  const { selectedTeam = '' } = teamState;

  return {
    integration,
    teams: teams && teams.length ? teams : team ? [team] : [],
    selectedTeam,
    refreshInterval: (integrationState.refreshInterval || 5) * 1000,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateMetricsByUserID: ({ id, metrics, statusMetrics }) => {
      dispatch(updateMetricsByUserID({ id, metrics, statusMetrics }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
