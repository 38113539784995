import React, { Component } from 'react';
import styles from './countdown.scss';
import { userLogout } from '../../services';
import { logoutReason } from '../../utils';
import { refreshClock } from '../../utils/autoLogoutClock';

class CountDown extends Component {
  constructor(props) {
    super(props);

    const { userState, activityState } = this.props;
    let inactivity = userState.inactivity || {
      inactivity_timeout_countdown_seconds: 10,
    };

    this.state = {
      intervalId: null,
      ...inactivity,
      count: inactivity.inactivity_timeout_countdown_seconds,
      statusBeforeCountDown: activityState.statusBeforeCountDown,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.render = this.render.bind(this);
    this.logout = this.logout.bind(this);
    this.clickHandler = this.clickHandler.bind(this);
  }

  componentDidMount() {
    const { store } = this.props;
    const { dispatch, getState } = store;
    if (!this.state.intervalId) {
      let intervalId = setInterval(() => {
        if (this.state.count === 0) {
          clearInterval(this.state.intervalId);
          refreshClock();
          this.setState({
            intervalId: null,
            count: null,
          });

          const { activityState } = getState();
          this.logout();

          dispatch({
            type: 'UPDATE_ACTIVITY',
            activityState: {
              ...activityState,
              showCountDown: false,
              statusBeforeCountDown: null,
            },
          });
        } else {
          this.setState({
            count: this.state.count - 1,
            intervalId,
          });
        }
      }, 1000);
    }
  }

  async logout() {
    await userLogout(logoutReason.AUTO);
    localStorage.removeItem('env');
    localStorage.removeItem(`${new Date().toDateString()}`);
    localStorage.removeItem('enable-achieve-okta');
    this.props.authService.logout();
    sessionStorage.clear();
  }

  clickHandler(e) {
    clearInterval(this.state.intervalId);
    refreshClock();
    this.setState({
      ...this.state,
      intervalId: null,
    });
    const { dispatch, getState } = this.props.store;

    dispatch({
      type: 'UPDATE_ACTIVITY',
      activityState: {
        ...getState().activityState,
        showCountDown: false,
        statusBeforeCountDown: null,
      },
    });

    this.props.services
      .updateUserStatus(this.state.statusBeforeCountDown)
      .then(res => res.json())
      .then(data => {
        const { status, available } = data;
        dispatch({
          type: 'UPDATE_USER',
          userState: {
            ...getState().userState,
            status,
            available,
          },
        });
      });
  }

  render() {
    const { helpers, activityState } = this.props;
    const { mergeClasses } = helpers;
    return (
      <div className={mergeClasses(styles.countdown_container)}>
        <div className={mergeClasses(styles.countdown_header)}>
          Auto Logout Warning
        </div>
        <div className={mergeClasses(styles.countdown_body)}>
          <div className={mergeClasses(styles.countdown_text)}>
            Are you still there?
          </div>
          <div className={mergeClasses(styles.countdown_count)}>
            {this.state.count || 0}
          </div>
          <button
            className={mergeClasses(styles.countdown_button)}
            onClick={this.clickHandler}
          >
            OK
          </button>
        </div>
      </div>
    );
  }
}

export default CountDown;
