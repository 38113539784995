export const updateCallInfo = ({ call_info, task_id, call_id }) => {
  return {
    type: 'UPDATE_CALL_INFO',
    call_info,
    task_id,
    call_id,
  };
};

export const updateGenesysCallInfo = ({ participants, genesysCallId }) => {
  return {
    type: 'UPDATE_GENESYS_CALL_INFO',
    participants,
    genesysCallId,
  };
};

export const updateCallTask = task => {
  return {
    type: 'UPDATE_CALL_TASK',
    task,
  };
};

export const updateCallState = callState => {
  return {
    type: 'UPDATE_CALL_STATE',
    callState,
  };
};

export const archiveCallTask = task => {
  return {
    type: 'ARCHIVE_CALL_TASK',
    task,
  };
};
