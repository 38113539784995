import React from 'react';
import { connect } from 'react-redux';
import { updateErrorMsg } from '../../../store/action/error';

import Recording from './Recording';

const Recordings = props => {
  const { recordings, limit, updateErrorMsg, userTelephonyProvider } = props;
  if (recordings && recordings.length > limit) {
    updateErrorMsg({
      error_msg: `limit reached`,
      error_type: 'vm_error',
    });
  }

  // for twilio user show all recordings
  // for genesys user show recordings with voice_hub_ivr_id
  let filteredVMs = recordings;
  if (userTelephonyProvider === 'genesys') {
    filteredVMs = recordings.filter(vm => vm.voice_hub_ivr_id);
  }

  return (
    <>
      {filteredVMs &&
        filteredVMs.length > 0 &&
        filteredVMs.map((vm, vIndex) => {
          const recording = new Audio((vm || {}).url);
          return (
            <Recording
              {...props}
              recording_id={vm.id}
              name={vm.name}
              type={vm.type}
              url={vm.url}
              recording={recording}
              created_at={vm.created_at}
              vIndex={vIndex}
              key={vIndex}
            />
          );
        })}
    </>
  );
};

const mapStateToProps = state => {
  return {
    userTelephonyProvider: state.userState.telephonyProvider,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateErrorMsg: ({ error_msg, error_type }) => {
      dispatch(updateErrorMsg({ error_msg, error_type }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Recordings);
