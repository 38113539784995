import React from 'react';
import styles from './call.scss';

import { connect } from 'react-redux';
import { mergeClasses } from '../../utils';
import store from '../../store';

const { dispatch, getState } = store;

const Addbox = props => {
  const { taskState } = props;
  const active = true;
  const backButtonStyle = taskState.transfer ? '' : styles.hidden;
  return (
    <div
      className={mergeClasses(
        styles.add_box,
        styles[active ? 'active' : 'inactive']
      )}
    >
      <div className={mergeClasses(styles.add_box_control)}>
        <div
          className={mergeClasses(styles.addbox_back_button, backButtonStyle)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch({
              type: 'UPDATE_TASKS',
              taskState: {
                ...taskState,
                transfer: false,
              },
            });
          }}
        ></div>
        <div
          className={mergeClasses(styles.addbox_cancel_button)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            dispatch({
              type: 'UPDATE_TASKS',
              taskState: { ...taskState, add: null, transfer: false },
            });
          }}
        >
          CANCEL
        </div>
      </div>
      <div className={mergeClasses(styles.add_title)}>ADD A LINE</div>

      <div
        className={mergeClasses(styles.add_conf_button)}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        WC {0}/{0}
      </div>
      <div
        className={mergeClasses(styles.add_conf_button)}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        CS
      </div>
      <div
        className={mergeClasses(styles.add_conf_button)}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          dispatch({
            type: 'UPDATE_DIRECTORY',
            directoryState: {
              ...getState().directoryState,
              display: true,
              callId: props.call.id,
            },
          });
        }}
      >
        DIRECTORY
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return { taskState: state.taskState };
};

const mapDispatchToProps = dispatch => ({
  updateConnectionFunc: state => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(Addbox);
