import React, { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import styles from './ComposeEmail.scss';

import {
  Editor,
  EditorState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { updateEmailHtml } from '../../../store/action/email';

import { HEADER_TYPES, emailActions } from '../../../utils/mappings';

import { BoundaryError } from '../../reusable_components/boundaryerror';

import HeaderStyleDropdown from './editor/HeaderStyleDropdown';
import BlockStyleControls from './editor/BlockStyleControls';
import InlineStyleControls from './editor/InlineStyleControls';

import EmailInfo from './info';
import EmailAction from '../../wrapup/EmailAction';

class ComposeEmail extends Component {
  constructor(props) {
    super(props);
    this.editorRef = createRef();
    const draftEmailContent = (
      JSON.parse(localStorage.getItem(this.props.task.id)) || {}
    ).email;

    this.state = {
      editorState: draftEmailContent
        ? EditorState.createWithContent(convertFromRaw(draftEmailContent))
        : EditorState.createEmpty(),
    };

    this.focus = () => this.refs.editor.focus();
    this.onChange = editorState => this._onChange(editorState);
    this.handleKeyCommand = (editorState, command) =>
      this._handleKeyCommand(editorState, command);
    this.toggleBlockType = type => this._toggleBlockType(type);
    this.toggleInlineStyle = style => this._toggleInlineStyle(style);
  }

  _onChange = editorState => {
    const { updateEmailHtml, task } = this.props;
    this.setState({
      editorState,
      editorContentHtml: stateToHTML(editorState.getCurrentContent()),
    });

    const draftEmailContent = JSON.parse(localStorage.getItem(task.id)) || {};
    draftEmailContent.email = convertToRaw(editorState.getCurrentContent());
    localStorage.setItem(task.id, JSON.stringify(draftEmailContent));

    updateEmailHtml({ task_id: task.id, html: this.state.editorContentHtml });
  };

  _handleKeyCommand(editorState, command) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      return true;
    }
    return false;
  }

  _toggleBlockType(blockType) {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  }

  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle)
    );
  }

  componentDidMount() {
    this.editorRef.current.scrollIntoView(false);
  }

  componentDidUpdate(prevprops) {
    const { showEmailEditor } = prevprops;
    if (showEmailEditor !== this.props.showEmailEditor) {
      this.editorRef.current.scrollIntoView(false);
      const draftEmailContent = (
        JSON.parse(localStorage.getItem(this.props.task.id)) || {}
      ).email;

      !draftEmailContent &&
        this.setState({
          editorState: EditorState.createEmpty(),
        });
    }

    if (prevprops.task.id !== this.props.task.id) {
      const draftEmailContent = (
        JSON.parse(localStorage.getItem(this.props.task.id)) || {}
      ).email;

      this.setState({
        editorState: draftEmailContent
          ? EditorState.createWithContent(convertFromRaw(draftEmailContent))
          : EditorState.createEmpty(),
      });
    }
  }

  render() {
    const { task, showEmailEditor, showReplyAll, flags } = this.props;

    const { editorState } = this.state;

    const blockType =
      editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection()) &&
      editorState
        .getCurrentContent()
        .getBlockForKey(editorState.getSelection())
        .getType();

    return (
      <BoundaryError>
        {showEmailEditor && (
          <>
            <EmailInfo task_id={task.id} template={task.template} />
            <div className={styles.editor_wrapper}>
              <BoundaryError>
                <div className={styles.RichEditor_editor} onClick={this.focus}>
                  <Editor
                    blockStyleFn={myBlockStyleFn}
                    customStyleMap={styleMap}
                    editorState={editorState}
                    handleKeyCommand={this.handleKeyCommand}
                    onChange={this.onChange}
                    ref="editor"
                    spellCheck={true}
                    placeholder={'Reply...'}
                  />
                  {/* <pre>{this.state.editorContentHtml}</pre> */}
                </div>
              </BoundaryError>
            </div>
          </>
        )}
        <BoundaryError>
          <div className={styles.control_wrapper}>
            {showEmailEditor && (
              <>
                <HeaderStyleDropdown
                  headerOptions={HEADER_TYPES}
                  active={blockType}
                  onToggle={this.toggleBlockType}
                />
                <BlockStyleControls
                  editorState={editorState}
                  onToggle={this.toggleBlockType}
                />
                <InlineStyleControls
                  editorState={editorState}
                  onToggle={this.toggleInlineStyle}
                />
              </>
            )}
            <BoundaryError>
              <div className={styles.action_wrapper}>
                {flags.emailActions &&
                  emailActions.map((ea, eaIndex) => {
                    const { label, style, action, updateState } = ea;
                    let displayAction =
                      (ea.canClickOnReply
                        ? !showEmailEditor
                        : showEmailEditor) &&
                      !(ea.canReplyAll && !(showReplyAll > 1));

                    ea.newEmail &&
                      !showEmailEditor &&
                      (task?.email_list?.length
                        ? (displayAction = false)
                        : (displayAction = true));

                    !task?.email_list?.length &&
                      ea?.label?.toLowerCase() === 'reply' &&
                      (displayAction = false);

                    return (
                      <Fragment key={`email_action_${eaIndex}`}>
                        {displayAction ? (
                          <EmailAction
                            key={eaIndex}
                            {...this.props}
                            task={task}
                            label={label}
                            style={style}
                            action={action}
                            updateState={updateState}
                          />
                        ) : null}
                      </Fragment>
                    );
                  })}
              </div>
            </BoundaryError>
          </div>
          <div ref={this.editorRef} id="editor_ref" />
        </BoundaryError>
      </BoundaryError>
    );
  }
}

const myBlockStyleFn = contentBlock => {
  switch (contentBlock.getType()) {
    case 'header-one':
      return styles.superFancyHeader;
    case 'header-two':
      return styles.superFancyBlockquote;
    case 'center':
      return styles.superFancyCenter;
    case 'right':
      return styles.superFancyRight;
    case 'left':
      return styles.superFancyLeft;
    case 'justify':
      return styles.superFancyJustify;
    case 'adjust-font':
      return styles.superFancyFontSize;
    case 'color-picker':
      return styles.superFancyColorPicker;
    default:
      return null;
  }
};

const styleMap = {
  colorPicker: {
    color: 'rgba(255, 0, 0, 1.0)',
  },
};

const mapStateToProps = (state, ownprops) => {
  const { task } = ownprops;
  const { emailState } = state;
  const emailTask = { ...(emailState[task.id] || {}) };
  const { email_list = [] } = emailTask;

  const latestEmail =
    email_list.length &&
    email_list.reduce((a, i) => {
      return a.created_at > i.created_at ? a : i;
    });
  const { recipients = [] } = latestEmail;
  const showReplyAll = recipients.length;

  return { showReplyAll };
};
const mapDispatchToProps = dispatch => ({
  updateEmailHtml: ({ task_id, html }) => {
    dispatch(updateEmailHtml({ task_id, html }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(ComposeEmail));
