export const updateTask = taskState => {
  return {
    type: 'UPDATE_TASKSTATE',
    taskState,
  };
};

export const setSelectedTask = id => {
  return {
    type: 'SET_SELECTED_TASK',
    id,
  };
};

export const archiveTask = task => {
  return {
    type: 'ARCHIVE_TASK',
    task,
  };
};

export const removeTask = id => {
  return {
    type: 'REMOVE_TASK',
    id,
  };
};

export const showTaskFromWeb = ({ id, filteredTask, tab, filterTaskType }) => {
  return {
    type: 'SHOW_TASK_FROM_WEB',
    id,
    filteredTask,
    tab,
    filterTaskType,
  };
};

export const removeTaskFromWeb = ({ tab, filterTaskType }) => {
  return {
    type: 'REMOVE_TASK_FROM_WEB',
    tab,
    filterTaskType,
  };
};

export const updateCallError = ({ callError }) => {
  return {
    type: 'UPDATE_CALL_ERROR',
    callError,
  };
};

export const offerPresented = ({ offer }) => {
  return {
    type: 'OFFER_PRESENTED',
    offer,
  };
};

export const updateOfferStatus = offer => {
  return {
    type: 'UPDATE_OFFER_STATUS',
    offer,
  };
};

export const cancelOffer = ({ offer }) => {
  return {
    type: 'OFFER_CANCELED',
    offer,
  };
};

export const updateExpand = expand => {
  return {
    type: 'UPDATE_CALL_DOCK_VIEW',
    expand,
  };
};

export const updateTaskStateSort = sort => {
  return {
    type: 'UPDATE_TASKS_SORT',
    sort,
  };
};

export const updateArchivedTasks = ({ tab, archives }) => {
  return {
    type: 'UPDATE_ARCHIVED_TASKS',
    tab,
    archives,
  };
};

export const updateTaskAndEvents = ({ tasks, events, currentCallTask }) => {
  return {
    type: 'UPDATE_TASKS_AND_EVENTS',
    tasks,
    events,
    currentCallTask,
  };
};

export const updateTasks = ({ tasks }) => {
  return {
    type: 'UPDATE_TASKS',
    tasks,
  };
};

export const updateTasksWithDetailView = ({ tasks, detailView }) => {
  return {
    type: 'UPDATE_TASKS_WITH_DETAILVIEW',
    tasks,
    detailView,
  };
};

export const updateArchivedTasksFromDtFilter = ({
  tab,
  archiveFilter,
  archives,
}) => {
  return {
    type: 'UPDATE_ARCHIVED_TASKS_FROM_DT_FILTER',
    tab,
    archiveFilter,
    archives,
  };
};
