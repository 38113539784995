export const updateVMRecordings = data => {
  return {
    type: 'UPDATE_VM_RECORDINGS',
    data,
  };
};

export const addNewVoiceMailRecording = recordingType => {
  return {
    type: 'ADD_NEW_VM',
    recordingType,
  };
};

export const deleteVMById = ({ recording_id }) => {
  return {
    type: 'DELETE_VM_BY_ID',
    recording_id,
  };
};

export const deleteVMByName = ({ name }) => {
  return {
    type: 'DELETE_VM_BY_NAME',
    name,
  };
};

export const updateVMLabel = ({ name, label }) => {
  return {
    type: 'UPDATE_VM_LABEL',
    label,
    name,
  };
};
