import React from 'react';
import { connect } from 'react-redux';
import styles from './Email.scss';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { mergeClasses } from '../../../utils/helpers';
import { deleteErrorMsg } from '../../../store/action/error';

const EmailBody = ({ children, errorState }) => {
  const highlightErrorEmail = errorState.email_error && styles.highlight;
  return (
    <div className={mergeClasses(styles.emailbody, highlightErrorEmail)}>
      {children}
      {errorState.email_error && (
        <div
          className={styles.error}
          onClick={e => {
            deleteErrorMsg({ error_type: 'email_error' });
          }}
        >
          <ErrorOutlineIcon className={styles.error_icon} />
          <div className={styles.error_msg}>Error {errorState.email_error}</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return { emailState: state.emailState, errorState: state.errorState };
};

const mapDispatchToProps = dispatch => ({
  deleteErrorMsg: ({ error_type }) => {
    dispatch(deleteErrorMsg({ error_type }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EmailBody);
