import React from 'react';
import {
  Typography,
  FormControlLabel,
  Checkbox,
  FormControl,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  formControl: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
  root: {
    color: '#e7474e',
  },
  checked: {
    color: '#e7474e !important',
  },
  item: {
    paddingRight: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
}));

const UserDetails = ({ capacities, active, max_priority }) => {
  const classes = useStyles();
  return (
    <FormControl>
      <Typography>Max Priority: {max_priority}</Typography>
      <Grid container>
        {Object.entries(capacities)?.map(([key, value], index) => {
          return (
            <Grid item classes={{ item: classes.item }} key={`${key}_${index}`}>
              <Typography key={index}>
                C{key}: {value}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <FormControlLabel
        className={classes.formControl}
        control={
          <Checkbox
            classes={{ root: classes.root, checked: classes.checked }}
            checked={active}
          />
        }
        label="Active"
      />
    </FormControl>
  );
};

export default UserDetails;
