import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { updateTeamSortByName } from '../../../store/action/team';

const useStyles = makeStyles(theme => ({
  form_root: {
    borderTop: '1px dotted #fff',
    marginTop: theme.spacing(2),
  },
  root: {
    color: '#e7474e',
  },
  checked: {
    color: '#e7474e !important',
  },
}));

const TeamSorter = ({ updateTeamSortByName }) => {
  const classes = useStyles();
  const sortList = [
    { name: 'username', label: 'Username' },
    { name: 'id', label: 'ID' },
    { name: 'first_name', label: 'Firstname' },
    { name: 'last_name', label: 'Lastname' },
    { name: 'team', label: 'Team' },
  ];

  const handleSortName = e => {
    updateTeamSortByName(sortList.find(sl => sl.name === e.target.value));
  };

  return (
    <RadioGroup
      row
      classes={{ root: classes.form_root }}
      defaultValue={'username'}
      onChange={handleSortName}
    >
      {sortList.map((sl, index) => (
        <FormControlLabel
          key={`${sl.name}_${index}`}
          value={sl.name}
          control={
            <Radio classes={{ root: classes.root, checked: classes.checked }} />
          }
          label={sl.label}
        />
      ))}
    </RadioGroup>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    updateTeamSortByName: name => dispatch(updateTeamSortByName(name)),
  };
};

export default connect(null, mapDispatchToProps)(TeamSorter);
