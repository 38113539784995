'use strict';

import { fetcher } from '.';
import { getOktaHeader } from '../utils';
import store from '../store';

import { updateErrorMsg } from '../store/action/error';
import { logger } from './server_side_logging';
import { updateGenesysConnection } from '../store/action/genesysConnection';

const { dispatch, getState } = store;

export const registorGenesysWS = async (integration, userId, accessToken) => {
  const resp = await fetch(
    `/api/registorGenesysWS?integration=${integration}&access_token=${accessToken}&user_id=${userId}`
  );
  return resp
    .json()
    .then(data => {
      if (data.status > 200) {
        console.log(
          `[genesys] registorGenesysWS failed, error: ${data.message}`
        );
        return { error: data.message };
      } else {
        return data;
      }
    })
    .catch(err => {
      console.log(`[genesys] registorGenesysWS failed, error: ${err}`);
      return { error: err };
    });
};

export const subscripbeToGenesys = async (
  integration,
  accessToken,
  genesysUserId,
  connectionId,
  userId
) => {
  const resp = await fetch(
    `/api/subscribeToGenesysTopic?integration=${integration}&access_token=${accessToken}&connection_id=${connectionId}&genesys_user_id=${genesysUserId}&user_id=${userId}`
  );
  return resp
    .json()
    .then(data => data)
    .catch(err => {
      console.log(`[genesys] subscripbeToGenesys failed, error: ${err}`);
      return { error: err };
    });
};

export const refreshGenesysToken = async () => {
  console.log('refreshing genesys token');
  const { url } = getState().environmentState;
  const requestUrl = `${url}/v1/user/refresh_access_token`;
  const headers = getOktaHeader();

  const request = async () => {
    const res = await fetcher(requestUrl, {
      ...headers,
      method: 'GET',
    });
    const data = await res.json();
    if (!data.AccessToken || !data.AccessTokenExpiresAt) {
      logger.warn(
        'empty AccessToken or AccessTokenExpiresAt from token refreshing response',
        {
          responseData: data,
          requestHeaders: headers,
        }
      );
    }

    return data;
  };

  // simply retry on 401 error
  try {
    return request();
  } catch (err) {
    if (err.code === 401) {
      try {
        return request();
      } catch (err) {
        handleRefreshError(err, {
          url: requestUrl,
          headers,
        });
      }
    } else {
      handleRefreshError(err, {
        url: requestUrl,
        headers,
      });
    }
  }
};

export const getGenesysDomain = async integration => {
  try {
    const resp = await fetch(
      `/api/getGenesysDomain?integration=${integration}`
    );
    const domain = await resp.text();
    return domain;
  } catch (error) {
    logger.error('getGenesysDomain failed', {
      error: error,
      integration: integration,
    });
    return '';
  }
};

/**
 * Function to handle errors during token refresh.
 * close genesys websocket on repeated error
 */
function handleRefreshError(reqParams, err) {
  const currentWS = getState().genesysConnectionState.ws;
  if (currentWS) {
    currentWS.close();
  }

  dispatch(
    // 1. set wsStatus to closed to prevent it from re-connecting to WS,
    updateGenesysConnection({
      wsStatus: 'closed',
      ws: null,
    })
  );

  // try {
  //   closeExistingWebSocket('Genesys token refreshing error');
  //   console.log('WebSocket closed successfully.');
  // } catch (error) {
  //   console.error('Error while closing WebSocket:', error);
  // }
  // avoid displaying error message to agents
  // dispatch(
  //   updateErrorMsg({
  //     error_msg: `Error refreshing genesys token`,
  //     error_type: 'Token Refresh',
  //     error_severity: 'error',
  //   })
  // );
  logger.error('refreshing genesys token failed', {
    error: err,
    request: reqParams,
  });
}
