import React, { useState } from 'react';
import { connect } from 'react-redux';
import DOMPurify from 'dompurify';

import Script from './script';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '../../reusable_components/Expansions';
import { BoundaryError } from '../../reusable_components/boundaryerror';
import FeedbackSnackbar from '../../feedback/FeedbackSnackbar';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './scripts.scss';

export const Scripts = ({
  call_scripts,
  text,
  html,
  currentSelection,
  isCurrentTask,
  task_id,
}) => {
  const [feedback, setFeedback] = useState({ msg: '', severity: '' });

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'left'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
        />
      )}
      {call_scripts.map((cs, csIndex) => {
        const { type, opened_by_default, scripts } = cs;
        return (
          <BoundaryError key={`${csIndex}_error`}>
            <ExpansionPanel defaultExpanded={opened_by_default}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon className={styles.expand_icon} />}
              >
                {(type || '').toUpperCase()}{' '}
                {'SCRIPT' + (scripts.length > 1 ? 'S' : '')}
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={styles.scripts}>
                  {(scripts || []).map((script, index) => {
                    return (
                      <Script
                        task_id={task_id}
                        type={type}
                        open={open}
                        handleOpen={handleOpen}
                        key={`${script.name}_${index}`}
                        script={script}
                        updateFeedback={updateFeedback}
                      />
                    );
                  })}
                </div>
                {(currentSelection || {}).scriptType === type &&
                  open &&
                  isCurrentTask &&
                  (html ? (
                    <div
                      className={styles.script_html}
                      dangerouslySetInnerHTML={{
                        __html: `${DOMPurify.sanitize(html)}`,
                      }}
                    />
                  ) : (
                    <div className={styles.script_text}>{text}</div>
                  ))}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </BoundaryError>
        );
      })}
    </>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { scriptState } = state;
  const { currentSelection = {} } = scriptState;
  const { name = '' } = currentSelection;
  const { text = '' } = scriptState[name] || {};
  const { html = '' } = scriptState[name] || {};
  const isCurrentTask = currentSelection?.task_id === ownprops?.task_id;

  return { text, html, currentSelection, isCurrentTask };
};

export default connect(mapStateToProps)(Scripts);
