import React from 'react';
import { connect } from 'react-redux';
import styles from '../supervisor.scss';
import { FormControl, MenuItem, Select, Typography } from '@material-ui/core';
import { selectTeam } from '../../../store/action/team';

const SelectTeam = ({ selectedTeam, selectTeam, teams }) => {
  return (
    <>
      <p id="team_header" className={styles.team_header}>
        Team:{' '}
      </p>
      {teams && teams.length > 1 ? (
        <FormControl>
          <Select
            MenuProps={{ classes: { paper: styles.team_selection_toggled } }}
            value={selectedTeam || ''}
            displayEmpty
            className={styles.team_selection}
            classes={{ icon: styles.icon_color }}
            onChange={e => {
              e.preventDefault();
              e.stopPropagation();
              console.log('new team: ', e.target.value);
              selectTeam(e.target.value);
            }}
          >
            <MenuItem value="" disabled className={styles.menu_item}>
              Select
            </MenuItem>
            {teams.map(team => {
              return (
                <MenuItem key={team} className={styles.menu_item} value={team}>
                  {team}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <Typography>{teams[0]}</Typography>
      )}
    </>
  );
};

const mapStateToProps = state => {
  const { userState, teamState } = state;
  const { team, teams = [] } = userState;

  return {
    teams: teams.length ? teams : team ? [team] : [],
    selectedTeam: teamState.selectedTeam || '',
  };
};

const mapDispatchToProps = dispatch => {
  return {
    selectTeam: team => dispatch(selectTeam(team)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectTeam);
