import { consts } from '../../config/consts';

// based on browser userAgent
export const isWebapp = () => {
  const userAgent = navigator.userAgent;

  return userAgent.toLowerCase().indexOf('electron') === -1;
};

export const hasWebappAccess = oktaToken => {
  return oktaToken?.app_type?.includes(consts.app.webapp);
};

export const hasElectronAppAccess = oktaToken => {
  return oktaToken?.app_type?.includes(consts.app.electronApp);
};

export const hasAppAccess = oktaToken => {
  if (isWebapp() && hasWebappAccess(oktaToken)) {
    return true;
  }
  if (!isWebapp() && hasElectronAppAccess(oktaToken)) {
    return true;
  }

  return false;
};
