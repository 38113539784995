import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  InputAdornment,
  OutlinedInput,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { AddCircleOutlined as AddIcon } from '@material-ui/icons';
import { makeStyles, fade } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import GroupRow from './grouprow';
import styles from './directory.scss';
import store from '../../store';
import { BoundaryError } from '../reusable_components/boundaryerror';
const { dispatch, getState } = store;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    color: 'white',
  },
  container: {
    backgroundColor: 'inherit',
  },
  cell: {
    backgroundColor: 'inherit',
    color: 'white',
  },
});

const fields = [
  'Username',
  'Name',
  'Integration Id',
  'Role',
  'Status',
  'Online',
];

const fieldsMap = {
  Username: ['id', 'username'],
  Name: ['name', 'username'],
  'Integration Id': ['integration'],
  Role: ['roles'],
  Status: ['available'],
  Online: ['online'],
};

let rows = [
  { group: 'group1' },
  { group: 'group1' },
  { group: 'group1' },
  { group: 'group1' },
  { group: 'group1' },
];

const Group = props => {
  const { taskState, helpers, directoryState, task, services, search } = props;
  const searchTerm = search;
  const { mergeClasses } = helpers;
  const classes = useStyles();

  const state = getState();

  const { environmentState } = state;

  return (
    <div id="group">
      <TableContainer
        classes={{
          root: mergeClasses(classes.container, styles.table_container),
        }}
        component={Paper}
      >
        <Table className={classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                classes={{
                  root: mergeClasses(classes.cell, styles.head_cell),
                }}
              >
                Groups
              </TableCell>
              <TableCell
                classes={{
                  root: mergeClasses(classes.cell, styles.head_cell),
                }}
              >
                Available
              </TableCell>
              <TableCell
                classes={{
                  root: mergeClasses(classes.cell, styles.head_cell),
                }}
              >
                Online
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(((task || {}).directory || {}).groups || [])
              .filter(group => {
                if (!group || !group.type) {
                  return false;
                }
                if (!searchTerm || !searchTerm.length) return true;
                if (
                  group.label &&
                  group.label.toLowerCase().indexOf(searchTerm) > -1
                ) {
                  return true;
                }
                return false;
              })
              .map((group, index) => {
                return (
                  <BoundaryError key={`group_${index}`}>
                    <GroupRow
                      key={index}
                      {...props}
                      group={group}
                      task={task}
                    />
                  </BoundaryError>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <FormControl
        fullWidth
        classes={{ root: mergeClasses(classes.margin, styles.add_form) }}
        variant="outlined"
      >
        <OutlinedInput
          id="outlined-adornment"
          value={directoryState.addNumber}
          placeholder={'(XXX) XXX - XXXX'}
          onChange={e => {
            dispatch({
              type: 'UPDATE_DIRECTORY',
              directoryState: {
                ...directoryState,
                addNumber: e.target.value,
              },
            });
          }}
          startAdornment={
            <InputAdornment position="start">
              <AddIcon
                aria-label="Transfer"
                onClick={async e => {
                  e.preventDefault();
                  e.stopPropagation();

                  let { addNumber, callId } = directoryState;

                  let res = await services
                    .addToCall(directoryState.callId, {
                      number: addNumber,
                    })
                    .then(data => data.json())
                    .catch(err => {
                      console.error('Error transfering a call', err);
                    });

                  if (!res) {
                    alert('Opps something went wrong! Adding by number');
                  } else {
                    if (res.participants) {
                      let newParticipant = res.participants.find(part => {
                        return part.number === addNumber;
                      });

                      if (newParticipant) {
                        taskState.events[callId].events.push({
                          event_type: 'initiated',
                          user_id: newParticipant.id,
                          id: newParticipant.call_id,
                          called: res.called,
                          caller: res.caller,
                          direction: res.direction.toLowerCase(),
                          group_name: res.group_name,
                        });
                      }
                    }
                  }

                  dispatch({
                    type: 'UPDATE_TASKS',
                    taskState: {
                      ...taskState,
                      add: null,
                    },
                  });
                  clearInterval(directoryState.fetchAgentInterval);
                  dispatch({
                    type: 'UPDATE_DIRECTORY',
                    directoryState: {
                      ...directoryState,
                      tab: 0,
                      callId: null,
                      display: false,
                      searchTerm: null,
                      addNumber: null,
                      fetchAgentInterval: false,
                    },
                  });
                }}
                classes={{ root: styles.add_icon_input }}
              />
            </InputAdornment>
          }
          labelWidth={60}
        />
      </FormControl>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    taskState: state.taskState,
    userState: state.userState,
    directoryState: state.directoryState,
  };
};

const mapDispatchToProps = dispatch => ({
  updateConnectionFunc: state => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(Group);
