import React from 'react';
import WrapupRadioGroup from './wrapup_radio_group';
import styles from './wrapup.scss';
import { BoundaryError } from '../reusable_components/boundaryerror';
import { mergeClasses } from '../../utils';

const WrapupRadioRow = props => {
  let { list, task } = props;

  return (
    <div
      id="wrapup_radio_row"
      className={mergeClasses(styles.wrapup_radio_row)}
    >
      {list.map((item, index) => {
        return (
          <BoundaryError key={index}>
            <WrapupRadioGroup {...props} key={index} radioProps={item} />
          </BoundaryError>
        );
      })}
    </div>
  );
};

export default WrapupRadioRow;
