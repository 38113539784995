import { getTaskList } from '../../utils/helpers';

export const taskState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_TASKS': {
      const newState = {
        ...state,
        ...action.taskState,
      };
      let { detailView, tasks = [], archived = [], filterTaskType } = newState;
      const list = getTaskList({ tasks, archived, filterTaskType }) || [];
      detailView = (list.find(o => o.id === detailView) || list[0] || {}).id;
      return { ...newState, detailView };
    }
    case 'UPDATE_TASKS_AND_EVENTS': {
      const { tasks, events, currentCallTask } = action;
      let { detailView, archived = [], filterTaskType } = state;

      const list =
        getTaskList({ ...state, tasks, archived, filterTaskType }) || [];
      detailView = (list.find(o => o.id === detailView) || list[0] || {}).id;

      return { ...state, detailView, tasks, events, currentCallTask };
    }
    case 'UPDATE_TASKS': {
      const { tasks = [] } = action;
      let { detailView, archived = [], filterTaskType } = state;

      const list =
        getTaskList({ ...state, tasks, archived, filterTaskType }) || [];
      detailView = (list.find(o => o.id === detailView) || list[0] || {}).id;

      return { ...state, detailView, tasks };
    }
    case 'UPDATE_TASKS_WITH_DETAILVIEW': {
      const { tasks = [], detailView } = action;
      return { ...state, detailView, tasks };
    }
    case 'SET_SELECTED_TASK': {
      const newState = {
        ...state,
        detailView: (action || {}).id,
      };
      return newState;
    }
    case 'ARCHIVE_TASK': {
      const task = action.task;
      const { tasks, archived, tab } = state;
      const tasksList = tasks.filter(t => t.id !== task.id);
      const archiveList = !archived.find(t => t.id === task.id)
        ? [...archived, task]
        : archived.map(t => {
            if (t.id === task.id) {
              return task;
            }
            return t;
          });
      const list = (tab === 'tasks' ? tasksList : archiveList) || [];
      const detailView = (
        list.find(o => o.id === state.detailView) ||
        list[0] ||
        {}
      ).id;

      // reset the participantLeft flag for the next task
      const callId = task.call?.id;
      if (callId && state.events[callId]?.participantLeft) {
        state.events[callId].participantLeft = undefined;
      }
      return { ...state, tasks: tasksList, archived: archiveList, detailView };
    }
    case 'REMOVE_TASK': {
      const id = action.id;
      const { tasks, archived, tab } = state;
      const tasksList = tasks.filter(t => t.id !== id);
      const list = (tab === 'tasks' ? tasksList : archived) || [];
      const detailView = (
        list.find(o => o.id === state.detailView) ||
        list[0] ||
        {}
      ).id;
      return { ...state, tasks: tasksList, detailView };
    }
    case 'SHOW_TASK_FROM_WEB': {
      const { id, filteredTask, tab, filterTaskType } = action;
      const archived = filteredTask;
      const detailView = id;
      return { ...state, archived, detailView, tab, filterTaskType };
    }
    case 'REMOVE_TASK_FROM_WEB': {
      const { tab, filterTaskType } = action;
      const { tasks = [] } = state;
      const detailView = (tasks[0] || {}).id;
      return { ...state, archived: [], detailView, tab, filterTaskType };
    }
    case 'UPDATE_CALL_ERROR': {
      const { callError } = action;
      return { ...state, ...callError };
    }
    case 'OFFER_PRESENTED': {
      const { offer } = action;
      let { tasks = [], detailView, archived = [], filterTaskType } = state;
      tasks.unshift(offer);

      const list =
        getTaskList({ ...state, tasks, archived, filterTaskType }) || [];
      detailView = (list.find(o => o.id === detailView) || list[0] || {}).id;

      return { ...state, detailView };
    }
    case 'UPDATE_OFFER_STATUS': {
      let { offer } = action;
      offer.class_type = 'OFFER';
      let { tasks = [], archived = [], filterTaskType, detailView } = state;
      tasks.find(t => t.id === offer.id && t.task_id === offer.task_id)
        ? tasks.forEach(
            t =>
              t.id === offer.id &&
              t.task_id === offer.task_id &&
              (t.status = offer.status)
          )
        : tasks.unshift(offer);

      const list =
        getTaskList({ ...state, tasks, archived, filterTaskType }) || [];
      detailView = (list.find(o => o.id === detailView) || list[0] || {}).id;

      return { ...state, detailView };
    }
    case 'OFFER_CANCELED': {
      const { offer } = action;
      let { tasks = [], archived = [], filterTaskType, detailView } = state;

      tasks = tasks.filter(task => {
        return task.id !== offer.id;
      });

      const list =
        getTaskList({ ...state, tasks, archived, filterTaskType }) || [];
      detailView = (list.find(o => o.id === detailView) || list[0] || {}).id;

      return { ...state, tasks, detailView };
    }

    case 'UPDATE_CALL_DOCK_VIEW': {
      const { expand } = action;
      return { ...state, expand: !expand };
    }
    case 'UPDATE_TASKS_SORT': {
      const { sort } = action;
      return { ...state, sort: -sort };
    }
    case 'UPDATE_ARCHIVED_TASKS': {
      const { tab, archives = [] } = action;
      const { archived = [] } = { ...state };
      archived.push(...archives);
      return { ...state, tab, archived };
    }
    case 'UPDATE_ARCHIVED_TASKS_FROM_DT_FILTER': {
      const { tab, archiveFilter, archives = [] } = action;
      let { detailView } = state;
      let archived = [];
      archived.push(...archives);
      detailView = (
        archived?.find(o => o?.id === detailView) ||
        archived[0] ||
        {}
      ).id;
      return { ...state, tab, archived, detailView, archiveFilter };
    }
    default:
      return state;
  }
};
