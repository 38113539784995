export const updateConnection = connectionState => {
  return {
    type: 'UPDATE_CONNECTION',
    connectionState,
  };
};

export const openReLoginDialog = open => {
  return {
    type: "SHOW_RE_LOGIN_DIALOG",
    open,
  }
}
