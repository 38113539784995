// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".participantStatus__participant_status___2oQHA{display:flex;justify-content:center;background-color:#fff;color:#212121;padding:2px;margin:5px;font-size:14px}", ""]);
// Exports
exports.locals = {
	"participant_status": "participantStatus__participant_status___2oQHA"
};
module.exports = exports;
