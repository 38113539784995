import React, { useState, useEffect } from 'react';
import styles from '../supervisor.scss';

import { UserListConnector } from './connectors';

import Archives from './Archives';
import User from './User';
import { BoundaryError } from '../../reusable_components/boundaryerror';
import { fetchTeam } from '../../../services';

const Userlist = props => {
  const {
    users,
    selectedTeam,
    refreshInterval,
    updateAgentData,
    updateTeamWithUsers,
    teamview,
    limit = 20,
    args,
  } = props;
  const [loading, setLoading] = useState(false);

  const [expanded, setExpanded] = useState('');
  const [agent, setAgent] = useState(null);
  let mounted = true;

  useEffect(() => {
    const user = users.find(u => u.id === agent);
    updateAgentData({ data: user || {} });
  }, [agent]);

  useEffect(() => {
    setLoading(true);
    fetchTeam({ ...args })
      .then(data => {
        data?.length && updateTeamWithUsers(data);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchTeam({ ...args }).then(data => {
        data.length && mounted && updateTeamWithUsers(data);
      });
    }, refreshInterval);
    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [args, selectedTeam]);

  const handleAgent = agentId => {
    setAgent(agentId);
  };

  const changeExpanded = (panel, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  if (users.length) {
    return (
      <>
        <div className={styles.team_block}>
          {teamview === 'tasks' && (
            <BoundaryError>
              {users.map((user, index) => {
                return (
                  <User
                    key={user.id}
                    index={index}
                    changeExpanded={changeExpanded}
                    expanded={expanded}
                    agent={agent}
                    handleAgent={handleAgent}
                    userId={(user || {}).id}
                    limit={limit}
                    teleponyProvider={user.telephonyProvider}
                  />
                );
              })}
            </BoundaryError>
          )}
        </div>
      </>
    );
  }

  return (
    <div className={styles.no_match}>
      {loading ? <>Loading...</> : <>No Matching Users</>}
    </div>
  );
};

export default UserListConnector(Userlist);
