export const updateTeam = teamState => {
  return {
    type: 'UPDATE_TEAM',
    teamState,
  };
};

export const selectTeam = team => {
  return {
    type: 'SELECT_TEAM',
    team,
  };
};

export const updateTeamWithUsers = users => {
  return {
    type: 'UPDATE_TEAM_WITH_USERS',
    users,
  };
};

export const updateTeamWithCompleteTaskData = ({ userId, tasks }) => {
  return {
    type: 'UPDATE_TEAM_WITH_COMPLETE_TASK_DATA',
    userId,
    tasks,
  };
};

export const updateTeamView = teamview => {
  return {
    type: 'UPDATE_TEAM_VIEW',
    teamview,
  };
};

export const updateTeamArgsByName = ({ name, value }) => {
  return {
    type: 'UPDATE_TEAM_ARGS',
    name,
    value,
  };
};

export const updateTeamSortByName = name => {
  return {
    type: 'UPDATE_TEAM_SORT_BY_NAME',
    name,
  };
};

export const showActiveCallUsersInTeam = active => {
  return {
    type: 'SHOW_ACTIVE_CALL_USERS_IN_TEAM',
    active,
  };
};
