// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Dialpad__break___3NFld{height:10px}.Dialpad__dialpad_number___BRIY8{width:30px;height:30px}.Dialpad__dialpad___NNm74{padding:0px 16px}.Dialpad__dialpad___NNm74:hover{background-color:#54698d}.Dialpad__dialpad_wrapper___3-BlT{width:100%;display:flex;justify-content:center;align-items:center}", ""]);
// Exports
exports.locals = {
	"break": "Dialpad__break___3NFld",
	"dialpad_number": "Dialpad__dialpad_number___BRIY8",
	"dialpad": "Dialpad__dialpad___NNm74",
	"dialpad_wrapper": "Dialpad__dialpad_wrapper___3-BlT"
};
module.exports = exports;
