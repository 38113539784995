export const Feedback = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};

//note that logout reason should map to datahub logout reason string
export const logoutReason = {
  MANUAL: 'LOGOUT_REASON_MANUAL_LOGOUT',
  AUTO: 'LOGOUT_REASON_AUTO_LOGOUT',
  SOCKET_DISCONNECT: 'LOGOUT_REASON_SOCKET_DISCONNECT',
  SOCKET_CLOSED: 'LOGOUT_REASON_SOCKET_CLOSED',
  SESSION_EXPIRED: 'LOGOUT_REASON_SESSION_EXPIRED',
  UNDEFINED:'LOGOUT_REASON_UNDEFINED',
};

export const saveAndArchiveTooltipMessages = {
  agentOnCall: `Can't archive. You are in active call`,
  ringing: `Can't archive. Your phone is ringing`,
  queued: `Can't archive. Your call is queued`,
  pending: `Can't archive. Your call is pending`,
  requiredFieldsFilled: `Can't archive. Required fields(*) are not filled`,
  dontAllowArchive: `Template doesn't allow archiving the task`,
};

export const IVRresultMapping = {
  timeouted: 'TIMED OUT',
  success: 'SUCCESS',
  failed: 'FAILED',
};

export const participantStatusMapping = args => {
  const { ivr } = args;
  const mapping = {
    ivr: `${ivr} In Progress`,
  };

  return mapping.ivr;
};
export const defaultType = 'to';
export const emailActions = [
  {
    label: 'REPLY',
    style: 'reply',
    canClickOnReply: true,
    updateState: 'email',
    align: 'right',
  },
  {
    label: 'New Email',
    style: 'create',
    canClickOnReply: true,
    updateState: 'email',
    newEmail: true,
  },
  {
    label: 'REPLY ALL',
    style: 'reply_all',
    canClickOnReply: true,
    canReplyAll: true,
    updateState: 'email',
    align: 'right',
  },
  {
    label: 'REASSIGN TASK',
    style: 'reassign',
    canClickOnReply: true,
    updateState: 'directory',
    align: 'left',
  },
  {
    label: 'CANCEL',
    style: 'cancel',
    canClickOnReply: false,
    align: 'left',
  },
  {
    label: 'SEND',
    style: 'send',
    action: 'sendEmailByTaskId',
    canClickOnReply: false,
    align: 'right',
    // updateState: 'email',
  },
  {
    label: 'SAVE',
    style: 'save',
    canClickOnReply: false,
    updateState: 'email',
    align: 'right',
  },
  {
    label: 'FORWARD',
    style: 'forward',
    updateState: 'forward',
    canClickOnReply: true,
    align: 'right',
  },
];

export const HEADER_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
];

export const BLOCK_TYPES = [
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'left', style: 'left' },
  { label: 'justify', style: 'justify' },
  { label: 'center', style: 'center' },
  { label: 'right', style: 'right' },
  { label: 'font', style: 'adjust-font' },
];

export const INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  // { label: 'color-picker', style: 'COLORPICKER' },
];

export const environments = {
  development: 'dev',
  staging: 'stg',
  production: 'prd',
};

export const dialpadNumbers = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '*',
  '0',
  '#',
];

export const types = [
  { type: 'GENERAL', label: 'General' },
  { type: 'CALL', label: 'Call' },
  { type: 'MISSEDCALL', label: 'Missed Call' },
  { type: 'VOICEMAIL', label: 'Voicemail' },
  { type: 'COACHING', label: 'Coaching' },
  { type: 'SMS', label: 'SMS' },
  { type: 'EMAIL', label: 'Email' },
  { type: 'SCANNER', label: 'Scanner' },
  { type: 'FREE_FORM_CALL', label: 'Free Form Call' },
];
