import React, { useState, Fragment } from 'react';
import styles from './EditRecipients.scss';
import AddOrSelectEmailId from './AddOrSelectEmailId';
import { Close as CloseIcon } from '@material-ui/icons';

const EditRecipients = ({
  recipients,
  handleRecipientType,
  editType,
  task_id,
  buttonClasses,
}) => {
  return (
    <div className={styles.recipient_ids}>
      {editType.map(item => {
        return (
          <Fragment key={item.type}>
            {item.isEditable && (
              <div className={styles.select_wrapper}>
                <AddOrSelectEmailId
                  task_id={task_id}
                  type={item.type}
                  defaultValue={recipients.filter(
                    recipient =>
                      (recipient.type || '').toLowerCase() ===
                        (item.type || '').toLowerCase() &&
                      (recipient.name || recipient.email)
                  )}
                />
                <CloseIcon
                  className={buttonClasses}
                  onClick={e => {
                    e.preventDefault();
                    console.log('clicked ');
                    handleRecipientType(item.type);
                  }}
                />
              </div>
            )}
            <div className={styles.additional_recipients_wrapper}>
              <div
                className={styles.to}
                onClick={e => {
                  e.preventDefault();
                  console.log(`click edit type: ${item.type}`);
                  handleRecipientType(item.type);
                }}
              >
                {!item.isEditable && item.type}
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default EditRecipients;
