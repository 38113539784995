import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { fetchBroadcastMessages } from '../../../services';
import { displayTimestamp, Feedback } from '../../../utils';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Paper, Grid } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paper_root: {
    padding: theme.spacing(2),
    margin: theme.spacing(3),
    color: '#fff',
    border: '1px solid',
    borderColor: 'rgba(255,255,255,0.23)',
    backgroundColor: 'transparent',
    opacity: 0.7,
    minHeight: 200,
  },
  h6: {
    color: '#fff',
    marginLeft: theme.spacing(3),
  },
  time: {
    opacity: 0.45,
  },
  message: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export const Messages = ({
  updateFeedback,
  id,
  handlePastMessages,
  pastMessages,
}) => {
  let mounted = true;
  const classes = useStyles();
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchBroadcastMessages({ user_id: id, limit: 3 })
      .then(res => res.json())
      .then(data => {
        console.log('Past messages', data);
        mounted && handlePastMessages(data);
      })
      .catch(
        err =>
          mounted &&
          updateFeedback(`${err.code}:'${err.message}`, Feedback.ERROR)
      );

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <>
      <Typography variant={'h6'} classes={{ h6: classes.h6 }}>
        Past Broadcasts
      </Typography>
      <Paper elevation={3} classes={{ root: classes.paper_root }}>
        {(pastMessages || []).map(message => {
          if (message?.sender !== id) return null;
          return (
            <Grid container spacing={2} key={message?.id}>
              <Grid item>
                <Typography classes={{ root: classes.time }}>
                  {displayTimestamp(message?.created_at)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>{message?.text}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </Paper>
    </>
  );
};

const mapStateToProps = state => {
  const { userState } = state;
  const { id = '' } = userState;

  return {
    id,
  };
};

export default connect(mapStateToProps)(Messages);
