'use strict';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  updateUserStatus as updateUserStatusFunc,
  updateUserInactivityTimers,
  updateUserStatusList,
} from '../../store/action/user';
import { updateIntState } from '../../store/action/integration';
import styles from './header.scss';
import { mergeClasses, getUserLogoutalertMsg, logoutReason } from '../../utils';
import {
  fetchStatusConfig,
  fetchIntegrationConfig,
  fetchIntegrationGroup,
  userLogout,
  updateUserStatus,
} from '../../services';
import { FormControl, Select, MenuItem, Divider } from '@material-ui/core';
import { isWebapp } from '../../utils/webapp/user_access_control';
import { registerWebappEventHandlerAdapter } from '../../utils/webapp/extension_event_handler_adapter';

if (!isWebapp()) {
  const electron = window.require('electron');
  window.electron = electron;
} else {
  registerWebappEventHandlerAdapter();
}

const loadCustomScript = (uri, cb) => {
  if (uri && uri !== '') {
    const script = document.createElement('script');
    script.src = uri;
    script.id = 'custom-script';
    document.body.appendChild(script);
    script.onload = () => {
      console.log('custom script loaded');
      if (cb) {
        cb();
      }
    };
  }
};

class Connection extends Component {
  componentDidMount() {
    const {
      statusList,
      updateIntState,
      integrationState,
      integration,
      updateUserInactivityTimers,
      group,
      updateUserStatusList,
      statusFetchCount,
    } = this.props;

    if (
      statusList.length === 1 &&
      statusList[0].name === 'logout' &&
      integration &&
      !statusFetchCount
    ) {
      // Fetch Inactivity time
      fetchIntegrationGroup({ integration, group })
        .then(group => {
          updateIntState({
            ...integrationState,
            ...group,
          });
          const {
            inactivity_timeout_countdown_seconds,
            inactivity_timeout_seconds,
          } = group;
          inactivity_timeout_countdown_seconds &&
            inactivity_timeout_seconds &&
            updateUserInactivityTimers({
              inactivity: {
                inactivity_timeout_countdown_seconds,
                inactivity_timeout_seconds,
              },
            });
        })
        .catch(err =>
          console.error(
            `Error Fetching ${integration} (Int) ${group} (group) details`,
            err
          )
        );

      fetchStatusConfig({ integration, group }).then(data => {
        let logoutItem = statusList.find(item => {
          return item.name === 'logout';
        });
        logoutItem && data.push(logoutItem);
        updateUserStatusList({ statusList: data });
      });
      fetchIntegrationConfig(integration)
        .then(async config => (config || {}).custom_script_url)
        .then(uri => {
          if (uri && uri != '') {
            this.setState({ customScript: uri });
          }
        })
        .catch(console.error);
    }

    // fetch custom script
  }

  async componentDidUpdate(prevProps, prevState) {
    const {
      integration,
      group,
      integrationState,
      updateIntState,
      statusList,
      updateUserInactivityTimers,
      updateUserStatusList,
    } = this.props;

    const prevIntegration = prevProps.integration || '';
    const curIntegration = integration || '';

    if (prevIntegration === '' && curIntegration !== '') {
      // Fetch Inactivity time
      await fetchIntegrationGroup({ integration, group })
        .then(group => {
          updateIntState({
            ...integrationState,
            ...group,
          });
          const {
            inactivity_timeout_countdown_seconds,
            inactivity_timeout_seconds,
          } = group;
          inactivity_timeout_countdown_seconds &&
            inactivity_timeout_seconds &&
            updateUserInactivityTimers({
              inactivity: {
                inactivity_timeout_countdown_seconds,
                inactivity_timeout_seconds,
              },
            });
        })
        .catch(err =>
          console.error(
            `Error Fetching ${integration} (Int) ${group} (group) details in update: `,
            err
          )
        );
      if (statusList.length === 1 && statusList[0].name === 'logout') {
        await fetchStatusConfig({ integration, group }).then(data => {
          let logoutItem = statusList.find(item => {
            return item.name === 'logout';
          });
          logoutItem && data.push(logoutItem);
          updateUserStatusList({ statusList: data });
        });
      }
      // fetch custom script
      fetchIntegrationConfig(integration)
        .then(async config => (config || {}).custom_script_url)
        .then(uri => {
          if (uri && uri != '') {
            this.setState({ customScript: uri });
          }
        })
        .catch(console.error);
    }

    if (
      statusList?.length === 1 &&
      statusList[0].name === 'logout' &&
      integration &&
      group &&
      prevProps.statusList !== statusList
    ) {
      await fetchStatusConfig({ integration, group }).then(data => {
        let logoutItem = statusList.find(item => {
          return item.name === 'logout';
        });
        logoutItem && data.push(logoutItem);
        updateUserStatusList({ statusList: data });
      });
    }

    if (prevState.customScript !== this.state.customScript) {
      loadCustomScript(this.state.customScript, () =>
        console.log('custom script loaded')
      );
    }
  }
  constructor(props, state) {
    super(props, state || {});
    this.state = state || {};
    this.render = this.render.bind(this);
  }
  render = () => {
    let {
      ws,
      statusList,
      status,
      available,
      user_id,
      group,
      updateUserStatusFunc,
      tasks,
      telephony_status,
    } = this.props;

    let iconColor = !(ws && ws.readyState === 1)
      ? 'red'
      : available
      ? 'green'
      : 'yellow';

    let oktaStorage = localStorage.getItem('okta-token-storage')
      ? JSON.parse(localStorage.getItem('okta-token-storage'))
      : null;
    let claimName =
      oktaStorage && oktaStorage.idToken && oktaStorage.idToken.claims
        ? oktaStorage.idToken.claims.name || ''
        : '';

    statusList &&
      sessionStorage.setItem('statusList', JSON.stringify(statusList));

    return (
      <div id="connection" className={styles.connection_status}>
        <div id="connection_text" className={styles.connection_text}>
          <div id="username" className={styles.username}>
            {claimName}
          </div>

          <div id="status" className={styles.status}>
            {statusList ? (
              <FormControl className={styles.status}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  className={styles.status_select}
                  classes={{
                    select: styles.status_select,
                    icon: styles.icon_color,
                  }}
                  MenuProps={{ classes: { paper: styles.status_option } }}
                  onChange={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    const { value } = e.target;

                    if (value === 'logout') {
                      const cantLogout = getUserLogoutalertMsg(tasks, user_id);

                      if (cantLogout) {
                        alert(cantLogout);
                      } else {
                        let logout = confirm(
                          'Are you sure you want to logout?'
                        );

                        if (logout) {
                          userLogout(logoutReason.MANUAL);
                          localStorage.removeItem('env');
                          sessionStorage.clear();
                          localStorage.removeItem(
                            `${new Date().toDateString()}`
                          );
                          localStorage.removeItem('enable-achieve-okta');
                          window.authService.logout();
                        } else {
                          updateUserStatusFunc({ status, available });
                        }
                      }
                    } else {
                      updateUserStatus(value, 0, 'LOGOUT_REASON_UNDEFINED')
                        .then(res => res.json())
                        .then(data => {
                          updateUserStatusFunc({
                            available: data.available,
                            status: data.status,
                          });
                        })
                        .catch(console.error);
                    }
                  }}
                >
                  {statusList
                    .filter(item => {
                      return (
                        item &&
                        item.groups &&
                        (item.groups === 'all' ||
                          item.groups.indexOf(group) > -1)
                      );
                    })
                    .map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          disabled={item?.system_only}
                          className={styles.status_option}
                          value={item.label}
                        >
                          {item.label}
                        </MenuItem>
                      );
                    })}
                  <Divider light />
                  <MenuItem
                    key="logout"
                    value="logout"
                    className={styles.status_option}
                  >
                    LOGOUT
                  </MenuItem>
                </Select>
              </FormControl>
            ) : (
              'Loading'
            )}
          </div>
        </div>
        <div
          className={mergeClasses(
            styles.telephony_status,
            styles[telephony_status?.toUpperCase()]
          )}
        />

        <div
          id="connection_icon"
          className={mergeClasses(styles.connection_icon, styles[iconColor])}
        >
          <div
            id="connection_icon_text"
            className={styles.connection_icon_text}
          >
            {claimName ? claimName[0] : ''}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = state => {
  const { callState, userState, connectionState } = state;
  const tasks = callState || [];
  const {
    statusList = [],
    status = '',
    available,
    integration = '',
    statusFetchCount = 0,
    group,
    id,
    telephony_status,
  } = userState;
  return {
    ws: connectionState.ws || {},
    tasks,
    statusList,
    statusFetchCount,
    integration,
    available,
    status,
    telephony_status,
    group,
    user_id: id,
    integrationState: state.integrationState,
  };
};

const mapDispatchToProps = dispatch => ({
  updateUserInactivityTimers: ({ inactivity }) => {
    dispatch(updateUserInactivityTimers({ inactivity }));
  },
  updateIntState: group => {
    dispatch(updateIntState(group));
  },
  updateUserStatusFunc: ({ available, status }) => {
    dispatch(updateUserStatusFunc({ available, status }));
  },
  updateUserStatusList: ({ statusList }) => {
    dispatch(updateUserStatusList({ statusList }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Connection);
