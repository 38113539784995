import React, { Component } from 'react';
import styles from '../ComposeEmail.scss';
import { mergeClasses } from '../../../../utils/helpers';

class StyleButton extends Component {
  constructor() {
    super();
    this.onToggle = e => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }

  render() {
    const clicked_style = this.props.active ? styles.clicked : '';
    const format_style = styles[this.props.style.toLowerCase()];

    return (
      <span
        className={mergeClasses(styles.style_ind, format_style, clicked_style)}
        onMouseDown={this.onToggle}
      />
    );
  }
}

export default StyleButton;
