import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from '../details.scss';
import { ClosedCaption as GetTranscription } from '@material-ui/icons';
import AudioText from './AudioText';
import { fetchAudioTransription } from '../../../services';
import { updateTranscriptionByRecId } from '../../../store/action/transcription';
import { mergeClasses } from '../../../utils/helpers';
import FeedbackSnackbar from '../../feedback/FeedbackSnackbar';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { BoundaryError } from '../../reusable_components/boundaryerror';
import { Spinner } from '../../reusable_components/Spinner';
import { refreshClock } from '../../../utils/autoLogoutClock';

class AudioPlayer extends Component {
  componentDidMount() {
    const { initPlay, url } = this.props;
    this.player.addEventListener('play', e => {
      initPlay(url);
    });
    this.player.addEventListener('playing', e => {
      initPlay(url);
    });
    this.player.addEventListener('ended', e => {
      this.player.currentTime = 0;
      initPlay('');
    });
  }
  componentDidUpdate(prevProps) {
    const { currAudio, url } = this.props;
    const prevAudio = prevProps.currAudio;
    if (currAudio !== prevAudio) {
      if (currAudio != url) {
        this.player.pause();
      }
    }
  }

  audioPlaying = async e => {
    e.preventDefault();
    e.stopPropagation();

    sessionStorage.setItem(
      'audioPlaying',
      JSON.stringify({ id: this.props.id, playing: true })
    );
    refreshClock();
  };

  audioPaused = async e => {
    e.preventDefault();
    e.stopPropagation();

    let audioPlaying = JSON.parse(sessionStorage.getItem('audioPlaying'));
    if (audioPlaying && audioPlaying.id === this.props.id) {
      sessionStorage.setItem(
        'audioPlaying',
        JSON.stringify({ id: this.props.id, playing: false })
      );
    }

    refreshClock();
  };

  timeUpdate = async e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ currentTime: this.player.currentTime });
  };

  handleTextClick = startTime => {
    this.player && (this.player.currentTime = startTime);
  };

  updateFeedback = (msg, severity = 'info') => {
    this.setState({ msg, severity });
  };

  handleTextClick = startTime => {
    this.player && (this.player.currentTime = startTime);
  };

  componentWillUnmount() {
    let audioPlaying = JSON.parse(sessionStorage.getItem('audioPlaying'));
    audioPlaying &&
      audioPlaying.playing &&
      sessionStorage.setItem(
        'audioPlaying',
        JSON.stringify({ id: this.props.id, playing: false })
      );
  }

  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.state = {
      currentTime: 0,
      msg: '',
      severity: '',
      expandCC: false,
    };
  }

  render = () => {
    const {
      url,
      id,
      user_id,
      created_at,
      type,
      timeFormat,
      transcription,
      updateTranscriptionByRecId,
      flags,
    } = this.props;

    const captionStyle = this.state.msg.length ? styles.error : null;

    return (
      <div recording={id} type={type}>
        <div className={styles.recording_stats}>
          {timeFormat(created_at)} {'  '} USER: {user_id}
        </div>
        <div className={styles.audio_wrapper}>
          <audio
            id={id}
            controls
            controlsList="nodownload"
            className={styles.recordings}
            ref={ref => (this.player = ref)}
            onPause={this.audioPaused}
            onPlaying={this.audioPlaying}
            onTimeUpdate={this.timeUpdate}
          >
            <source src={url} type="audio/mpeg" />
          </audio>
          {flags.enableTranscription && (
            <GetTranscription
              className={mergeClasses(styles.caption, captionStyle)}
              onClick={e => {
                e.preventDefault();
                !this.state.expandCC &&
                  !transcription.length &&
                  fetchAudioTransription(id)
                    .then(res => res.json())
                    .then(data => {
                      if (!data.length)
                        this.updateFeedback(
                          `No transcription data for this rec id: ${id}`,
                          'info'
                        );

                      updateTranscriptionByRecId({
                        id,
                        transcription: data,
                        user_id,
                      });
                    })
                    .catch(err => {
                      this.updateFeedback(
                        `${err.code}: ${err.message}`,
                        'error'
                      );
                    });

                this.setState({
                  expandCC: !this.state.expandCC,
                });
              }}
            />
          )}
        </div>
        {this.state.msg.length > 0 && (
          <FeedbackSnackbar
            vertical={'bottom'}
            horizontal={'left'}
            children={this.state.msg}
            severity={this.state.severity}
            updateFeedback={this.updateFeedback}
          />
        )}
        {this.state.expandCC &&
          transcription.map((item, index) => {
            return (
              <BoundaryError key={`boundary_${index}`}>
                <AudioText
                  key={index}
                  currentTime={this.state.currentTime}
                  handleTextClick={this.handleTextClick}
                  transcription={item.words}
                  who={item.type === 1 ? user_id : 'others'}
                />
              </BoundaryError>
            );
          })}
      </div>
    );
  };
}

const mapStateToProps = (state, ownprops) => {
  const { transcriptionState } = state;
  const { id } = ownprops;
  const transcription = (transcriptionState[id] || {}).transcription || [];
  return { transcription };
};

const mapDispatchToProps = dispatch => {
  return {
    updateTranscriptionByRecId: ({ id, transcription }) =>
      dispatch(updateTranscriptionByRecId({ id, transcription })),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(AudioPlayer));
