import React from 'react';
import styles from './ForwardButton.scss';
import { Button } from '../reusable_components';
import { mergeClasses } from '../../utils';

import store from '../../store';
const { dispatch, getState } = store;

const ForwardButton = props => {
  const { task } = props;

  let disableForward = task.sms && task.sms.cant_send;

  return (
    <Button
      allowMultipleClicks={false}
      className={mergeClasses(
        styles.forward_button,
        disableForward ? styles.forward_disabled : ''
      )}
      disableButton={!!disableForward}
      onClickHandler={async e => {
        e.preventDefault();
        e.stopPropagation();
        dispatch({
          type: 'UPDATE_DIRECTORY',
          directoryState: {
            ...getState().directoryState,
            display: true,
            forwardSms: true,
            task,
          },
        });
      }}
    >
      <div className={mergeClasses(styles.forward_icon)}></div>
      {'FORWARD'}
    </Button>
  );
};

export default ForwardButton;
