import React, { useEffect, useRef } from 'react';

import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import * as helpers from '../../utils';
import * as services from '../../services';

import { connect } from 'react-redux';
import store from '../../store';
import { useState } from 'react';
const { dispatch } = store;

// AutoLogout component should be loaded after Okta authencated
// isAutoLogoutEnabled defaults to true.
//   1. it's not configured on backend
//   2. only configurable on the settings panel
// two intervals defined in userState inactivity object
//   1. inactivity_timeout_seconds           interval for displaying the popup
//   2. inactivity_timeout_countdown_seconds interval for the countdown on the popup
const AutoLogout = props => {
  const { userState, autoLogoutState, flags } = props;
  if (!userState.id && !userState.integration) {
    // empty id and integration means the auth hasn't completed yet
    return <></>;
  }

  const { isAutoLogoutEnabled } = userState;
  if (
    isAutoLogoutEnabled != undefined &&
    isAutoLogoutEnabled != null &&
    !isAutoLogoutEnabled
  ) {
    return <></>; // don't bother
  }

  const [user, setUser] = useState(null);
  const [clockTimer, setClockTimer] = useState(null);
  const resetClockMarkRef = useRef(false);

  useEffect(() => {
    // fetch user skills
    const fetchUser = async () => {
      const users = await services.filterUsers({ ids: userState.id });
      console.log(users);
      if (users?.length > 0) {
        setUser(users[0]);
      }
    };
    fetchUser();

    // for throttling DOM event
    setInterval(() => {
      if (resetClockMarkRef.current) {
        dispatch({
          type: 'SET_REFRESH_CLOCK',
        });
        resetClockMarkRef.current = false;
      }
    }, 2000);
  }, []);

  useEffect(() => {
    if (isAutoLogoutOn()) {
      // DOM Events
      document.onclick = markResetClock;
      document.onmousemove = markResetClock;
      document.onmouseup = markResetClock;
      document.onmousedown = markResetClock;
      document.onkeypress = markResetClock;

      // we should exclude message from Okta reAuth. Otherwise the auto logout will be hardly triggered
      window.onmessage = markResetClock;

      // initial clock, one per reload
      startClock();
    } else {
      clearTimeout(clockTimer);
    }
  }, [flags, user]);

  const isAutoLogoutOn = () => {
    if (!user || !flags.enableAutoLogout) {
      return true;
    }

    console.log(flags.enableAutoLogout);
    const autoLogoutLDFilters = flags.enableAutoLogout || {};

    let enableAutoLogout = false;
    Object.keys(autoLogoutLDFilters).forEach(filterKey => {
      const userVal = user[filterKey];
      if (!userVal) {
        return;
      }

      // enableAutoLogout LD flag is an object of arrays.
      const filterVals = autoLogoutLDFilters[filterKey];
      filterVals.forEach(filterVal => {
        if (Array.isArray(userVal)) {
          userVal.forEach(val => {
            if (val.toLowerCase().includes(filterVal.toLowerCase())) {
              enableAutoLogout = true;
            }
          });
        } else {
          if (userVal.toLowerCase().includes(filterVal.toLowerCase())) {
            enableAutoLogout = true;
          }
        }
      });
    });

    return enableAutoLogout;
  };

  const startClock = () => {
    const warnInterval = userState.inactivity
      ? userState.inactivity.inactivity_timeout_seconds * 1000
      : 600 * 1000;
    const timer = setTimeout(() => {
      dispatch({
        type: 'SET_TRIGGER_AUTO_LOGOUT',
      });
    }, warnInterval);

    setClockTimer(timer);
  };

  // handler for DOM event.
  const markResetClock = e => {
    resetClockMarkRef.current = true;
    if (e.origin) {
      console.log(`markResetClock triggered by: ${e.origin}`);
    }
  };

  useEffect(() => {
    if (autoLogoutState.triggerAutoLogout) {
      clearTimeout(clockTimer);
      triggerAutoLogoutNotification();

      dispatch({
        type: 'UNSET_TRIGGER_AUTO_LOGOUT',
      });
      return;
    }

    if (autoLogoutState.refreshClock) {
      clearTimeout(clockTimer);

      startClock();

      dispatch({
        type: 'UNSET_REFRESH_CLOCK',
      });
    }
  }, [autoLogoutState]);

  const triggerAutoLogoutNotification = () => {
    const { inactivity, statusList } = userState;

    const logoutInterval = inactivity
      ? inactivity.inactivity_timeout_countdown_seconds * 1000
      : 10 * 1000; // count down for re-enabling session before actual logout

    const ignoreInactivity = JSON.parse(
      localStorage.getItem('ignoreInactivity')
    );
    const audioPlaying = JSON.parse(sessionStorage.getItem('audioPlaying'));
    if (ignoreInactivity || (audioPlaying && audioPlaying.playing)) {
      return;
    }

    try {
      if (!window.document.hasFocus()) {
        const logoffNotification = new window.Notification('Inactivity', {
          body: `You will be logged out in ${logoutInterval / 1000} secs`,
        });
      }

      dispatch({
        type: 'UPDATE_ACTIVITY',
        activityState: {
          showCountDown: true,
          statusBeforeCountDown: userState.status,
        },
      });

      // put agent to unavailable status
      const unavailableStatus = statusList.find(
        sl => (sl.label || sl.name || '').toLowerCase() === 'unavailable'
      );

      unavailableStatus &&
        services
          .updateUserStatus(
            unavailableStatus?.label,
            0,
            'LOGOUT_REASON_UNDEFINED'
          )
          .then(res => res.json())
          .then(data => {
            const { available, status } = data;
            dispatch({
              type: 'UPDATE_USER',
              userState: {
                ...userState,
                status,
                available,
              },
            });
          });
    } catch (e) {
      console.log('Error on notification = ', e);
    }
  };

  useEffect(() => {
    window.timerInterval =
      window.timerInterval ||
      setInterval(() => {
        const list = Array.from(document.getElementsByClassName('timer') || []);

        list.forEach((node, index) => {
          const timerOption = JSON.parse(node.dataset.timer);

          const text = helpers[timerOption.function](timerOption);

          node.innerText = text;
        });
      }, 5000);
  }, []);

  return <></>;
};

const mapStateToProps = state => {
  const { userState, autoLogoutState } = state;
  return {
    userState,
    autoLogoutState,
  };
};

export default connect(mapStateToProps)(withLDConsumer()(AutoLogout));
