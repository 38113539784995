const env = {
  production: {
    env: 'production',
    url: 'communicator-controller.prd.ffngcp.com',
    voicehubBaseUrl: 'https://comm-voice-hub.prd.ffngcp.com',
    communicatorUrl: 'communicator://communicator-client.prd.ffngcp.com',
    communicatorWebappUrl: 'https://communicator-client.prd.ffngcp.com',
    genesysEnvironment: 'mypurecloud.com',
  },
  staging: {
    env: 'staging',
    url: 'communicator-controller.stg.ffngcp.com',
    voicehubBaseUrl: 'https://comm-voice-hub.stg.ffngcp.com',
    communicatorUrl: 'communicator://communicator-client.stg.ffngcp.com',
    communicatorWebappUrl: 'https://communicator-client.stg.ffngcp.com',
    genesysEnvironment: 'usw2.pure.cloud',
  },
  development: {
    env: 'development',
    url: 'communicator-controller.dev.ffngcp.com',
    voicehubBaseUrl: 'https://comm-voice-hub.dev.ffngcp.com',
    communicatorUrl: 'communicator://communicator-client.dev.ffngcp.com',
    communicatorWebappUrl: 'https://communicator-client.dev.ffngcp.com',
    genesysEnvironment: 'usw2.pure.cloud',
  },
  local: {
    env: 'local',
    url: 'localhost:8088',
    voicehubBaseUrl: 'https://comm-voice-hub.dev.ffngcp.com',
    communicatorUrl: 'communicator://localhost:8080',
    communicatorWebappUrl: 'https://communicator-client.dev.ffngcp.com',
    genesysEnvironment: 'usw2.pure.cloud',
  },
  custom: { env: 'custom', socketURL: '' },
};

export default env;
