import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TableCell,
  TableRow,
} from '@material-ui/core';
import { useState, default as React } from 'react';
import { mergeClasses } from '../../../utils';
import styles from './skills.scss';
import { UserMngConnector } from './connectors';
import { Feedback } from '../../../utils';
import { BoundaryError } from '../../reusable_components/boundaryerror';

const UserSkillRow = props => {
  const {
    agent,
    managedSkills = [],
    editable,
    updateSkills,
    updateFeedback,
  } = props;

  const agentSkills = agent.skills || [];
  const displayedSkills = [...(managedSkills || [])];
  for (const skill of agentSkills) {
    if (!displayedSkills.find(s => s.name === skill)) {
      displayedSkills.push({
        name: skill,
      });
    }
  }

  const initialSkills = {};
  (agent.skills || []).forEach(s => {
    initialSkills[s] = true;
  });
  const [selectedSkills, setSelectedSkills] = useState(initialSkills);
  const [updating, setUpdating] = useState(false);
  const handleChange = event => {
    setSelectedSkills({
      ...selectedSkills,
      [event.target.name]: event.target.checked,
    });
  };

  const updated = !(
    Object.keys(selectedSkills)
      .map(s => selectedSkills[s] === !!initialSkills[s])
      .reduce((acc, curr) => acc && curr, true) &&
    Object.keys(initialSkills)
      .map(s => !!selectedSkills[s] == initialSkills[s])
      .reduce((acc, curr) => acc && curr, true)
  );

  return (
    <BoundaryError>
      <TableRow>
        <TableCell className={mergeClasses(styles.cell)}>
          {agent.username}
        </TableCell>
        <TableCell className={mergeClasses(styles.cell)}>
          {agent.team}
        </TableCell>
        <TableCell className={mergeClasses(styles.cell)}>
          {agent.group}
        </TableCell>
        <TableCell className={mergeClasses(styles.cell)}>
          <FormGroup row>
            {displayedSkills
              .sort((a, b) =>
                (a.name || '').toUpperCase() > (b.name || '').toUpperCase()
                  ? 1
                  : -1
              )
              .map(s => {
                return (
                  <FormControlLabel
                    key={s.name}
                    control={
                      <Checkbox
                        checked={!!selectedSkills[s.name]}
                        onChange={editable ? handleChange : () => null}
                        name={s.name}
                        color="default"
                      />
                    }
                    label={s.name}
                    disabled={!editable}
                    className={
                      (agent.skills || []).includes(s.name) !=
                      !!selectedSkills[s.name]
                        ? mergeClasses(styles.updated, styles.skill)
                        : mergeClasses(styles.skill)
                    }
                    style={{ color: 'white !important' }}
                  />
                );
              })}
            {editable && (
              <Button
                disabled={!updated}
                variant="contained"
                color="primary"
                onClick={
                  updating
                    ? () => null
                    : async () => {
                        setUpdating(true);
                        try {
                          const skills = Object.keys(selectedSkills).filter(
                            s => selectedSkills[s] === true
                          );
                          await updateSkills(skills);
                        } catch (e) {
                          console.error(e);
                          updateFeedback(
                            `${e.code}: ${e.message}`,
                            Feedback.ERROR
                          );
                        }
                        setUpdating(false);
                      }
                }
              >
                {updating ? 'Updating...' : 'Save'}
              </Button>
            )}
          </FormGroup>
        </TableCell>
      </TableRow>
    </BoundaryError>
  );
};

export default UserMngConnector(UserSkillRow);
