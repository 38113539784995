import React, { Component } from 'react';

export class BoundaryError extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, open: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error(
      `Error loading component: [${error}] ${errorInfo.componentStack}`
    );
  }

  render() {
    const { error, errorInfo, open } = this.state;
    if (errorInfo) {
      return (
        <div style={{ color: '#fff' }}>
          <h2>Error Loading Component</h2>
          <details open={open} onClick={() => this.setState({ open: !open })}>
            <b>{error && error.toString()}</b>
            <br />
            {errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}
