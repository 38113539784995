import React from 'react';
import { connect } from 'react-redux';
import { Box, Card, CardHeader, CardContent, Grid } from '@material-ui/core';

import styles from './Metric.scss';
import { formatTime, mergeClasses, sortByKey } from '../../../utils';

const Metric = ({ metrics, statusMetrics, selectedUser }) => {
  const headingMapping = {
    avg_call_task_complete_seconds: 'AHT',
    dir_in_offers_total: 'Direct Inbound Calls',
    call_task_talk_seconds_total: 'Talk time',
    oat: 'OAT%',
    offers_accepted_total: 'Offers Accepted',
    offers_total: 'Offers',
    out_call_offers_total: 'Outbound Calls',
    rr_avg_accepted_seconds: 'RR Avg Accept Time',
    rr_in_call_offers_total: 'RR Call Offers',
    sms_received_total: 'SMS Received',
    sms_sent_total: 'SMS Sent',
  };
  metrics = (metrics).filter(m => !!headingMapping[m.name]);
  return (
    <Box m={1}>
      <Grid container spacing={4}>
        {(metrics || []).map((metric, index) => {
          return (
            <Grid item xs={3} key={`${metric}_${index}`}>
              <Card className={mergeClasses(styles.card)}>
                <CardHeader
                  title={headingMapping[metric.name]}
                  aria-label={`${selectedUser}_${metric.name}`}
                />
                <CardContent
                  aria-label={`${selectedUser}_${metric.name}_value`}
                >
                  {(metric.name || '').includes('seconds')
                    ? formatTime(metric.value || 0)
                    : Math.floor(metric.value || 0)}
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        {sortByKey((statusMetrics || {}).statuses || [], 'name').map((s, i) => {
          return (
            <Grid item xs={3} key={`${s}_${i}`}>
              <Card className={mergeClasses(styles.card)}>
                <CardHeader title={s.name} />
                <CardContent>{formatTime(s.seconds || 0)}</CardContent>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { metricsState } = state;
  const { userMetrics, userStatusMetrics, selectedUser } = ownprops;

  return {
    metrics: selectedUser ? metricsState[selectedUser].metrics : userMetrics,
    statusMetrics: selectedUser
      ? metricsState[selectedUser].statusMetrics
      : userStatusMetrics,
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Metric);
