import * as services from '../../services';
import {
  increaseEmailCount,
  updateEmailList,
  pushEmail,
} from '../../store/action/email';
import { updateTask } from '../../store/action/task';

const updateTaskState = async (message, helpers, store) => {
  const { task_id } = message;
  const { getState, dispatch } = store;
  const { taskState } = getState();
  let { tasks } = taskState;
  let updatedTask = await services.fetchUpdatedTaskById(task_id);

  tasks = tasks.map(item => {
    if (item.id === updatedTask.id) {
      return updatedTask;
    }

    return item;
  });
  taskState.tasks = tasks;

  dispatch({
    type: 'UPDATE_TASKS',
    taskState: {
      ...taskState,
    },
  });
};

export const emailEvent = (message, helpers, store) => {
  const {
    task_id,
    text,
    business_id,
    direction,
    sender,
    recipient,
    email,
    event_type,
    subject,
    status,
    created_at,
    user_id,
    email_id,
  } = message;
  console.log(`email ${event_type}`, message);
  const { getState, dispatch } = store;
  const { emailState, taskState } = getState();
  emailState[task_id] &&
  emailState[task_id].email_list &&
  emailState[task_id].email_list.find(
    email => email.id.toString() === email_id.toString()
  )
    ? dispatch(updateEmailList(task_id, email))
    : dispatch(pushEmail(task_id, email));

  switch (event_type.toLowerCase()) {
    case 'received': {
      console.log('received');
      const { detailView, tasks } = taskState;
      if (task_id !== detailView) {
        dispatch(increaseEmailCount(task_id));
      }

      try {
        const notification = new window.Notification('New Email', {
          body: `${message.sender}: ${message.subject}`,
        });
      } catch (e) {
        console.log('Error on email notification = ', e);
      }

      tasks.map(task => {
        task.id === task_id && (task.updated_at = created_at);
      });

      dispatch(updateTask(taskState));
      break;
    }
    case 'sent': {
      console.log('sent');
    }

    default:
      updateTaskState(message, helpers, store);
      break;
  }
};
