// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Metric__card___1zwIb{height:100%}.Metric__card___1zwIb.Metric__selected___BwuK2{background-color:#21b0e8}", ""]);
// Exports
exports.locals = {
	"card": "Metric__card___1zwIb",
	"selected": "Metric__selected___BwuK2"
};
module.exports = exports;
