import React, { useState, useEffect } from 'react';
import styles from '../supervisor.scss';
import { connect } from 'react-redux';
import { mergeClasses, timerFormatter, getOktaHeader } from '../../../utils';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import {
  fetchAgentStateById,
  coachTaskbyId,
  disconnectUserByCallId,
} from '../../../services';

import store from '../../../store';
const { dispatch, getState } = store;

const TaskList = props => {
  const { task, supervisorState, agentState, taskState, user, supervisorTelephonyProvider } = props;
  const userTelephonyProvider = user.telephonyProvider?.toLowerCase().includes('genesys') ? 'genesys' : 'twilio';
  const coachingAvailable = supervisorTelephonyProvider === userTelephonyProvider;

  const timer = timerFormatter({ start: task.updated_at });

  const [tick, setTick] = useState(timer);

  // Update timer next to active call
  useEffect(() => {
    const taskInterval = setInterval(() => {
      setTick(tick => timerFormatter({ start: task.updated_at }));
    });
    return () => {
      clearInterval(taskInterval);
    };
  }, [tick]);
  const key = Object.keys(supervisorState);
  const coachingTask =
    key &&
    key.find(item => {
      return (
        supervisorState[item] &&
        supervisorState[item].parent_task_id &&
        supervisorState[item].parent_task_id === task.id
      );
    });

  let supInCall = coachingTask && supervisorState[coachingTask].supInCall;

  taskState.tasks &&
    taskState.tasks.map(task => {
      task.type.toUpperCase() === 'COACHING' &&
        supervisorState[task.parent_task_id] &&
        supervisorState[task.parent_task_id].task_id === task.id &&
        supervisorState.shouldSwitchView &&
        useHistory().push('/');
    });

  let CallState = supervisorState[coachingTask] && (
    <>
      {supervisorState[coachingTask].status ? (
        <>
          <span
            className={mergeClasses(
              styles.task_icon,
              styles[
                supervisorState[coachingTask].status === 'CONNECTED'
                  ? supervisorState[coachingTask].status
                  : 'CONNECTING'
              ]
            )}
          ></span>
          <span
            className={mergeClasses(
              styles.call_connected,
              styles[
                supervisorState[coachingTask].status === 'CONNECTED'
                  ? supervisorState[coachingTask].status
                  : 'CONNECTING'
              ]
            )}
          >
            {supervisorState[coachingTask].status}
          </span>
        </>
      ) : (
        <>
          <span
            className={mergeClasses(styles.task_icon, styles[task.type])}
          ></span>
          <span className={styles.task_type_info}>
            {task.type} {tick}
          </span>
        </>
      )}
    </>
  );

  return (
    <div
      key={task.id}
      className={mergeClasses(
        styles.tasks,
        styles[
          supervisorState[coachingTask] && supervisorState[coachingTask].status
            ? supervisorState[coachingTask].status === 'CONNECTED'
              ? supervisorState[coachingTask].status
              : 'CONNECTING'
            : ''
        ]
      )}
      onClick={async e => {
        e.preventDefault();
        if (!coachingAvailable) {
          return;
        }

        await fetchAgentStateById(task.user_id);

        if (task.call && task.call_info) {
          const participants =
            task.call_info.participants && task.call_info.participants;
          const callStatus = task.call_info.status;

          dispatch({
            type: 'UPDATE_AGENT',
            agentState: {
              ...agentState,
              taskId: agentState.taskId === task.id ? null : task.id,
              user,
              tab: 'tasks',
            },
          });

          if (!supInCall) {
            if (callStatus === 'active') {
              if (participants.length >= 2) {
                // Check if supervisor is already in the same call
                let coachAgentCall = await coachTaskbyId(task.id);

                if (coachAgentCall.ok) {
                  console.log('%cCoaching task set to ok', 'color: #fba130');
                } else {
                  console.log('coaching task is not ok');
                }
              } else {
                console.warn('Agent call on hold');
                dispatch({
                  type: 'UPDATE_SUPERVISOR',
                  supervisorState: {
                    ...supervisorState,
                    [task.id]: {
                      ...supervisorState[task.id],
                      parent_task_id: task.id,
                      status: 'CANNOT JOIN',
                      supInCall: false,
                    },
                  },
                });
              }
            }
          } else {
            // Sup is already in a call. Disconnect the active call to rejoin
            let disconnectSupCall = await disconnectUserByCallId(task.id);

            if (disconnectSupCall.ok) {
              console.log(
                'succesfully disconnected sup from call',
                await disconnectSupCall
              );
            } else {
              console.log(
                'didnot disconnec tsup from call',
                await disconnectSupCall
              );
            }
          }
        }
      }}
    >
      {!coachingAvailable ? 
      <span 
        className={styles.task_type_warn}>
          not available
        </span> : ''}

      {CallState ? (
        <>{CallState}</>
      ) : (
        <>
          <span
            className={mergeClasses(styles.task_icon, styles[task.type])}
          ></span>
          <span className={styles.task_type_info}>
            {task.type} {tick}
          </span>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const {
    userState,
    integrationState,
    supervisorState,
    taskState,
    agentState,
  } = state;
  const { roles = [] } = userState;
  const { acl = {} } = integrationState;
  const userRoles = [];
  for (const role of roles) {
    userRoles.push(role, ...((acl || {})[role] || []));
  }

  const supervisorTelephonyProvider = userState.telephonyProvider;
  return { supervisorState, userRoles, taskState, agentState, supervisorTelephonyProvider };
};

export default connect(mapStateToProps)(TaskList);
