export const broadcastState = (state = {}, action) => {
  switch (action.type) {
    case 'BROADCAST_MESSAGE': {
      const { text, sender, created_at, id } = action;
      const newState = { ...state };
      let { unreadMessages = [] } = newState;
      !unreadMessages.includes(id) && unreadMessages.push(id);
      newState.message = { text, sender, created_at, id };

      return { ...newState, unreadMessages };
    }
    case 'UPDATE_BROADCAST_MESSAGES': {
      const { data } = action;
      const newState = { ...state };
      const list = [...data];
      let { selectedId } = newState;
      newState.messages = list;
      selectedId = (list.find(o => o.id === selectedId) || list[0] || {}).id;
      return { ...newState, selectedId, message: {} };
    }
    case 'SELECT_MESSAGE_BY_ID': {
      const { id } = action;
      const newState = {
        ...state,
        selectedId: id,
      };
      return newState;
    }
    case 'BROADCAST_MESSAGE_READ_BY_ID': {
      const { id } = action;
      const newState = { ...state };
      const { unreadMessages = [] } = newState;

      return {
        ...newState,
        unreadMessages: unreadMessages.filter(um => um !== id),
      };
    }
    case 'MARK_ALL_MESSAGES_AS_READ': {
      return { ...state, unreadMessages: [] };
    }
  }

  return state;
};
