export const updateHDPhones = ({ phones }) => {
  return {
    type: 'UPDATE_USER_HOTDESK_PHONES',
    phones,
  };
};

export const setOpenHotdeskModalFlag = ({ openHotdeskModal }) => {
  return {
    type: 'UPDATE_USER_HOTDESK_MODAL_FLAG',
    openHotdeskModal,
  };
};
