/*
 Use a dummy electron IPCRenderer object for accomodating event handler defined in FDR suip_v2_extension
 Use standard custom event for event distributing
*/
export const registerWebappEventHandlerAdapter = () => {
  window.electron = {
    ipcRenderer: {
      on: (eventId, handler) => {
        document.addEventListener(eventId, event => {
          handler(event, event.detail);
        });
      },
    },
    shell: {
      openExternal: url => {
        window.open(url, '_blank');
      },
    },
  };
};

/* 
  eventId is definied in action section of task template
  for example
    "actions": [
        {
            "id": "OPEN_SALEFORCE_PROFILE",
            "icon": "cloud",
            "label": "Open in Salesforce"
        }
    ],

  currently only OPEN_SALEFORCE_PROFILE is defined for opening SF app on the client
*/
export const createTaskEvent = (eventId, task) => {
  // there's a bug in current suip_v2_extension.js on destructuring task.contact.metadata.suip object
  // to fix it, use a dummy empty suip object if it's not defined
  // NOTE. this fix only works with current existing suip task template structure.
  let taskInput = task;
  if (!task.contact?.metadata?.suip) {
    taskInput = {
      ...task,
      contact: {
        ...(task.contact || {}),
        metadata: {
          ...(task.contact.metadata || {}),
          suip: {
            // for testing
            custom_search_url: 'https://www.google.com',
          },
        },
      },
    };
  }
  return new CustomEvent(eventId, {
    detail: taskInput,
  });
};
