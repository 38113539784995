import React from 'react';
import { TextField } from '@material-ui/core';

const SMSFilter = ({
  setClientSMSNumber,
  clientSMSNumber
}) => {
  return (
    <TextField
      value={clientSMSNumber}
      onChange={e => {
        e.preventDefault();
        e.stopPropagation();
        setClientSMSNumber(e.target.value);
      }}
      id="phone"
      label="SMS participant"
      variant="standard"
    />
  );
};


export default SMSFilter;
