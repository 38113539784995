import React, { useEffect, useState } from 'react';
import { InputBase, Tab, Tabs } from '@material-ui/core';
import {
  Search as SearchIcon,
  Close as CloseIcon,
  Cancel as CancelIcon,
} from '@material-ui/icons';
import { makeStyles, fade } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import AtoZ from './atoz';
import Group from './group';
import styles from './directory.scss';
import store from '../../store';
import { BoundaryError } from '../reusable_components/boundaryerror';
const { dispatch } = store;

const useStyles = makeStyles(theme => {
  return {
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: 400,
      alignItems: 'center',
      justifyContent: 'center',
    },
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 1),
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    tabs: {
      width: '100%',
    },
    buttonTab: {
      width: '100%',
    },
  };
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
  };
}

let directoryTab = 0;

const Directory = props => {
  const {
    taskState,
    teamState,
    helpers,
    services,
    directoryState,
    callState,
  } = props;
  const { mergeClasses } = helpers;
  const classes = useStyles();
  const task =
    directoryState.task ||
    helpers.getTaskByCallInfo({ id: directoryState.callId } || 0, callState) ||
    {};
  const userDirectory = (task.directory || {}).directory_filter;
  const [search, setSearch] = useState('');
  const [next_search, setNextSearch] = useState('');
  const [waitForSearch, setWaitForSearch] = useState(null);
  useEffect(() => {
    clearInterval(waitForSearch);
    setWaitForSearch(
      setTimeout(() => {
        setSearch(next_search.toLowerCase());
      }, 500)
    );
  }, [next_search]);
  return (
    <div id="directory" className={styles.directory}>
      <div className={mergeClasses(styles.search_row)}>
        <div className={mergeClasses(classes.search, styles.search_bar)}>
          <div className={mergeClasses(classes.searchIcon)}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: mergeClasses(classes.inputInput, styles.search_field),
            }}
            value={directoryState.searchTerm || ''}
            onChange={e => {
              e.preventDefault();
              e.stopPropagation();

              let val = e.target.value;
              setNextSearch(val);
            }}
            value={next_search}
          ></InputBase>
          <div
            className={mergeClasses(classes.cancelIcon, styles.search_cancel)}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();

              dispatch({
                type: 'UPDATE_DIRECTORY',
                directoryState: {
                  ...directoryState,
                  searchTerm: null,
                },
              });
            }}
          >
            <CancelIcon />
          </div>
        </div>

        <CloseIcon
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            clearInterval(directoryState.fetchAgentInterval);
            dispatch({
              type: 'UPDATE_DIRECTORY',
              directoryState: {
                ...directoryState,
                callId: null,
                display: false,
                addNumber: '',
                fetchAgentInterval: null,
              },
            });
          }}
        />
      </div>
      <Tabs
        variant="fullWidth"
        value={directoryState.tab}
        indicatorColor="primary"
        classes={{
          flexContainer: mergeClasses(classes.tab),
          root: mergeClasses(styles.tab_bar),
        }}
        onChange={(e, newValue) => {
          e.preventDefault();
          e.stopPropagation();

          dispatch({
            type: 'UPDATE_DIRECTORY',
            directoryState: {
              ...directoryState,
              tab: newValue,
              searchTerm: null,
            },
          });
        }}
      >
        <Tab label="GROUP" {...a11yProps(0)} />
        <Tab label="A-Z" {...a11yProps(1)} />
      </Tabs>

      {directoryState.tab === 0 ? (
        <BoundaryError>
          <Group {...props} task={task} search={search} />
        </BoundaryError>
      ) : (
        <BoundaryError>
          <AtoZ {...props} filter={userDirectory} search={search} />
        </BoundaryError>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    taskState: state.taskState,
    userState: state.userState,
    teamState: state.teamState,
    directoryState: state.directoryState,
    callState: state.callState,
  };
};

const mapDispatchToProps = dispatch => ({
  updateConnectionFunc: state => {},
});

export default connect(mapStateToProps, mapDispatchToProps)(Directory);
