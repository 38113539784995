import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import { filterUsers } from '../../../services';
import { TextField, Chip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  indicator: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  textRoot: {
    '& .MuiFormLabel-root': {
      color: 'rgba(255, 255, 255, 0.54)',
      textTransform: 'capitalize',
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.42)',
    },
    '& .MuiInputBase-input': {
      color: 'rgba(255, 255, 255, 0.89)',
    },
  },
  tag: { color: 'rgba(255, 255, 255, 0.54)' },
  deleteIcon: { fill: '#e7474e' },
  label: { color: '#000' },
}));

export const Selection = ({
  teams,
  label,
  integration,
  handleList,
  selectedList,
}) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);

  let mounted = true;

  useEffect(() => {
    if (mounted) {
      handleList([]);
    }

    const handler = async () => {
      // fetch list of users
      const users = [];
      const limit = 100;
      let offset = 0;
      let chunk = null;
      try {
        while (chunk == null || chunk.length === limit) {
          chunk = await filterUsers({
            integration,
            team: teams,
            limit,
            offset,
          });
          chunk = chunk || [];
          offset = offset + limit;
          users.push(...chunk);
        }

        mounted && setOptions([...users.map(u => u.id)]);
      } catch (e) {
        console.error(e);
        mounted && updateFeedback(`${e.code}: ${e.message}`, Feedback.ERROR);
      }
    };
    label === 'agent' ? handler() : setOptions([...teams]);

    return () => {
      mounted = false;
    };
  }, [label]);

  return (
    <Autocomplete
      style={{ minWidth: '400px' }}
      value={selectedList}
      onChange={(event, value) => {
        handleList(value);
      }}
      classes={{
        popupIndicator: classes.indicator,
        clearIndicator: classes.indicator,
        tag: classes.tag,
      }}
      multiple
      id={'select'}
      options={options}
      limitTags={2}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              classes={{
                deleteIcon: classes.deleteIcon,
                label: classes.label,
              }}
              label={option}
              {...getTagProps({ index })}
            />
          );
        })
      }
      getOptionLabel={option => option}
      getOptionSelected={(option, value) => option === value}
      renderInput={params => {
        return (
          <TextField
            {...params}
            variant="standard"
            label={label}
            className={classes.textRoot}
          />
        );
      }}
    />
  );
};

const mapStateToProps = state => {
  const { userState } = state;
  const { team, teams = [], integration } = userState;

  return {
    teams: teams.length ? teams : team ? [team] : [],
    integration,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Selection);
