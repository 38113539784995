import React, { useState } from 'react';
import { connect } from 'react-redux';
import styles from './Email.scss';
import sanitizeHtml from 'sanitize-html';
import {
  displayTimestamp,
  mergeClasses,
  getImagesList,
} from '../../../utils/helpers';
import { deleteErrorMsg } from '../../../store/action/error';
import EmailBody from './EmailBody';
import Subject from './info/Subject';
import Sender from './info/Sender';
import Recipients from './info/Recipients';
import Attachments from './info/Attachments';

const Email = ({
  id,
  sender,
  recipients,
  subject,
  html,
  status,
  created_at,
  events,
  direction,
  showEmailEditor,
  errorState,
  deleteErrorMsg,
  text,
  isLatestEmail,
  attachments,
  task_id,
}) => {
  const [expand, setExpand] = useState(false);
  const imgs = getImagesList(html);
  return (
    <div className={styles.email_wrapper}>
      <Subject mergeClasses={mergeClasses} editable={false} subject={subject} status={status} events={events} recipients={recipients} />
      <div className={styles.main_info}>
        <div className={styles.icon}>
          {(sender.email || '').toUpperCase().slice(0, 1)}
        </div>
        <div className={styles.email_peek}>
          <Sender
            email_id={id}
            sender={sender}
            timestamp={displayTimestamp(created_at)}
            attachments={attachments}
            task_id={task_id}
          />
          {!expand && !isLatestEmail && (
            <div
              className={styles.email_preview}
              onClick={async e => {
                e.preventDefault();
                setExpand(!expand);
              }}
            >
              {text}
            </div>
          )}
          {(expand || isLatestEmail) && <Recipients recipients={recipients} />}
        </div>
      </div>

      {(expand || isLatestEmail) && (
        <>
          <EmailBody>
            <div
              onClick={e => {
                e.preventDefault();
                !expand && setExpand(!expand);
              }}
              style={{ color: '#fff' }}
              className={styles.message}
              dangerouslySetInnerHTML={{
                __html: `${sanitizeHtml(html)}`,
              }}
            />
          </EmailBody>
          {attachments && <Attachments attachments={attachments} imgs={imgs} />}
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { task_id } = ownprops;
  return {
    showEmailEditor: (state.emailState[task_id] || {}).showEmailEditor,
    errorState: state.errorState,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteErrorMsg: ({ error_type }) => {
    dispatch(deleteErrorMsg({ error_type }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Email);
