import React from 'react';
import { connect } from 'react-redux';

import { userLogout } from '../../../services/user';
import { isWebapp } from '../../../utils/webapp/user_access_control';
import styles from '../home.scss';
import { withOktaAuth } from '@okta/okta-react';

const NoAccessLogout = props => {
  const { authService, authState, environmentState } = props;

  return (
    <div className={styles.setenv_login}>
      <div className={styles.login_wrap}>
        <div className={styles.desktop_icon}></div>
        <p>You are not allowed to access Communicator</p>
        <p>{isWebapp() ? ' Web App' : ' Desktop App'}</p>
        <p>Please contact @platcon-help on slack </p>
      </div>

      <button
        className={styles.login_button}
        onClick={() => {
          userLogout(
            'logged out by not granted access to webapp or electronapp'
          );
          authService.logout('/');
        }}
      >
        Logout
      </button>
      <p style={{ color: '#fff' }}>
        {environmentState.appVersion
          ? `Version ${environmentState.appVersion} from ${window.location.hostname}`
          : environmentState.appVersionLoading
          ? 'Fetching Version...'
          : 'Failed to fetch version '}
      </p>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    environmentState: state.environmentState,
  };
};

export default withOktaAuth(connect(mapStateToProps)(NoAccessLogout));
