// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SMSList__sms_list___3EILN{overflow:hidden !important;display:flex;flex-direction:column;max-height:600px}.SMSList__sms_list___3EILN:hover{overflow-y:overlay !important}.SMSList__date_separator___mUTGh{background-color:#424242;min-height:3px;width:100%}.SMSList__date___1vJpu{height:16px;font-family:Rubik;font-size:14px;font-weight:500;font-stretch:normal;font-style:normal;line-height:1.14;letter-spacing:.25px;color:#fff;margin:2%}", ""]);
// Exports
exports.locals = {
	"sms_list": "SMSList__sms_list___3EILN",
	"date_separator": "SMSList__date_separator___mUTGh",
	"date": "SMSList__date___1vJpu"
};
module.exports = exports;
