import React from 'react';
import styles from '../info/Attachments.scss';
import AttachmentIcon from '@material-ui/icons/Attachment';

const Attachments = ({ attachments, imgs }) => {
  return (
    <>
      {/* {imgs.length > 0 &&
        imgs.map((img, iIndex) => {
          return (
            <div className={styles.not_loaded} key={iIndex}>
              To protect your privacy some pictures in this email are BLOCKED:{' '}
              <span className={styles.img_src} data-tooltip={img.text}>
                {img.src}
              </span>
            </div>
          );
        })} */}
      <div className={styles.attachments}>
        {attachments && attachments.length > 0 && (
          <div className={styles.attachments_count}>
            {attachments.length} Attachment
            {attachments.length !== 1 && <>{`s`}</>}
          </div>
        )}

        {attachments &&
          attachments.length &&
          attachments.map(attachment => (
            <div key={attachment.id} className={styles.attachment}>
              <AttachmentIcon />
              <div className={styles.filename}>{attachment.filename}</div>
            </div>
          ))}
      </div>
    </>
  );
};

export default Attachments;
