export const updateSMSText = (task_id, text) => {
  return {
    type: 'UPDATE_SMS_TEXT',
    task_id,
    text,
  };
};

export const updateSMSList = (task_id, sms) => {
  return {
    type: 'UPDATE_SMS_LIST',
    task_id,
    sms,
  };
};

export const removeSMSText = task_id => {
  return {
    type: 'REMOVE_SMS_TEXT',
    task_id,
  };
};

export const initSMSList = (task_id, list) => {
  return {
    type: 'INIT_SMS_LIST',
    task_id,
    list,
  };
};

export const updateSMSReplyLabel = ({ task_id, label }) => {
  return {
    type: 'UPDATE_SMS_REPLY_LABEL',
    task_id,
    label,
  };
};

export const pushSMS = (task_id, sms) => {
  return {
    type: 'PUSH_SMS',
    task_id,
    sms,
  };
};
export const pushSMSList = (task_id, sms_list) => {
  return {
    type: 'PUSH_SMS_LIST',
    task_id,
    sms_list,
  };
};

export const unshiftSMSList = (task_id, sms_list) => {
  return {
    type: 'UNSHIFT_SMS_LIST',
    task_id,
    sms_list,
  };
};

export const smsError = ({ task_id, error, sms_id, status }) => {
  return {
    type: 'SMS_ERROR',
    task_id,
    error,
    sms_id,
    status,
  };
};

export const unshiftSMS = (task_id, list) => {
  return {
    type: 'UNSHIFT_SMS',
    task_id,
    list,
  };
};

export const increaseSMSCount = task_id => {
  return {
    type: 'INCREASE_SMS_COUNT',
    task_id,
  };
};

export const decreaseSMSCount = task_id => {
  return {
    type: 'DECREASE_SMS_COUNT',
    task_id,
  };
};

export const setScrollToTheLastSMS = () => {
  return {
    type: 'SET_SCROLL_TO_THE_LAST_SMS',
  };
};

export const unsetScrollToTheLastSMS = () => {
  return {
    type: 'UNSET_SCROLL_TO_THE_LAST_SMS',
  };
};
