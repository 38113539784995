import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

import { withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';

export const ExpansionPanel = withStyles({
  root: {
    width: '100%',
    backgroundColor: '#212121',
    color: '#fff',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    border: '1px solid #424242',
    marginBottom: -1,
    minHeight: 10,
    '&$expanded': {
      minHeight: 10,
    },
  },
  content: {
    display: 'initial',
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const ExpansionPanelDetails = withStyles(theme => ({
  root: {
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#333',
    marginTop: '2px',
  },
}))(MuiAccordionDetails);

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#333',
    color: '#fff',
  },
  body: {
    fontSize: 14,
    backgroundColor: '#333',
    color: '#fff',
  },
}))(TableCell);
