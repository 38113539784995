import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import styles from './Recipient.scss';
import Recipient from './Recipient';
import { mergeClasses, groupRecipientsByType } from '../../../../utils/helpers';
import { BoundaryError } from '../../../reusable_components/boundaryerror';
import AddOrSelectEmailId from '../editor/AddOrSelectEmailId';
import EditRecipients from '../editor/EditRecipients';
import { Edit as EditIcon } from '@material-ui/icons';
import { makeStyles, fade } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    color: '#21b0e8',

    '&:hover': {
      color: fade('#21b0e8', 0.8),
    },
  },
}));

export const Recipients = ({
  recipients,
  editable,
  showEmailEditor,
  task_id,
}) => {
  const classes = useStyles();
  const formatRecipients = groupRecipientsByType(recipients);

  const fetchRecipientsSeparatedByComma = (
    recipients.map(r => r.name || r.email) || []
  ).join(', ');

  const [expand, setExpand] = useState(false);
  const [editType, setEditType] = useState([
    { type: 'To', isEditable: false },
    { type: 'Cc', isEditable: false },
    { type: 'Bcc', isEditable: false },
  ]);

  const [to, cc, bcc] = editType;
  const lessStyle = expand && styles.less;

  const handleRecipientType = type => {
    setEditType(editType =>
      editType.map(item => {
        const { isEditable } = item;
        return item.type.toUpperCase() === type.toUpperCase()
          ? {
              ...item,
              isEditable: !isEditable,
            }
          : item;
      })
    );
  };

  return (
    <div className={styles.recipients}>
      <div className={styles.recipients_container}>
        {!showEmailEditor && (
          <span
            className={styles.to}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setExpand(!expand);
            }}
          >
            To{'  '}
          </span>
        )}
        <span
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            setExpand(!expand);
          }}
          className={mergeClasses(styles.expand, lessStyle)}
        />

        {editType.find(item => item.isEditable) && editable ? (
          <EditRecipients
            task_id={task_id}
            recipients={recipients}
            handleRecipientType={handleRecipientType}
            editType={editType}
            buttonClasses={classes.button}
          />
        ) : (
          <span className={styles.recipients_name}>
            {fetchRecipientsSeparatedByComma}
          </span>
        )}
        {editable && !to.isEditable && !cc.isEditable && !bcc.isEditable && (
          <EditIcon
            className={classes.button}
            onClick={e => {
              e.preventDefault();
              handleRecipientType(to.type);
            }}
          />
        )}
      </div>
      {expand && (
        <div className={styles.recipients_box}>
          {formatRecipients &&
            (Object.keys(formatRecipients) || []).map((type, tIndex) => {
              let recipients = (formatRecipients[type] || []).map(recipient => {
                const { id, email, name, type } = recipient;
                return (
                  <BoundaryError key={`boundaryKey${id}`}>
                    <Recipient key={id} name={name} email={email} type={type} />
                  </BoundaryError>
                );
              });
              return (
                <div key={`fragment_${tIndex}`} className={styles.type_wrapper}>
                  <div className={styles.type}>
                    {type.charAt(0).toUpperCase() + type.slice(1)}
                  </div>
                  <div className={styles.recipients_wrapper}>{recipients}</div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { task_id } = ownprops;
  const { emailState, errorState } = state;
  const showEmailEditor = (emailState[task_id] || {}).showEmailEditor;
  return {
    showEmailEditor,
    errorState,
  };
};

const mapDispatchToProps = dispatch => ({
  deleteErrorMsg: ({ error_type }) => {
    dispatch(deleteErrorMsg({ error_type }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Recipients);
