import React, { Component } from 'react';

class TimeCounter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastCheck: Date.now(),
    };
    this.interval = null;
  }
  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({ lastCheck: Date.now() });
    }, 500);
  }
  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
  render() {
    const { start, helpers, style } = this.props;
    return start && helpers ? (
      <div
        className={helpers.mergeClasses('timer', style)}
        data-timer={JSON.stringify({
          direction: 'forward',
          function: 'timerFormatter',
          start,
        })}
      >
        {helpers.timerFormatter({
          direction: 'forward',
          function: 'timerFormatter',
          start,
        })}
      </div>
    ) : null;
  }
}
export default TimeCounter;
