import React from 'react';

import styles from './sidebar.scss';
import { mergeClasses } from '../../utils';

const Supervisor = ({ roles }) => {
  if (roles) {
    return (
      <>
        <div className={styles.icon_wrap}>
          <div
            id="supervisor_icon"
            className={mergeClasses(
              styles.sidebar_icons,
              styles.supervisor_icon
            )}
          ></div>
        </div>
        <div id="supervisor_tab_text" className={styles.tab_text}>
          Supervisor
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default Supervisor;
