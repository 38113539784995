export const teamState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_TEAM': {
      const newState = { ...state, ...action.teamState };
      return newState;
    }
    case 'SELECT_TEAM': {
      return { ...(state || {}), selectedTeam: action.team };
    }
    case 'UPDATE_TEAM_WITH_USERS': {
      return { ...(state || {}), users: action.users };
    }
    case 'UPDATE_TEAM_WITH_COMPLETE_TASK_DATA': {
      const { userId, tasks } = action;
      const newState = { ...state };
      (newState.users || []).map(u => u.id === userId && (u.tasks = tasks));
      return newState;
    }
    case 'UPDATE_TEAM_VIEW': {
      const { teamview } = action;
      const newState = { ...state };
      newState.teamview = teamview;
      return newState;
    }
    case 'UPDATE_TEAM_ARGS': {
      const { name, value } = action;
      const newState = { ...state };
      const { args = {} } = newState;
      args[name] = { ...args[name], value };

      return newState;
    }
    case 'UPDATE_TEAM_SORT_BY_NAME': {
      const { name } = action;
      const newState = { ...state };
      newState.sortBy = name;
      return newState;
    }
    case 'SHOW_ACTIVE_CALL_USERS_IN_TEAM': {
      const { active } = action;
      const newState = { ...state };
      newState.showActiveCallUsers = !!active;
      return newState;
    }
    default:
      return state;
  }
};
