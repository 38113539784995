import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './Subject.scss';
import { updateEmailText } from '../../../../store/action/email';
import { Done as DoneIcon, Edit as EditIcon } from '@material-ui/icons';
import { makeStyles, fade } from '@material-ui/core/styles';
import Status from './Status';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    color: '#21b0e8',

    '&:hover': {
      color: fade('#21b0e8', 0.8),
    },
  },
}));

export const Subject = ({
  subject,
  emailState,
  updateEmailText,
  editable,
  task_id,
  status,
  events,
  recipients,
}) => {
  const classes = useStyles();

  let mounted = true;

  const emailTask = { ...(emailState[task_id] || {}) };
  const { emailText = {} } = emailTask;

  const [editSubject, setEditSubject] = useState(
    !(subject || emailText.subject) ? true : false
  );

  const handleClick = () => {
    setEditSubject(!editSubject);
  };

  const check = editSubject ? styles.check : '';

  useEffect(() => {
    mounted && setEditSubject(!(subject || emailText?.subject) ? true : false);
    return () => {
      mounted = false;
    };
  }, []);

  return (
    <div className={styles.subject_wrapper}>
      {editSubject && editable ? (
        <input
          type="text"
          placeholder="Subject"
          value={subject || emailText.subject}
          className={styles.input}
          onChange={e => {
            console.log(e.target.value);
            updateEmailText({
              ...emailState,
              [task_id]: {
                ...emailTask,
                emailText: {
                  ...emailTask.emailText,
                  subject: e.target.value,
                },
              },
            });
          }}
        />
      ) : (
        <div className={styles.nonedit_subject_wrapper}>
          <div className={styles.subject}>{subject || emailText.subject}</div>
          <Status status={status} events={events} recipients={recipients} />
        </div>
      )}
      {editable && (
        <>
          {check ? (
            <DoneIcon className={classes.button} onClick={handleClick} />
          ) : (
            <EditIcon className={classes.button} onClick={handleClick} />
          )}
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return { emailState: state.emailState };
};

const mapDispatchToProps = dispatch => ({
  updateEmailText: emailState => {
    dispatch(updateEmailText(emailState));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Subject);
