import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import styles from '../supervisor.scss';
import { updateAgentStatus, fetchStatusConfig } from '../../../services';
import FeedbackSnackbar from '../../feedback/FeedbackSnackbar';
import { Feedback } from '../../../utils';

const UserStatus = ({
  user_id,
  status,
  group,
  integration,
  statusList,
  handleAvailableFlag,
  isUserInDiffrentGroup,
  userRoles,
  available,
}) => {
  const canEditStatus = (userRoles || []).includes('status.edit');

  const [selectedStatus, selectStatus] = useState(status);
  const [statuses, setStatuses] = useState([]);
  const [feedback, setFeedback] = useState({ msg: '', severity: '' });

  const updateFeedback = (msg, severity = 'info') => {
    setFeedback({ msg, severity });
  };

  useEffect(() => {
    let mounted = true;
    if (isUserInDiffrentGroup)
      fetchStatusConfig({ group, integration })
        .then(data => {
          mounted && setStatuses(data);
        })
        .catch(err => {
          console.error(`${err.code}: ${err.desription}`);
          if (mounted)
            updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
        });
    else mounted && setStatuses(statusList);
    if (mounted && status !== selectedStatus) {
      handleAvailableFlag(available);
      selectStatus(status);
    }
    return () => {
      mounted = false;
    };
  }, [status, isUserInDiffrentGroup]);
  return (
    <>
      {feedback.msg.length > 0 && (
        <FeedbackSnackbar
          vertical={'bottom'}
          horizontal={'left'}
          children={feedback.msg}
          severity={feedback.severity}
          updateFeedback={updateFeedback}
        />
      )}
      {canEditStatus ? (
        <FormControl>
          <Select
            MenuProps={{ classes: { paper: styles.team_selection_toggled } }}
            value={selectedStatus || ''}
            displayEmpty
            className={styles.team_selection}
            classes={{ icon: styles.icon_color }}
            onChange={e => {
              e.preventDefault();
              e.stopPropagation();
              console.log(
                'new team: ',
                e.target.value,
                user_id,
                statuses.find(sl => sl.label === e.target.value)
              );
              updateAgentStatus({
                user_id,
                payload: {
                  ...statuses.find(s => s.label === e.target.value),
                  status: e.target.value,
                },
              })
                .then(res => res.json())
                .then(data => {
                  handleAvailableFlag(data.available);
                  selectStatus(data.status);
                })
                .catch(err =>
                  updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR)
                );
            }}
          >
            {statuses.map((st, index) => {
              if (!st) return <span key={index} />;
              return (
                <MenuItem
                  key={`${st.name}_${index}`}
                  className={styles.menu_item}
                  value={st.label}
                  disabled={st?.system_only}
                >
                  {st.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <>{status}</>
      )}
    </>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { userState, integrationState } = state;
  const { statusList, group, roles = [] } = userState;
  const { acl = {} } = integrationState;
  const userRoles = [];
  for (const role of roles) {
    userRoles.push(role, ...((acl || {})[role] || []));
  }

  let isUserInDiffrentGroup = false;
  if (group !== (ownprops || {}).group) isUserInDiffrentGroup = true;

  return {
    statusList,
    isUserInDiffrentGroup,
    userRoles,
  };
};

export default connect(mapStateToProps)(UserStatus);
