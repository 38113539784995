import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styles from './setenv.scss';
import store from '../../store';
import env from '../../config/environment';

let envKeys = Object.keys(env);
const { dispatch, getState } = store;

const onSelect = e => {
  const { environmentState } = getState();

  dispatch({
    type: 'UPDATE_ENVIRONMENT',
    environmentState: {
      ...environmentState,
      selection: e.target.value,
      url: '',
    },
  });
};

const onType = e => {
  const { environmentState } = getState();

  dispatch({
    type: 'UPDATE_ENVIRONMENT',
    environmentState: {
      ...environmentState,
      selection: 'custom',
      url: e.target.value,
    },
  });
};

const SetEnv = props => {
  const { environmentState } = getState();

  return (
    <>
      <div className={styles.env_selection} />
      <div id="setenv" className={styles.setenv}>
        Choose your environment
        {envKeys.map(key => {
          return (
            <div key={key} className={styles.selection}>
              <input
                type="radio"
                name={key}
                value={key}
                onChange={onSelect}
                className={styles.radio}
                checked={key === environmentState.selection}
              />
              {env[key].env.toUpperCase()}
              <br />
            </div>
          );
        })}
        <input
          type="text"
          onChange={onType}
          placeholder="Enter Custom URL Here"
          disabled={environmentState.selection !== 'custom'}
          value={
            environmentState.selection === 'custom' ? environmentState.url : ''
          }
        />
        <button
          id="submit_env"
          className={styles.button}
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();

            const { environmentState } = getState();

            const url =
              environmentState.selection === 'custom'
                ? environmentState.url
                : env[environmentState.selection].url;

            let httpProtocol =
              environmentState.selection === 'local' ? 'http' : 'https';
            let socketProtocol =
              environmentState.selection === 'local' ? 'ws' : 'wss';

            let newEnv = {
              ...environmentState,
              env: environmentState.selection,
              url: `${httpProtocol}://${url}`,
              socketURL: `${socketProtocol}://${url}`,
              communicatorUrl: `communicator://${url}`,
              communicatorWebappUrl: `${httpProtocol}://${url}`,
            };

            localStorage.setItem('env', JSON.stringify(newEnv));

            dispatch({
              type: 'UPDATE_ENVIRONMENT',
              environmentState: newEnv,
            });
          }}
        >
          Continue
        </button>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return { environmentState: state.environmentState };
};

const mapDispatchToProps = dispatch => ({
  updateConnectionFunc: state => {
    dispatch(updateConnection(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetEnv);
