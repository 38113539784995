import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import store from '../../store';
import {
  mergeClasses,
  hasUnknownWrapUp,
  getWrapupMessageFromLocalByTaskId,
  setWrapupMessageToLocalByTaskId,
} from '../../utils';

const { dispatch, getState } = store;

import styles from './wrapup.scss';

const muiStyle = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(),
    marginRight: theme.spacing(),
    width: '100%',
    color: 'rgba(255, 255, 255, 0.6);',
  },

  cssLabel: {
    color: 'rgba(255, 255, 255, 0.6);',
    '&$cssFocused': {
      color: 'white',
    },
    '&$cssError': {
      color: '#e7474e',
    },
  },

  cssOutlinedInput: {
    color: 'rgba(255, 255, 255, 0.6);',
    fontSize: '16px',
    '&$cssFocused $notchedOutline': {
      borderColor: `white !important`,
      color: 'white',
    },
  },

  cssFocused: {
    color: 'white',
  },

  cssError: {
    color: '#e7474e',
  },

  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'rgba(255, 255, 255, 0.6) !important',
  },
});

const helperTextStyles = makeStyles(theme => ({
  root: {
    color: '#fff',
    '&$error': {
      color: '#e7474e',
    },
  },
  error: {},
}));

const WrapupTextRow = props => {
  let { list, task, classes: muiClasses, wrapupState, template } = props;

  const { wrapup_fields = {} } = template;
  const { wrapup } = wrapupState;

  return (
    <div id="wrapup_text_row" className={mergeClasses(styles.wrapup_radio_row)}>
      {list.map((item, index) => {
        let { id, name, label, required } = item;
        let wrapupText =
          (task.wrap_up
            ? task.wrap_up.notes
            : wrapupState.wrapup[task.id][id]) ||
          getWrapupMessageFromLocalByTaskId(task.id)[id];

        return (
          <Fragment key={index}>
            <TextField
              id="outlined-multiline-flexible"
              label={`${label}${required ? ' *' : ''}` || 'Notes'}
              className={mergeClasses(styles.wrapup_input)}
              multiline
              key={`text_${id}_${task.id}`}
              disabled={
                !hasUnknownWrapUp({
                  wrap_up: task.wrap_up || wrapup[task.id],
                  wrapup_fields,
                })
              }
              value={wrapupText}
              onChange={e => {
                e.preventDefault();
                e.stopPropagation();
                wrapupState.wrapup[task.id] = wrapupState.wrapup[task.id] || {};
                wrapupState.wrapup[task.id][id] = e.target.value;
                setWrapupMessageToLocalByTaskId(
                  task.id,
                  id,
                  wrapupState.wrapup[task.id][id]
                );

                dispatch({
                  type: 'UPDATE_WRAPUP',
                  wrapupState: {
                    ...wrapupState,
                  },
                });
              }}
              InputLabelProps={{
                classes: {
                  root: muiClasses.cssLabel,
                  focused: muiClasses.cssFocused,
                  disabled: styles.input_disabled,
                  error: muiClasses.cssError,
                },
              }}
              InputProps={{
                classes: {
                  root: muiClasses.cssOutlinedInput,
                  focused: muiClasses.cssFocused,
                  notchedOutline: muiClasses.notchedOutline,
                  disabled: styles.input_disabled,
                },
                inputMode: 'numeric',
              }}
              variant="outlined"
              error={
                item.character_limit
                  ? ((wrapupState.wrapup[task.id || ''] || {})[id] || '')
                      .length > 0 &&
                    ((wrapupState.wrapup[task.id || ''] || {})[id] || '')
                      .length < item.character_limit &&
                    !/[a-zA-Z]/.test(
                      (wrapupState.wrapup[task.id || ''] || {})[id]
                    )
                  : false
              }
              helperText={
                item.character_limit > 0 &&
                `${label} (min. ${item.character_limit} chars)`
              }
              FormHelperTextProps={{ classes: helperTextStyles() }}
            />
          </Fragment>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { templateState, wrapupState } = state;
  const { task } = ownprops;
  const template = templateState[task.template];

  return {
    template,
    wrapupState,
  };
};

const mapDispatchToProps = dispatch => ({
  updateConnectionFunc: state => {
    dispatch(updateConnection(state));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(muiStyle)(WrapupTextRow));
