import { updateUserStatus } from '../../services';
import store from '../../store';
const { dispatch, getState } = store;

export const autoUnavailableAgent = (task, enabled, wrapUp) => {
  if (!enabled) {
    return;
  }

  // auto-unavailable applys only on CALL task
  if (task.type.toLowerCase() !== 'call') {
    return;
  }

  // only on auto archiving
  if (wrapUp?.reason !== 'auto archived by communicator') {
    return;
  }

  // not an auto-archive if wrapup_code is not empty
  if (wrapUp?.wrapup_code) {
    return;
  }

  // not an auto-archive if wrapup_code_list is not empty
  if (Array.isArray(wrapUp?.wrapup_code_list) && len(wrapUp.wrapup_code_list)) {
    return;
  }

  // wait for half second for the controller WS message
  setTimeout(() => {
    updateUserStatus('Unavailable', 0, 'LOGOUT_REASON_TASK_AUTO_ARCHIVED') // LOGOUT_REASON_AUTO_LOGOUT must exactly match datahub protobuf
      .then(res => res.json())
      .then(data => {
        const { available, status } = data;
        dispatch({
          type: 'UPDATE_USER',
          userState: {
            ...getState().userState,
            status,
            available,
          },
        });
      });
  }, 500);
};

export const removeArchivedTasks = () => {
  const taskIdsToRemove = [];

  for (var key in localStorage) {
    const cachedTask = JSON.parse(localStorage.getItem(key));
    if (cachedTask?.isArchived) {
      taskIdsToRemove.push(key);
    }
  }

  taskIdsToRemove.forEach(taskId => {
    localStorage.removeItem(taskId);
  });
};
