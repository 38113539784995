import envVar from '../../assets/NODE_ENV.json';
var jwtDecode = require('jwt-decode');

const token = (
  (JSON.parse(localStorage.getItem('okta-token-storage')) || {}).accessToken ||
  {}
).accessToken;

const decodedToken = (token && jwtDecode(token)) || {};

const email =
  decodedToken.sub ||
  (
    (
      (JSON.parse(localStorage.getItem('okta-token-storage')) || {}).idToken ||
      {}
    ).claims || {}
  ).preferred_username;

const group = decodedToken['communicator-role'];
const integration = decodedToken['communicator-integration-id'];
const name = (
  ((JSON.parse(localStorage.getItem('okta-token-storage')) || {}).idToken || {})
    .claims || {}
).name;

const { team, roles, bsn } = decodedToken;

const user = {
  key: email,
  name,
  email,
  custom: {
    integration,
    group,
    team,
    roles,
    bsn,
  },
};

const configDev = {
  clientSideID: '5f21a729412e490b5f945c0d',
  user,
};

const configStg = {
  clientSideID: '5f21a713ea13fb0b5878f243',
  user,
};

const configPrd = {
  clientSideID: '5f21a713ea13fb0b5878f244',
  user,
};

let configMap = {
  development: configDev,
  staging: configStg,
  production: configPrd,
};

const getLaunchdarklyConfig = (env = envVar.NODE_ENV) => {
  console.log('Launchdarkly ENV', env);
  if (env in configMap) return configMap[env];
  return configDev;
};

export default getLaunchdarklyConfig;
