export const updateErrorMsg = ({ error_msg, error_type, error_severity }) => {
  return {
    type: 'UPDATE_ERROR_MESSAGE',
    error_msg,
    error_type,
    error_severity,
  };
};

export const deleteErrorMsg = ({ error_type }) => {
  return {
    type: 'DELETE_ERROR_MESSAGE',
    error_type,
  };
};
