export const updateAgent = agentState => {
  return {
    type: 'UPDATE_AGENT',
    agentState,
  };
};

export const updateAgentTaskId = ({ taskId }) => {
  return {
    type: 'UPDATE_AGENT_TASK_ID',
    taskId,
  };
};

export const updateAgentData = ({ data }) => {
  return {
    type: 'UPDATE_AGENT_DATA',
    data,
  };
};

export const updateAgentSelectedTask = ({ task, tab, user }) => {
  return {
    type: 'UPDATE_AGENT_SELECTED_TASK',
    task,
    tab,
    user,
  };
};

export const updateAgentSelectedOffer = ({ offer, tab, user }) => {
  return {
    type: 'UPDATE_AGENT_SELECTED_OFFER',
    offer,
    tab,
    user,
  };
};

export const updateAgentArchives = ({ archives, filter }) => {
  return {
    type: 'UPDATE_AGENT_ARCHIVES',
    archives,
    filter,
  };
};
