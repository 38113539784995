import React from 'react';
import styles from './call.scss';

import { connect } from 'react-redux';
import { mergeClasses } from '../../utils';
import store from '../../store';
const { dispatch } = store;

const Endbox = props => {
  const {
    taskState,
    participant,
    helpers,
    call,
    services,
    updateTasks,
    callState,
  } = props;
  const { call_id } = participant;

  return (
    <div className={mergeClasses(styles.end_box)}>
      <p className={mergeClasses(styles.end_title)}>END CALL FOR ALL LINE</p>
      <p className={mergeClasses(styles.end_message)}>
        No lines will be speaking with one another.
      </p>
      <div className={mergeClasses(styles.end_button_group)}>
        <button
          className={mergeClasses(styles.end_buttons)}
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            updateTasks(taskState);
          }}
        >
          No, CANCEL
        </button>
        <button
          className={mergeClasses(styles.end_buttons)}
          onClick={async e => {
            e.preventDefault();
            e.stopPropagation();

            let res = await services.endCall(call_id).catch(err => {
              console.error('Error droping calls', err);
            });

            let task = helpers.getTaskByCallInfo(call, callState);
            if (task) {
              let newTask = await services.fetchUpdatedTaskById(task.id);
              helpers.updateTask(newTask, taskState, dispatch);
            }

            console.log('Drop response', res.status);
          }}
        >
          Yes, End Call for All
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return { taskState: state.taskState, callState: state.callState };
};

const mapDispatchToProps = dispatch => ({
  updateTasks: taskState => {
    dispatch({
      type: 'UPDATE_TASKS',
      taskState: { ...taskState, end: null },
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Endbox);
