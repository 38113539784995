import React, { forwardRef } from 'react';
import { connect } from 'react-redux';
import styles from './wrapup.scss';
import { LightTooltip } from '../reusable_components/LightTooltip';
import { mergeClasses } from '../../utils';
import { updateWrapup } from '../../store/action/wrapup';

const WrapupButton = props => {
  let { task, buttonProps, wrapupState, updateWrapup } = props;
  // const { wrapup } = taskState;
  const wrapup =
    task.wrap_up && task.wrap_up != {}
      ? task.wrap_up
      : wrapupState.wrapup[task.id] || {};
  // wrapup[task.id] = wrapup[task.id] || {};
  let { wrapup_code_list = [] } = wrapup; // !!!IMPORTANT!!! wraup_code_list is reserved, when suip team asks for change to wrapup_code, it should not be changed since the `wrapup_code` is used for Radio component and it will make conflict.

  let isSelected = wrapup_code_list.indexOf(buttonProps.id) > -1;
  return (
    <LightTooltip title={buttonProps.label} arrow placement="top">
      <ReferredButton
        key={buttonProps.id}
        id={`wrapup_button_${buttonProps.id}`}
        value={isSelected}
        disabled={task.wrap_up}
        className={mergeClasses(
          styles.wrapup_button,
          isSelected ? styles.selected_code : '',
          false ? styles.disabled_button : ''
        )}
        disabled={task.status !== 'ASSIGNED'}
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();

          console.log('Button clicked', buttonProps);

          const { wrapup } = wrapupState;
          if (isSelected) {
            let index = wrapup_code_list.indexOf(buttonProps.id);

            wrapup_code_list.splice(index, 1);
          } else {
            wrapup_code_list.push(buttonProps.id);
          }

          wrapup[task.id] = wrapup[task.id] || {};
          wrapup[task.id].wrapup_code_list = wrapup_code_list;
          updateWrapup({
            ...wrapupState,
            wrapup,
          });
        }}
      >
        {buttonProps.id.toUpperCase()}
        <span>{buttonProps.required && ' *'}</span>
      </ReferredButton>
    </LightTooltip>
  );
};

const ReferredButton = forwardRef((props, ref) => {
  return <button {...props} ref={ref} />;
});

const mapStateToProps = state => {
  return { taskState: state.taskState, wrapupState: state.wrapupState };
};

const mapDispatchToProps = dispatch => ({
  updateWrapup: state => {
    dispatch(updateWrapup(state));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WrapupButton);
