import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getUserState, updateTask } from '../../services';
import { archiveCallTask } from '../../store/action/call';
import { updateErrorMsg } from '../../store/action/error';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import { logger } from '../../services/server_side_logging';
import { archiveTask } from '../../store/action/task';
import { updateUserStatus } from '../../store/action/user';
import {
  autoUnavailableAgent,
  removeArchivedTasks,
} from './AutoArchiveTimerHandler';

const timerFormatter = (end, now) => {
  if (!end) return `Loading....`;
  if (!now) now = new Date().getTime();
  const diff = Math.floor((end - now) / 1000);
  let hours, minutes, seconds;
  seconds = diff % 60;
  hours = Math.floor(diff / (60 * 60));
  minutes = Math.floor((diff % (60 * 60)) / 60);

  return `in ${hours > 0 ? `${hours}h ` : ''} ${
    minutes > 0 ? `${minutes}m ` : ''
  } ${seconds >= 0 ? `${seconds}s ` : ''}`;
};

const AutoArchiveTimer = ({
  timeout,
  taskId,
  userId,
  wrapup,
  archiveTask,
  archiveCallTask,
  updateUserStatus,
  tasks,
  flags,
}) => {
  const [timer, setTimer] = useState(
    timerFormatter(JSON.parse(localStorage.getItem(taskId))?.endTimer)
  );

  useEffect(() => {
    const taskFromlocalStorage = JSON.parse(localStorage.getItem(taskId));

    if (!taskFromlocalStorage?.startTimer)
      localStorage.setItem(
        taskId,
        JSON.stringify({
          ...taskFromlocalStorage,
          startTimer: Date.now(),
          endTimer: Date.now() + timeout,
        })
      );
    else if (
      taskFromlocalStorage?.startTimer &&
      taskFromlocalStorage?.timeElapsed > 0
    ) {
      localStorage.setItem(
        taskId,
        JSON.stringify({
          ...taskFromlocalStorage,
          startTimer: Date.now(),
          endTimer: Date.now() + taskFromlocalStorage.timeElapsed,
        })
      );
    }

    const interval = setInterval(() => {
      const cachedTask = JSON.parse(localStorage.getItem(taskId));
      if (cachedTask) {
        if (cachedTask.isArchived) {
          setTimeout(() => {
            // wait for a few secs and remove the cache
            localStorage.removeItem(taskId);

            removeArchivedTasks();
          }, 3000);
        } else {
          // update timelapsed
          const taskEndTimer = cachedTask.endTimer;
          const now = new Date().getTime();
          setTimer(timerFormatter(taskEndTimer, now));

          localStorage.setItem(
            taskId,
            JSON.stringify({
              ...cachedTask,
              timeElapsed: taskEndTimer - now,
            })
          );
        }
      }
    }, 200);

    const time = setTimeout(
      () => {
        const taskFromlocalStorage = JSON.parse(localStorage.getItem(taskId));

        if (
          !taskFromlocalStorage?.isArchived &&
          !taskFromlocalStorage?.doNotAutoArchive
        ) {
          localStorage.setItem(
            taskId,
            JSON.stringify({
              ...taskFromlocalStorage,
              isArchived: true,
            })
          );

          const taskToArchive = tasks.find(t => t.id === taskId);
          const wrapUp = {
            ...wrapup[taskId],
            reason: 'auto archived by communicator',
            isAutoArchived: true,
          };

          updateTask(taskId, 'COMPLETED', wrapUp)
            .then(data => {
              // remove call data from redux for auto archived tasks
              data &&
                (data.type.toUpperCase() === 'CALL' ||
                  data?.type?.toUpperCase() === 'FREE_FORM_CALL' ||
                  data.type.toUpperCase() === 'COACHING') &&
                archiveCallTask(data);
            })
            .catch(err => {
              logger.error(`auto archive failed`, {
                taskId,
                userId,
                reason: wrapup[taskId],
                message: err.message,
              });

              if (taskToArchive) {
                // remove the task from task state
                archiveTask(taskToArchive);
                // remove the task from call state
                archiveCallTask({
                  id: taskId,
                });

                getUserState(userId)
                  .then(data => {
                    updateUserStatus({
                      available: data.available,
                      status: data.status,
                      telephony_status: data.telephony_status,
                    });
                  })
                  .catch(err => {
                    logger.error(
                      'flipping user status after auto-archive failed',
                      {
                        taskId,
                        userId,
                        message: err.message,
                      }
                    );
                  });
              }

              localStorage.setItem(
                taskId,
                JSON.stringify({ ...taskFromlocalStorage, isArchived: false })
              );
            });

          autoUnavailableAgent(
            taskToArchive,
            flags.enableUnavailableOnAutoArchiveOnCallTask,
            wrapUp
          );
        }
      },
      taskFromlocalStorage?.timeElapsed > 0
        ? taskFromlocalStorage.timeElapsed
        : timeout
    );

    if (taskFromlocalStorage?.doNotAutoArchive) clearTimeout(time);

    return () => {
      const taskFromlocalStorage = JSON.parse(localStorage.getItem(taskId));

      clearInterval(interval);
      if (taskFromlocalStorage?.doNotAutoArchive) clearTimeout(time);
    };
  }, [taskId]);

  return (
    <div id={`autoarchive_${taskId}`}> Task will be auto archived {timer}</div>
  );
};

const mapStateToProps = state => {
  const { wrapupState } = state;

  const { wrapup } = wrapupState;

  return {
    wrapup,
    tasks: state.taskState.tasks,
    userId: state.userState.id,
  };
};

const mapDispatchToProps = dispatch => ({
  archiveCallTask: task => {
    dispatch(archiveCallTask(task));
  },
  archiveTask: task => {
    dispatch(archiveTask(task));
  },
  updateErrorMsg: ({ error_msg, error_type, error_severity }) => {
    dispatch(updateErrorMsg({ error_msg, error_type, error_severity }));
  },
  updateUserStatus: status => {
    dispatch(updateUserStatus(status));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(AutoArchiveTimer));
