// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".IVRresults__details___3hN9Z{display:flex;padding:2px;margin:2px;flex-direction:column;font-family:Roboto;font-size:16px;font-weight:normal;font-stretch:normal;font-style:normal;line-height:1.75;letter-spacing:.5px;color:#fff;border-bottom:2px solid gray}.IVRresults__ivr_details_wrapper___2tVkj{display:flex}.IVRresults__ivr_details_wrapper___2tVkj.IVRresults__timeouted___1bYI5{color:#dec831 !important}.IVRresults__ivr_details_wrapper___2tVkj.IVRresults__success___2EiHh{color:#04844b}.IVRresults__ivr_details_wrapper___2tVkj.IVRresults__failed___3d_h0{color:#c12620}.IVRresults__participant___3Qa1-{margin:0px 5px}.IVRresults__participant___3Qa1-.IVRresults__timeouted___1bYI5{color:#dec831}.IVRresults__participant___3Qa1-.IVRresults__success___2EiHh{color:#04844b}.IVRresults__participant___3Qa1-.IVRresults__failed___3d_h0{color:#c12620}.IVRresults__participant_wrapper___hXn2z{padding:5px}.IVRresults__number_pressed___jroDa{padding-left:5px}.IVRresults__number_pressed___jroDa.IVRresults__timeouted___1bYI5{color:#dec831}.IVRresults__number_pressed___jroDa.IVRresults__success___2EiHh{color:#04844b}.IVRresults__number_pressed___jroDa.IVRresults__failed___3d_h0{color:#c12620}.IVRresults__ivr_name___2v51E{margin:0px 5px}.IVRresults__ivr_name___2v51E.IVRresults__timeouted___1bYI5{color:#dec831}.IVRresults__ivr_name___2v51E.IVRresults__success___2EiHh{color:#04844b}.IVRresults__ivr_name___2v51E.IVRresults__failed___3d_h0{color:#c12620}", ""]);
// Exports
exports.locals = {
	"details": "IVRresults__details___3hN9Z",
	"ivr_details_wrapper": "IVRresults__ivr_details_wrapper___2tVkj",
	"timeouted": "IVRresults__timeouted___1bYI5",
	"success": "IVRresults__success___2EiHh",
	"failed": "IVRresults__failed___3d_h0",
	"participant": "IVRresults__participant___3Qa1-",
	"participant_wrapper": "IVRresults__participant_wrapper___hXn2z",
	"number_pressed": "IVRresults__number_pressed___jroDa",
	"ivr_name": "IVRresults__ivr_name___2v51E"
};
module.exports = exports;
