import React, { useState } from 'react';
import styles from '../supervisor.scss';
import { buildArchiveQuery, mergeClasses as mClasses } from '../../../utils';
import { ArchivesConnector } from './connectors';
import Filter from './Filter';
import { getArchiveQuery } from '../../../utils/helpers';
import { fetchArchives } from '../../../services';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

const Archives = props => {
  const {
    flags,
    filter,
    archives,
    status,
    availabilityStatus,
    iconText,
    noOfTasks,
    user,
    limit = 20,
    mergeClasses = mClasses,
    updateTeamView,
    updateAgentTaskId,
    date,
    id,
    updateAgentArchives,
  } = props;
  const [pageNo, setPageNo] = useState(1);

  // reset the pageNo if the data is reset by the filter
  if (archives.length <= limit && pageNo > 1) {
    setPageNo(1);
  }

  const pagination = ((filter &&
    filter.agent === user &&
    filter.found.length > limit &&
    filter.found.length / limit > pageNo) ||
    (archives &&
      archives.length >= limit &&
      archives.length / limit >= pageNo)) && (
    <NavigateNextRoundedIcon
      onClick={async e => {
        let query,
          archiveData = archives || [];

        query = getArchiveQuery({
          date: date,
          offset: archiveData.length,
        });
        await fetchArchives(id, query)
          .then(archives => {
            archiveData.push(...archives);
            updateAgentArchives({
              archives: archiveData,
              filter: null,
            });
          })
          .catch(err => {
            console.error(
              `Error fetching archive list with query: ${query}`,
              err
            );
          });
        setPageNo(pageNo + 1);
      }}
    />
  );

  const newPagination = archives &&
    archives.length >= limit &&
    archives.length / limit >= pageNo && (
      <NavigateNextRoundedIcon
        onClick={async e => {
          let query,
            archiveData = archives || [];

          query = filter
            ? buildArchiveQuery({
                taskIds: filter.taskIds,
                clientSMSNumber: filter.clientSMSNumber,
                clientCallNumber: filter.clientCallNumber,
                createdAtFrom: filter.fromDate,
                createdAtTo: filter.toDate,
                types: filter.types,
                offset: archives.length,
              })
            : buildArchiveQuery({
                offset: archives.length,
              });
          await fetchArchives(id, query)
            .then(archives => {
              archiveData.push(...archives);
              updateAgentArchives({
                archives: archiveData,
                filter: filter,
              });
            })
            .catch(err => {
              console.error(
                `Error fetching archive list with query: ${query}`,
                err
              );
            });
          setPageNo(pageNo + 1);
        }}
      />
    );

  return (
    <div className={styles.team_block}>
      <div className={styles.user_basic_view}>
        <div className={styles.profile}>
          <span
            className={mergeClasses(
              styles.icon_text,
              styles[status],
              styles[availabilityStatus]
            )}
          >
            {iconText}
          </span>
          {noOfTasks === 0 ? (
            <span
              className={mergeClasses(
                styles.notification,
                styles['no_tasks'],
                styles[availabilityStatus]
              )}
            >
              {noOfTasks}
            </span>
          ) : (
            <span
              className={mergeClasses(
                styles.notification,
                styles[status],
                styles[availabilityStatus]
              )}
            >
              {noOfTasks}
            </span>
          )}
        </div>
        <div
          className={mergeClasses(
            styles.name_status,
            styles[availabilityStatus]
          )}
        >
          <span className={styles.username}>{user}</span>
          <span className={styles.user_status}>{status}</span>
        </div>
      </div>
      <div className={styles.user_expand_view}>
        <Filter user={user} pageNo={pageNo} limit={limit} {...props} />s
        <button
          className={styles.expand_button}
          onClick={async e => {
            e.preventDefault();
            // handleShowArchives();
            updateTeamView('tasks');
            updateAgentTaskId({ taskId: null });
          }}
        >
          GO TO ACTIVE TASKS
        </button>
        <div className={styles.expand_button}>
          <div className={styles.archive_buttons_wrapper}>
            {pageNo > 1 && (
              <NavigateBeforeRoundedIcon
                onClick={e => {
                  e.stopPropagation();
                  setPageNo(pageNo - 1);
                }}
              />
            )}
            <div className={styles.archives_pageno}>Page {pageNo}</div>
            {flags.enableSupervisorArchiveFilters ? (
              <>{newPagination}</>
            ) : (
              <>{pagination}</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArchivesConnector(withLDConsumer()(Archives));
