import React, { useRef } from 'react';
import styles from '../supervisor.scss';

import { connect } from 'react-redux';
import { displayTimestamp, mergeClasses } from '../../../utils';
import AgentTask from './AgentTask';
import Calendar from '../Calendar';
import Time from '../Time';
import { BoundaryError } from '../../reusable_components/boundaryerror';
import { updateAgent } from '../../../store/action/agent';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import TaskFilter from '../../main/TaskFilter';

const Filter = props => {
  const { flags, id, user, archives, pageNo, limit, filter, taskId, updateAgent } = props;
  const text = useRef('');

  const onChange = e => {
    e.preventDefault();
    if (text.current.value !== '') {
      const regex = new RegExp(`${text.current.value}`, 'gi');
      const found =
        archives &&
        archives.filter(archive => {
          return (
            archive.description.match(regex) ||
            archive.type.match(regex) ||
            archive.call.caller.match(regex)
          );
        });
      updateAgent({
        filter: {
          found: found,
          agent: user,
        },
      });
    } else {
      updateAgent({ filter: null });
    }
  };

  const archivelist =
    archives &&
    archives.map((archive, index) => {
      if (index < pageNo * limit && index >= pageNo * limit - limit) {
        return (
          <BoundaryError key={`boundary_filter_${archive.id}`}>
            <AgentTask
              key={archive.id}
              task={archive}
              tab="archives"
              {...props}
            />
          </BoundaryError>
        );
      }
    });

  const archiveFilter = (
    <>
      <div className={styles.date_time_wrapper}>
        <div className={styles.calendar}>
          <BoundaryError>
            <Calendar user={user} {...props} />
          </BoundaryError>
        </div>
        <div className={styles.time}>
          <BoundaryError>
            <Time user={user} {...props} />
          </BoundaryError>
        </div>
      </div>
      <div className={styles.sort_wrapper}>
        <div className={styles.task_sort}> FILTER BY </div>
        <div className={styles.name_filter}>{/* <Customer /> */}</div>
        <form
          onSubmit={async e => {
            e.preventDefault();
          }}
        >
          <input
            className={styles.name_filter}
            ref={text}
            type="text"
            placeholder="Filter..."
            onChange={onChange}
          />
        </form>
      </div>
    </>
  );

  const archiveListComponent = (
    <div className={styles.expand_tasks}>
      {filter && filter.agent === user ? (
        <>
          {filter.found.map((filt, index) => {
            const template = filt.template ? filt.template : null;
            const { task_icon } = template || { task_icon: '' };

            if (index < pageNo * limit && index >= pageNo * limit - limit) {
              return (
                <div key={index}>
                  <div
                    className={
                      taskId && taskId === filt.id
                        ? mergeClasses(
                        styles.expand_task_details,
                        styles['select']
                        )
                        : styles.expand_task_details
                    }
                  >
                    <div
                      className={mergeClasses(
                        styles.expand_task_icon,
                        styles[task_icon],
                        styles[(filt.type || '').toLowerCase()],
                        styles[filt.call && filt.call.direction]
                      )}
                    ></div>
                    <div
                      className={styles.expand_task_detail_block}
                      onClick={async e => {
                        e.preventDefault();
                        updateAgent({
                          tab: 'archives',
                          taskId: taskId === filt.id ? null : filt.id,
                        });
                        console.log(
                          'Clicked archive view for an agent after filter'
                        );
                      }}
                    >
                      <div className={styles.expand_task_description}>
                        {filt.description} - {filt.type}
                      </div>
                      <div className={styles.expand_task_created}>
                        {displayTimestamp(filt.created_at)}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </>
      ) : (
        <>{archivelist}</>
      )}
    </div>
  );

  return (
    <div className={styles.filter}>
      {
        flags.enableSupervisorArchiveFilters ?
          (
            <div className={styles.filter}>
              <TaskFilter uId={id}/>
              <div className={styles.expand_tasks}>
                <>{archivelist}</>
              </div>
            </div>
          ) : (
            <div className={styles.filter}>
              {archiveFilter}
              {archiveListComponent}
            </div>
          )
      }
    </div>
  );
};

const mapStateToProps = state => {
  const { agentState } = state;
  const { archives = [], filter, taskId } = agentState;
  return { archives, filter, taskId };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAgent: agentState => dispatch(updateAgent(agentState)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withLDConsumer()(Filter));
