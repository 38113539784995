import React, { Component, createRef } from 'react';
import styles from './details.scss';
import { connect } from 'react-redux';
import { templateState } from '../../store/reducer';
import store from '../../store';
const { dispatch } = store;
import AudioPlayer from './audio';
import CustomBtn from './CustomBtn';
import Participants from './ParticipantList';
import IVRresults from './IVRresults';
import SMSList from './SMSList';
import EmailList from './email/EmailList';
import SendReply from './SendReply';
import Scripts from './scripts';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';
import {
  getTaskList,
  mergeClasses,
  timeFormat,
  parseJSONPath,
  localTimeFormatter,
  displayTimestamp,
  groupMessagesByDate,
  scrollToRef,
} from '../../utils/helpers';
import {
  fetchTemplateWithName,
  fetchListByTaskId,
  sendSMSbyTaskId,
} from '../../services';
import icons from '../../utils/icons';
import { Grid } from '@material-ui/core';
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '../reusable_components/Expansions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { BoundaryError } from '../reusable_components/boundaryerror';

class Details extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.state = {};
    this.taskInfoRef = createRef();
    this.replyRef = createRef();
  }

  componentDidMount() {
    const {
      userState,
      taskState,
      template,
      view,
      selectedTask,
      scroller = scrollToRef,
    } = this.props;
    if (this.taskInfoRef && this.taskInfoRef.current) {
      scroller(
        this.taskInfoRef.current.parentNode,
        this.taskInfoRef.current.clientHeight +
          (this.replyRef.current ? this.replyRef.current.clientHeight : 0)
      );
    }
    const { currAudio } = this.state;
    if (!template) {
      const { integration } = userState;
      const { detailView, tasks, archived, filterTaskType } = taskState;

      const list = getTaskList({ tasks, archived, filterTaskType });

      const detailViewItem =
        view === 'supervisor'
          ? selectedTask
          : list.find(item => {
              return item.id === detailView;
            });

      if (detailViewItem) {
        fetchTemplateWithName(integration, detailViewItem.template)
          .then(response => {
            templateState[response.name] = response;
            dispatch({
              type: 'UPDATE_TEMPLATE',
              templateState: {
                ...templateState,
              },
            });
          })
          .catch(err => console.error(err));
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      userState,
      taskState,
      template,
      view,
      selectedTask,
      scroller = scrollToRef,
    } = this.props;
    const { integration } = userState;
    const { detailView, tasks, archived, filterTaskType } = taskState;
    const list = getTaskList({ tasks, archived, filterTaskType });

    const detailViewItem =
      view === 'supervisor'
        ? selectedTask
        : list.find(item => {
            return item.id === detailView;
          });

    if (!prevProps.template || !template) {
      detailViewItem &&
        fetchTemplateWithName(integration, detailViewItem.template)
          .then(response => {
            templateState[response.name] = response;
            dispatch({
              type: 'UPDATE_TEMPLATE',
              templateState: {
                ...templateState,
              },
            });
          })
          .catch(err => console.error(err));
    }
    if (
      detailView != prevProps.taskState.selectedTasks &&
      this.taskInfoRef &&
      this.taskInfoRef.current
    ) {
      scroller(
        this.taskInfoRef.current.parentNode,
        this.taskInfoRef.current.clientHeight
      );
    }
  }

  render = () => {
    const {
      taskState,
      supervisorState,
      task,
      flags,
      template,
      view,
      selectedTask,
      smsState,
      emailState,
    } = this.props;

    const { currAudio } = this.state;
    const { detailView, tasks, archived, filterTaskType } = taskState;
    const list = getTaskList({ tasks, archived, filterTaskType });
    const customButtons = (template || {}).actions;
    const detailViewItem =
      view === 'supervisor'
        ? selectedTask
        : list.find(item => {
            return item.id === detailView;
          });

    let recordingsList = [];
    if (detailViewItem && detailViewItem.audio_url) {
      recordingsList = [
        {
          id: detailViewItem.id,
          type: detailViewItem.type,
          user_id: detailViewItem.user_id,
          url: detailViewItem.audio_url,
          created_at: detailViewItem.created_at,
        },
      ];
    } else if (
      detailViewItem &&
      detailViewItem.call_info &&
      detailViewItem.call_info.status === 'completed'
    ) {
      recordingsList = detailViewItem.call_info.recordings;
    }

    if (!recordingsList) {
      recordingsList = [];
    }
    const recordings = recordingsList.map(rec => {
      return (
        <AudioPlayer
          key={rec.id}
          url={rec.url}
          id={rec.id}
          user_id={rec.user_id}
          created_at={rec.created_at}
          type={rec.type}
          currAudio={currAudio}
          initPlay={url => {
            this.setState({ currAudio: url });
          }}
          timeFormat={timeFormat}
        />
      );
    });

    const showTaskInfo =
      recordingsList.length > 0 ||
      (detailViewItem &&
        (detailViewItem.ivrs_results ||
          detailViewItem.type === 'EMAIL' ||
          (detailViewItem.sms_list && detailViewItem.sms_list.length > 0)));

    if (
      (detailViewItem &&
        detailViewItem.contact &&
        template &&
        template.display_fields) ||
      (template && template.display_fields)
    ) {
      const detailList = template.display_fields.map((item, index) => {
        let { component, id, label, value_path } = item;

        let newPath = `$.${value_path}`;

        let parsedValue = parseJSONPath(task, newPath);

        let value =
          component === 'Time'
            ? localTimeFormatter(parseInt(parsedValue))
            : parsedValue;
        return { label, value };
      });

      const error =
        (detailViewItem.task_id &&
          (detailViewItem.status.toUpperCase() === 'CREATED' ||
            detailViewItem.status.toUpperCase() === 'PRESENTED') &&
          `${detailViewItem.user_id
            .split('@')
            .splice(0, 1)} didnot accept this offer yet`) ||
        (supervisorState.error &&
          (detailViewItem.id === supervisorState.error.taskId ||
            (detailViewItem.type.toUpperCase() === 'CALL' &&
              detailViewItem.call &&
              detailViewItem.call.id === supervisorState.error.callId)) &&
          supervisorState.error.message);

      const { template_metadata = {} } = detailViewItem;

      return (
        <div id="details" className={styles.details}>
          {view === 'supervisor' && error && (
            <div className={styles.details_error}>{error}</div>
          )}
          <div>
            {flags.enableCampaignLogo &&
              template_metadata &&
              template_metadata.campaign && (
                <div
                  className={styles.campaign_wrapper}
                  id={`${detailViewItem.id}_campaign_details`}
                >
                  <img
                    className={styles.campaign_logo}
                    src={(template_metadata.campaign || {}).logo}
                    alt={
                      (template_metadata.campaign || {}).alt || 'campaign Logo'
                    }
                  />
                  <p className={styles.campaign_text}>
                    {(template_metadata.campaign || {}).text}
                  </p>
                </div>
              )}
            <div className={styles.detail_header}>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                {(customButtons || []).map((customBtn, index) => {
                  const CustomIcon = icons[customBtn.icon];
                  return (
                    <CustomBtn
                      key={index}
                      task={detailViewItem}
                      label={customBtn.label}
                      textLabel={customBtn.text_label}
                      id={customBtn.id}
                      CustomIcon={CustomIcon}
                    />
                  );
                })}
              </Grid>
            </div>

            <ExpansionPanel defaultExpanded={true}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon className={styles.expand_icon} />}
              >
                CLIENT INFO
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {detailList.map((item, index) => {
                  let { value, label } = item;

                  return (
                    <div key={index} className={styles.entry}>
                      <div id={`contact_${label}`} className={styles.key}>
                        {label}
                      </div>
                      <div
                        id={`contact_${label}_detail`}
                        className={styles.value}
                      >
                        {value}
                      </div>
                    </div>
                  );
                })}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {flags.enableCallScripts &&
              detailViewItem &&
              detailViewItem.call_scripts &&
              detailViewItem.call_scripts.length && (
                <BoundaryError>
                  <Scripts
                    call_scripts={detailViewItem.call_scripts}
                    task_id={detailViewItem.id}
                  />
                </BoundaryError>
              )}
            {showTaskInfo && (
              <ExpansionPanel defaultExpanded={true} ref={this.taskInfoRef}>
                <ExpansionPanelSummary
                  expandIcon={<ExpandMoreIcon className={styles.expand_icon} />}
                >
                  TASK INFO
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ maxHeight: '500px' }}>
                  <>
                    {detailViewItem.ivrs_results && (
                      <BoundaryError>
                        <IVRresults
                          results={detailViewItem.ivrs_results}
                          {...this.props}
                        />
                      </BoundaryError>
                    )}

                    {recordingsList.length > 0 && (
                      <div className={styles.recordings_container}>
                        {recordings}
                      </div>
                    )}
                    {detailViewItem &&
                      detailViewItem.type.toUpperCase() === 'SMS' && (
                        <BoundaryError>
                          <SMSList
                            view={view}
                            detailViewItem={detailViewItem}
                            mergeClasses={mergeClasses}
                            displayTimestamp={displayTimestamp}
                            groupMessagesByDate={groupMessagesByDate}
                            fetchListByTaskId={fetchListByTaskId}
                          />
                        </BoundaryError>
                      )}
                    {detailViewItem &&
                      detailViewItem.type.toUpperCase() === 'EMAIL' && (
                        <BoundaryError>
                          <EmailList
                            view={view}
                            detailViewItem={detailViewItem}
                            mergeClasses={mergeClasses}
                            fetchListByTaskId={fetchListByTaskId}
                          />
                        </BoundaryError>
                      )}
                  </>
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
            {view !== 'supervisor' &&
              detailViewItem &&
              detailViewItem.status.toUpperCase() !== 'COMPLETED' &&
              detailViewItem.type.toUpperCase() === 'SMS' &&
              detailViewItem.sms && (
                // detailViewItem.sms_list &&
                // detailViewItem.sms_list.length > 0 && (
                <ExpansionPanel defaultExpanded={true} ref={this.replyRef}>
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon className={styles.expand_icon} />
                    }
                  >
                    SMS REPLY
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <BoundaryError>
                      {task && task.sms && task.sms.cant_send ? (
                        <div className={styles.forward}>
                          Forwarded to:{' '}
                          {(task.metadata || {}).user_id ||
                            'Info Not available'}
                        </div>
                      ) : (
                        <SendReply
                          sendSMSbyTaskId={sendSMSbyTaskId}
                          task={task}
                        />
                      )}
                    </BoundaryError>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
            {view === 'supervisor' &&
              detailViewItem.type.toUpperCase() === 'CALL' &&
              detailViewItem.call_info && (
                <ExpansionPanel defaultExpanded={true}>
                  <ExpansionPanelSummary
                    expandIcon={
                      <ExpandMoreIcon className={styles.expand_icon} />
                    }
                  >
                    PARTICIPANTS INFO
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Participants detailViewItem={detailViewItem} />
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              )}
          </div>
        </div>
      );
    }

    return (
      <div id="details" className={styles.details}>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="flex-start"
        >
          {(customButtons || []).map((customBtn, index) => {
            const CustomIcon = icons[customBtn.icon];
            return (
              <CustomBtn
                key={index}
                task={detailViewItem}
                label={customBtn.label}
                id={customBtn.id}
                CustomIcon={CustomIcon}
              />
            );
          })}
        </Grid>
      </div>
    );
  };
}

const mapStateToProps = state => {
  const {
    taskState,
    userState,
    templateState,
    smsState,
    emailState,
    supervisorState,
  } = state;
  return {
    taskState,
    userState,
    templateState,
    smsState,
    emailState,
    supervisorState,
  };
};

const mapDispatchToProps = dispatch => ({
  updateConnectionFunc: state => {},
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLDConsumer()(Details));
