import envVar from '../../assets/NODE_ENV.json';

const commonConfig = {
  redirectUri: `${window.location.origin}/oktacallback`,
  pkce: true,
  scopes: ['openid', 'email', 'profile', 'offline_access'],
  responseType: ['code'],
  tokenManager: {
    autoRenew: true,
    expireEarlySeconds: 120,
  },
};

const achieveOktaConfig = {
  development: {
    issuer: 'https://sso-stg.achieve.com/oauth2/auscf5zq2wnqiZY1x1d7',
    clientId: '0oa8pj5pmk7N71i4n1d7',
  },
  staging: {
    issuer: 'https://sso-stg.achieve.com/oauth2/aus9dp4vfppDeatP01d7',
    clientId: '0oa8pjjfaz9fZpxyw1d7',
  },
  production: {
    issuer: 'https://sso.achieve.com/oauth2/aus6uesbadB3n6oRA697',
    clientId: '0oa2qbuwl40cVq6ps697',
  },
};

const ffnOktaConfig = {
  development: {
    issuer:
      'https://freedomfinancialnetwork.oktapreview.com/oauth2/ausp0quvjkaL2CHdW0h7',
    clientId: '0oap0n3a86AMqGKTD0h7',
  },
  staging: {
    issuer:
      'https://freedomfinancialnetwork.oktapreview.com/oauth2/ausqej7sxdt3sajey0h7',
    clientId: '0oaqefs1d3l9B8p1b0h7',
  },
  production: {
    issuer:
      'https://freedomfinancialnetwork.okta.com/oauth2/aus6ziuz6pOem6ajq2p7',
    clientId: '0oa6zij059HZ8dNOF2p7',
  },
};

const getOktaConfig = (env = envVar.NODE_ENV, enableAchieveOkta) => {
  const oktaConfig = enableAchieveOkta ? achieveOktaConfig : ffnOktaConfig;
  const issuerAndClientId = oktaConfig[env]
    ? oktaConfig[env]
    : oktaConfig['development'];

  const conf = {
    ...commonConfig,
    ...issuerAndClientId,
  };

  console.log(`---okta config---`);
  console.log(conf);

  return conf;
};

export default getOktaConfig;
