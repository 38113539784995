import { getAudio, playSound, stopSound } from '../sounds';
import * as services from '../../services';
import {
  updateCallTask,
  updateCallInfo,
  updateCallState,
} from '../../store/action/call';
import { updateCallError } from '../../store/action/task';
import { updateErrorMsg } from '../../store/action/error';
import { updateUser, updateUserActivityFlags } from '../../store/action/user';
import { initiateTransriptionByRecId } from '../../store/action/transcription';
import { participantStatusMapping } from '../../utils';
import { refreshClock } from '../autoLogoutClock';

const updateTaskState = async (message, helpers, store) => {
  const { task_id } = message;
  const { getState, dispatch } = store;
  let { callState } = getState();
  let updatedTask = task_id && (await services.fetchUpdatedTaskById(task_id));

  callState = callState.map(item => {
    if (updatedTask && item.id === updatedTask.id) {
      return updatedTask;
    }

    return item;
  });

  dispatch(updateCallState(callState));
};

const sortByCreatedAt = (a, b) => {
  if (a.created_at <= b.created_at) {
    return -1;
  }

  return 1;
};

const updateSupervisorState = async (message, helpers, store) => {
  const { call_info, call_id: callId } = message;
  const { getState, dispatch } = store;
  const { callState, taskState, supervisorState } = getState();
  let updatedTask;
  callState.length &&
    callState.map(task => {
      task.call &&
        call_info &&
        task.call.id === call_info.id &&
        (task.call_info = call_info) &&
        (updatedTask = task);
    });

  if (!updatedTask) {
    const currentTask =
      helpers.getTaskByCallInfo(message, callState) ||
      tasks.find(item => item.id === task_id) ||
      {};
    updatedTask =
      currentTask && (await services.fetchUpdatedTaskById(currentTask.id));
  }
  const supParticipant = updatedTask.call_info.participants.find(
    p => p.id === updatedTask.user_id
  );

  if (supParticipant && supParticipant.status.toLowerCase() === 'connected') {
    let coaching_tasks = JSON.parse(
      localStorage.getItem('coaching_tasks') || '[]'
    );
    !coaching_tasks.find(ct => ct === updatedTask.id) &&
      coaching_tasks.unshift(updatedTask.id);
    localStorage.setItem('coaching_tasks', JSON.stringify(coaching_tasks));
  }

  dispatch({
    type: 'UPDATE_SUPERVISOR',
    supervisorState: {
      ...supervisorState,
      [updatedTask.parent_task_id]: {
        ...supervisorState[updatedTask.parent_task_id],
        status: supParticipant ? supParticipant.status.toUpperCase() : false,
        supInCall: !!supParticipant,
        callId,
      },
      shouldSwitchView: false,
    },
  });
};
const showCallError = (message, helpers, store) => {
  const {
    role = '',
    event_type,
    task_id,
    call_id,
    user_id,
    created_at,
  } = message;
  const { getState, dispatch } = store;
  const { taskState } = getState();
  const errMsg = helpers.errorTypeMapping(event_type);

  const { callError } = taskState;
  // Add error type to callError
  const newError = {
    [call_id]: {
      errorType: errMsg,
      taskId: task_id,
      callId: call_id,
      userId: user_id,
      role,
      displayError: true,
      created_at,
    },
  };

  callError.find(
    currError => currError[call_id] && currError[call_id].callId === call_id
  )
    ? callError.forEach(currError => {
        if (
          currError[call_id] &&
          currError[call_id].callId === newError[call_id].callId &&
          currError[call_id].taskId === newError[call_id].taskId
        ) {
          currError[call_id].created_at = newError[call_id].created_at;
          currError[call_id].displayError = newError[call_id].displayError;
        }
      })
    : callError.push(newError);

  dispatch(updateCallError({ callError }));

  if (role.toLowerCase() === 'supervisor') {
    dispatch({
      type: 'UPDATE_SUPERVISOR',
      supervisorState: {
        ...supervisorState,
        [task_id]: {
          ...supervisorState[task_id],
          callError,
        },
      },
    });
  }
};

const processEvents = async (message, helpers, store) => {
  const {
    task_id,
    role = '',
    caller,
    call_id,
    call_info,
    user_id = '',
    event_type,
    created_at,
  } = message;
  const { getState, dispatch } = store;
  const {
    userState,
    taskState,
    supervisorState,
    wrapupState,
    callState,
  } = getState();
  switch (event_type.toLowerCase()) {
    case 'initiated': {
      console.log('initiated');
      try {
        user_id !== userState.id && playSound(getAudio.callStartAudio);
      } catch (e) {
        console.error('error playing sound', e);
      }
      break;
    }
    case 'failed': {
    }
    case 'busy': {
    }
    case 'no-answer': {
      console.log('call Error');
      if (role === 'agent' && caller === 'audio_recording')
        return dispatch(
          updateErrorMsg({ error_msg: event_type, error_type: 'vm_error' })
        );
      if (role === 'agent' && userState.id !== user_id) return;
      showCallError(message, helpers, store);
      break;
    }
    case 'participant-join': {
      console.log('participant-join');
      if (role === 'agent' && userState.id !== user_id) {
        callState.forEach(item => {
          item.call_info &&
            item.call_info.id === call_id &&
            item.call_info.participants &&
            call_info.participants &&
            item.call_info.participants !== call_info.participants &&
            (item.call_info.participants = call_info.participants);
        });
        dispatch(updateCallState(callState));
      }
      if (user_id === userState.id) {
        try {
          playSound(getAudio.callStartAudio);
        } catch (e) {
          console.error('error playing sound', e);
        }

        localStorage.setItem('ignoreInactivity', true);

        dispatch(
          updateUserActivityFlags({
            userIncall: true,
            ignoreInactivity: true,
          })
        );

        dispatch({
          type: 'UPDATE_ACTIVITY',
          activityState: {
            showCountDown: false,
            statusBeforeCountDown: userState.status,
          },
        });

        refreshClock();
      } else {
        try {
          playSound(getAudio.partipantJoinAudio);
        } catch (e) {
          console.error('error playing sound', e);
        }
      }
      break;
    }
    case 'pending-participant-join': {
      console.log('pending-participant-join');
      break;
    }
    case 'in-progress': {
      console.log('in progress');
      if (role === 'agent' && userState.id !== user_id) {
        callState.forEach(item => {
          item.call_info &&
            item.call_info.id === call_id &&
            item.call_info.participants &&
            call_info.participants &&
            item.call_info.participants !== call_info.participants &&
            (item.call_info.participants = call_info.participants);
        });
        dispatch(updateCallState(callState));
      }
      break;
    }
    case 'canceled': {
    }
    case 'participant-leave': {
      try {
        playSound(getAudio.hangUpAudio);
      } catch (e) {
        console.error('error playing sound', e);
      }
      const { tasks, events, callError } = taskState;
      const { getTaskByCallInfo } = helpers;
      const { wrapup } = wrapupState;
      // Play hangup sound

      const task =
        getTaskByCallInfo(message, tasks) ||
        tasks.find(item => item.id === task_id) ||
        {};

      wrapup[task.id] = wrapup[task.id] || {};
      wrapup[task.id].submit = true;
      events[call_id].callEnded = true;
      if (user_id === userState.id) {
        events[call_id].participantLeft = true;
        dispatch({
          type: 'UPDATE_TASKS',
          taskState: {
            ...taskState,
            events: events,
          },
        });
        dispatch({
          type: 'UPDATE_WRAPUP',
          wrapupState: {
            ...wrapupState,
            wrapup,
          },
        });
        localStorage.setItem('ignoreInactivity', false);

        dispatch(
          updateUserActivityFlags({
            userIncall: false,
            ignoreInactivity: false,
          })
        );
        refreshClock();
      }

      if (message.user_id === userState.id) {
        if (message.task_id) {
          services
            .fetchUpdatedTaskById(message.task_id)
            .then(data => dispatch(updateCallTask(data)));
        }

        let timeout = () => {
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              let x = 1;
              resolve(x);
              reject();
            }, 3000);
          });
        };

        await timeout();

        const delayedState = getState();
        const { taskState: delayedTaskState } = delayedState;
        const { events: delayedEvents } = delayedTaskState;

        dispatch({
          type: 'UPDATE_TASKS',
          taskState: {
            ...delayedTaskState,
            events: delayedEvents,
          },
        });
      }

      if (
        role.toLowerCase() === 'client' &&
        call_info &&
        call_info.participants &&
        call_info.participants.find(
          p => p.id === user_id && p.status && p.status.toLowerCase() !== 'ivr'
        )
      ) {
        const userJoinEvent =
          events[message.call_id].events.find(
            callEvent =>
              callEvent.event_type === 'participant-join' &&
              callEvent.user_id === userState.id
          ) ||
          events[message.call_id].events.find(
            callEvent =>
              callEvent.event_type === 'ringing' &&
              callEvent.user_id === userState.id
          ) ||
          events[message.call_id].events.find(
            callEvent =>
              callEvent.event_type === 'initiated' &&
              callEvent.user_id === userState.id
          ) ||
          !events[message.call_id].events.find(
            callEvent => callEvent.user_id === userState.id
          );
        console.log('userEvent: ', userJoinEvent);
        if (
          userJoinEvent &&
          message.created_at - userJoinEvent.created_at <= 3000
        ) {
          showCallError(
            { ...message, event_type: 'client-hung-up' },
            helpers,
            store
          );
        }
      }

      if (message.role !== 'agent') {
        events[message.call_id].events.find(callEvent => {
          console.log('Call Event: ', callEvent);
          return false;
        });
      }
      break;
    }
    case 'participant-mute': {
      console.log('participant mute');
      callState.forEach(item => {
        if (item.call_info && item.call_info.id === call_id) {
          item.call_info.participants &&
            item.call_info.participants.forEach(part => {
              if (part.id.toString() === user_id.toString()) {
                part.muted = true;
              }
            });
        }
      });

      dispatch(updateCallState(callState));

      break;
    }
    case 'participant-unmute': {
      callState.forEach(item => {
        if (item.call_info && item.call_info.id === message.call_id) {
          item.call_info.participants &&
            item.call_info.participants.forEach(part => {
              if (part.id.toString() === message.user_id.toString()) {
                part.muted = false;
              }
            });
        }
      });
      dispatch(updateCallState(callState));

      break;
    }
    case 'participant-hold': {
      callState.forEach(item => {
        if (item.call_info && item.call_info.id === message.call_id) {
          item.call_info.participants &&
            item.call_info.participants.forEach(part => {
              if (part.id.toString() === message.user_id.toString()) {
                part.hold = true;
              }
            });
        }
      });
      dispatch(updateCallState(callState));

      break;
    }
    case 'participant-unhold': {
      callState.forEach(item => {
        if (item.call_info && item.call_info.id === message.call_id) {
          item.call_info.participants &&
            item.call_info.participants.forEach(part => {
              if (part.id.toString() === message.user_id.toString()) {
                part.hold = false;
              }
            });
        }
      });
      dispatch(updateCallState(callState));

      break;
    }
    case 'ringing': {
      callState.forEach(item => {
        if (item.call_info && item.call_info.id === message.call_id) {
          item.call_info.participants &&
            item.call_info.participants.forEach(part => {
              if (part.id.toString() === message.user_id.toString()) {
                part.status = 'ringing';
              }
            });
        }
      });
      dispatch(updateCallState(callState));

      break;
    }
    case 'call-ended': {
      console.log('call ended');
      try {
        playSound(getAudio.hangUpAudio);
      } catch (e) {
        console.error('error playing sound', e);
      }

      let { getTaskByCallInfo, getTaskIndexByTaskId } = helpers;

      const { tasks, events, callError } = taskState;
      const { userIncall } = userState;
      const { wrapup } = wrapupState;

      let task = getTaskByCallInfo(message, callState);
      let eventLists = await services.fetchEventsByCallId(call_id);

      if (eventLists) {
        const { phone_events } = eventLists;
        phone_events && phone_events.sort(sortByCreatedAt);

        events[call_id] = {
          task: (events[call_id] && events[call_id].task) || task,
          events: eventLists,
          callEnded: true,
        };
        // Check if call end before 3 secs reach call duration for user
      }

      if (
        taskState.currentCallTask &&
        taskState.currentCallTask.id === message.task_id
      ) {
        taskState.currentCallTask = null;
      }

      if (task) {
        wrapup[task.id] && (wrapup[task.id].submit = true);
        let updatedTask = await services.fetchUpdatedTaskById(task.id);
        updatedTask.call_info.participants = [];
        updatedTask.call.participants = [];
        if (updatedTask) {
          let index = getTaskIndexByTaskId(updatedTask.id, tasks);

          tasks[index] = updatedTask;

          callError.map(item => {
            item[call_id] &&
              item[call_id].callId === call_id &&
              (item[call_id].displayError = false);
          });
          dispatch({
            type: 'UPDATE_WRAPUP',
            wrapupState: { ...wrapupState, wrapup },
          });
          dispatch({
            type: 'UPDATE_TASKS',
            taskState: {
              ...taskState,
            },
          });
          localStorage.setItem('ignoreInactivity', false);
          dispatch(
            updateUserActivityFlags({
              userIncall: !userIncall,
              ignoreInactivity: false,
            })
          );

          refreshClock();
        } else {
          !role.toLowerCase() === 'supervisor' &&
            alert(
              'Opps something went wrong fetching updated task after call end'
            );
        }
      } else {
        console.warn('Could not find task with call Id', call_id);
      }

      break;
    }

    case 'conference-end': {
      console.log('conference-end');
    }
    case 'completed': {
      let { tasks, events } = taskState;
      const task =
        tasks.find(task => task.id === task_id) ||
        helpers.getTaskByCallInfo(message, tasks);
      let updatedTask = task && (await services.fetchUpdatedTaskById(task.id));
      if (updatedTask) {
        dispatch(updateCallTask(updatedTask));
        let index = helpers.getTaskIndexByTaskId(updatedTask.id, tasks);
        tasks[index] = updatedTask;
      }

      if (
        taskState.currentCallTask &&
        taskState.currentCallTask.id === task_id
      ) {
        taskState.currentCallTask = null;
      }

      let eventLists = await services.fetchEventsByCallId(call_id);

      if (eventLists) {
        const { phone_events } = eventLists;
        phone_events && phone_events.sort(sortByCreatedAt);

        events[call_id] = {
          task: (events[call_id] && events[call_id].task) || task,
          events: eventLists,
          callEnded: true,
        };
      }

      dispatch({
        type: 'UPDATE_TASKS',
        taskState: {
          ...taskState,
        },
      });
      if (message?.user_id === userState?.id) {
        localStorage.setItem('ignoreInactivity', false);

        dispatch(
          updateUserActivityFlags({
            userIncall: false,
            ignoreInactivity: false,
          })
        );
        refreshClock();
      }
      break;
    }
    case 'ivr-complete': {
      console.log('ivr-complete');
      if (!window.document.hasFocus()) {
        try {
          const notification = new window.Notification(message.ivr_name, {
            body: `IVR Result: ${message.ivr_result}`,
          });
        } catch (e) {
          console.log('Error on notification = ', e);
        }
      }
      break;
    }
    case 'transcription-available': {
      console.log('transcription-available');

      // if (!window.document.hasFocus()) {
      try {
        const notification = new window.Notification(message.event_type, {
          body: `Transcription available`,
        });
      } catch (e) {
        console.log('Error on notification = ', e);
      }

      dispatch(initiateTransriptionByRecId({ id: message.phone_recording_id }));
      // }
      break;
    }

    default:
      updateTaskState(message, helpers, store);
      break;
  }
};

export const callEvent = async (message, helpers, store) => {
  const {
    role = '',
    caller,
    event_type,
    call_info = {},
    task_id,
    call_id,
    created_at,
    reason,
    participant_id,
  } = message;
  const { getState, dispatch } = store;
  const { userState, taskState, callState } = getState();
  console.log(`call ${event_type}`, message);
  const errorEventTypes = ['no-answer', 'failed', 'busy', 'canceled'];

  let dtmf_participants = JSON.parse(
    localStorage.getItem('dtmf_participants') || '[]'
  );

  if ((reason || '').toLowerCase() === 'dtmf') {
    !dtmf_participants.find(dp => dp?.id === participant_id) &&
      dtmf_participants.unshift({ id: participant_id, dtmf: true, call_id });
    localStorage.setItem(
      'dtmf_participants',
      JSON.stringify(dtmf_participants)
    );

    callState.forEach(item => {
      item.call_info &&
        item.call_info.id === call_id &&
        item.call_info.participants &&
        call_info.participants &&
        item.call_info.participants !== call_info.participants &&
        (item.call_info.participants = call_info.participants);
    });
    dispatch(updateCallState(callState));

    return;
  }

  if (
    event_type === 'participant-join' &&
    dtmf_participants.find(
      dp => dp?.id === participant_id && dp?.call_id === call_id && dp?.dtmf
    )
  ) {
    localStorage.setItem(
      'dtmf_participants',
      JSON.stringify(dtmf_participants.filter(dp => dp.id === !participant_id))
    );

    callState.forEach(item => {
      item.call_info &&
        item.call_info.id === call_id &&
        item.call_info.participants &&
        call_info.participants &&
        item.call_info.participants !== call_info.participants &&
        (item.call_info.participants = call_info.participants);
    });
    dispatch(updateCallState(callState));

    return;
  }

  if (
    role === 'agent' &&
    caller === 'audio_recording' &&
    (event_type === 'call-ended' || event_type === 'completed')
  )
    return dispatch(
      updateErrorMsg({
        error_msg: 'recording ended by polycom',
        error_type: 'phone_recording_error',
      })
    );

  if (
    (role === 'agent' &&
      caller === 'audio_recording' &&
      !errorEventTypes.includes(event_type)) ||
    event_type === 'transcription-available'
  )
    return;

  if (
    role === 'agent' &&
    (userState || {}).id === (message || {}).user_id &&
    errorEventTypes.includes(event_type)
  ) {
    const current_call_events = (taskState.events || {})[call_id];
    const { events = [] } = current_call_events;
    // particpant already left call. Show error message and return
    if (
      events.find(
        e =>
          e.created_at <= created_at &&
          e.event_type === 'participant-leave' &&
          e.user_id === (message || {}).user_id
      )
    ) {
      showCallError(message, helpers, store);
      return;
    }
  }

  if (
    event_type === 'participant-leave' &&
    call_info &&
    call_info.participants
  ) {
    call_info.participants.forEach(participant => {
      if (
        participant.id !== (userState || {}).id &&
        participant?.status?.toLowerCase() === 'ivr'
      ) {
        participant.displayStatus =
          participant?.metadata &&
          (participant?.metadata?.ivr_label ||
            participant?.metadata?.ivr_name) &&
          participantStatusMapping({
            ivr:
              participant?.metadata?.ivr_label ||
              participant?.metadata?.ivr_name ||
              participant?.status,
          });
      }
    });
  }

  console.log(' @@@ updating call info', event_type, ':\n', message);
  await dispatch(updateCallInfo({ call_info, task_id, call_id }));

  role.toLowerCase() === 'supervisor' &&
    updateSupervisorState(message, helpers, store);

  processEvents(message, helpers, store);
};
