// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".callError__call_error_box___22CtT{background-color:#828a9f;color:#fff;display:flex;height:184px;flex-direction:column;justify-content:space-around;align-items:center}.callError__call_error_title___3-FXx{font-family:Roboto;font-size:24px;font-weight:normal;font-stretch:normal;font-style:normal;line-height:1;letter-spacing:.8px;text-align:center;margin:5px}.callError__call_error_cancel___22QSA{align-self:flex-end;font-size:14px;color:#fff;background-color:#828a9f;border:none;cursor:pointer}.callError__call_error_block___sbeMZ{font-family:Roboto;font-size:16px;font-stretch:normal;font-style:normal;line-height:1;letter-spacing:.8px;text-align:center;display:flex;flex-direction:column;margin-top:10px}.callError__call_error_type___1Vku2{font-size:20px}.callError__call_error_user_role___1iaf7{padding:2px;font-size:14px;font-weight:normal}.callError__call_error_user_id___2FlFB{font-size:14px;font-weight:bold}.callError__call_error_task___2y7n9{font-size:14px;font-weight:normal}.callError__call_error_task_id___2PkVV{font-size:14px;font-weight:bold}", ""]);
// Exports
exports.locals = {
	"call_error_box": "callError__call_error_box___22CtT",
	"call_error_title": "callError__call_error_title___3-FXx",
	"call_error_cancel": "callError__call_error_cancel___22QSA",
	"call_error_block": "callError__call_error_block___sbeMZ",
	"call_error_type": "callError__call_error_type___1Vku2",
	"call_error_user_role": "callError__call_error_user_role___1iaf7",
	"call_error_user_id": "callError__call_error_user_id___2FlFB",
	"call_error_task": "callError__call_error_task___2y7n9",
	"call_error_task_id": "callError__call_error_task_id___2PkVV"
};
module.exports = exports;
