import React from 'react';
import styles from './IVR.scss';
import { IVRresultMapping } from '../../utils/mappings';

const IVRInfo = ({ ivrs_result, mergeClasses }) => {
  const ivr_names = ivrs_result.results && Object.keys(ivrs_result.results);
  return (
    <div>
      {ivr_names.length > 0 &&
        ivr_names.map((ivr, index) => {
          const keys = Object.values(ivrs_result.results[ivr]).reverse();
          const last_ivr_result =
            IVRresultMapping[keys[0]] || ` PRESSED ${keys[0]}`;
          return (
            <div
              key={index}
              className={mergeClasses(styles.ivr_info, styles[keys[0]])}
            >
              {(ivrs_result.results && ivrs_result.results[ivr].label) || ivr}
              {','}
              {last_ivr_result}
            </div>
          );
        })}
    </div>
  );
};

export default IVRInfo;
