export const updateLogGenesysWSMessage = flag => {
  return {
    type: 'UPDATE_LOG_GENESYS_WS_MESSAGE',
    flag,
  };
};

export const updateLogControllerWSMessage = flag => {
  return {
    type: 'UPDATE_LOG_CONTROLLER_WS_MESSAGE',
    flag,
  };
};

export const updateSendGenesysWSMessage = flag => {
  return {
    type: 'UPDATE_SEND_CONTROLLER_WS_MESSAGE',
    flag,
  };
};
