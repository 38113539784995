import envMap from './environment';

import envVar from '../../assets/NODE_ENV.json';

let env = envVar.NODE_ENV;

let getEnv = () => {
  if (env === 'staging' || env === 'production') {
    let envVar = envMap[env];
    envVar.socketURL = 'wss://' + envVar.url;
    envVar.url = 'https://' + envVar.url;
    return envVar;
  }

  let storage = localStorage.getItem('env');
  if (env === 'development') {
    const devEnv = envMap[env];
    if (storage) {
      const envInStorage = JSON.parse(storage);
      envInStorage.voicehubBaseUrl = devEnv.voicehubBaseUrl;
      envInStorage.communicatorUrl = devEnv.communicatorUrl;
      envInStorage.communicatorWebappUrl = devEnv.communicatorWebappUrl;
      envInStorage.genesysEnvironment = devEnv.genesysEnvironment;
      return envInStorage;
    }
  }

  return {
    env: undefined,
    url: '',
    selection: '',
  };
};

const initialState = {
  connectionState: {
    connection: 'Disconnected',
    ws: null,
    interval: null,
    intent: true,
    timeout: null,
    attempts: 0,
    sockets: [],
  },
  genesysConnectionState: {
    ws: null,
    authStatus: '', // authenticating/authenticated/failed
    wsStatus: '', // connecting/connected/failed/closed
    token: {},
  },
  environmentState: {
    ...getEnv(),
    binaryVersion: envVar.BINARY_VERSION,
  },
  callState: [],
  taskState: {
    filterTaskType: 'tasks',
    tab: 'tasks',
    sort: -1,
    sortBy: 'age',
    expand: true,
    tasks: [],
    archived: [],
    detailView: null,
    complete: {},
    add: null,
    end: null,
    wrapup: {},
    events: {},
    clicked: {},
    lastArchiveLength: 0,
    currentCallTask: null,
    callError: [],
    archiveFilter: {
      taskIds: [],
      types: [],
      fromDate: '',
      toDate: '',
      clientCallNumber: '',
      clientSMSNumber: '',
    },
  },
  supervisorState: {
    error: null,
  },
  teamState: {
    users: [],
    teamview: 'tasks',
    sortBy: { name: 'username', label: 'Username' },
    showActiveCallUsers: false,
    args: {
      limit: { label: 'Limit', value: 50, component: null },
      offset: { label: 'Offset', value: 0, component: null },
      available: {
        label: 'Available',
        value: false,
        component: 'Check',
      },
      online: { label: 'Online', value: false, component: 'Check' },
      group: { label: 'Group', value: [], component: 'Selection' },
      skills: { label: 'Skills', value: [], component: 'Selection' },
      ids: { label: 'IDs', value: [], component: 'Selection' },
    },
  },
  agentState: {
    tab: null,
    taskId: null,
    filter: null,
    archiveFilter: {
      taskIds: [],
      types: [],
      fromDate: '',
      toDate: '',
      clientCallNumber: '',
      clientSMSNumber: '',
    },
  },
  userState: {
    statusList: [
      { id: 'logout', text: 'Logout', roles: 'all', name: 'logout' },
    ],
    userIncall: false,
    ignoreInactivity:
      JSON.parse(localStorage.getItem('ignoreInactivity')) || false,
    notifications: [
      {
        id: 'offers',
        settings: JSON.parse(localStorage.getItem('receiveNotifications')),
        title: 'New Offer',
      },
    ],
    status: 'logout',
    isAutoLogoutEnabled:
      JSON.parse(localStorage.getItem('isAutoLogoutEnabled')) === null
        ? true
        : JSON.parse(localStorage.getItem('isAutoLogoutEnabled')),
    telephonyProvider: 'twilio',
  },
  directoryState: {
    tab: 0,
    callId: null,
    display: false,
    searchTerm: null,
  },
  templateState: {},
  sidebarState: {
    expand: false,
  },
  wrapupState: {
    wrapup: {},
  },
  smsState: { totalCount: 0 },
  emailState: {
    totalCount: 0,
  },
  activityState: {
    showCountDown: false,
  },
  autoLogoutState: {
    triggerAutoLogout: false,
    refreshClock: false,
  },
  integrationState: {},
  vmState: [],
  errorState: {},
  scannerState: {},
  transcriptionState: {},
  metricsState: {},
  scriptState: {},
  skillsState: {},
  broadcastState: {},
  hotdeskState: {
    hotdeskPhones: [],
    assigned_phone: '',
    openHotdeskModal: false,
  },
  loggingState: {
    logGenesysWSMessage: false,
    logControllerWSMessage: false,
    sendGenesysWSMessageInterval: -1, // value from feature flag enableSendGenesysEventToController. -1 means off,
  },
};

export default initialState;
