export const updateUser = userState => {
  return {
    type: 'UPDATE_USER',
    userState,
  };
};

export const updateUserStatus = ({ available, status, telephony_status }) => {
  return {
    type: 'UPDATE_USER_STATUS',
    available,
    status,
    telephony_status,
  };
};

export const updateTelephonyStatus = ({ telephony_status }) => {
  return {
    type: 'UPDATE_TELEPHONY_STATUS',
    telephony_status,
  };
};

export const updateUserStatusList = ({ statusList }) => {
  return {
    type: 'UPDATE_USER_STATUSLIST',
    statusList,
  };
};

export const updateUserInactivityTimers = ({ inactivity }) => {
  return {
    type: 'UPDATE_USER_INACTIVITY_TIMERS',
    inactivity,
  };
};

export const updateUserActivityFlags = ({ userIncall, ignoreInactivity }) => {
  return {
    type: 'UPDATE_USER_ACTIVITY_FLAGS',
    userIncall,
    ignoreInactivity,
  };
};

export const updateUserAssignedPhone = ({ assigned_phone, sip_number }) => {
  return {
    type: 'UPDATE_USER_ASSIGNED_PHONE',
    assigned_phone,
    sip_number,
  };
};

export const updateUserTelephonyProvider = ({ telephonyProvider }) => {
  return {
    type: 'UPDATE_USER_TELEPHONY_PROVIDER',
    telephonyProvider,
  };
};
