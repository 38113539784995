import envVar from '../../assets/NODE_ENV.json';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import * as reducers from './reducer';

import initialState from '../config/initial_state';

const middlewares = [thunk, logger];

let reducer = combineReducers(reducers);

let store = createStore(reducer, initialState, applyMiddleware(...middlewares));

store.subscribe(function storeCallback() {
  console.log('Store Updated', store.getState());
});

if (envVar.NODE_ENV === 'development') {
  window.redux_dispatch = store.dispatch;
  window.redux_getState = store.getState;
}
export default store;
