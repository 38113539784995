import React, { useState } from 'react';
import { displayTimestamp, mergeClasses } from '../../../utils';
import { connect } from 'react-redux';

import styles from '../supervisor.scss';
import TaskList from './TaskList';
import { userCoachingACL } from '..';

import {
  updateAgentSelectedTask,
  updateAgentSelectedOffer,
} from '../../../store/action/agent';

const AgentTask = props => {
  let {
    task,
    offer,
    tab,
    agentState,
    user,
    userRoles,
    updateAgentSelectedTask,
    updateAgentSelectedOffer,
  } = props;
  const allowCoaching =
    (userRoles || []).includes(userCoachingACL) ||
    (userRoles || []).includes('supervisor');

  const time = task
    ? task.created_at && displayTimestamp(task.created_at)
    : offer && offer.created_at && displayTimestamp(offer.created_at);

  const template = task
    ? task.template
      ? task.template
      : null
    : offer
    ? offer.template
    : null;

  const taskId = task ? task.id : offer.task_id;
  const description = task ? task.description : offer.description;
  const status = task ? task.status : offer.status;
  const type = task
    ? task && task.type
      ? task.type
      : ''
    : offer && offer.type
    ? offer.type
    : '';

  const direction = task ? task.call && task.call.direction : offer.direction;

  return (
    <div
      task={task ? task.id : offer.task_id}
      className={
        agentState.taskId && agentState.taskId === taskId
          ? mergeClasses(styles.expand_task_details, styles['select'])
          : styles.expand_task_details
      }
      onClick={async e => {
        e.preventDefault();
        task &&
          updateAgentSelectedTask({
            tab: tab && tab.toLowerCase() === 'archives' ? tab : 'tasks',
            user,
            task,
          });
        offer &&
          updateAgentSelectedOffer({
            tab: tab && tab.toLowerCase() === 'archives' ? tab : 'tasks',
            user,
            offer,
          });
      }}
    >
      <span
        className={mergeClasses(
          styles.expand_task_icon,
          styles[template],
          styles[type.toLowerCase()],
          styles[direction]
        )}
      ></span>
      <span className={styles.expand_task_detail_block}>
        <span className={styles.expand_task_description}>
          {description} - {status}
        </span>
        <span className={styles.expand_task_created}>{time}</span>
        {allowCoaching &&
        task &&
        task.call &&
        task.call_info &&
        task.call_info.status.toLowerCase() === 'active' &&
        task.call_info.participants.length > 0 ? (
          // task.call_info.participants.some(
          //   participant =>
          //     participant.id === user &&
          //     participant.status.toLowerCase() === 'connected'
          // ) ? (
          <TaskList key={task.id} task={task} {...props} />
        ) : (
          <div />
        )}
      </span>
    </div>
  );
};

const mapStateToProps = state => {
  const { userState, agentState, integrationState } = state;
  const { roles = [] } = userState;
  const { acl = {} } = integrationState;

  const userRoles = [];
  for (const role of roles) {
    userRoles.push(role, ...((acl || {})[role] || []));
  }
  return { agentState, userRoles };
};

const mapDispatchToProps = dispatch => {
  return {
    updateAgentSelectedTask: ({ task, tab, user }) =>
      dispatch(updateAgentSelectedTask({ task, tab, user })),
    updateAgentSelectedOffer: ({ offer, tab, user }) =>
      dispatch(updateAgentSelectedOffer({ offer, tab, user })),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentTask);
