import React from 'react';
import styles from './call.scss';
import { withLDConsumer } from 'launchdarkly-react-client-sdk';

import { connect } from 'react-redux';
import store from '../../store';
import TimeCounter from './timercounter';
import { Feedback } from '../../utils';

const { dispatch, getState } = store;

const activeStyleMap = (style, activeStatus) => {
  const status = activeStatus || 'active';
  return style[status] ? style[status] : '';
};

const CallControl = props => {
  const {
    participantType,
    callType,
    participant = {},
    otherParticipants,
    taskState,
    helpers,
    expand,
    showDelayedLeftMessage,
    call,
    services,
    callState,
    user_id,
    updateFeedback,
    flags,
    userTelephonyProvider,
  } = props;

  const state = getState();
  const { mergeClasses, participantInCall } = props.helpers;

  const participantJoined =
    taskState.events[call.id] &&
    participantInCall(taskState.events[call.id].events, user_id);

  const ivrPlaying =
    otherParticipants.find(p => {
      return p.status.toLowerCase() === 'ivr';
    }) ||
    (call &&
      call.taskId &&
      callState.find(task => {
        return (
          task.id === call.taskId &&
          task.call_info &&
          task.call_info.participants &&
          task.call_info.participants.length > 0 &&
          task.call_info.participants.find(p => {
            return p.status.toLowerCase() === 'ivr';
          })
        );
      }));

  const callStyle = callType === 'single' ? styles.single : styles.conference;
  const participantStyle =
    participantType === 'agent' ? styles.agent : styles.client;

  // !NOTE! introduced by ticket: https://billsdev.atlassian.net/browse/V2FFNCOM-163
  // an issue caused by it
  // When transferring a call to an agent, the agent will only see the YOU LEFT THE CALL screen, instead of the incomming call transfer screen
  // if the agent has an unwrapped task left by another transfer
  const activeStyle = activeStyleMap(
    styles,
    showDelayedLeftMessage ? 'inactive' : 'active'
  );

  const allowAgentToLeaveConference =
    call?.taskId &&
    callState.find(
      task =>
        task.id === call.taskId &&
        task?.call_info?.participants &&
        task?.call_info?.participants?.length &&
        task?.call_info?.participants?.filter(
          p => p?.status?.toLowerCase() === 'connected'
        )?.length >= 3
    );

  let { muted } = participant;

  let currentParticipant =
    participantType === 'agent' && callType === 'single'
      ? otherParticipants[0]
      : participant;

  if (!currentParticipant) {
    return <></>;
  }
  let { hold } = currentParticipant;

  /*
  feature flag is on
    twilio agent, always show
    genesys agents, only show on the client tile
  feature flag is off
    show hold button only for twilio agent
  */
  // enableOnhold=true shows the button regardless of other conditions
  const shouldShowHoldButton = () => {
    if (userTelephonyProvider !== 'genesys') {
      // always show for twilio agent
      return true;
    }

    if (flags.enableOnhold) {
      // for genesys agent
      // show if 1. the tile is for client, 2. there's only one agent talking to the client
      if (
        (userTelephonyProvider === 'genesys' &&
          (participant?.role === 'client' ||
            participant?.role === 'external')) ||
        otherParticipants.length == 1
      ) {
        return true;
      }

      return false;
    }
  };

  const showHoldButton = shouldShowHoldButton();

  return (
    <div
      className={mergeClasses(
        styles.call_control,
        participantStyle,
        callStyle,
        expand ? '' : styles.compacted
      )}
    >
      {callType === 'conference' && participantType === 'agent' ? (
        <div
          className={mergeClasses(
            styles.agent_time,
            styles[callType],
            showDelayedLeftMessage ? styles.you_left_time : ''
          )}
        >
          <div
            className={helpers.mergeClasses('timer', styles.call_duration)}
            data-timer={JSON.stringify({
              function: 'localTimeFormatter',
            })}
          >
            {helpers.localTimeFormatter()}
          </div>
          <TimeCounter
            {...{
              helpers,
              start: participant.joinedAt || participant.updated_at,
              style: styles.call_duration,
            }}
          />
        </div>
      ) : null}

      <div
        className={mergeClasses(
          styles.call_button_group,
          showDelayedLeftMessage ? styles.you_left_buttons : ''
        )}
      >
        <div
          className={mergeClasses(
            styles.in_call_buttons,
            styles[call.type.toLowerCase()],
            callStyle,
            participantStyle
          )}
        >
          <button
            aria-label={'Mute'}
            className={mergeClasses(
              styles.call_button,
              styles.call_mute,
              !showDelayedLeftMessage
                ? muted
                  ? styles.toggle
                  : styles.active
                : '',
              callStyle,
              activeStyle,
              participantStyle,
              !participantJoined && styles['inactive'],
              muted ? 'onmute' : 'notonmute'
            )}
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();
              let { call_id, id: participantId, muted } = participant;
              try {
                await services.toggledMutedParticipant(
                  call_id,
                  participantId,
                  muted
                );
              } catch (err) {
                updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR);
                console.error(err);
              }
            }}
          ></button>
          {call.type !== 'COACHING' && (
            <>
              {showHoldButton && (
                <button
                  aria-label={'Hold'}
                  className={mergeClasses(
                    styles.call_button,
                    styles.call_hold,
                    !showDelayedLeftMessage
                      ? hold
                        ? styles.toggle
                        : styles.active
                      : '',
                    callStyle,
                    activeStyle,
                    participantStyle,
                    !participantJoined && styles['inactive'],
                    hold ? 'onhold' : 'notonhold'
                  )}
                  onClick={async e => {
                    e.preventDefault();
                    e.stopPropagation();
                    let { call_id, id: participantId } = participant;
                    participantId =
                      participantType === 'agent' && callType === 'single'
                        ? otherParticipants[0].id
                        : participantId;
                    try {
                      await services.toggledHoldParticipant(
                        call_id,
                        participantId,
                        hold
                      );
                    } catch (err) {
                      updateFeedback(
                        `${err.code}: ${err.message}`,
                        Feedback.ERROR
                      );
                      console.error(err);
                    }
                  }}
                />
              )}
              <button
                aria-label={'Add'}
                className={mergeClasses(
                  styles.call_button,
                  styles.call_add,
                  styles.active,
                  callStyle,
                  activeStyle,
                  !participantJoined && styles['inactive'],
                  participantStyle
                )}
                onClick={async e => {
                  e.preventDefault();
                  e.stopPropagation();
                  const { call_id, id: participantId } = participant;
                  dispatch({
                    type: 'UPDATE_DIRECTORY',
                    directoryState: {
                      ...state.directoryState,
                      display: true,
                      callId: call_id,
                    },
                  });
                }}
              />
            </>
          )}
        </div>

        <div
          className={mergeClasses(
            styles.exit_buttons,
            styles[call.type.toLowerCase()],
            callStyle,
            participantStyle
          )}
        >
          {call.type !== 'COACHING' && (
            <button
              aria-label={'End-Call-for-All'}
              className={mergeClasses(
                styles.call_button,
                styles.call_disconnect,
                activeStyle,
                callStyle,
                !participantJoined && styles['inactive'],
                ivrPlaying && styles['inactive'],
                participantStyle
              )}
              onClick={async e => {
                e.preventDefault();
                e.stopPropagation();
                const { call_id, id: participantId } = participant;

                if (callType === 'single') {
                  try {
                    await services.endCall(call_id).catch(err => {
                      console.error(
                        'Error in disconnecting call: ',
                        call_id,
                        err
                      );
                      updateFeedback(
                        `${err.code}: ${err.message}`,
                        Feedback.ERROR
                      );
                    });
                    if (
                      taskState.currentCallTask &&
                      taskState.currentCallTask.call.id === call_id
                    ) {
                      dispatch({
                        type: 'UPDATE_TASKS',
                        taskState: {
                          ...taskState,
                          currentCallTask: null,
                        },
                      });
                    }
                  } catch (err) {
                    console.log('Drop response', err);
                    dispatch({
                      type: 'UPDATE_TASKS',
                      taskState: {
                        ...taskState,
                        currentCallTask: null,
                      },
                    });
                  }
                } else {
                  if (participantType === 'agent') {
                    dispatch({
                      type: 'UPDATE_TASKS',
                      taskState: {
                        ...taskState,
                        end: call_id,
                        currentCallTask: null,
                      },
                    });
                  } else {
                    let res = await services
                      .disconnectParticipant(call_id, participant.id)
                      .catch(err => {
                        console.error(
                          `Error in disconnecting ${participant.id}. Call ID: ${call_id}`,
                          err
                        );
                        updateFeedback(
                          `${err.code}: ${err.message}`,
                          Feedback.ERROR
                        );
                      });
                    dispatch({
                      type: 'UPDATE_TASKS',
                      taskState: {
                        ...taskState,
                        currentCallTask: null,
                      },
                    });
                  }
                }
              }}
            />
          )}
          <button
            aria-label={'Leave-Conference'}
            className={mergeClasses(
              styles.call_button,
              styles.call_leave,
              callStyle,
              activeStyle,
              !participantJoined && styles['inactive'],
              flags?.leaveConferenceFlagEnabled &&
                !allowAgentToLeaveConference &&
                styles['inactive'],
              participantStyle
            )}
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              const { call_id, id: participantId } = participant;

              services
                .disconnectParticipant(call_id, participantId)
                .then(data => {
                  dispatch({
                    type: 'UPDATE_TASKS',
                    taskState: {
                      ...taskState,
                      currentCallTask: null,
                    },
                  });
                  console.log(data.status);
                })
                .catch(err =>
                  updateFeedback(`${err.code}: ${err.message}`, Feedback.ERROR)
                ); // example
            }}
          ></button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    taskState: state.taskState,
    callState: state.callState,
    user_id: state.userState.id,
    userTelephonyProvider: state.userState.telephonyProvider,
  };
};

export default connect(mapStateToProps)(withLDConsumer()(CallControl));
