import store from '../../store';
import { updateGenesysCallInfo } from '../../store/action/call';
import { updateErrorMsg } from '../../store/action/error';
import { updateGenesysConnection } from '../../store/action/genesysConnection';
import { genesysErrorMsg } from './error.mappings';
import { Feedback } from '../mappings';

import { logGenesysWSMessage, sendMessageToController } from './message.sender';
import { logger } from '../../services/server_side_logging';
import { isGenesysTokenValid } from './auth';
import { registorGenesysWS, subscripbeToGenesys } from '../../services/genesys';

const { getState, dispatch } = store;

export const connectToGenesys = async oktaToken => {
  const currentWSStatus = getState().genesysConnectionState.wsStatus;

  // TODO, implement retry if ws is closed or failed
  if (currentWSStatus === 'closed' || currentWSStatus === 'failed') {
    return;
  }

  if (currentWSStatus === 'connecting' || currentWSStatus === 'connected') {
    return;
  }

  const genesysToken = getState().genesysConnectionState.token;
  if (!isGenesysTokenValid(genesysToken)) {
    return;
  }

  const userId = oktaToken.legacy_username
    ? oktaToken.legacy_username
    : oktaToken.sub;
  const integration = oktaToken['communicator-integration-id'];

  dispatch(
    updateGenesysConnection({
      ws: null,
      wsStatus: 'connecting',
    })
  );

  const genesysWSChannel = await registorGenesysWS(
    integration,
    userId,
    genesysToken.accessToken
  );
  if (genesysWSChannel.error) {
    logger.error('registorGenesysWS failed', {
      userId,
      genesysToken,
      genesysWSChannel,
    });

    dispatch(
      updateGenesysConnection({
        ws: null,
        wsStatus: 'failed',
      })
    );

    dispatch(
      updateErrorMsg({
        error_msg: genesysErrorMsg.WS_ERROR,
        error_severity: Feedback.ERROR,
      })
    );

    return;
  }

  let ws = new WebSocket(genesysWSChannel.connectUri);

  ws.onmessage = async e => {
    const parsedGeneysysWSEvent = JSON.parse(e.data);
    const { topicName, eventBody, metadata } = parsedGeneysysWSEvent;

    if (
      topicName === `v2.users.${genesysToken.genesysUserId}.conversations.calls`
    ) {
      sendMessageToController(parsedGeneysysWSEvent);

      logGenesysWSMessage('info', 'genesys ws message', parsedGeneysysWSEvent);

      const { participants = [], id: genesysCallId } = eventBody;
      // check for any errors on participants
      const { errorInfo } = participants.find(p => p.errorInfo) || {};
      if (
        errorInfo &&
        errorInfo?.messageParams?.type !== 'cloud.command.disconnect'
      ) {
        logger.error('error in genesys ws message', { userId, eventBody });

        const { message, code } = errorInfo;
        dispatch(
          updateErrorMsg({
            error_msg: message,
            error_type: code,
            error_severity: Feedback.ERROR,
          })
        );
      }

      let participantOnIVR = false;

      const state = getState();
      state.callState.map(c => {
        c?.call_info?.participants.map(p => {
          if (p.status == 'ivr') {
            participantOnIVR = true;
          }
        });
      });

      if (participantOnIVR) {
        logGenesysWSMessage(
          'warn',
          'skipping genesys ws message due to IVR playing',
          parsedGeneysysWSEvent
        );
      }

      // Reducer will map the participant status with old statuses

      dispatch(
        updateGenesysCallInfo({
          participants,
          genesysCallId,
        })
      );
    }
  };

  ws.onopen = event => {
    ws.misses = 0;
    dispatch(
      updateGenesysConnection({
        ws: ws,
        wsStatus: 'connected',
      })
    );
  };

  ws.onclose = event => {
    logger.error('Genesys websocket closed', {
      event,
      userId,
      genesysToken,
      genesysWSChannel,
    });

    // dispatch(
    //   updateErrorMsg({
    //     error_msg: genesysErrorMsg.WS_CONNECTION_CLOSED,
    //     error_severity: Feedback.WARNING,
    //   })
    // );

    // TODO. retry.
    // do NOT alter genesysConnectionState here because we don't know what triggered the onClose. Let the caller manage the state
    // dispatch(
    //   updateGenesysConnection({
    //     ws: null,
    //     wsStatus: 'closed',
    //   })
    // );
  };

  ws.onerror = event => {
    logger.error('genesys WS error triggerred', {
      userId,
      genesysToken,
      genesysWSChannel,
      event,
    });

    // dispatch(
    //   updateErrorMsg({
    //     error_msg: genesysErrorMsg.WS_ERROR,
    //     error_severity: Feedback.ERROR,
    //   })
    // );
    // dispatch(
    //   updateGenesysConnection({
    //     ws: null,
    //     wsStatus: 'failed',
    //   })
    // );
  };

  const result = await subscripbeToGenesys(
    integration,
    genesysToken.accessToken,
    genesysToken.genesysUserId,
    genesysWSChannel.id,
    userId
  );
  if (result.error) {
    logger.error('subscripbeToGenesys failed', {
      userId,
      genesysToken,
      genesysWSChannel,
      result,
    });

    dispatch(
      updateErrorMsg({
        error_msg: genesysErrorMsg.SUBSCRIPTION_ERROR,
        error_severity: Feedback.ERROR,
      })
    );
    dispatch(
      updateGenesysConnection({
        ws: null,
        wsStatus: 'failed',
      })
    );
  }

  dispatch(
    updateErrorMsg({
      error_msg: 'Genesys connected successfully',
      error_type: Feedback.INFO,
      error_severity: Feedback.INFO,
    })
  );
};
