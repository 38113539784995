import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getTaskQueues } from '../../services';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  available: {
    fontFamily: 'inherit',
    color: '#45ae57 !important',
  },
  notAvailable: {
    fontFamily: 'inherit',
    color: '#e7474e !important',
  },
  queue_item: {
    color: '#fff',
  },
  count: {
    textAlign: 'center',
  },
  green: {
    backgroundColor: '#45ae57',
    color: theme.palette.getContrastText('#45ae57'),
  },
  orange: {
    backgroundColor: '#ff9404',
    color: theme.palette.getContrastText('#ff9404'),
  },
  red: {
    color: theme.palette.getContrastText('#e7474e'),
    backgroundColor: '#e7474e',
  },
}));

const TaskQueue = ({
  integration,
  group,
  task_type,
  task_direction,
  distribution_type,
  label,
  low,
  high,
}) => {
  const classes = useStyles();

  let mounted = true;
  const [count, setCount] = useState(0);
  const taskQueueCounter = async () => {
    const response = await getTaskQueues({
      integration,
      group,
      task_type,
      task_direction,
      distribution_type,
    })
      .then(res => res.json())
      .then(data => {
        mounted && setCount(data?.tasks_counter);
      })
      .catch(err => console.error(err));

    return response;
  };

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    mounted && taskQueueCounter();
    const interval = setInterval(taskQueueCounter, 15000);
    return () => {
      clearInterval(interval);
      mounted = false;
    };
  }, [integration, group, task_type, task_direction, distribution_type]);

  const getColorForCount = count => {
    switch (true) {
      case count < low:
        return classes.green;
      case count < high && count >= low:
        return classes.orange;
      case count >= high:
        return classes.red;

      default:
        classes.green;
    }
  };

  return (
    <Grid
      item
      style={{ width: 'fit-content', padding: '0px 8px' }}
      classes={{ root: classes.queue_item }}
      container
      direction="column"
      spacing={2}
    >
      <Grid item>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item>
        <Typography
          classes={{ root: classes.count }}
          className={getColorForCount(count)}
        >
          {count}
        </Typography>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state, ownprops) => {
  const { userState = {} } = state;
  const { integration } = userState;

  const { task_queue = {} } = ownprops;

  const {
    group,
    task_type,
    task_direction,
    distribution_type,
    label,
    color = {},
  } = task_queue;

  const { low = 5, high = 10 } = color;

  return {
    integration,
    group,
    task_type,
    task_direction,
    distribution_type,
    label,
    low,
    high,
  };
};

const mapDispatchToProps = dispatch => ({
  expandSidebar: expand => {
    dispatch(expandSidebar(expand));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskQueue);
