import React from 'react';
import { BoundaryError } from '../../reusable_components/boundaryerror';
import AgentTask from './AgentTask';

const AgentTasks = props => {
  const { tasks, user } = props;

  return (
    <BoundaryError>
      <>
        {tasks &&
          tasks.map(task => {
            return (
              <AgentTask key={task.id} task={task} user={user} {...props} />
            );
          })}
      </>
    </BoundaryError>
  );
};

export default AgentTasks;
