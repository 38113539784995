import React, { Component, forwardRef } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { Button } from '../reusable_components';
import { mergeClasses, parseJSONPath } from '../../utils/helpers';
import styles from './details.scss';
import { isWebapp } from '../../utils/webapp/user_access_control';
import { createTaskEvent } from '../../utils/webapp/extension_event_handler_adapter';

const ToolTippedButton = forwardRef((props, ref) => {
  const {
    onTouchStart,
    onTouchEnd,
    onMouseOver,
    onMouseLeave,
    onFocus,
    onBlur,
  } = props;
  return (
    <div
      {...{
        onTouchStart,
        onTouchEnd,
        onMouseOver,
        onMouseLeave,
        onFocus,
        onBlur,
      }}
      ref={ref}
    >
      <Button {...props}></Button>
    </div>
  );
});

class CustomBtn extends Component {
  constructor(props) {
    super(props);
    this.render = this.render.bind(this);
    this.triggerAction = this.triggerAction.bind(this);
  }
  render = () => {
    const { label, textLabel, CustomIcon, task } = this.props;
    let parsedValue;
    if (/^\$.*/g.test(textLabel)) parsedValue = parseJSONPath(task, textLabel);
    else parsedValue = textLabel;

    return (
      <>
        <Grid item>
          {CustomIcon ? (
            <Tooltip title={label} arrow placement="top">
              <ToolTippedButton
                onClickHandler={this.triggerAction}
                className={mergeClasses(styles.custom, styles[CustomIcon])}
              ></ToolTippedButton>
            </Tooltip>
          ) : (
            <Button onClickHandler={this.triggerAction}>{label}</Button>
          )}
        </Grid>
        <Grid item>
          <div
            className={styles.name}
            onClick={this.triggerAction}
            data-tooltip={label}
          >
            {parsedValue || (task.contact && task.contact.name)}
          </div>
        </Grid>
      </>
    );
  };

  triggerAction = () => {
    const { id, task } = this.props;

    if (!isWebapp()) {
      window.electron.ipcRenderer.send('trigger', id, task);
    } else {
      document.dispatchEvent(createTaskEvent(id, task));
    }
  };
}

export default CustomBtn;
