export const vmState = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_VM_RECORDINGS': {
      const { data } = action;
      const newState = [...data];
      return newState;
    }
    case 'ADD_NEW_VM': {
      const newState = [...state];
      const newVM = {
        name: `Name #${newState.length + 1}`,
        type: action.recordingType || `Type #${newState.length + 1}`,
      };
      newState.push(newVM);
      return newState;
    }
    case 'DELETE_VM_BY_ID': {
      const newState = [...state].filter(vm => vm.id !== action.recording_id);
      return newState;
    }
    case 'DELETE_VM_BY_NAME': {
      const newState = [...state].filter(vm => vm.name !== action.name);
      return newState;
    }
    case 'UPDATE_VM_LABEL': {
      const { name, label } = action;
      const newState = [...state].map(vm => {
        vm.name === name && (vm.label = label);
      });
      return newState;
    }

    default:
      return state;
  }
};
