import React, { Fragment } from 'react';
import styles from './Recipient.scss';

export const Recipient = ({ id, name, email, type }) => {
  return (
    <div className={styles.recipient_wrapper}>
      <div className={styles.name}>{name}</div>
      <div className={styles.email}>&lt; {email} &gt;</div>
    </div>
  );
};

export default Recipient;
