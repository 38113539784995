import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import Timer from 'react-compound-timer';
import styles from './VM.scss';
import {
  ExitToApp as LeaveVM,
  ArrowDropUp as DropUp,
  ErrorOutline as ErrorIcon,
} from '@material-ui/icons';

import Menu from '@material-ui/core/Menu';
import { StyledMenuItem } from '../reusable_components/StyledMenuItem';
import { Spinner } from '../reusable_components/Spinner';
import { updateErrorMsg } from '../../store/action/error';

const VM = ({
  vmState,
  services,
  task,
  updateErrorMsg,
  errorState,
  deleteErrorMsg,
  countdown,
  partJOined,
  userTelephonyProvider,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  let filteredVMs = vmState || [];
  if (userTelephonyProvider === 'genesys') {
    filteredVMs = filteredVMs.filter(vm => vm.voice_hub_ivr_id);
  }

  const defaultVM =
    filteredVMs.find(vm => vm.name === task?.default_one_click_vm) ||
    filteredVMs[0];

  const [leaveButtonLoading, setleaveButtonLoading] = useState(false);
  const [dropupName, setDropupName] = useState(defaultVM ? defaultVM.name : '');
  const [dropupType, setDropupType] = useState(defaultVM ? defaultVM.type : '');
  const [recordingId, setRecordingId] = useState(defaultVM ? defaultVM.id : '');
  const [selectedByAgent, setSelectedByAgent] = useState(false);
  const [showVM, setShowVM] = useState(true);

  if (!selectedByAgent && defaultVM && defaultVM.name !== dropupName) {
    setDropupName(defaultVM.name);
    setDropupType(defaultVM.type);
    setRecordingId(defaultVM.id);
  }

  const handleMenuClick = e => {
    e.preventDefault();
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = e => {
    e.preventDefault();
    setAnchorEl(null);
  };

  const handleMenuItemClick = (e, name, type, id) => {
    e.preventDefault();
    console.log('selected vm: ', name, type, id);
    setDropupName(name);
    setDropupType(type);
    setRecordingId(id);
    setAnchorEl(null);
    setSelectedByAgent(true);
  };

  return (
    <>
      {true && (
        <>
          {errorState.play_vm && (
            <div
              className={styles.error}
              onClick={e => {
                deleteErrorMsg({ error_type: 'play_vm' });
              }}
            >
              <ErrorIcon className={styles.error_icon} />
              <div className={styles.error_msg}>{errorState.play_vm}</div>
            </div>
          )}
          {showVM && (
            <div className={styles.vm_container}>
              <div className={styles.vm_dropup_wrapper}>
                <div className={styles.vm_dropup_name}>
                  {dropupName || dropupType}
                </div>
                <DropUp
                  onClick={handleMenuClick}
                  className={styles.vm_dropup_icon}
                />
              </div>

              <Menu
                id="vm-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                {filteredVMs.map(vm => {
                  if (vm.url)
                    return (
                      <StyledMenuItem
                        key={vm.name}
                        onClick={e =>
                          handleMenuItemClick(e, vm.name, vm.type, vm.id)
                        }
                      >
                        {vm.name}
                      </StyledMenuItem>
                    );
                })}
              </Menu>
              <div className={styles.leave_vm_wrapper}>
                <div className={styles.leave_vm_msg}>Leave message</div>
                {leaveButtonLoading ? (
                  <Spinner size={20} />
                ) : (
                  <LeaveVM
                    className={styles.leave_vm_icon}
                    onClick={async e => {
                      console.log('Leave msg', recordingId);
                      setleaveButtonLoading(true);
                      await services
                        .playVMRecording({
                          recording_id: recordingId,
                          task_id: task.id,
                        })
                        .then(res => setleaveButtonLoading(false))
                        .catch(err => {
                          console.error('Error while playing VM', err);
                          setleaveButtonLoading(false);
                          updateErrorMsg({
                            error_msg: `Error while playing VM`,
                            error_type: 'play_vm',
                          });
                        });
                    }}
                  />
                )}
              </div>

              <div className={styles.timer}>
                <Timer
                  initialTime={countdown}
                  direction="backward"
                  checkpoints={[
                    {
                      time: 0,
                      callback: () => {
                        console.log('Timer end');
                        setShowVM(false);
                      },
                    },
                  ]}
                >
                  <Timer.Minutes
                    formatValue={value => (value == 0 ? null : `${value}m`)}
                  />
                  <Timer.Seconds
                    formatValue={value => (value == 0 ? null : `${value}s`)}
                  />
                </Timer>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    vmState: state.vmState,
    errorState: state.errorState,
    countdown:
      (state.integrationState.recording_cant_be_played_after || 30) * 1000,
    userTelephonyProvider: state.userState.telephonyProvider,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateErrorMsg: ({ error_msg, error_type }) => {
      dispatch(updateErrorMsg({ error_msg, error_type }));
    },
    deleteErrorMsg: ({ error_type }) => {
      dispatch(deleteErrorMsg({ error_type }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VM);
