const log = async (logLevel, description, messageJson) => {
  try {
    const res = await fetch(`/api/log/${logLevel}`, {
      method: 'POST',
      body: JSON.stringify({
        description,
        payload: messageJson
      }),
      headers: {
        'Content-type': 'application/json'
      }
    })
    if (res.status > 200) {
      console.warn(res.statusText);
    }
  } catch(err) {
    console.log(err);
  }
};

export const logger = {
  info: async (description, messageJson) => {
    log('info', description, messageJson);
  },
  warn: async (description, messageJson) => {
    log('warn', description, messageJson);
  },
  error: async (description, messageJson) => {
    log('error', description, messageJson);
  },
}