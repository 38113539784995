import React from 'react';
import styles from './SMS.scss';

const errorStatuses = [
  'invalid',
  'failed',
  'suppressed',
  'withheld',
  'undelivered',
];

const SMS = props => {
  const {
    direction,
    status,
    text,
    sender,
    recipient,
    name,
    created_at,
    mergeClasses,
    displayTimestamp,
    taskCreated,
    error,
  } = props;

  const tail_direction = direction === 'inbound' ? 'left_top' : 'right_top';
  const archived = created_at < taskCreated && 'archived';
  const error_style = error ? styles.error : '';

  return (
    <div
      className={mergeClasses(
        styles.sms_box,
        styles.sms_tail,
        styles[direction],
        styles[tail_direction],
        styles[archived]
      )}
    >
      {(error || errorStatuses.includes(status?.toLowerCase())) && (
        <div className={styles.sms_error}>
          {error || <>{`SMS ${status}, NOT DELIVERED`}</>}
        </div>
      )}
      <div className={mergeClasses(styles.sms_text, error_style)}>{text}</div>
      <div className={styles.metadata_wrapper}>
        <div className={styles.name}>{name ? name : sender}</div>
        <div className={styles.timestamp}>{displayTimestamp(created_at)}</div>
      </div>
    </div>
  );
};

export default SMS;
