export const resetDirectory = ({
  tab,
  forwardSms,
  callId,
  display,
  searchTerm,
  addNumber,
  fetchAgentInterval,
  task,
}) => {
  return {
    type: 'RESET_DIRECTORY',
    tab,
    forwardSms,
    callId,
    display,
    searchTerm,
    addNumber,
    fetchAgentInterval,
    task,
  };
};

export const updateDirectoryState = directoryState => {
  return {
    type: 'UPDATE_DIRECTORY',
    directoryState,
  };
};

export const forwardTask = id => {
  return {
    type: 'FORWARD_THIS_TASK',
    id,
  };
};
